import { ROUTE_LOGIN, ROUTE_PANES_VIEWPORT } from '../routes';
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import { GLOBAL_QUERY_PARAMS } from '../../useGlobalQueryParams';
import NoAuthScreenWrapper from '../../../components/auth/noAuthScreenWrapper';
import PaneContentLoading from '../../../components/panes/paneContentLoading';
import { PaneKeys } from '../../../components/panes/paneTypes/paneKeys';
import { fastApiParamsSerializer } from '../../api/subscriptionHelpers';
import { useProjectsGet } from '../../api/subscriptions/projects/projectsGet';

interface MatchParams {
  projectId: string;
}

/** Forwards a user to a project that was shared with him */
export default function ProjectUrlForwarder({ match }: RouteComponentProps<MatchParams>) {
  const projectId = parseInt(match.params.projectId);
  const { data: project, isError } = useProjectsGet(projectId);
  const history = useHistory();

  useEffect(() => {
    if (!project) return;

    const url =
      ROUTE_PANES_VIEWPORT +
      '?' +
      fastApiParamsSerializer({
        [GLOBAL_QUERY_PARAMS.panes]: [PaneKeys.projectsList, PaneKeys.entriesList],
        [GLOBAL_QUERY_PARAMS.entriesList_directoryId]: project.directory_id,
      });

    history.replace(url);
  }, [!!project]);

  if (isError) return <Redirect to={ROUTE_LOGIN + '?nextUrl=' + window.location.href} />;

  return (
    <NoAuthScreenWrapper>
      <PaneContentLoading />
    </NoAuthScreenWrapper>
  );
}
