import { Button, makeStyles } from '@material-ui/core';
import {
  useQueryParam_tagInstancePredictions_directoryId,
  useQueryParam_tagInstancePredictions_documentId,
  useQueryParam_tagInstancePredictions_entryTagId,
  useQueryParam_tagInstancePredictions_orderBy,
  useQueryParam_tag_tagId,
} from '../../../../controllers/useGlobalQueryParams';

import SearchResultsItem from '../search/searchResultsItem';
import Codefy from '../../../../codefy';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useMeasure } from 'react-use';
import { useOpenSavedSearchTermsDialog } from '../../../dialogs/edit/editTagSavedSearchTermsDialog';
import { useTagInstancePredictionsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';
import { useUserSetting_assistantResults_snippetVariant } from '../../../../controllers/api/subscriptions/users/userSettings';

const useStyles = makeStyles(() => ({
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function AssistantResults() {
  const { t } = useTranslation();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType: 'annotation' });
  const [tagInstancePredictions_documentId] = useQueryParam_tagInstancePredictions_documentId();
  const [tagInstancePredictions_directoryId] = useQueryParam_tagInstancePredictions_directoryId();
  const [tagInstancePredictions_entryTagId] = useQueryParam_tagInstancePredictions_entryTagId();
  const [tagInstancePredictions_orderBy] = useQueryParam_tagInstancePredictions_orderBy();

  const { id: currentCaseId } = useCurrentCase();

  const openSavedSearchTermsDialog = useOpenSavedSearchTermsDialog();

  const [
    userSetting_assistantResults_snippetVariant,
  ] = useUserSetting_assistantResults_snippetVariant();

  const { data: tag } = useTagsGet({ tag_id: tag_tagId, case_id: currentCaseId });

  const {
    data: resultsPages,
    isLoading: predictionsLoading,
    fetchNextPage: predictionsFetchMore,
    hasNextPage,
  } = useTagInstancePredictionsList({
    tag_id: tag_tagId,
    document_ids: tagInstancePredictions_documentId
      ? [tagInstancePredictions_documentId]
      : undefined,
    directory_ids: tagInstancePredictions_directoryId
      ? [tagInstancePredictions_directoryId]
      : undefined,
    entry_tag_ids: tagInstancePredictions_entryTagId
      ? [tagInstancePredictions_entryTagId]
      : undefined,
    order_by: tagInstancePredictions_orderBy,
  });

  const results =
    resultsPages?.pages.reduce((acc: Codefy.Objects.SearchResult[], cur) => {
      for (const result of cur.results) {
        acc.push(result);
      }
      return acc;
    }, []) || [];

  if (!tag_tagId) {
    return <PaneContentEmpty text={t('taglist.tagInstancePredictionsSelectTag')} />;
  }

  if (!tag || predictionsLoading) {
    return <PaneContentLoading />;
  }

  const renderResult = (index: number) => {
    if (!results[index]) return <span></span>;
    const searchResult = results[index];
    return (
      <SearchResultsItem
        searchResult={searchResult}
        index={index}
        key={'pane-document-' + searchResult.document_id + 'part-' + searchResult.part_ix}
        isInstasearchResult={false}
        reviewAssistantTagId={tag_tagId}
        defaultSnippetVariant={userSetting_assistantResults_snippetVariant || 'image'}
      />
    );
  };

  if (!tag?.saved_search_terms || tag.saved_search_terms.length === 0) {
    return (
      <PaneContentEmpty
        text={t('useTagMenuEntries.noSavedSearchTermsYet')}
        button={
          <Button
            className={globalClasses.dialogButton}
            onClick={() => openSavedSearchTermsDialog({ tag_id: tag?.id })}>
            {t('useTagMenuEntries.saveSearchTerm')}
          </Button>
        }
      />
    );
  }

  if (results.length === 0) {
    return <PaneContentEmpty text={t('searchBar.noResults')} />;
  }

  return (
    <div className={classes.virtuoso} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={results.length || 0}
        next={predictionsFetchMore}
        height={height - 2}
        hasMore={!!hasNextPage}
        loader={<PaneContentLoading />}>
        {results.map((_, index) => renderResult(index))}
      </InfiniteScroll>
    </div>
  );
}
