import { useIsPaneOpen, useQueryParam_tag_tagId } from '../../controllers/useGlobalQueryParams';

import { PaneKeys } from '../panes/paneTypes/paneKeys';
import { useEffect } from 'react';
import { usePaneActions } from '../panes/usePaneActions';
import { useTagsGet } from '../../controllers/api/subscriptions/taglists/tags/tagsGet';

/** Opens the description for the annotation tag, if there is one */
export function AnnotationTagDescriptionListener() {
  const [annotationTagId] = useQueryParam_tag_tagId({ taglistType: 'annotation' });
  const { data: annotationTag } = useTagsGet({ tag_id: annotationTagId });
  const paneActions = usePaneActions();
  const annotationTagPaneOpen = useIsPaneOpen(PaneKeys.annotationTag);

  useEffect(() => {
    if (!annotationTagPaneOpen) {
      paneActions.closePanes([PaneKeys.annotationTagDescription]);
    }

    if (!annotationTag?.id) return;
    if (!annotationTag.description) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.annotationTagDescription,
      inBackground: true,
    });
  }, [annotationTag?.id, annotationTagPaneOpen]);

  return null;
}
