import { Badge } from '@material-ui/core';
import Codefy from '../../../../../codefy';
import { GenericNestableMenuEntry } from '../../../../menus/genericNestableMenuEntry';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { PaneKeys } from '../../paneKeys';
import React from 'react';
import useCurrentCase from '../../../../../hooks/useCurrentCase';
import { useDirectoriesGet } from '../../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../../controllers/api/subscriptions/documents/documentsGet';
import { usePaneActions } from '../../../usePaneActions';
import { useTagInstancesList } from '../../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { useTranslation } from 'react-i18next';

/** Open all annotations inside a document/directory*/
export function AnnotationsMenuEntry({
  directoryId,
  documentId,
}: {
  directoryId?: Codefy.Objects.Directory['id'];
  documentId?: Codefy.Objects.Document['id'];
}): GenericNestableMenuEntry | undefined {
  const paneActions = usePaneActions();
  const { t } = useTranslation();

  const { data: directory } = useDirectoriesGet(directoryId);
  const { data: document } = useDocumentsGet(documentId);
  const { id: currentCaseId } = useCurrentCase();

  const name =
    document?.path.entry_name ||
    directory?.path?.entry_name ||
    directory?.path?.project_name ||
    '...';

  const { data: tagInstancesPages, isFetching } = useTagInstancesList({
    directory_ids: directoryId ? [directoryId] : undefined,
    document_ids: documentId ? [documentId] : undefined,
    case_ids: currentCaseId ? [currentCaseId] : undefined,
    types: ['annotation'],
  });

  const annotationsCount = !isFetching ? tagInstancesPages?.pages[0]?.num_tag_instances || 0 : 0;

  return {
    key: 'annotations',
    icon: (
      <Badge
        badgeContent={annotationsCount}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <LabelOutlinedIcon />
      </Badge>
    ),
    text: t('annotations.showAnnotationsInside', { name }),
    onClick: () => {
      paneActions.addOrUpdatePane({
        closePanes: [PaneKeys.annotationTaglist],
        paneKey: PaneKeys.annotationTag,
        params: {
          annotationTag_directoryId: directoryId,
          annotationTag_documentId: documentId,
        },
      });
    },
  };
}
