import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
} from '../../../../controllers/useGlobalQueryParams';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import SearchIcon from '../../../icons/search';
import { setSearchBarInputValue } from '../../../navbar/searchBar/setSearchBarInputValue';
import { useDispatch } from 'react-redux';
import { usePaneActions } from '../../usePaneActions';
import Highlighter from 'react-highlight-words';

const useStyles = makeStyles(() =>
  createStyles({
    listItemIcon: { marginRight: '-23px', marginBottom: '-3px' },
    listItemSpan: {
      fontSize: '100%',
      fontWeight: 'bold',
      color: COLORS.primary,
    },
    highlight: {
      backgroundColor: COLORS.textHighlightBackground,
      color: COLORS.primary,
    },
    listItemFreq: {
      opacity: 0.3,
      marginLeft: '5px',
      color: 'black',
    },
    linkStyle: {
      textDecoration: 'none',
    },
  }),
);
// eslint-disable-next-line react/display-name
const Autocomplete = ({ autocomplete, query }: { autocomplete: string; query: string }) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const paneActions = usePaneActions();

  const [searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId] = useQueryParam_searchBar_documentId();

  const onClick = () => {
    document.getElementById('search-results')?.scrollTo(0, 0);
    dispatch({
      type: 'setSearch',
      open: false,
      query: autocomplete,
    });
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.search,
      params: {
        search_query: autocomplete,
        search_directoryId: searchBar_directoryId,
        search_documentId: searchBar_documentId,
      },
    });
    setSearchBarInputValue(autocomplete);
  };

  return (
    <div onClick={onClick} className={classes.linkStyle} data-e2e-id="autocomplete">
      <ListItem button>
        <ListItemIcon className={classes.listItemIcon}>
          <SearchIcon />
        </ListItemIcon>

        <ListItemText
          className={globalClasses.listEntry}
          primary={
            <span className={classes.listItemSpan}>
              <Highlighter
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={autocomplete}
                unhighlightStyle={{ fontWeight: 'bold' }}
                highlightStyle={{
                  //backgroundColor: COLORS.textHighlightBackground,
                  backgroundColor: '#00000000',
                  color: COLORS.primary,
                  fontWeight: 'normal',
                }}
              />
            </span>
          }
        />
      </ListItem>
    </div>
  );
};

export default Autocomplete;
