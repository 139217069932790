import Codefy from '../../../../codefy';
import { ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH } from './entriesDelete';
import { executeAction } from '../actionHelpers';

export const entriesTrash = (data: {
  entry_ids: Codefy.Objects.Entry['id'][];
  trashed?: boolean;
}) =>
  executeAction<Codefy.Objects.Entry>({
    config: { url: '/api/v1/entries/trash', method: 'POST' },
    data,
    queryKeysToRefresh: ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH,
  });
