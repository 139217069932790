import { Box, Button, DialogActions, DialogProps, Grid } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import Codefy from '../../codefy';
import { E2E } from '../../testing/common/data-e2e-ids';
import { GenericDialog } from './genericDialog';
import React from 'react';
import clsx from 'clsx';
import { useGlobalStyles } from '../../globalThemeSettings';
//@ts-ignore
import useKeyPress from 'react-use-keypress';
import { useTranslation } from 'react-i18next';

export default function GenericDeleteDialog({
  open,
  title,
  icon,
  content,
  onDelete,
  onClose,
  dialogProps,
  deleteButtonText,
  writePermission,
  owner,
}: {
  open: boolean;
  title: string;
  icon?: JSX.Element;
  content?: JSX.Element;
  onDelete: () => void;
  onClose: () => void;
  dialogProps?: DialogProps;
  deleteButtonText?: string;
  writePermission?: boolean;
  owner?: Codefy.Objects.User;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const onSubmit = () => {
    onDelete();
    onClose();
  };

  /* Allow pressing "Enter" to confirm the deletion. */
  useKeyPress('Enter', () => {
    /* This `if` is EXTREMELY important! Otherwise, when pressing Enter, the user would delete
    everything potentially deletable, which is most likely all projects and even his account */
    if (!open) return;
    if (writePermission === false) return null;
    onSubmit();
  });

  if (writePermission === false) {
    if (!owner) return null;
    return (
      <GenericDialog title={title} onClose={onClose} open={open} {...dialogProps}>
        <Alert severity="info">
          This entry can only be deleted by <strong>{owner?.name}</strong> (
          <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
          ).
        </Alert>
      </GenericDialog>
    );
  }

  return (
    <GenericDialog title={title} onClose={onClose} open={open} {...dialogProps}>
      <Box>
        <Grid container alignItems="center" spacing={1}>
          {icon && (
            <Grid item>
              <Box mb={-0.5}>{icon}</Box>
            </Grid>
          )}
          {content && <Grid item>{content}</Grid>}
        </Grid>
        <Box mt={1}>
          <DialogActions>
            <Button
              data-e2e-id={E2E.dialog_generic_delete_confirm}
              size="small"
              onClick={onSubmit}
              className={clsx(globalClasses.dialogButton, globalClasses.deleteButton)}>
              {deleteButtonText || t('deleteDialog.delete')}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </GenericDialog>
  );
}
