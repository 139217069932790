import Codefy from '../../../../codefy';
import { PUBLICATIONS_LIST_KEY_TYPE } from '../../subscriptions/publications/publicationsList';
import { executeAction } from '../actionHelpers';

export type PublicationsCreateRequestType = {
  project_id: Codefy.Objects.Project['id'];
  target_url: Codefy.Objects.Publication['target_url'];
  title: Codefy.Objects.Publication['title'];
  description: Codefy.Objects.Publication['description'];
  image_blob: File;
};

export const publicationsCreate = (data: PublicationsCreateRequestType) =>
  executeAction<Codefy.Objects.Publication>({
    config: { url: '/api/v1/publications/create', method: 'POST' },
    data,
    queryKeysToRefresh: [PUBLICATIONS_LIST_KEY_TYPE],
  });
