import { PdfViewerVariantType, getPdfPageElementId } from './pdfViewer';
import { useEffect, useState } from 'react';

import Codefy from '../../../../codefy';
import { PDF_PAGE_HIGHLIGHT_BOXES_ID } from './pdfPageHighlightBoxes';
import stringify from 'fast-stringify';
import { useQueryParam_pdfViewer_boxes } from '../../../../controllers/useGlobalQueryParams';

export function usePdfScrollToPageOnExternalTrigger({
  pdfViewerVariant,
  pdfViewer_documentId,
  pdfViewer_page,
}: {
  pdfViewerVariant: PdfViewerVariantType;
  pdfViewer_documentId?: number;
  pdfViewer_page?: number;
}) {
  const [alreadyScrolled, setAlreadyScrolled] = useState<boolean>(false);

  const [highlightBoxes] = useQueryParam_pdfViewer_boxes(pdfViewerVariant);
  const boxes = highlightBoxes as Codefy.Objects.Box[] | undefined;

  /* When the highlight boxes change (e.g. because the user clicked on another search result), start
 scrolling again */
  useEffect(() => {
    if (highlightBoxes) {
      setAlreadyScrolled(false);
    }
  }, [stringify(highlightBoxes)]);

  useEffect(() => {
    if (!pdfViewer_page || !pdfViewer_documentId || alreadyScrolled) return;

    /** Since the pdfViewer opens on page 1 and thus updates the pdfViewer_page very quickly to 1,
     * skip operation if we are on page 1 so that the initial scroll to another page number can be
     * done */
    if (pdfViewer_page === 1 && !boxes) return;

    const checkPageExist = setInterval(() => {
      const targetPage = boxes && boxes?.length > 0 ? boxes[0].page : pdfViewer_page;
      const targetPageElement = document.getElementById(
        getPdfPageElementId(pdfViewerVariant, pdfViewer_documentId, targetPage - 1),
      );
      if (targetPageElement) {
        targetPageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        clearInterval(checkPageExist);
        setAlreadyScrolled(true);

        const checkHighlightBoxExist = setInterval(() => {
          const target = document.getElementById(PDF_PAGE_HIGHLIGHT_BOXES_ID);
          if (target) {
            clearInterval(checkHighlightBoxExist);
            target.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              /* No inline alignment because otherwise there is interference with our "panes" concept
              and the panes might be jumping slightly left and right when the user is clicking
              through search results. */
            });
          }
        }, 200);
      }
    }, 200);
  }, [pdfViewer_page, alreadyScrolled, boxes]);
}
