import Codefy from '../../../../codefy';
import { QueryKey } from 'react-query';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export type EntriesListRequestParams = {
  directory_ids?: Codefy.Objects.Directory['id'][];
  /** "created_at" | "name" | "mimetype" | "num_pages" | "trashed_at" */
  order_by?: 'created_at' | 'name' | 'mimetype' | 'num_pages' | 'trashed_at';
  descending?: boolean;
  sha256?: string;
  name?: string;
  starred?: boolean;
  offset?: number;
  limit?: number;
  mimetypes?: Codefy.Objects.EntryMimeType[];
  recursive?: boolean;
  hide_unprocessed_documents?: boolean;
  trashed?: boolean;
};

export type EntriesListReturnType = {
  entries: Codefy.Objects.Entry[];
  num_entries: number;
  offset: number;
};

export const ENTRIES_LIST_KEY_TYPE = 'entriesList';

export const entriesListKey = (params: EntriesListRequestParams): QueryKey => [
  ENTRIES_LIST_KEY_TYPE,
  params,
];

export const useEntriesList = (params: EntriesListRequestParams) =>
  useCreateInfiniteQuery<EntriesListReturnType>(
    entriesListKey(params),
    params.limit || 60,
    '/api/v1/entries/list',
    'GET',
    params,
    'entries',
    true,
  );
