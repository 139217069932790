import { Box, Tooltip } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import { useGlobalStyles } from '../../globalThemeSettings';

export default function TagLabelName({ name, maxWidth }: { name: string; maxWidth?: number }) {
  const globalClasses = useGlobalStyles();

  const [showTooltip, setShowTooltip] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  /* If the text overflows, show a tooltip. Otherwise, don't as it would be extremely annoying every
time the user hovers over a tag label */
  React.useLayoutEffect(() => {
    if (!ref?.current) return;

    if (ref?.current?.clientWidth < ref?.current?.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  return (
    <Tooltip title={showTooltip ? name : ''} enterDelay={1000}>
      <Box ml={0.5} mr={0.3} maxWidth={maxWidth || 150}>
        <div
          ref={ref}
          className={clsx(globalClasses.tagLabelForegroundText, globalClasses.overflowEllipsis)}>
          {name}
        </div>
      </Box>
    </Tooltip>
  );
}
