import React, { useState } from 'react';

import Codefy from '../../../codefy';
import FolderIcon from '@material-ui/icons/Folder';
import GenericDeleteDialog from '../genericDeleteDialog';
import { projectsTrash } from '../../../controllers/api/actions/projects/projectsTrash';
import { useBetween } from 'use-between';
import { useProjectsGet } from '../../../controllers/api/subscriptions/projects/projectsGet';
import { useTranslation } from 'react-i18next';
import { useTrashModeStrings } from '../../../hooks/useTrashModeVariants';

type DialogDeleteProjectStateType = { projectId: Codefy.Objects.Project['id'] };

const useDialogDeleteProjectState = () => useState<DialogDeleteProjectStateType>();

const useSharedDialogDeleteProjectState = () => useBetween(useDialogDeleteProjectState);

export const DeleteProjectDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteProjectState();

  const { data: project } = useProjectsGet(state?.projectId);

  const trashModeStrings = useTrashModeStrings();

  const onClose = () => setState(undefined);

  const onTrash = () => {
    if (!state?.projectId) return;
    projectsTrash({ project_ids: [state.projectId] });
  };

  if (!project?.name) return null;

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      icon={<FolderIcon />}
      title={t('deleteDialog.trashWorkspace')}
      content={<span>Workspace {project.name}</span>}
      deleteButtonText={trashModeStrings('trash')}
      onDelete={onTrash}
      onClose={onClose}
    />
  );
};

export const useOpenDeleteProjectDialog = () => {
  const [, setState] = useSharedDialogDeleteProjectState();
  return (state: DialogDeleteProjectStateType) => () => setState(state);
};
