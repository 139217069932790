import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../../subscriptions/annotations/annotationsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../../../subscriptions/annotations/annotationsGet';
import Codefy from '../../../../../../codefy';
import { ENTRIES_GET_KEY_TYPE } from '../../../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../../../subscriptions/entries/entriesList';
import { TAGLISTS_GET_KEY_TYPE } from '../../../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../../../actionHelpers';
import { tagInstancePredictionsListKey } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';

export const tagInstancesCreate = (
  data: {
    tag_id: Codefy.Objects.Tag['id'];
    /** Where did the user create the tag instance from? Used for metrics. */
    source: string;
    before_pos?: string;
  } & (
    | {
        type: 'annotation';
        annotation_id: Codefy.Objects.Annotation['id'];
      }
    | {
        type: 'entry';
        entry_id: Codefy.Objects.Entry['id'];
      }
    | {
        type: 'annotation_tag';
        annotation_tag_id: Codefy.Objects.Tag['id'];
        case_id: Codefy.Objects.Directory['id'];
      }
  ),
) =>
  executeAction<Codefy.Objects.TagInstance>({
    config: { url: '/api/v1/taglists/tags/instances/create', method: 'POST' },
    data,
    queryKeysToRefresh: [
      TAGLISTS_GET_KEY_TYPE,
      TAG_INSTANCES_LIST_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
      ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
      ANNOTATIONS_LIST_KEY_TYPE,
      ANNOTATIONS_GET_KEY_TYPE,
      ENTRIES_LIST_KEY_TYPE,
      ENTRIES_GET_KEY_TYPE,
      TAG_INSTANCES_GET_KEY_TYPE,
      tagInstancePredictionsListKey({ tag_id: data.tag_id }),
    ],
  });
