import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export type SearchSnippetParams = {
  document_id: Codefy.Objects.Document['id'];
  highlights: Codefy.Objects.Highlight[];
};

export type SearchSnippetResponse = string; // base64

export const SEARCH_SNIPPET_URL = '/api/v1/search/snippet';

export const SEARCH_SNIPPET_KEY_TYPE = 'searchSnippet';

export const searchSnippetKey = (
  params: SearchSnippetParams,
): [string, Record<string, any> | undefined] => [SEARCH_SNIPPET_KEY_TYPE, params];

export const useSearchSnippet = (params: SearchSnippetParams) =>
  useQuery(searchSnippetKey(params), async () => {
    if (!(params.document_id && params.highlights)) return undefined;
    const { data } = await axios(SEARCH_SNIPPET_URL, {
      method: 'POST',
      data: fastApiDataSerializer(params || {}),
    });
    return data as SearchSnippetResponse;
  });
