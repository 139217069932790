import Codefy from '../../../../codefy';
import { PROJECTS_GET_KEY_TYPE } from '../../subscriptions/projects/projectsGet';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { PermissionAccessLevel } from '../../../../components/dialogs/share/shareProjectDialog';
import { executeAction } from '../actionHelpers';

export const projectsPermission = (data: {
  project_id: Codefy.Objects.Project['id'];
  email: Codefy.Objects.User['email'];
  access_level: PermissionAccessLevel | undefined;
}) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/permission', method: 'POST' },
    data: { ...data, email: data.email.trim() },
    queryKeysToRefresh: [PROJECTS_LIST_KEY_TYPE, PROJECTS_GET_KEY_TYPE],
  });
