import {
  GLOBAL_QUERY_PARAMS,
  useQueryParam_focusedPane,
  useQueryParam_panes,
  useQueryParams_collapsedPanes,
} from '../../controllers/useGlobalQueryParams';

import { DEFAULT_PANES } from './panesViewport';
import { ModalKeys } from '../old_modals/modalTypes';
import { PaneKeys } from './paneTypes/paneKeys';
import { connectedPanes } from './usePaneConnected';
import { getPaneElementId } from './pane';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useUpdateUrlParams } from '../../hooks/useUpdateUrlParams';

export function usePaneActions() {
  /*
   Note that the setQueries should be one atomic operation (i.e. don't write several `setQuery`s throughout a
   function) so that the users back button works.

   Note also that the order of the spreads is essential when resetting params!
   */

  const history = useHistory();

  const updateUrlParams = useUpdateUrlParams();
  const [focusedPane, set_focusedPane] = useQueryParam_focusedPane();
  const [panes] = useQueryParam_panes();
  const [collapsedPanes] = useQueryParams_collapsedPanes();

  const getPanes = () => panes || DEFAULT_PANES;
  const getCollapsedPanes = () => collapsedPanes;

  /** Returns the params object necessary to remove all params for a pane. This should ALWAYS be the
   * first parameter when spreading, i.e. {...getParamsToResetPane(pane), ...otherParams}, because
   * otherwise we will overwrite the other params! */
  const getParamsToResetPane = (
    pane: PaneKeys | ModalKeys,
  ): { [key in GLOBAL_QUERY_PARAMS]?: undefined } => {
    const resetParams: { [key: string]: undefined } = {};

    const urlParams = queryString.parse(history.location.search);

    Object.keys(urlParams).forEach((key) => {
      if (key.startsWith(pane + '_')) {
        resetParams[key] = undefined;
      }
    });

    return resetParams;
  };

  const focusPane = (paneToFocus: PaneKeys) => {
    if (focusedPane !== paneToFocus) set_focusedPane(paneToFocus);
    document
      .getElementById(getPaneElementId(paneToFocus))
      ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  const addOrUpdatePane = ({
    paneKey,
    params,
    reset,
    inBackground,
    closePanes,
  }: {
    paneKey: PaneKeys;
    params?: {
      [key in GLOBAL_QUERY_PARAMS]?: any;
    };
    /** Should all other params that are currently set for this pane be removed first? */
    reset?: boolean;
    /** Position the pane should appear in */
    position?: number;
    /** If true, the pane is not focused after adding/updating */
    inBackground?: boolean;
    /** Optionally, also close some panes */
    closePanes?: PaneKeys[];
  }) => {
    let panes = getPanes();
    const collapsedPanes = getCollapsedPanes();

    if (reset) {
      params = { ...getParamsToResetPane(paneKey), ...params };
    }

    if (!panes?.includes(paneKey)) panes.push(paneKey);

    if (closePanes) {
      panes = panes.filter((pane) => !closePanes.includes(pane));
    }

    /* This should be one atomic operation (i.e. don't write several `setQuery`s throughout a
    function) so that the users back button works */
    /* The order of the spreads is essential! */

    updateUrlParams(
      {
        ...params,
        panes,
        collapsedPanes: (collapsedPanes || []).filter((p) => p !== paneKey),
        focusedPane: paneKey,
      },
      'pushIn',
    );

    if (!inBackground) {
      const checkExist = setInterval(function () {
        /** Since React is async, there might be a race condition resulting in the scroll-into-view
         * being executed before the pane gets rendered, therefore we need this workaround */
        if (document.getElementById(getPaneElementId(paneKey))) {
          focusPane(paneKey);
          clearInterval(checkExist);
        }
      }, 100); // check every 100ms
    }
  };

  const toggleCollapsePane = (paneKeyToCollapse: PaneKeys) => {
    const collapsedPanes = getCollapsedPanes();

    if (collapsedPanes?.includes(paneKeyToCollapse)) {
      updateUrlParams(
        {
          collapsedPanes: collapsedPanes.filter((pane) => pane !== paneKeyToCollapse),
          focusedPane: paneKeyToCollapse,
        },
        'pushIn',
      );
    } else {
      updateUrlParams(
        {
          collapsedPanes: [...(collapsedPanes || []), paneKeyToCollapse],
          /** Make the pane focused so that it's clearer to the user where the pane is located now */
          focusedPane: paneKeyToCollapse,
        },
        'pushIn',
      );
    }
  };

  const closePanes = (paneKeysToClose: PaneKeys[]) => {
    let params = { panes: getPanes() };

    for (const paneKeyToClose of paneKeysToClose) {
      /** Connected panes always close themselves together */
      let connectedPanesCloseParams = {};
      let connectedPanesClosePaneKey: PaneKeys[] = [];
      connectedPanes.map(([left, right]) => {
        if (paneKeyToClose === left) {
          connectedPanesCloseParams = {
            ...connectedPanesCloseParams,
            ...getParamsToResetPane(right),
          };
          connectedPanesClosePaneKey = [...connectedPanesClosePaneKey, right];
        }
      });

      params = {
        ...params,
        /* The order of the spreads is essential! */
        ...getParamsToResetPane(paneKeyToClose),
        ...connectedPanesCloseParams,
        panes: params.panes?.filter(
          (pane) => pane !== paneKeyToClose && !connectedPanesClosePaneKey.includes(pane),
        ),
      };
    }

    /* This should be one atomic operation (i.e. don't write several `setQuery`s throughout a
    function) so that the users back button works */
    updateUrlParams(params, 'pushIn');
  };

  return {
    addOrUpdatePane,
    toggleCollapsePane,
    closePanes,
    focusPane,
  };
}
