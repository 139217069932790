import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import Codefy from '../../../codefy';
import { DOCUMENTS_GET_URL } from '../../api/subscriptions/documents/documentsGet';
import NoAuthScreenWrapper from '../../../components/auth/noAuthScreenWrapper';
import PaneContentLoading from '../../../components/panes/paneContentLoading';
import { PaneKeys } from '../../../components/panes/paneTypes/paneKeys';
import { Redirect } from 'react-router';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../api/subscriptionHelpers';

/** This has abbreviated key names to save a few characters and shorten the resulting link */
export type SharedSelection = {
  /** Boxes */
  b: Codefy.Objects.Box[];
  /** Document_id */
  d: Codefy.Objects.Document['id'];
};

/** A component that redirects the user from the selection link to the selection */
const SelectionUrlForwarder = () => {
  const [redirectTo, setRedirectTo] = useState<string>();
  const [query] = useQueryParams({ s: StringParam });

  /* Run once to determine the link we need to redirect to */
  useEffect(() => {
    /* Needs to be extracted into a function since we are using async */
    const run = async () => {
      const selectionZip = query.s;
      if (selectionZip) {
        const selection = JSON.parse(atob(selectionZip)) as SharedSelection;

        const _documentResponse = await axios.get<Codefy.Objects.Document>(DOCUMENTS_GET_URL, {
          params: { document_id: selection.d },
        });
        const _document = _documentResponse.data;

        setRedirectTo(
          `/?` +
            fastApiParamsSerializer({
              panes: [PaneKeys.projectsList, PaneKeys.entriesList, PaneKeys.pdfViewer],
              entriesList_directoryId: _document.path.directory_id,
              pdfViewer_documentId: selection.d,
              pdfViewer_boxes: JSON.stringify(selection.b),
              pdfViewer_page: selection.b[0].page,
            }),
        );
      } else {
        /** This should not happen ^^ */
        setRedirectTo('/');
      }
    };
    run();
    // eslint-disable-next-line
  }, []);

  /* As soon as the `redirectTo` is set, we redirect the user */
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  /* Meanwhile, show a loading indicator. This should usually be at most a few hundred ms. */
  return (
    <NoAuthScreenWrapper>
      <PaneContentLoading />
    </NoAuthScreenWrapper>
  );
};

export default SelectionUrlForwarder;
