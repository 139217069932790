import * as websocketEventTypes from '../websocketEventTypes';

import Codefy from '../../../codefy';
import { t } from './index';

/** A list of notification types and the corresponding popups to be shown. Note that this
 * is a list because we might want to show several popups per notification event. Also
 * in the case of document_moved_from and document_moved_to, they should be in a fixed
 * order (namely document_moved_to should be after document_moved_from), so that it
 * instantly overwrites the document_moved_from popup, so that the user is not confused
 * why seemingly one action ("move a document") caused two popups. */
const popups: Codefy.Notifications.Popup[] = [
  [
    websocketEventTypes.USER_QUOTA_ALMOST_EXCEEDED,
    (action: Codefy.Notifications.Payloads.UserQuotaAlmostExceeded) => ({
      textIfCurrentUser:
        action.quota_type === 'Disk'
          ? t('popups.userQuota.diskQuotaAlmostExceeded', {
              percentage: action.quota_percentage,
            })
          : t('popups.userQuota.fileQuotaAlmostExceeded', {
              percentage: action.quota_percentage,
            }),
      textIfOtherUser: false,
    }),
  ],
];

export default popups;
