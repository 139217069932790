import Codefy from '../../../../codefy';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { UPLOAD_BATCHES_GET_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesGet';
import { UPLOAD_BATCHES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatches';
import { UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { executeAction } from '../actionHelpers';

export const projectsTrash = (data: {
  project_ids: Codefy.Objects.Project['id'][];
  trashed?: boolean;
}) =>
  executeAction<Codefy.Objects.Project>({
    config: { url: '/api/v1/projects/trash', method: 'POST' },
    data,
    queryKeysToRefresh: [
      ENTRIES_LIST_KEY_TYPE, // to close the pane of the project, if open
      PROJECTS_LIST_KEY_TYPE,
      UPLOAD_BATCHES_LIST_KEY_TYPE,
      UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE,
      UPLOAD_BATCHES_GET_KEY_TYPE,
    ],
  });
