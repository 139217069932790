import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { commonTextFieldProps, useStyles } from '../index';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { handleActionError } from '../../../../../controllers/api/actions/actionHelpers';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userChangeName } from '../../../../../controllers/api/actions/user/userChangeName';

// eslint-disable-next-line react/display-name
export const NameSettingContent = forwardRef(
  (
    props: {
      name: string;
      setSaveDisabled: any;
      setLoading: any;
    },
    ref: any,
  ): JSX.Element => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const [name, setName] = useState(props.name);

    // The component instance (ref) gets extended
    // with a function returned from the callback
    // passed as the second argument
    useImperativeHandle(ref, () => ({
      saveData() {
        props.setLoading(ref, true);
        // submit data after password input
        dispatch(
          userChangeName({
            name,
          }),
        )
          // @ts-ignore
          .then(() => {
            props.setLoading(ref, false);
          })
          // @ts-ignore
          .catch((error: any) => {
            props.setLoading(ref, false);
            handleActionError(error);
          });
      },
    }));

    useEffect(() => {
      if (props.name !== name) {
        props.setSaveDisabled(ref, false);
      }
    });

    return (
      <React.Fragment>
        <div>
          <Typography className={classes.secondaryHeading} variant="caption">
            {t('account.nameDescription')}
          </Typography>

          <Typography variant="caption">
            <TextField
              {...commonTextFieldProps}
              id="name"
              label={t('authScreen.name')}
              name="name"
              variant="standard"
              size="small"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Typography>
        </div>
      </React.Fragment>
    );
  },
);
