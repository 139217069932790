import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import { DataFunc, Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions';
import React, { useState } from 'react';

import AddCommentIcon from '../../../icons/addComment';
import Codefy from '../../../../codefy';
import { GenericCommentParameters } from '../../../../controllers/api/subscriptions/comments/commentsList';
import PaneContentLoading from '../../../panes/paneContentLoading';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import classNames from './mentions.module.css';
import { commentsCreate } from '../../../../controllers/api/actions/comments/commentsCreate';
//@ts-ignore
import findEmails from 'find-emails-in-string';
import { useEntriesGet } from '../../../../controllers/api/subscriptions/entries/entriesGet';
import useLocalStorage from '@rehooks/local-storage';
import { useOpenConfirmInviteDialog } from '../../../dialogs/share/confirmInviteDialog';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import { useSelector } from 'react-redux';
import { useTagInstancesGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

// TODO: Perhaps this can be refactored to be written together with the AnnotationComment component?
// Seems there is some redundant code
// TODO: Add tests for the feature of notifying/sharing after writing a comment

const useCreateCommentContext = ({
  createCommentParams,
}: {
  createCommentParams: GenericCommentParameters;
}): { project: Codefy.Objects.Project | undefined; addPermission: boolean | undefined } => {
  const { data: entry } = useEntriesGet(
    createCommentParams.type === 'entry' ? createCommentParams.entry_id : undefined,
  );

  const { data: tag } = useTagsGet({
    tag_id: createCommentParams.type === 'tag' ? createCommentParams.tag_id : undefined,
  });

  const { data: tagTaglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const { data: tagInstance } = useTagInstancesGet(
    createCommentParams.type === 'tag_instance' ? createCommentParams.tag_instance_id : undefined,
  );

  const { data: tagInstanceTaglist } = useTaglistsGet({ taglist_id: tagInstance?.taglist_id });

  const { data: project } = useProjectsGet(
    entry?.path?.project_id || tagTaglist?.path.project_id || tagInstanceTaglist?.path.project_id,
  );

  if (entry) return { project, addPermission: entry.path?.add_permission };
  if (tagTaglist) return { project, addPermission: tagTaglist.path.add_permission };
  if (tagInstanceTaglist)
    return {
      project,
      addPermission: tagInstance?.path.add_permission,
    };

  return {
    project: undefined,
    addPermission: true /* since most users are logged in, so they don't get shown the loading screens */,
  };
};

export function CreateComment({
  createCommentParams,
  disableAutoFocus,
  onDone,
  alwaysFull,
}: {
  createCommentParams: GenericCommentParameters;
  disableAutoFocus?: boolean;
  onDone?: () => void;
  alwaysFull?: boolean;
}) {
  const globalClasses = useGlobalStyles();
  const user = useSelector((state: Codefy.State) => state.user);
  const { t } = useTranslation();
  const openConfirmInviteDialog = useOpenConfirmInviteDialog();

  const MENTIONS_TEXT_INPUT_ID = 'mentions-text-input-' + JSON.stringify(createCommentParams);

  const { project, addPermission } = useCreateCommentContext({ createCommentParams });

  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState(false);

  /** A representation of the comment in Markdown format that includes mentions syntax (for
   * highlighting the selected mentions) in the comment input area after the user selects them */
  const [commentTextMarkdown, setCommentTextMarkdown] = useLocalStorage(
    `comment-draft-text-` + JSON.stringify(createCommentParams),
    '',
  );

  /** A list of emails that will receive notifications. New emails are added when the user selects
   * an email from the mentions suggestion menu, but they can be manually removed by the user */
  const [mentions, setMentions] = useLocalStorage<{ email: string; name: string }[]>(
    `comment-draft-mentions-` + JSON.stringify(createCommentParams),
    [],
  );

  /** All entered emails will receive a share invitation by default, but the user can choose to
   * disable this per email */
  const [excludedEmails, setExcludedEmails] = useLocalStorage<string[]>(
    `comment-draft-excludedEmails-` + JSON.stringify(createCommentParams),
    [],
  );
  const excludeEmail = (email: string) => {
    if (!excludedEmails.includes(email)) {
      setExcludedEmails([...excludedEmails, email]);
    }
  };

  /** An array of emails from the users mentioned as well as the emails typed in manually */
  const allEmails = mentions
    /** Remove mentions that the user added and then removed again */
    .filter((mention) => commentTextMarkdown.includes(mention.email))
    .map((mention) => mention.email)
    .concat(findEmails(commentTextMarkdown))
    /* Get distinct */
    .filter((item, pos, arr) => {
      return arr.indexOf(item) == pos;
    });

  const emailsNotSharedWithYet = allEmails
    .filter((email) => !excludedEmails.includes(email))
    .filter(
      (email) => !project?.permissions.map((permission) => permission.user.email).includes(email),
    );

  const emailsAlreadySharedWith = allEmails
    .filter((email) => !excludedEmails.includes(email))
    .filter((email) =>
      project?.permissions.map((permission) => permission.user.email).includes(email),
    );

  /** Retrieves users given a search term for the mentions functionality */
  const mentionSearch: DataFunc = (search, callback) => {
    axios.get('/api/v1/users/list', { params: { limit: 5, name: search } }).then((response) => {
      if (response?.data?.users) {
        const users: { id: string; display: string }[] = response?.data?.users.map(
          (user: Codefy.Objects.User) => ({
            id: user.email,
            display: '@' + user.name,
          }),
        );

        callback(users);
      } else {
        callback([]);
      }
    });
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onSave = async () => {
    const resetInput = () => {
      setCommentTextMarkdown('');
      setMentions([]);
      setExcludedEmails([]);
      //@ts-ignore
      document.getElementById(MENTIONS_TEXT_INPUT_ID)?.focus();
    };

    if (emailsNotSharedWithYet.length > 0) {
      openConfirmInviteDialog({
        projectName: project?.name || '',
        createCommentParams,
        text: commentTextMarkdown,
        emails: [...emailsNotSharedWithYet, ...emailsAlreadySharedWith],
        emailsNotSharedWithYet,
        onDone: resetInput,
      });
    } else {
      setSaving(true);

      /** Creates the comment, optionally notifying selected users */
      await commentsCreate({
        ...createCommentParams,
        text: commentTextMarkdown,
        emails: [...emailsNotSharedWithYet, ...emailsAlreadySharedWith],
      });

      setSaving(false);

      onDone?.();

      resetInput();
    }
  };

  /** Every time a key is pressed, check if it happens to be the enter key AND ctrl is held down, if
   * yes, save the comment */
  const onKeyDownSaveIfEnter = (
    event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const keyCodeForTheEnterKey = 13;
    if (event.keyCode === keyCodeForTheEnterKey) {
      if (event.ctrlKey || event.metaKey /* Mac OSX */) {
        onSave();
      }
    }
  };

  /** When the user types in the comment field */
  const onChange: OnChangeHandlerFunc = (event, newValue) => {
    setCommentTextMarkdown(newValue);

    if (newValue.length === 0) {
      setExcludedEmails([]);
    }
  };

  /** When the user selects from the mention suggestions popup */
  const onAdd = (id: string | number, display: string) => {
    /** Skip if email already exists */
    if (mentions.find((entry) => entry.email === id)) return;

    setMentions([...mentions, { email: id.toString(), name: display }]);
  };

  if (!user) return null;
  if (!addPermission) return null;

  return (
    <div onClick={(event) => event.stopPropagation()} className={globalClasses.fullWidth}>
      {saving && <PaneContentLoading />}
      <Grid
        container
        wrap="nowrap"
        alignContent="center"
        alignItems="center"
        className={globalClasses.fullWidth}>
        <Box m={1} mb={0.25}>
          <AddCommentIcon />
        </Box>

        <Box width="100%" ml={1.5}>
          <MentionsInput
            id={MENTIONS_TEXT_INPUT_ID}
            placeholder={t('annotations.comments.mentionInputPlaceholder')}
            disabled={saving}
            autoFocus={!disableAutoFocus}
            onKeyDown={onKeyDownSaveIfEnter}
            onFocus={onFocus}
            rows={2}
            data-e2e-id="add-comment-to-annotation-field"
            value={commentTextMarkdown}
            onChange={onChange}
            className="mentions"
            classNames={classNames}>
            <Mention
              className={classNames.mentions__mention}
              trigger="@"
              markup="[__display__](mailto:__id__)"
              data={mentionSearch}
              appendSpaceOnAdd
              onAdd={onAdd}
              style={{
                backgroundColor: COLORS.primaryLight,
                /* Fixes weird bug where the highlight would be slightly too short on the right side */
                paddingRight: '5px',
              }}
            />
          </MentionsInput>
        </Box>

        {(focused || alwaysFull) && (
          <Box display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <IconButton size="small" disabled={commentTextMarkdown.length === 0 || saving}>
                <SendIcon onClick={onSave} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Grid>

      <Box ml={6.5} pr={3} display="flex" flexWrap="wrap" whiteSpace="pre-wrap">
        {(focused || alwaysFull) && (
          <Box m={1}>
            <Typography className={globalClasses.textLight}>
              {t('comments.typeXToCreateATask')}
            </Typography>
          </Box>
        )}

        {emailsAlreadySharedWith.length > 0 &&
          emailsAlreadySharedWith.map((email) => {
            const name = project?.permissions.find((permission) => permission.user.email === email)
              ?.user.name;
            return (
              <Box m={1} key={email}>
                <Typography className={globalClasses.textLight}>
                  {t('comments.willBeNotified', {
                    postProcess: 'markdown-jsx',
                    name,
                    email,
                  })}
                </Typography>
              </Box>
            );
          })}

        {emailsNotSharedWithYet.map((email) => (
          <Box m={1} key={email}>
            <Typography className={globalClasses.textLight}>
              {t('comments.thisCommentWillInvite', {
                postProcess: 'markdown-jsx',
                email,
                projectName: project?.name || '',
              })}{' '}
              {t('shareProjectDialog.descDefault')}{' '}
              <span className={globalClasses.linkLight} onClick={() => excludeEmail(email)}>
                {t('comments.dontShare')}
              </span>
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
}
