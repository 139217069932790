import './tagDescriptionStyle.css';

import { ALERT_STYLE } from '../../../../globalThemeSettings';
import { Box } from '@material-ui/core';
import Codefy from '../../../../codefy';
import { NO_DRAG_SCROLL_CLASSNAME } from '../../panesViewportDragScrollHandler';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import gfm from 'remark-gfm';
import { useQueryParam_tag_tagId } from '../../../../controllers/useGlobalQueryParams';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';

export default function TagDescription({
  taglistType,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const [tag_id] = useQueryParam_tag_tagId({ taglistType });
  const { data: tag } = useTagsGet({ tag_id });
  if (!tag?.description) return null;

  return (
    <div style={ALERT_STYLE}>
      <Box m={2}>
        <ReactMarkdown
          className={clsx('description', NO_DRAG_SCROLL_CLASSNAME)}
          remarkPlugins={[gfm]}>
          {tag.description}
        </ReactMarkdown>
      </Box>
    </div>
  );
}
