import React, { useState } from 'react';

import Codefy from '../../../codefy';
import GenericDeleteDialog from '../genericDeleteDialog';
import { TagListTag } from '../../panes/paneTypes/taglist/tagListTag';
import getTagsHierarchy from '../../panes/paneTypes/tag/getTagsHierarchy';
import { tagsDelete } from '../../../controllers/api/actions/taglists/tags/tagsDelete';
import { useBetween } from 'use-between';
import { useEntriesGet } from '../../../controllers/api/subscriptions/entries/entriesGet';
import { useSelector } from 'react-redux';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

type DialogDeleteTagStateType = { tagId: Codefy.Objects.Project['id'] };

const useDialogDeleteTagState = () => useState<DialogDeleteTagStateType>();

const useSharedDialogDeleteTagState = () => useBetween(useDialogDeleteTagState);

export const DeleteTagDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteTagState();

  const userId = useSelector((state: Codefy.State) => state.user?.id);
  const { data: tag } = useTagsGet({ tag_id: state?.tagId });
  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });
  const { data: entry } = useEntriesGet(taglist?.path.entry_id);

  const tagWithChildTags =
    (taglist?.tags && getTagsHierarchy(taglist?.tags).find((t) => t.id === state?.tagId)) || tag;

  if (!state?.tagId || !tagWithChildTags || !taglist) return null;

  const onClose = () => setState(undefined);

  const onDelete = () => {
    tagsDelete({ tag_id: state.tagId });
  };

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      title={
        tagWithChildTags.childTags
          ? t('deleteDialog.deleteTagWithChildTags')
          : t('deleteDialog.deleteTag')
      }
      content={
        <TagListTag
          project_id={taglist.path.project_id}
          tag={tagWithChildTags}
          writePermission={taglist.path.write_permission}
          addPermission={taglist.path.add_permission}
          hideButtons
        />
      }
      onDelete={onDelete}
      onClose={onClose}
      dialogProps={{ open: !!state, fullWidth: true }}
      writePermission={taglist.path.write_permission || tag?.owned_by?.id === userId}
      owner={entry?.owned_by}
    />
  );
};

export const useOpenDeleteTagDialog = () => {
  const [, setState] = useSharedDialogDeleteTagState();
  return (state: DialogDeleteTagStateType) => () => setState(state);
};
