export enum PaneKeys {
  'projectsList' = 'projectsList',
  'entriesList' = 'entriesList',
  'search' = 'search',
  'pdfViewer' = 'pdfViewer',
  'pdfViewer2' = 'pdfViewer2',
  'tagInstancePredictions' = 'tagInstancePredictions',
  'annotationTaglist' = 'annotationTaglist',
  'annotationTag' = 'annotationTag',
  'annotationTagDescription' = 'annotationTagDescription',
  'entryTaglist' = 'entryTaglist',
  'entryTag' = 'entryTag',
  'entryTagDescription' = 'entryTagDescription',
  'account' = 'account',
  'uploadBatches' = 'uploadBatches',
  'uploadBatch' = 'uploadBatch',
  'mentionsList' = 'mentionsList',
  'comments' = 'comments',
}
