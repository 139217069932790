import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  useQueryParam_editExportTextTemplates_tagId,
  useQueryParam_modal,
} from '../../../../controllers/useGlobalQueryParams';

import DeleteIcon from '@material-ui/icons/Delete';
import { tagsExportTextTemplates } from '../../../../controllers/api/actions/taglists/tags/tagsExportTextTemplates';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

export default function EditExportTextTemplatesModal() {
  const { t } = useTranslation();
  const [editExportTextTemplates_tagId] = useQueryParam_editExportTextTemplates_tagId();
  const [, set_modal] = useQueryParam_modal();
  const onCloseModal = useCallback(() => set_modal(undefined, 'replaceIn'), []);

  const { data: tag } = useTagsGet({ tag_id: editExportTextTemplates_tagId });

  const [newEntry, setNewEntry] = useState<string>();
  const [exportTextTemplates, setExportTextTemplates] = useState<string[]>(
    tag?.export_text_templates || [],
  );

  const onSave = () => {
    if (!editExportTextTemplates_tagId) return;

    if (exportTextTemplates) {
      tagsExportTextTemplates({
        tag_id: editExportTextTemplates_tagId,
        export_text_templates: exportTextTemplates,
      });
      onCloseModal();
    }
  };

  const onAdd = () => {
    if (!newEntry) return;
    if (!exportTextTemplates || exportTextTemplates?.length === 0) {
      setExportTextTemplates([newEntry]);
    } else {
      setExportTextTemplates([...exportTextTemplates, newEntry]);
    }
    setNewEntry('');
  };

  const onDelete = (template: string) => () => {
    setExportTextTemplates(exportTextTemplates.filter((t) => t !== template));
  };

  return (
    <div>
      <DialogTitle>
        {tag?.export_text_templates?.length === 0
          ? t('editExportTextTemplatesModal.addExportTextTemplates')
          : t('editExportTextTemplatesModal.editExportTextTemplates')}
      </DialogTitle>
      <DialogContent>
        <Box mt={1} mb={2}>
          <List>
            {exportTextTemplates?.map((template) => (
              <ListItem key={template}>
                <ListItemText primary={template} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={onDelete(template)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Box mt={1} mb={1}>
            <TextField
              placeholder="New template…"
              value={newEntry}
              onChange={(event) => setNewEntry(event.target.value)}
              fullWidth
            />
          </Box>
          <Box mt={1} mb={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={onAdd}
              disabled={!newEntry}
              size="small">
              {t('editExportTextTemplatesModal.add')}
            </Button>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <ButtonGroup>
          <Button color="primary" variant="contained" onClick={onSave}>
            {t('editExportTextTemplatesModal.save')}
          </Button>
          <Button color="secondary" onClick={onCloseModal}>
            {t('editExportTextTemplatesModal.close')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </div>
  );
}
