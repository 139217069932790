import { DocumentIdContext, PdfPageIndexContext } from '../pdfViewer';
import React, { useContext } from 'react';

import { AnnotationRectangle } from './rectangles/AnnotationRectangle';
import { AnnotationRectangleHighlight } from './rectangles/AnnotationRectangleHighlight';
import { AnnotationTagLabel } from './tagLabels/AnnotationTagLabel';
import Codefy from '../../../../../codefy';
import PaneContentLoading from '../../../paneContentLoading';
import useAnnotationPatchBoxesRenderRectangle from './rectangles/useAnnotationPatchBoxesRenderRectangle';
import useAnnotationPatchTagLabelPositions from './tagLabels/useAnnotationPatchTagLabelPositions';
import { useAnnotationsList } from '../../../../../controllers/api/subscriptions/annotations/annotationsList';
import useHover from '../../../../../hooks/useHover';
import { useQueryParam_trashMode } from '../../../../../controllers/useGlobalQueryParams';

export function PdfPageAnnotationLayer() {
  const documentId = useContext(DocumentIdContext);
  const pageIndex = useContext(PdfPageIndexContext);
  const [trashMode] = useQueryParam_trashMode();

  const { data: originalAnnotations, isFetching } = useAnnotationsList({
    pages: [pageIndex + 1],
    document_ids: documentId ? [documentId] : undefined,
    type: 'annotation',
    descending: trashMode || false,
    trashed: trashMode,
  });

  let annotations = originalAnnotations?.annotations;

  annotations = useAnnotationPatchTagLabelPositions(annotations);
  annotations = useAnnotationPatchBoxesRenderRectangle(annotations);

  if (isFetching && !annotations) {
    return (
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <PaneContentLoading />
      </div>
    );
  }

  return (
    <>
      {annotations?.map((annotation) => (
        <PdfPageAnnotation key={annotation.id} annotation={annotation} />
      ))}
    </>
  );
}

function PdfPageAnnotation({ annotation }: { annotation: Codefy.Objects.Annotation }) {
  const [hoverRef, isHovered] = useHover();

  return (
    <>
      <div ref={hoverRef}>
        <AnnotationTagLabel annotation={annotation} />
        {isHovered && <AnnotationRectangleHighlight annotation={annotation} />}
      </div>
      <AnnotationRectangle annotation={annotation} />
    </>
  );
}
