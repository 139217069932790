import ArchiveIcon from '@material-ui/icons/Archive';
import CancelIcon from '@material-ui/icons/Cancel';
import Codefy from '../codefy';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useTrashModeVariant = () => {
  const deletionPeriod = useSelector((state: Codefy.State) => state.user?.deletion_period);
  if (deletionPeriod === 'disabled') {
    return 'archive';
  } else {
    return 'trash';
  }
};

/** Helper hook that returns the correct strings to differentiate between a trash bin and an archive */
export function useTrashModeStrings() {
  const { t } = useTranslation();
  const trashModeVariant = useTrashModeVariant();

  return (string: 'trash' | 'youAreViewing' | 'open' | 'restore' | 'binEmpty') => {
    if (trashModeVariant === 'archive') {
      switch (string) {
        /** We are using this verbose version instead of just `trashMode.${string}` so that the
         * i18n checker can work correctly */
        case 'binEmpty':
          return t('trashMode.binEmptyArchive') || '';
        case 'open':
          return t('trashMode.openArchive') || '';
        case 'restore':
          return t('trashMode.restoreArchive') || '';
        case 'trash':
          return t('trashMode.trashArchive') || '';
        case 'youAreViewing':
          return t('trashMode.youAreViewingArchive') || '';
      }
    } else {
      switch (string) {
        /** We are using this verbose version instead of just `trashMode.${string}` so that the
         * i18n checker can work correctly */
        case 'binEmpty':
          return t('trashMode.binEmpty') || '';
        case 'open':
          return t('trashMode.open') || '';
        case 'restore':
          return t('trashMode.restore') || '';
        case 'trash':
          return t('trashMode.trash') || '';
        case 'youAreViewing':
          return t('trashMode.youAreViewing') || '';
      }
    }
  };
}

/** Helper hook that returns the correct strings to differentiate between a trash bin and an archive */
export function useTrashModeIcons() {
  const trashModeVariant = useTrashModeVariant();

  return (
    iconType: 'trash' | 'restore',
    small?: boolean,
    className?: string,
    cancelIcon?: boolean,
  ) => {
    if (trashModeVariant === 'archive') {
      switch (iconType) {
        case 'trash':
          return <ArchiveIcon fontSize={small ? 'small' : undefined} className={className} />;
        case 'restore':
          if (cancelIcon) {
            return <CancelIcon fontSize={small ? 'small' : undefined} className={className} />;
          }
          return <UnarchiveIcon fontSize={small ? 'small' : undefined} className={className} />;
      }
    } else {
      switch (iconType) {
        case 'trash':
          return <DeleteIcon fontSize={small ? 'small' : undefined} className={className} />;
        case 'restore':
          if (cancelIcon) {
            return <CancelIcon fontSize={small ? 'small' : undefined} className={className} />;
          }
          return (
            <RestoreFromTrashIcon fontSize={small ? 'small' : undefined} className={className} />
          );
      }
    }
  };
}
