import { useDirectoriesGet } from '../controllers/api/subscriptions/directories/directoriesGet';
import { useQueryParam_entriesList_directoryId } from '../controllers/useGlobalQueryParams';

export default function useCurrentProjectId() {
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const { data: directory } = useDirectoriesGet(entriesList_directoryId);

  return directory?.path?.project_id;
}
