import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../subscriptions/annotations/annotationsList';
import {
  COMMENTS_LIST_KEY_TYPE,
  GenericCommentParameters,
} from '../../subscriptions/comments/commentsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../subscriptions/annotations/annotationsGet';
import { COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE } from '../../subscriptions/commentSubscriptions/commentSubscriptionsGet';
import Codefy from '../../../../codefy';
import { DOCUMENTS_GET_KEY_TYPE } from '../../subscriptions/documents/documentsGet';
import { ENTRIES_GET_KEY_TYPE } from '../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { MENTIONS_LIST_KEY_TYPE } from '../../subscriptions/mentions/mentionsList';
import { TAGLISTS_GET_KEY_TYPE } from '../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../actionHelpers';

type CommentsCreateRequestType = GenericCommentParameters & {
  text: string;
  /** Notify these users via email about the comment. Users must have access to the project */
  emails?: string[];
};

export const COMMENT_QUERY_KEYS_TO_REFRESH = [
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_GET_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
  COMMENTS_LIST_KEY_TYPE,
  ENTRIES_LIST_KEY_TYPE,
  ENTRIES_GET_KEY_TYPE,
  TAGLISTS_GET_KEY_TYPE,
  DOCUMENTS_GET_KEY_TYPE,
  MENTIONS_LIST_KEY_TYPE,
  COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE,
  TAG_INSTANCES_GET_KEY_TYPE,
];

export const commentsCreate = async (data: CommentsCreateRequestType) =>
  executeAction<Codefy.Objects.Comment>({
    config: { url: '/api/v1/comments/create', method: 'POST' },
    data,
    queryKeysToRefresh: COMMENT_QUERY_KEYS_TO_REFRESH,
  });
