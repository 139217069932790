import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import Typography from '@material-ui/core/Typography';
import { handleActionError } from '../../controllers/api/actions/actionHelpers';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userRegister } from '../../controllers/api/actions/user/userRegister';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
  }),
);

/** A screen showed after the user registers, also allowing the user to the confirmation email */
const AfterRegister = (props: {
  location: {
    state: {
      email: string;
      password: string;
      name: string;
    };
  };
}): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [counter, setCounter] = React.useState(30);

  const { email, password, name } = props.location.state;

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const resendEmail = () => {
    // re-send the confirmation e-mail
    dispatch(userRegister({ email, password, name }))
      // @ts-ignore
      .then(() => {
        // success
        toast.success(t('afterRegister.emailSuccess'));
      }) // @ts-ignore
      .catch((error: any) => {
        handleActionError(error);
      });
  };

  return (
    <NoAuthScreenWrapper title={t('afterRegister.accountReadyTitle')}>
      <div className={classes.textBlock}>
        <Typography variant="body1" align="center" color="primary">
          <b> {t('afterRegister.accountReadyText')}</b>
        </Typography>
      </div>
      <Grid container justify="center">
        <Grid item>
          {counter > 0 ? (
            <Typography variant="body2">{t('afterRegister.didntGetEmail', { counter })}</Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={counter !== 0}
              onClick={() => {
                // re-send the confirmation email
                resendEmail();
                // set countdown to 30 seconds
                setCounter(30);
              }}>
              {t('afterRegister.resendEmail')}
            </Button>
          )}
        </Grid>
      </Grid>
    </NoAuthScreenWrapper>
  );
};

export default AfterRegister;
