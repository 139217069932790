import { Box, Button, CircularProgress, Grid, GridSize, Tooltip } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import React, { useEffect, useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import Codefy from '../../../../codefy';
import DoneIcon from '@material-ui/icons/Done';
import { ReactNode } from 'react-markdown';
import { annotationsCreate } from '../../../../controllers/api/actions/annotations/annotationsCreate';
import clsx from 'clsx';
import { tagInstancesDelete } from '../../../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesDelete';
import { useTranslation } from 'react-i18next';

export default function PredictionResponseWrapper({
  searchResult,
  assistantTagId,
  children,
}: {
  searchResult: Codefy.Objects.SearchResult;
  assistantTagId?: Codefy.Objects.Tag['id'];
  children: React.ReactNode;
}) {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  /** We use the response from the POST request to set this variable so that the
   * UI updates quicker. */
  const [annotation, setAnnotation] = useState<Codefy.Objects.Annotation | undefined>(
    searchResult.annotation,
  );
  const [isSavingAnnotation, setIsSavingAnnotation] = useState<boolean>(false);

  /** Reset the locally cached annotation when switching between tags */
  useEffect(() => {
    setAnnotation(searchResult.annotation);
  }, [assistantTagId]);

  const annotationIsPositive = annotation?.tag_instances?.[0]?.type === 'annotation';

  const buttonIconStyle = { color: '#000000CC' };
  const buttons: {
    key: string;
    backgroundColor: string;
    helpText: string;
    active: boolean;
    text: ReactNode | string;
    onClick: Function;
  }[] = [
    {
      key: 'good',
      backgroundColor: COLORS.resolved,
      active: !!(annotation && annotationIsPositive),
      helpText: t('assistantPane.resolve'),
      text: <DoneIcon style={buttonIconStyle} />,
      onClick: async () => {
        if (!assistantTagId) return;

        const annotation = await annotationsCreate({
          document_id: searchResult.document_id,
          boxes: searchResult.highlights.map((hl) => hl.word_box),
          type: 'annotation',
          source: 'tagInstancePredictionFeedback',
          tag_id: assistantTagId,
        });

        setAnnotation(annotation);
      },
    },
    {
      key: 'bad',
      backgroundColor: COLORS.rejected,
      active: !!(annotation && !annotationIsPositive),
      helpText: t('assistantPane.reject'),
      text: <ClearIcon style={buttonIconStyle} />,
      onClick: async () => {
        if (!assistantTagId) return;

        const annotation = await annotationsCreate({
          document_id: searchResult.document_id,
          boxes: searchResult.highlights.map((hl) => hl.word_box),
          type: 'review_annotation',
          positive: false,
          tag_id: assistantTagId,
          source: 'tagInstancePredictionFeedback',
        });

        setAnnotation(annotation);
      },
    },
  ];

  const onDelete = async () => {
    if (!annotation?.tag_instances?.[0].id) return;

    await tagInstancesDelete({
      tag_instance_id: annotation?.tag_instances?.[0].id,
    });

    setAnnotation(undefined);
  };

  return (
    <Box width="100%">
      <div
        className={clsx(
          globalClasses.assistantResultBase,
          annotation &&
            (annotationIsPositive
              ? globalClasses.assistantResultPositive
              : globalClasses.assistantResultNegative),
        )}>
        {children}
      </div>
      {assistantTagId && (
        <div
          onClick={(event) => event.stopPropagation()}
          style={{
            //backgroundColor: 'black',
            width: '100%',
            height: '50px',
            zIndex: 8,
            // margin: '10px 0px 10px 0px',
          }}>
          <Grid
            container
            style={{
              height: '100%',
              width: '100%',
              zIndex: 9,
              paddingLeft: '7px',
              paddingRight: '0px',
              marginTop: '2px',
            }}
            alignItems="center"
            justify="center"
            spacing={1}>
            {isSavingAnnotation ? (
              <CircularProgress style={{ height: '18px', width: '18px' }} />
            ) : (
              buttons.map((button) => (
                <Grid
                  key={button.key}
                  item
                  md={(12 / buttons.length) as GridSize}
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                  style={{ height: '100%', width: '100%' }}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100%', height: '100%', width: '100%' }}>
                    <Grid item style={{ width: '100%', height: '100%' }}>
                      <Tooltip enterDelay={1000} title={button.helpText}>
                        <Button
                          disabled={isSavingAnnotation}
                          onClick={async () => {
                            if (isSavingAnnotation) return;

                            setIsSavingAnnotation(true);
                            await onDelete();
                            if (!button.active) {
                              await button.onClick();
                            }
                            setIsSavingAnnotation(false);
                          }}
                          variant="outlined"
                          fullWidth
                          style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: button.active ? button.backgroundColor : undefined,
                            color: 'white',
                            fontSize: '120%',
                          }}>
                          {button.text}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
        </div>
      )}
    </Box>
  );
}
