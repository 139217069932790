import axios from 'axios';
import { useQuery } from 'react-query';

export type UsersQuotaResponse = {
  file: number;
  disk: number;
  file_quota: number;
  disk_quota: number;
};

export const USERS_QUOTA_KEY_TYPE = 'usersQuota';

export const useUsersQuota = () =>
  useQuery(USERS_QUOTA_KEY_TYPE, async () => {
    const { data } = await axios.get('/api/v1/users/quota');
    return data as UsersQuotaResponse;
  });
