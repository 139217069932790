import { BOX_SHADOWS, useGlobalStyles } from '../../../globalThemeSettings';
import { Box, Button, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';

import Codefy from '../../../codefy';
import { GenericCommentParameters } from '../../../controllers/api/subscriptions/comments/commentsList';
import { GenericDialog } from '../genericDialog';
import { commentsCreate } from '../../../controllers/api/actions/comments/commentsCreate';
import { useBetween } from 'use-between';
import { useTranslation } from 'react-i18next';

type DialogConfirmInviteStateType = {
  projectName: Codefy.Objects.Project['name'];
  createCommentParams: GenericCommentParameters;
  text: string;
  emails: string[];
  emailsNotSharedWithYet: string[];
  onDone?: () => void;
};

const useDialogConfirmInviteState = () => useState<DialogConfirmInviteStateType>();

const useSharedDialogConfirmInviteState = () => useBetween(useDialogConfirmInviteState);

export const ConfirmInviteDialog = () => {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogConfirmInviteState();

  const onClose = () => {
    setState(undefined);
  };

  const onConfirm = async () => {
    if (!state) return;
    await commentsCreate({ ...state.createCommentParams, text: state.text, emails: state.emails });

    state.onDone?.();
    onClose();
  };

  if (!state) return null;

  return (
    <GenericDialog
      /* Important, otherwise it will be shown below the context menu and will not be clickable! */
      style={{ zIndex: 1000000 }}
      PaperProps={{ style: { boxShadow: BOX_SHADOWS.medium, zIndex: 1000000 } }}
      title={t('confirmInviteDialog.confirmSharing', { projectName: state.projectName })}
      open={!!state}
      onClose={onClose}>
      <Box>
        {t('confirmInviteDialog.youAreAboutToShare', { projectName: state.projectName })}
        {state.emails.map((email) => (
          <Box m={1} key={email}>
            <li>{email}</li>
          </Box>
        ))}
        <DialogActions>
          <Button onClick={onClose} className={globalClasses.cancelButton}>
            {t('confirmInviteDialog.cancel')}
          </Button>
          <Button onClick={onConfirm} className={globalClasses.dialogButton}>
            {t('confirmInviteDialog.share')}
          </Button>
        </DialogActions>
      </Box>
    </GenericDialog>
  );
};

export const useOpenConfirmInviteDialog = () => {
  const [, setState] = useSharedDialogConfirmInviteState();
  return (state: DialogConfirmInviteStateType) => setState(state);
};
