import axios, { AxiosRequestConfig } from 'axios';

import Codefy from '../../../../codefy';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import store from '../../../store';

export const userChangeName = (data: { name: Codefy.Objects.User['name'] }) => {
  return async () => {
    const config: AxiosRequestConfig = {
      url: '/api/v1/users/name',
      method: 'POST',
      data: fastApiDataSerializer(data),
    };

    const response = await axios(config);

    store.dispatch({
      type: 'UserGet',
      user: response.data,
    });

    return await axios(config);
  };
};
