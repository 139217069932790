import { PdfPageIndexContext, PdfPagePageHeightContext } from '../../pdfViewer';
import React, { useContext } from 'react';

import Codefy from '../../../../../../codefy';
import { Fade } from '@material-ui/core';
import { PDF_PAGE_AREA_SELECTION_LAYER_Z_INDEX } from '../../pdfPageAreaSelectionLayer/pdfPageAreaSelectionLayer';
import TagLabels from '../../../../../tagLabels/tagLabelsRenderer';

export function AnnotationTagLabel({ annotation }: { annotation: Codefy.Objects.Annotation }) {
  const pageIndex = useContext(PdfPageIndexContext);
  const pageHeight = useContext(PdfPagePageHeightContext);

  /* Otherwise, the selection layer would think one is selecting text and the selectionMenu would
  open after interacting with the AnnotationTagLabel */
  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

  return (
    <>
      {annotation.boxes[0]?.page === pageIndex + 1 && (
        <Fade in>
          <div
            onClick={onClick}
            style={{
              position: 'absolute',
              top:
                (annotation.tagLabelYmin || /* fallback just in case */ annotation.boxes[0].ymin) *
                pageHeight,
              right: '95%',
              zIndex: PDF_PAGE_AREA_SELECTION_LAYER_Z_INDEX + 1,
            }}>
            <TagLabels
              tagInstances={annotation.tag_instances}
              lineBreaks
              taglistType="annotation"
            />
          </div>
        </Fade>
      )}
    </>
  );
}
