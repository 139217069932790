import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { AxiosRequestConfig } from 'axios';
import { Button } from '@material-ui/core';
import Cookies from 'universal-cookie';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isAxiosError } from './axiosHelper';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
  }),
);

const allCookies = new Cookies();

/** A screen allowing the user to confirm email change */

const ConfirmChangeEmail = (): JSX.Element => {
  const classes = useStyles();
  const [token] = useQueryParam('token', StringParam);
  const { t } = useTranslation();
  const loggedIn = !!allCookies.get('session');

  const config: AxiosRequestConfig = {
    url: '/api/v1/users/confirm_change_email',
    method: 'GET',
    params: { token },
  };

  const [{ response, loading, error }, refetch] = useAxios(config, { manual: true });

  useEffect(() => {
    if (token) {
      refetch(config);
    }
  }, [token]);

  // in case of an error, check if user is logged in or not and display
  // the data accordingly. Otherwise (success), we get a cookie set, so display continue
  const actionText = isAxiosError(error)
    ? loggedIn
      ? t('confirmChangeEmail.continue')
      : t('confirmChangeEmail.signIn')
    : t('confirmChangeEmail.continue');

  return (
    <NoAuthScreenWrapper title={t('confirmChangeEmail.emailChangeConfirmation')}>
      <div className={classes.textBlock}>
        {loading && <Skeleton variant="text" />}
        {!token && (
          <Typography color="error" variant="body1" align="center">
            {t('confirmChangeEmail.invalidTokenText')}
          </Typography>
        )}
        {isAxiosError(error) && (
          <Typography color="error" variant="body1" align="center">
            {getErrorText(error?.response?.data) || error?.name}
          </Typography>
        )}
        {response && (
          <Typography
            variant="body1"
            align="center"
            dangerouslySetInnerHTML={{
              __html: t('confirmChangeEmail.successConfirmationText', {
                email: response?.data?.email,
                action: actionText?.toLowerCase(),
              }),
            }}
          />
        )}
      </div>
      {!loading && token && (
        <Grid container justify="center">
          <Grid item>
            <Link style={{ textDecoration: 'none' }} href="/">
              <Button variant="contained" color="primary">
                {actionText}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </NoAuthScreenWrapper>
  );
};

export default ConfirmChangeEmail;
