import React, { useState } from 'react';

import Codefy from '../../../codefy';
import GenericDeleteDialog from '../genericDeleteDialog';
import { TagInstanceLabel } from '../../tagLabels/tagLabelsRenderer';
import { tagInstancesDelete } from '../../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesDelete';
import { useBetween } from 'use-between';
import { useTagInstancesGet } from '../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTranslation } from 'react-i18next';

type DialogDeleteTagInstanceStateType = { tagInstanceId: Codefy.Objects.TagInstance['id'] };

const useDialogDeleteTagInstanceState = () => useState<DialogDeleteTagInstanceStateType>();

const useSharedDialogDeleteTagInstanceState = () => useBetween(useDialogDeleteTagInstanceState);

export const DeleteTagInstanceDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogDeleteTagInstanceState();

  const { data: tagInstance } = useTagInstancesGet(state?.tagInstanceId);
  const { data: taglist } = useTaglistsGet({ taglist_id: tagInstance?.taglist_id });

  if (!state?.tagInstanceId || !tagInstance || !taglist) return null;

  const onClose = () => setState(undefined);

  const onDelete = () => {
    tagInstancesDelete({ tag_instance_id: state.tagInstanceId });
  };

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      title={t('deleteDialog.deleteTagInstance')}
      content={
        <TagInstanceLabel tagInstance={tagInstance} taglistType={taglist.type} lineBreaks={false} />
      }
      onDelete={onDelete}
      onClose={onClose}
      dialogProps={{ open: !!state, fullWidth: true }}
      writePermission={tagInstance.path.write_permission}
      owner={tagInstance.owned_by}
    />
  );
};

export const useOpenDeleteTagInstanceDialog = () => {
  const [, setState] = useSharedDialogDeleteTagInstanceState();
  return (state: DialogDeleteTagInstanceStateType) => setState(state);
};
