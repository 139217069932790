import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
  AnnotationsListReturnType,
} from '../../subscriptions/annotations/annotationsList';
import { QueryKey } from 'react-query';

import Codefy from '../../../../codefy';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../actionHelpers';
import { tagInstancePredictionsListKey } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';
import { queryClient } from '../../../../App';

// TODO: This is not really optimized, causing lots of unnecessary refreshes
export const ANNOTATIONS_CREATE_QUERY_KEYS_TO_REFRESH = [
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
];

export const annotationsCreate = async (data: {
  document_id: Codefy.Objects.Document['id'];
  boxes: Codefy.Objects.Box[];
  area?: Codefy.Objects.Annotation['area'];
  type: Codefy.Objects.TagInstance['type']; // but only "annotation" and "review_annotation"
  positive?: boolean;
  tag_id: Codefy.Objects.Tag['id'];
  /** Where did the user create the tag instance from? Used for metrics. */
  source: string;
}) => {
  if (data.boxes.length === 0) return undefined;

  const queryKeysToRefresh: QueryKey[] = ANNOTATIONS_CREATE_QUERY_KEYS_TO_REFRESH;

  if (data.tag_id) {
    queryKeysToRefresh.push(tagInstancePredictionsListKey({ tag_id: data.tag_id }));
  }

  const result = await executeAction<Codefy.Objects.Annotation>({
    config: { url: '/api/v1/annotations/create', method: 'POST' },
    data,
    queryKeysToRefresh,
  });

  if (!result) return;

  if (data.type === 'annotation') {
    /** Use the response to manually mutate the local cache for maximum performance */
    for (const [qk, data] of queryClient.getQueriesData<AnnotationsListReturnType>(
      ANNOTATIONS_LIST_KEY_TYPE,
    )) {
      if (!data) continue;
      /* We just add it to all pages since when rendering we filter by page again, so it will not be
displayed twice */
      queryClient.setQueryData(qk, {
        ...data,
        annotations: [...data.annotations, result],
      });
    }
  }

  return result;
};
