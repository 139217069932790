import Codefy from '../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export const DIRECTORIES_PARENTS_KEY_TYPE = 'directoriesParents';

type DirectoriesParentsReturnType = {
  directories: Codefy.Objects.Directory[];
  num_directories: number;
};

export const directoriesParentsKey = (
  id?: Codefy.Objects.Directory['id'],
): [string, Codefy.Objects.Directory['id'] | undefined] => [DIRECTORIES_PARENTS_KEY_TYPE, id];

export const useDirectoriesParents = (directory_id?: Codefy.Objects.Directory['id'] | string) => {
  if (typeof directory_id === 'string') {
    directory_id = parseInt(directory_id);
  }
  return useQuery(
    directoriesParentsKey(directory_id),
    async () => {
      if (!directory_id) return undefined;
      const { data } = await axios.get<DirectoriesParentsReturnType>(
        '/api/v1/directories/parents',
        { params: { directory_id } },
      );
      return data;
    },
    { keepPreviousData: true },
  );
};
