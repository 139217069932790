import { Box, ListItem } from '@material-ui/core';

import React from 'react';

/** A component that shows that the pane content has loaded successfully, but is empty */
export default function PaneContentEmpty({
  text,
  button,
}: {
  text: string | React.ReactElement;
  button?: React.ReactElement;
}) {
  return (
    <Box>
      <ListItem disabled> {text}</ListItem>
      <Box ml={1}>{button}</Box>
    </Box>
  );
}
