import Codefy from '../../../../codefy';
import { PUBLICATIONS_LIST_KEY_TYPE } from '../../subscriptions/publications/publicationsList';
import { executeAction } from '../actionHelpers';

export type PublicationsUpdateRequestType = {
  publication_id: Codefy.Objects.Publication['id'];
  title: Codefy.Objects.Publication['title'];
  description: Codefy.Objects.Publication['description'];
};

export const publicationsUpdate = (data: PublicationsUpdateRequestType) =>
  executeAction<Codefy.Objects.Publication>({
    config: { url: '/api/v1/publications/update', method: 'POST' },
    data,
    queryKeysToRefresh: [PUBLICATIONS_LIST_KEY_TYPE],
  });
