import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Codefy from '../../../../codefy';
import { DeleteAccountSettingContent } from './settingContents/DeleteAccountSettingContent';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { E2E } from '../../../../testing/common/data-e2e-ids';
import EmailIcon from '@material-ui/icons/Email';
import { EmailSettingContent } from './settingContents/EmailSettingContent';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { GenericSetting } from './GenericSetting';
import LanguageIcon from '@material-ui/icons/Language';
import { LanguageSettingContent } from './settingContents/LanguageSettingContent';
import { NameSettingContent } from './settingContents/NameSettingContent';
import { PasswordSettingContent } from './settingContents/PasswordSettingContent';
import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import { TextFieldProps } from '@material-ui/core/TextField';
import { UserQuotaSettingContent } from './settingContents/UserQuotaSettingContent';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useTranslation } from 'react-i18next';
import { useUsersQuota } from '../../../../controllers/api/subscriptions/users/usersQuota';
import { userLogout } from '../../../../controllers/api/actions/user/userLogout';

export const languages = { eng: 'English', deu: 'Deutsch' };

export const useStyles = makeStyles((theme) => ({
  userArea: {
    maxWidth: 700,
    margin: '15px',
    height: '90%',
    overflowY: 'auto',
    paddingRight: '10px',
  },
  card: {
    border: '0px',
  },
  cardheader: {
    marginRight: '40px',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  panel: {
    width: '100%',
    marginTop: '20px',
    position: 'relative',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
    padding: theme.spacing(1, 2),
  },
  column2: {
    flexBasis: '50%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute',
  },
  deleteButtonContainer: {
    display: 'flex',
    marginTop: '30px',
    justifyContent: 'center',
  },
  logoutButtonContainer: { display: 'flex', justifyContent: 'flex-end' },
}));

/** Account area screen where the user can manage account related settings */
export default function Account(): JSX.Element {
  const globalClasses = useGlobalStyles();
  const user = useSelector((state: Codefy.State) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: quota } = useUsersQuota();

  const classes = useStyles();

  const settingsRefs = [
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
  ];

  const emailRef = useRef<JSX.Element>();
  const nameRef = useRef<JSX.Element>();
  const passRef = useRef<JSX.Element>();
  const langRef = useRef<JSX.Element>();

  const onLogout = () => {
    dispatch(userLogout());
  };

  const setSaveDisabled = (ref: React.RefObject<any>, disabled: boolean) => {
    switch (ref) {
      case emailRef:
        settingsRefs[0].current.setSaveDisabled(disabled);
        break;
      case nameRef:
        settingsRefs[1].current.setSaveDisabled(disabled);
        break;
      case passRef:
        settingsRefs[2].current.setSaveDisabled(disabled);
        break;
      case langRef:
        settingsRefs[3].current.setSaveDisabled(disabled);
        break;
      default:
    }
  };

  const setLoading = (ref: React.RefObject<any>, loading: boolean) => {
    switch (ref) {
      case emailRef:
        settingsRefs[0].current.setLoading(loading);
        break;
      case nameRef:
        settingsRefs[1].current.setLoading(loading);
        break;
      case passRef:
        settingsRefs[2].current.setLoading(loading);
        break;
      case langRef:
        settingsRefs[3].current.setLoading(loading);
        break;
      default:
    }
  };

  if (!user) {
    return <div></div>;
  }

  return (
    <div className={classes.userArea}>
      <Card className={classes.card} variant="outlined"></Card>
      <div className={classes.logoutButtonContainer}>
        <Button
          data-e2e-id={E2E.account_logout_button}
          className={globalClasses.dialogButton}
          color="primary"
          startIcon={<ExitToAppIcon />}
          disableElevation
          onClick={onLogout}>
          {t('account.logout')}
        </Button>
      </div>
      <GenericSetting
        id={1}
        type={t('account.email')}
        text={user?.email}
        icon={<EmailIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
        ref={settingsRefs[0]}
        content={
          <EmailSettingContent
            email={user?.email}
            ref={emailRef}
            setSaveDisabled={setSaveDisabled}
            setLoading={setLoading}
          />
        }
        contentRef={emailRef}
      />
      <GenericSetting
        id={2}
        type={t('account.name')}
        text={user?.name}
        icon={<PersonIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
        ref={settingsRefs[1]}
        content={
          <NameSettingContent
            name={user?.name}
            ref={nameRef}
            setSaveDisabled={setSaveDisabled}
            setLoading={setLoading}
          />
        }
        contentRef={nameRef}
      />
      <GenericSetting
        id={3}
        type={t('account.password')}
        text={t('account.passwordText')}
        icon={<VpnKeyIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
        ref={settingsRefs[2]}
        content={
          <PasswordSettingContent
            ref={passRef}
            setSaveDisabled={setSaveDisabled}
            setLoading={setLoading}
          />
        }
        contentRef={passRef}
      />
      <GenericSetting
        id={4}
        type={t('account.language')}
        text={user?.language && languages[user?.language]}
        icon={<LanguageIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
        ref={settingsRefs[3]}
        content={
          <LanguageSettingContent
            language={user?.language}
            ref={langRef}
            setSaveDisabled={setSaveDisabled}
            setLoading={setLoading}
          />
        }
        contentRef={langRef}
      />
      {(quota?.file_quota || quota?.disk_quota) && (
        <GenericSetting
          id={5}
          type={t('account.storage')}
          text={''}
          icon={<StorageIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
          ref={settingsRefs[4]}
          content={<UserQuotaSettingContent />}
          contentRef={langRef}
          noSave
        />
      )}
      <GenericSetting
        id={6}
        type={t('account.deleteAccount.deleteButtonTitle')}
        text={''}
        icon={<DeleteOutlineIcon style={{ marginBottom: '-5px' }} fontSize="small" />}
        ref={settingsRefs[5]}
        content={<DeleteAccountSettingContent />}
        contentRef={langRef}
        noSave
      />
      {process.env.REACT_APP_CODEFY_VERSION && (
        <Box m={3} style={{ opacity: 0.5 }}>
          Version: {process.env.REACT_APP_CODEFY_VERSION}
        </Box>
      )}
    </div>
  );
}
// Common props for the <TextField> components
export const commonTextFieldProps: TextFieldProps = {
  variant: 'outlined',
  margin: 'normal',
  required: true,
  fullWidth: true,
};
