import { PdfViewerVariantType, scrollToPage } from './pdfViewer';
import React, { useEffect, useRef, useState } from 'react';
import {
  useQueryParam_pdfViewer_documentId,
  useQueryParam_pdfViewer_page,
} from '../../../../controllers/useGlobalQueryParams';

import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';

export default function PdfViewerPaneTitleBarCurrentPage({
  pdfViewerVariant,
}: {
  pdfViewerVariant: PdfViewerVariantType;
}) {
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(pdfViewerVariant);
  const [pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);
  const [new_pdfViewer_page, set_new_pdfViewer_page] = useState<number>();

  const { data: document } = useDocumentsGet(pdfViewer_documentId);

  const inputRef = useRef<HTMLInputElement>(null);

  /* Update the current page when the user scrolls */
  useEffect(() => {
    if (!pdfViewer_page) return;

    set_new_pdfViewer_page(pdfViewer_page);
    if (inputRef.current) {
      inputRef.current.value = pdfViewer_page.toString();
    }
  }, [pdfViewer_page]);

  return (
    <span style={{ opacity: pdfViewer_page === 0 ? 0 : 1 }}>
      {pdfViewer_page === 0 ? (
        '...'
      ) : (
        <input
          ref={inputRef}
          defaultValue={pdfViewer_page}
          style={{ width: '40px', textAlign: 'right' }}
          onChange={(event) => set_new_pdfViewer_page(parseInt(event.target.value) - 1)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && pdfViewer_documentId && new_pdfViewer_page !== undefined) {
              scrollToPage(pdfViewerVariant, pdfViewer_documentId, new_pdfViewer_page);
            }
          }}
        />
      )}{' '}
      / {document?.num_pages}
    </span>
  );
}
