export const SEARCH_BAR_INPUT_ID = 'search-bar-input';

export const setSearchBarInputValue = (value: string) => {
  const el = document.getElementById(SEARCH_BAR_INPUT_ID) as HTMLInputElement;
  if (!el) return;

  el.value = value;
  el.focus();
};

export const getSearchBarInputValue = () => {
  const el = document.getElementById(SEARCH_BAR_INPUT_ID) as HTMLInputElement;
  if (!el) return;

  return el.value;
};

export const focusSearchBarInput = () => {
  const el = document.getElementById(SEARCH_BAR_INPUT_ID) as HTMLInputElement;
  if (!el) return;
  el.focus();
  // Hack because sometimes it doesn't focus :'(
  setTimeout(() => {
    el?.focus?.();
  }, 500);
};
