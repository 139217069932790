import { Box, Button, Grid, ListItemIcon, MenuItem } from '@material-ui/core';
import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_taglist_id,
} from '../../../../controllers/useGlobalQueryParams';

import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Codefy from '../../../../codefy';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import GenericContextMenu from '../../../menus/genericContextMenu';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import React from 'react';
import { t } from '../../../../controllers/websocket/popupHandler';
import useDirectoryTaglists from '../../../../hooks/useDirectoryTaglists';
import { useOpenCreateTaglistDialog } from '../../../dialogs/create/createTaglistDialog';
import { useStyles } from '../../../menus/selectionMenu';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';

export default function TaglistSelector({
  taglistType,
  directoryId,
  menu,
}: {
  taglistType: 'annotation' | 'entry';
  directoryId: Codefy.Objects.Directory['id'];
  menu?: boolean;
}) {
  const classes = useStyles();
  const [taglist_id, set_taglist_id] = useQueryParam_taglist_id({ taglistType });
  const { data: taglist } = useTaglistsGet({ taglist_id: taglist_id });
  const currentProjectTaglists = useDirectoryTaglists({ taglistType, directoryId });
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const openCreateTaglistDialog = useOpenCreateTaglistDialog();

  const icon = taglist_id ? (
    <EntryFileTypeIcon
      entryMimetype={
        taglistType === 'annotation'
          ? 'application/codefy.annotation-taglist'
          : 'application/codefy.entry-taglist'
      }
    />
  ) : taglistType === 'annotation' ? (
    <LabelOutlinedIcon />
  ) : (
    <LabelIcon />
  );

  const text = taglist_id ? (
    <Grid container alignContent="center">
      {taglist?.path.entry_name} <ArrowDropDownIcon />
    </Grid>
  ) : (
    t('viewer.contextMenu.highlight')
  );

  return (
    <GenericContextMenu
      openOnLeftClick
      menuEntries={
        currentProjectTaglists.length === 0
          ? [
              {
                key: 'no_lists',
                text: t('taglist.noListsAvailable'),
                inactive: true,
              },
              {
                key: 'createNew',
                text: t('taglist.createNewList'),
                icon: <AddIcon />,
                onClick: () => {
                  if (!entriesList_directoryId) return;
                  openCreateTaglistDialog({ directoryId: entriesList_directoryId, taglistType })();
                },
              },
            ]
          : currentProjectTaglists.map((entry) => ({
              key: entry.id.toString(),
              selected: taglist_id === entry.taglist?.id,
              text: entry.name,
              icon: (
                <EntryFileTypeIcon
                  entryMimetype={
                    taglistType === 'annotation'
                      ? 'application/codefy.annotation-taglist'
                      : 'application/codefy.entry-taglist'
                  }
                />
              ),
              onClick: () => {
                set_taglist_id(entry.taglist?.id);
              },
            }))
      }>
      {menu ? (
        <MenuItem>
          <ListItemIcon>{icon}</ListItemIcon>
          {text}
        </MenuItem>
      ) : (
        <Button className={classes.horizontalMenuButton}>
          <Box component="span" className={classes.horizontalMenuIconBox} height={30}>
            {icon}
          </Box>

          {text}
        </Button>
      )}
    </GenericContextMenu>
  );
}
