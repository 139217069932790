import React, { useState } from 'react';

import Codefy from '../../../codefy';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GenericCreateDialog from '../genericCreateDialog';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { directoriesCreate } from '../../../controllers/api/actions/directories/directoriesCreate';
import { useBetween } from 'use-between';
import { useTranslation } from 'react-i18next';

type DialogCreateDirectoryStateType = {
  directoryId: Codefy.Objects.Directory['id'];
  isCase: boolean;
};

const useDialogCreateDirectoryState = () => useState<DialogCreateDirectoryStateType>();

const useSharedDialogCreateDirectoryState = () => useBetween(useDialogCreateDirectoryState);

export const CreateDirectoryDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogCreateDirectoryState();

  const onClose = () => setState(undefined);

  const onCreate = async (name: string) => {
    if (!name || !state?.directoryId) return;
    await directoriesCreate({
      directory_id: state.directoryId,
      name,
      type: state.isCase ? 'case' : 'directory',
    });
  };

  return (
    <GenericCreateDialog
      open={!!state}
      title={state?.isCase ? t('createDialog.newCase') : t('createDialog.newFolder')}
      icon={state?.isCase ? <WorkOutlineIcon /> : <FolderOpenIcon />}
      onClose={onClose}
      onCreate={onCreate}
    />
  );
};

export const useOpenCreateDirectoryDialog = () => {
  const [, setState] = useSharedDialogCreateDirectoryState();
  return (state: DialogCreateDirectoryStateType) => () => setState(state);
};
