import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const PUBLICATIONS_GET_BY_UUID_KEY_TYPE = 'publicationsGetByUuid';

export const PUBLICATIONS_GET_BY_UUID_URL = '/api/v1/publications/get_by_uuid';

export const publicationsGetByUuidKey = (
  publication_uuid?: Codefy.Objects.Publication['uuid'],
): [string, Codefy.Objects.Publication['uuid'] | undefined] => [
  PUBLICATIONS_GET_BY_UUID_KEY_TYPE,
  publication_uuid,
];

export const usePublicationsGetByUuid = (
  publication_uuid?: Codefy.Objects.Publication['uuid'] | string,
) => {
  return useQuery(publicationsGetByUuidKey(publication_uuid), async () => {
    if (!publication_uuid) return undefined;
    const { data } = await axios.get(PUBLICATIONS_GET_BY_UUID_URL, {
      params: { publication_uuid },
      paramsSerializer: fastApiParamsSerializer,
    });
    return data as Codefy.Objects.Publication;
  });
};
