import {
  CommentSubscriptionLevel,
  useCommentSubscriptionsGet,
} from '../../../../controllers/api/subscriptions/commentSubscriptions/commentSubscriptionsGet';
import React, { useMemo } from 'react';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { GenericCommentParameters } from '../../../../controllers/api/subscriptions/comments/commentsList';
import GenericContextMenu from '../../../menus/genericContextMenu';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { commentSubscriptionsSet } from '../../../../controllers/api/actions/commentSubscriptions/commentSubscriptionsSet';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useTranslation } from 'react-i18next';

export default function CommentSubscription({
  commentParams,
}: {
  commentParams: GenericCommentParameters;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const { data: subscriptionLevel } = useCommentSubscriptionsGet(commentParams);

  const text = useMemo(() => {
    switch (subscriptionLevel) {
      case CommentSubscriptionLevel.all:
        return t('annotations.comments.subscriptions.all');
      case CommentSubscriptionLevel.mentions:
        return t('annotations.comments.subscriptions.mentions');
      case CommentSubscriptionLevel.none:
        return t('annotations.comments.subscriptions.none');
    }
  }, [subscriptionLevel]);

  const menuEntries: GenericNestableMenuEntry[] = [
    {
      key: 'all',
      selected: subscriptionLevel === CommentSubscriptionLevel.all,
      icon: <VisibilityIcon />,
      text: t('annotations.comments.subscriptionsContextMenu.all'),
      onClick: () =>
        commentSubscriptionsSet({ ...commentParams, level: CommentSubscriptionLevel.all }),
    },
    {
      key: 'mentions',
      selected: subscriptionLevel === CommentSubscriptionLevel.mentions,
      icon: <AlternateEmailIcon />,
      text: t('annotations.comments.subscriptionsContextMenu.mentions'),
      onClick: () =>
        commentSubscriptionsSet({ ...commentParams, level: CommentSubscriptionLevel.mentions }),
    },
    {
      key: 'none',
      selected: subscriptionLevel === CommentSubscriptionLevel.none,
      icon: <NotificationsOffIcon />,
      text: t('annotations.comments.subscriptionsContextMenu.none'),
      onClick: () =>
        commentSubscriptionsSet({ ...commentParams, level: CommentSubscriptionLevel.none }),
      disabled: true, // Currently disabled so that we don't have to handle displaying unsubscribed users etc.
    },
  ];

  return (
    <GenericContextMenu openOnLeftClick menuEntries={menuEntries}>
      <div className={globalClasses.link}>
        {/* Note that no icon is shown here as it would add a lot of visual clutter
        during everyday use and manually setting the notification settings is
        considered a poweruser feature */}
        <span className={globalClasses.textLight}>{text}</span>
      </div>
    </GenericContextMenu>
  );
}
