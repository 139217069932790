import { Badge } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Codefy from '../../../../codefy';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import { useCommentsList } from '../../../../controllers/api/subscriptions/comments/commentsList';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

/** Open all comments inside a document/directory (note: does not ONLY mean the comments
 * of the document/directory itself) */
export function CommentsMenuEntry({
  directoryId,
  documentId,
}: {
  directoryId?: Codefy.Objects.Directory['id'];
  documentId?: Codefy.Objects.Document['id'];
}): GenericNestableMenuEntry | undefined {
  const paneActions = usePaneActions();
  const { t } = useTranslation();

  const { data: directory } = useDirectoriesGet(directoryId);
  const { data: document } = useDocumentsGet(documentId);
  const { id: currentCaseId } = useCurrentCase();

  const name =
    document?.path.entry_name ||
    directory?.path?.entry_name ||
    directory?.path?.project_name ||
    '...';

  const { data: commentsPages, isFetching } = useCommentsList({
    case_id: currentCaseId,
    directory_ids: directoryId ? [directoryId] : undefined,
    document_ids: documentId ? [documentId] : undefined,
  });

  const commentsCount = !isFetching ? commentsPages?.pages[0]?.num_comments || 0 : 0;

  return {
    key: 'comments',
    icon: (
      <Badge
        badgeContent={commentsCount}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <ChatOutlinedIcon />
      </Badge>
    ),
    text: t('comments.showCommentsInside', { name }),
    onClick: () => {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.comments,
        params: {
          comments_documentId: documentId,
          comments_directoryId: directoryId,
        },
        reset: true,
      });
    },
  };
}
