import Codefy from '../../../../codefy';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import { Grid } from '@material-ui/core';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_taglist_id } from '../../../../controllers/useGlobalQueryParams';
import useTaglistMenuEntries from './useTaglistMenuEntries';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';

export default function TaglistPaneTitleBar({
  taglistType,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const [taglist_id] = useQueryParam_taglist_id({ taglistType });
  const { data: taglist, isError } = useTaglistsGet({ taglist_id });
  const paneActions = usePaneActions();
  const openRenameEntryDialog = useOpenRenameEntryDialog();

  const taglistMenuEntries = useTaglistMenuEntries({ taglist });

  return (
    <PaneTitleBar
      icon={
        taglist &&
        (taglist?.type === 'annotation' ? (
          <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />
        ) : (
          <EntryFileTypeIcon entryMimetype="application/codefy.entry-taglist" />
        ))
      }
      onRenameCallback={taglist && openRenameEntryDialog({ entryId: taglist?.path.entry_id })}
      title={
        <span>
          <Grid container alignItems="baseline" spacing={1} wrap="nowrap">
            <Grid item>{taglist?.path.entry_name}</Grid>
          </Grid>
        </span>
      }
      paneKey={taglistType === 'annotation' ? PaneKeys.annotationTaglist : PaneKeys.entryTaglist}
      menuEntries={taglistMenuEntries}
      isError={isError}
      onCollapseRightPane={() => {
        if (taglistType === 'annotation') {
          paneActions.closePanes([PaneKeys.annotationTag, PaneKeys.tagInstancePredictions]);
        } else {
          paneActions.closePanes([PaneKeys.entryTag]);
        }
      }}
    />
  );
}
