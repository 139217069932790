import Codefy from '../../../codefy';
import { CommentsPaneTitleBar } from './comments/commentsPaneTitleBar';
import EntriesList from './entriesList/entriesList';
import EntriesListPaneTitleBar from './entriesList/entriesListPaneTitleBar';
import MentionsList from './mentionsList/mentionsList';
import MentionsListPaneTitleBar from './mentionsList/mentionsListPaneTitleBar';
import { PaneKeys } from './paneKeys';
import PdfViewer from './pdfViewer/pdfViewer';
import PdfViewerPaneTitleBar from './pdfViewer/pdfViewerPaneTitleBar';
import ProjectsListContent from './projectsList/projectsList';
import ProjectsListPaneTitleBar from './projectsList/projectsListPaneTitleBar';
import React from 'react';
import SearchPaneTitleBar from './search/searchPaneTitleBar/searchPaneTitleBar';
import SearchResultsPaneContent from './search/searchResultsPaneContent';
import Tag from './tag/tag';
import TagDescription from './tagDescription/tagDescription';
import TagDescriptionPaneTitleBar from './tagDescription/tagDescriptionPaneTitleBar';
import TagInstancePredictions from './tagInstancePredictions/tagInstancePredictions';
import TagInstancePredictionsPaneTitleBar from './tagInstancePredictions/tagInstancePredictionsPaneTitleBar';
import TagPaneTitleBar from './tag/tagPaneTitleBar';
import Taglist from './taglist/taglist';
import TaglistPaneTitleBar from './taglist/taglistPaneTitleBar';
import UploadBatch from './uploadBatch/uploadBatch';
import UploadBatchPaneTitleBar from './uploadBatch/uploadBatchPaneTitleBar';
import UploadBatches from './uploadBatches/uploadBatches';
import UploadBatchesPaneTitleBar from './uploadBatches/uploadBatchesPaneTitleBar';
import UserCommentsList from './comments/comments';

export type PaneType = {
  key: PaneKeys;
  content: React.ReactElement;
  paneTitleBar: React.ReactElement;
  width: number;
  /** Is the user is missing this feature flag, hide the pane */
  needsFeatureFlag?: Codefy.Objects.FeatureFlags;
  /** If the user is not logged in, hide the pane */
  needsLogin?: boolean;
  closable?: boolean;
  minWidth?: number;
};

export enum PANE_WIDTHS {
  'SMALL' = 300,
  'MEDIUM' = 400,
  'LARGE' = 550,
  /** For panes that might display preview snippets, these should be a bit wider
   * by default so that the text is legible */
  'SNIPPETS' = 750,
  /* Note that many users have screens with 1280px width, so the default should be somewhere below
  that to give the visual impression that there are more panes than the
  pdfViewer */
  'HUGE' = 1150,
}

export const paneTypes: PaneType[] = [
  {
    key: PaneKeys.projectsList,
    content: <ProjectsListContent />,
    paneTitleBar: <ProjectsListPaneTitleBar />,
    width: PANE_WIDTHS.SMALL,
    closable: false,
    needsLogin: true,
  },
  {
    key: PaneKeys.entriesList,
    content: <EntriesList />,
    paneTitleBar: <EntriesListPaneTitleBar />,
    closable: false,
    width: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.entryTaglist,
    content: <Taglist taglistType="entry" />,
    paneTitleBar: <TaglistPaneTitleBar taglistType="entry" />,
    width: PANE_WIDTHS.MEDIUM,
    needsFeatureFlag: 'operations_tags',
  },
  {
    key: PaneKeys.entryTag,
    content: <Tag taglistType="entry" />,
    paneTitleBar: <TagPaneTitleBar taglistType="entry" />,
    width: PANE_WIDTHS.LARGE,
    needsFeatureFlag: 'operations_tags',
  },
  {
    key: PaneKeys.search,
    content: <SearchResultsPaneContent />,
    paneTitleBar: <SearchPaneTitleBar />,
    width: PANE_WIDTHS.SNIPPETS,
  },
  {
    key: PaneKeys.pdfViewer,
    content: <PdfViewer pdfViewerVariant={1} />,
    paneTitleBar: <PdfViewerPaneTitleBar pdfViewerVariant={1} />,
    width: PANE_WIDTHS.HUGE,
    minWidth: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.pdfViewer2,
    content: <PdfViewer pdfViewerVariant={2} />,
    paneTitleBar: <PdfViewerPaneTitleBar pdfViewerVariant={2} />,
    width: PANE_WIDTHS.HUGE,
    minWidth: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.annotationTaglist,
    content: <Taglist taglistType="annotation" />,
    paneTitleBar: <TaglistPaneTitleBar taglistType="annotation" />,
    width: PANE_WIDTHS.MEDIUM,
  },

  {
    key: PaneKeys.tagInstancePredictions,
    content: <TagInstancePredictions />,
    paneTitleBar: <TagInstancePredictionsPaneTitleBar />,
    width: PANE_WIDTHS.SNIPPETS,
  },
  {
    key: PaneKeys.annotationTag,
    content: <Tag taglistType="annotation" />,
    paneTitleBar: <TagPaneTitleBar taglistType="annotation" />,
    width: PANE_WIDTHS.SNIPPETS,
  },
  {
    key: PaneKeys.annotationTagDescription,
    content: <TagDescription taglistType="annotation" />,
    paneTitleBar: <TagDescriptionPaneTitleBar taglistType="annotation" />,
    width: PANE_WIDTHS.MEDIUM,
  },
  {
    key: PaneKeys.comments,
    content: <UserCommentsList />,
    paneTitleBar: <CommentsPaneTitleBar />,
    width: PANE_WIDTHS.MEDIUM,
    needsLogin: true,
  },
  {
    key: PaneKeys.mentionsList,
    content: <MentionsList />,
    paneTitleBar: <MentionsListPaneTitleBar />,
    width: PANE_WIDTHS.MEDIUM,
    needsLogin: true,
  },
  {
    key: PaneKeys.uploadBatches,
    content: <UploadBatches />,
    paneTitleBar: <UploadBatchesPaneTitleBar />,
    width: PANE_WIDTHS.MEDIUM,
    needsLogin: true,
  },
  {
    key: PaneKeys.uploadBatch,
    content: <UploadBatch />,
    paneTitleBar: <UploadBatchPaneTitleBar />,
    width: PANE_WIDTHS.LARGE,
    needsLogin: true,
  },
];
