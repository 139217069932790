import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMeasure } from 'react-use';
import Codefy from '../../../../codefy';
import { useProjectsList } from '../../../../controllers/api/subscriptions/projects/projectsList';
import {
  useUserSetting_projects_descending,
  useUserSetting_projects_filterByOwner,
  useUserSetting_projects_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';
import {
  useQueryParam_projectsList_name,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import { useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import { ProjectsListEntry } from './projectsListItem';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function ProjectsListContent() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [projectsList_name] = useQueryParam_projectsList_name();
  const [projectsList_descending] = useUserSetting_projects_descending();
  const [projectsList_orderBy] = useUserSetting_projects_orderBy();
  const [projectsList_filterByOwner] = useUserSetting_projects_filterByOwner();
  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const { data: projectsPages, isLoading, fetchNextPage, hasNextPage } = useProjectsList({
    name: projectsList_name,
    order_by: trashMode ? 'trashed_at' : projectsList_orderBy,
    descending: trashMode ? true : projectsList_descending,
    trashed: trashMode,
  });

  const projectsList =
    projectsPages?.pages.reduce((acc: Codefy.Objects.Project[], cur) => {
      for (const project of cur.projects) {
        // If filtering is set, only add matching
        if (projectsList_filterByOwner && projectsList_filterByOwner == project.created_by?.email) {
          acc.push(project);
        }
        // If filtering is not set, add all
        if (!projectsList_filterByOwner) {
          acc.push(project);
        }
      }
      return acc;
    }, []) || [];

  if (isLoading && projectsList.length === 0) {
    return <PaneContentLoading />;
  }

  if (projectsList.length === 0)
    return (
      <PaneContentEmpty
        text={trashMode ? trashModeStrings('binEmpty') : t('projectsList.noProjects')}
      />
    );

  return (
    <div className={classes.root} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={projectsList?.length || 0}
        next={fetchNextPage}
        height={height - 1}
        hasMore={!!hasNextPage}
        loader={<PaneContentLoading />}>
        {projectsList.map((project) => (
          <ProjectsListEntry key={project.id} project={project} />
        ))}
      </InfiniteScroll>
    </div>
  );
}
