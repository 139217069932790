import i18n, { i18n_2_to_3_converter } from '../../../../i18n/i18n';

import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiDataSerializer } from '../../subscriptionHelpers';

export const userRegister = (data: Codefy.API.Users.Register.RequestData) => {
  return async () => {
    const config: Codefy.API.Users.Register.RequestConfig = {
      url: '/api/v1/users/register',
      method: 'POST',
      data: fastApiDataSerializer({
        ...data,
        email: data.email.trim(),
        language: i18n_2_to_3_converter(i18n.language),
      }),
    };

    return await axios(config);
  };
};
