import React, { useMemo } from 'react';
import {
  useUserSetting_projects_descending,
  useUserSetting_projects_filterByOwner,
  useUserSetting_projects_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import AddIcon from '@material-ui/icons/Add';
import Codefy from '../../../../codefy';
import { E2E } from '../../../../testing/common/data-e2e-ids';
import FilterListIcon from '@material-ui/icons/FilterList';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import PersonIcon from '@material-ui/icons/PersonOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import { useOpenCreateProjectDialog } from '../../../dialogs/create/createProjectDialog';
import { useSelector } from 'react-redux';
import useSetSearchParams from '../../../navbar/searchBar/useSetSearchParams';
import { useTranslation } from 'react-i18next';
import { useUsersList } from '../../../../controllers/api/subscriptions/users/usersList';

export default function ProjectsListPaneTitleBar() {
  const { t } = useTranslation();

  const [
    projectsList_descending,
    set_projectsList_descending,
  ] = useUserSetting_projects_descending();
  const [projectsList_orderBy, set_projectsList_orderBy] = useUserSetting_projects_orderBy();
  const [
    projectsList_filterByOwner,
    set_projectsList_filterByOwner,
  ] = useUserSetting_projects_filterByOwner();

  const openCreateProjectDialog = useOpenCreateProjectDialog();

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);

  const { data: users } = useUsersList({ project: true });
  const projectOwners = users?.users ?? [];
  const filteringEntries = [
    {
      key: 'projectsList_filterByOwner_all',
      text: t('projectsListPaneTitleBar.filterByAll'),
      onClick: () => set_projectsList_filterByOwner(undefined),
      selected: projectsList_filterByOwner === undefined,
    },
    {
      key: 'projectsList_filterByOwner_me',
      text: t('projectsListPaneTitleBar.filterByMe'),
      onClick: () => set_projectsList_filterByOwner(userEmail),
      selected: projectsList_filterByOwner === userEmail,
    },
  ];
  projectOwners.forEach((owner) => {
    filteringEntries.push({
      key: 'projectsList_filterByOwner_' + owner,
      text: owner.email,
      onClick: () => set_projectsList_filterByOwner(owner.email),
      selected: projectsList_filterByOwner === owner.email,
    });
  });

  const onSetSearchScopeEverywhere = useSetSearchParams('everywhere');

  const icon = useMemo(() => <HomeRoundedIcon />, []);

  /** If we didn't get the users email yet, don't show anything but the login screen.
   *  This is only possible because every user always must have an email address. */
  if (!userEmail) {
    return null;
  }

  return (
    <PaneTitleBar
      title={t('projectsList.projects')}
      paneKey={PaneKeys.projectsList}
      icon={icon}
      onSearchCallback={onSetSearchScopeEverywhere}
      onSearchCallbackText={t('projectsListPaneTitleBar.searchEverywhere')}
      menuEntries={[
        {
          key: 'new',
          text: t('projectsListPaneTitleBar.newWorkspace'),
          icon: <AddIcon />,
          onClick: openCreateProjectDialog,
          dataE2eId: E2E.projectsList_createProject_button,
        },
        {
          key: 'sort',
          text: t('projectsListPaneTitleBar.sortBy'),
          icon: <SortIcon />,
          menuEntries: [
            {
              key: 'sortName',
              text: t('projectsListPaneTitleBar.name'),
              icon: <SortByAlphaIcon />,
              selected: projectsList_orderBy === 'name',
              menuEntries: [
                {
                  key: 'sortNameAscending',
                  text: t('projectsListPaneTitleBar.AZName'),
                  selected: !projectsList_descending && projectsList_orderBy === 'name',
                  onClick: async () => {
                    await set_projectsList_descending(false);
                    await set_projectsList_orderBy('name');
                  },
                },
                {
                  key: 'sortNameDescending',
                  text: t('projectsListPaneTitleBar.ZAName'),
                  selected: projectsList_descending && projectsList_orderBy === 'name',
                  onClick: async () => {
                    await set_projectsList_descending(true);
                    await set_projectsList_orderBy('name');
                  },
                },
              ],
            },
            {
              key: 'sortCreationDate',
              text: t('projectsListPaneTitleBar.creationDate'),
              icon: <ScheduleIcon />,
              selected: projectsList_orderBy === 'created_at',
              menuEntries: [
                {
                  key: 'sortNameAscending',
                  text: t('projectsListPaneTitleBar.oldestFirst'),
                  selected: !projectsList_descending && projectsList_orderBy === 'created_at',
                  onClick: async () => {
                    await set_projectsList_descending(false);
                    await set_projectsList_orderBy('created_at');
                  },
                },
                {
                  key: 'sortNameDescending',
                  text: t('projectsListPaneTitleBar.newestFirst'),
                  selected: projectsList_descending && projectsList_orderBy === 'created_at',
                  onClick: async () => {
                    await set_projectsList_descending(true);
                    await set_projectsList_orderBy('created_at');
                  },
                },
              ],
            },
            {
              key: 'sortAccessDate',
              text: t('projectsListPaneTitleBar.accessDate'),
              icon: <ScheduleIcon />,
              selected: projectsList_orderBy === 'accessed_at',
              menuEntries: [
                {
                  key: 'sortNameAscending',
                  text: t('projectsListPaneTitleBar.oldestFirst'),
                  selected: !projectsList_descending && projectsList_orderBy === 'accessed_at',
                  onClick: async () => {
                    await set_projectsList_descending(false);
                    await set_projectsList_orderBy('accessed_at');
                  },
                },
                {
                  key: 'sortNameDescending',
                  text: t('projectsListPaneTitleBar.newestFirst'),
                  selected: projectsList_descending && projectsList_orderBy === 'accessed_at',
                  onClick: async () => {
                    await set_projectsList_descending(true);
                    await set_projectsList_orderBy('accessed_at');
                  },
                },
              ],
            },
          ],
        },
        {
          key: 'filter',
          text: t('projectsListPaneTitleBar.filterBy'),
          icon: <FilterListIcon />,
          selected: !!projectsList_filterByOwner,
          menuEntries: [
            {
              key: 'filterType',
              text: t('projectsListPaneTitleBar.filterByOwner'),
              icon: <PersonIcon />,
              selected: !!projectsList_filterByOwner,
              menuEntries: filteringEntries,
            },
          ],
        },
      ]}
    />
  );
}
