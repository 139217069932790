import { Box, Button, FilledInputProps, TextField } from '@material-ui/core';
import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';

import { E2E } from '../../testing/common/data-e2e-ids';
import { GenericDialog } from './genericDialog';
import PaneContentLoading from '../panes/paneContentLoading';
import { useGlobalStyles } from '../../globalThemeSettings';
import { useTranslation } from 'react-i18next';

export default function GenericCreateDialog({
  open,
  title,
  icon,
  onCreate,
  onClose,
}: {
  open: boolean;
  title: string;
  icon: JSX.Element;
  onCreate: (name: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await onCreate(name);
    setIsLoading(false);
    setName('');
    onClose();
  };

  const onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) =>
    setName(event.target.value);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const InputProps: Partial<FilledInputProps> = {
    startAdornment: <Box mr={1}>{icon}</Box>,
    endAdornment: (
      <Button
        onClick={onSubmit}
        disabled={!name || isLoading}
        color="primary"
        className={globalClasses.dialogButton}>
        {t('createDialog.create')}
      </Button>
    ),
    disableUnderline: true,
  };

  return (
    <GenericDialog title={title} onClose={onClose} open={open}>
      <Box>
        <TextField
          data-e2e-id={E2E.dialog_generic_create_input}
          placeholder={t('createDialog.enterName')}
          value={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          fullWidth
          autoFocus
          InputProps={InputProps}
        />
        {isLoading && (
          <Box p={2}>
            <PaneContentLoading />
          </Box>
        )}
      </Box>
    </GenericDialog>
  );
}
