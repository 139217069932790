import Codefy from '../codefy';
import { useDirectoriesParents } from '../controllers/api/subscriptions/directories/directoriesParents';
import { useEntriesList } from '../controllers/api/subscriptions/entries/entriesList';

export default function useDirectoryTaglists({
  taglistType,
  directoryId,
}: {
  taglistType: 'annotation' | 'entry';
  directoryId?: Codefy.Objects.Directory['id'];
}) {
  const { data: directories } = useDirectoriesParents(directoryId);

  const directory_ids = directories?.directories.map((directory) => directory.id);

  /** Add the directory itself, since we want to include "siblings" */
  if (directory_ids && directoryId) {
    directory_ids.push(directoryId);
  }

  const { data: taglists } = useEntriesList({
    directory_ids: directory_ids,
    recursive: false,
    mimetypes: [
      taglistType === 'annotation'
        ? 'application/codefy.annotation-taglist'
        : 'application/codefy.entry-taglist',
    ],
    limit: 999,
  });

  if (directoryId) {
    return taglists?.pages[0].entries || [];
  }

  return [];
}
