import { Box } from '@material-ui/core';
import MentionsIcon from '../../../icons/mentionsIcon';
import { PaneKeys } from '../../paneTypes/paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MentionsListPaneTitleBar() {
  const { t } = useTranslation();

  return (
    <PaneTitleBar
      title={t('mentions.mentions')}
      icon={
        <Box mb={-1}>
          <MentionsIcon />
        </Box>
      }
      paneKey={PaneKeys.mentionsList}
    />
  );
}
