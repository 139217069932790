import { AccountDialog } from './accountDialog';
import { CancelUploadBatchUploadsDialog } from './delete/cancelUploadsBatchUploads';
import CommentsDialog from './commentsDialog';
import { ConfirmInviteDialog } from './share/confirmInviteDialog';
import { CreateDirectoryDialog } from './create/createDirectoryDialog';
import { CreateProjectDialog } from './create/createProjectDialog';
import { CreatePublicationDialog } from './publication/createPublicationDialog';
import { CreateTagDialog } from './create/createTagDialog';
import { CreateTaglistDialog } from './create/createTaglistDialog';
import { DeleteAccountDialog } from './delete/deleteAccountDialog';
import { DeleteAnnotationDialog } from './delete/deleteAnnotationDialog';
import { DeleteEntryDialog } from './delete/deleteEntryDialog';
import { DeleteProjectDialog } from './delete/deleteProjectDialog';
import { DeleteTagDialog } from './delete/deleteTagDialog';
import { DeleteTagInstanceDialog } from './delete/deleteTagInstanceDialog';
import { DeleteUploadBatchDialog } from './delete/deleteUploadBatchDialog';
import { EditTagCellDialog } from './edit/editTagCellDialog';
import { EditTagDialog } from './edit/editTagDialog';
import { EditTagSavedSearchTermsDialog } from './edit/editTagSavedSearchTermsDialog';
import ExportPrettyWordDialog from './export/exportPrettyWordDialog';
import { MoveEntryDialog } from './moveEntryDialog';
import React from 'react';
import { RenameEntryDialog } from './rename/renameEntryDialog';
import { RenameProjectDialog } from './rename/renameProjectDialog';
import { ShareProjectDialog } from './share/shareProjectDialog';
import { UnshareProjectDialog } from './delete/unshareProjectDialog';

export default function DialogsRenderer() {
  return (
    <>
      <CreateProjectDialog />
      <CreateDirectoryDialog />
      <CreateTaglistDialog />
      <CreateTagDialog />
      <CreatePublicationDialog />

      <ShareProjectDialog />
      <UnshareProjectDialog />
      <ConfirmInviteDialog />

      <RenameProjectDialog />
      <RenameEntryDialog />

      <MoveEntryDialog />

      <EditTagDialog />
      <EditTagCellDialog />
      <EditTagSavedSearchTermsDialog />

      <ExportPrettyWordDialog />

      <CommentsDialog />

      <DeleteProjectDialog />
      <DeleteTagDialog />
      <DeleteTagInstanceDialog />
      <DeleteEntryDialog />
      <DeleteAccountDialog />
      <DeleteAnnotationDialog />
      <DeleteUploadBatchDialog />
      <CancelUploadBatchUploadsDialog />

      <AccountDialog />
    </>
  );
}
