import {
  DocumentIdContext,
  PdfPageIndexContext,
  PdfPagePageHeightContext,
  PdfPagePageWidthContext,
  PdfViewerVariantType,
  getPdfPageElementId,
} from '../pdfViewer';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import SelectionMenu from '../../../../menus/selectionMenu';
import { useDocumentsGet } from '../../../../../controllers/api/subscriptions/documents/documentsGet';

export const PDF_PAGE_AREA_SELECTION_LAYER_Z_INDEX = 500;

export default function PdfPageAreaSelectionLayer({
  pdfViewerVariant,
}: {
  pdfViewerVariant: PdfViewerVariantType;
}) {
  const documentId = useContext(DocumentIdContext);
  const pageIndex = useContext(PdfPageIndexContext);
  const pageWidth = useContext(PdfPagePageWidthContext);
  const pageHeight = useContext(PdfPagePageHeightContext);

  const { data: _document } = useDocumentsGet(documentId);

  /** Set a min size to prevent simply clicking on the page from opening the Seletion Menu */
  const MIN_SIZE = 10;

  const [xmin, set_xmin] = useState<number>();
  const [xmax, set_xmax] = useState<number>();
  const [ymin, set_ymin] = useState<number>();
  const [ymax, set_ymax] = useState<number>();
  const [selecting, set_selecting] = useState<boolean>();

  const onReset = () => {
    set_xmin(undefined);
    set_xmax(undefined);
    set_ymin(undefined);
    set_ymax(undefined);
    set_selecting(false);
  };

  useEffect(() => {
    const pageElementId = getPdfPageElementId(pdfViewerVariant, documentId, pageIndex);
    const pageElement = document.getElementById(pageElementId) as HTMLDivElement;
    if (!pageElement) return;

    const onMouseDown = (event: MouseEvent) => {
      set_selecting(true);
      set_xmin(event.offsetX);
      set_ymin(event.offsetY);
    };

    const onMouseMove = (event: MouseEvent) => {
      set_xmax(event.offsetX);
      set_ymax(event.offsetY);
    };

    const onMouseUp = () => {
      if (
        xmin &&
        xmax &&
        ymin &&
        ymax &&
        Math.abs(xmax - xmin) > MIN_SIZE &&
        Math.abs(ymax - ymin) > MIN_SIZE
      ) {
        set_selecting(false);
      } else {
        onReset();
      }
    };

    pageElement.addEventListener('mousedown', onMouseDown);
    pageElement.addEventListener('mousemove', onMouseMove);
    pageElement.addEventListener('mouseup', onMouseUp);

    return () => {
      pageElement.removeEventListener('mousedown', onMouseDown);
      pageElement.removeEventListener('mousemove', onMouseMove);
      pageElement.removeEventListener('mouseup', onMouseUp);
    };
  });

  const ref = useRef<HTMLDivElement>(null);

  const areaSelectionDivStyle: React.CSSProperties = useMemo(
    () => ({
      zIndex: PDF_PAGE_AREA_SELECTION_LAYER_Z_INDEX,
      width: pageWidth,
      height: pageHeight,
      position: 'absolute',
      top: 0,
      left: 0,
    }),
    [],
  );

  return (
    <>
      {xmin &&
        xmax &&
        ymin &&
        ymax &&
        Math.abs(xmax - xmin) > MIN_SIZE &&
        Math.abs(ymax - ymin) > MIN_SIZE && (
          <>
            {ref.current && !selecting && (
              <SelectionMenu
                isArea
                selectedBoxes={[
                  {
                    page: pageIndex + 1,
                    xmin: xmin / pageWidth,
                    xmax: xmax / pageWidth,
                    ymin: ymin / pageHeight,
                    ymax: ymax / pageHeight,
                  },
                ]}
                handleContextMenuClose={onReset}
                menuAnchorElement={ref.current}
                source="pdfviewer-page-area-selection"
                _document={_document}
              />
            )}
            <div
              ref={ref}
              style={{
                border: '2px dashed #003366',
                backgroundColor: '#00336622',
                position: 'absolute',
                left: Math.min(xmin, xmax),
                top: Math.min(ymin, ymax),
                width: Math.abs(xmax - xmin),
                height: Math.abs(ymax - ymin),
              }}>
              <div ref={ref} style={{ position: 'absolute', top: ymax - 200, left: xmax - 200 }} />
            </div>
          </>
        )}
      <div style={areaSelectionDivStyle} />
    </>
  );
}
