import * as serviceWorker from './serviceWorker';

import { App } from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import connectToNotificationsWebsocket from './controllers/websocket/connectToNotificationsWebsocket';
import { queryClient } from './App';
//@ts-ignore
import wakeEvent from 'wake-event';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

/* Launches React onto the index.html file that the user's browser receives. */
ReactDOM.render(<App />, document.getElementById('root'));

/* This was autogenerated by create-react-app when creating the project. We do not need
 * this functionality at the moment, as our app does nothing usefull when offline. */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* We have changed this from .unregister() to .register() to enable PWA functionality. */
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

/* Refresh possibly stale connections when the computer has woken up from sleep (e.g. when the lid
was closed and has been opened again) */
wakeEvent(() => {
  /* Do not reload if the window is not actively used by the user */
  if (!document.hasFocus()) return;

  /* Reconnect to the websocket */
  connectToNotificationsWebsocket();

  /* Refresh all data since it might be out of date while computer was asleep */
  queryClient.invalidateQueries(() => true);
});
