import { COLORS } from '../../globalThemeSettings';
import React from 'react';

/** Icon used for indicating the "intelligent"/similarity search mode */
export default function SearchCodefyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path
            fill={COLORS.primary}
            d="m 10.699219,3.0390625 c -3.3686233,0.0515 -6.2357073,2.5835073 -6.5889403,5.9339552 0,0 1.8739762,0.021285 2.0145756,0.026982 0.8204625,-2.7148353 2.2828622,-3.9512253 4.6814977,-3.9522367 2.193534,-9.249e-4 4.407892,1.7167837 4.504195,4.6514555 -0.178761,2.8108802 -1.987108,4.5207432 -4.606925,4.5893122 -0.238073,-0.0309 -0.480244,-0.0095 -0.7129908,-0.06163 L 10,16.276916 c 0.23043,0.05867 0.463838,0.04875 0.699219,0.08246 C 12.279725,16.357687 13.806652,15.786302 15,14.75 l 0.279297,0.289062 v 0.810547 L 20.439453,21 22,19.439453 16.849609,14.310547 H 16 L 15.75,14 c 1.036302,-1.193348 1.607687,-2.720276 1.609375,-4.3007812 8.6e-5,-3.6783384 -2.981818,-6.6602425 -6.660156,-6.6601563 z"
          />
          <polygon fill={'#36c'} points="8.83 17.68 4.56 15.3 1 17.43 4.98 20 8.83 17.68" />
          <polygon fill={'#036'} points="8.66 12.84 4.97 10.6 1 13.19 1 17.43 8.66 12.84" />
        </g>
      </g>
    </svg>
  );
}
