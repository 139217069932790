import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useInfiniteQuery, useQuery } from 'react-query';

/** Helper function that builds the params in a way that our FastAPI backend
 * understands. This is relevant when submitting arrays. Supports both params and
 * formdata */
export const fastApiSerializer = (
  input: {
    [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
  },
  resultType: 'params' | 'form' = 'params',
) => {
  const result = resultType === 'params' ? new URLSearchParams() : new FormData();
  for (const [key, value] of Object.entries(input)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        if (element) {
          if (value instanceof File && resultType === 'form') {
            result.append(key, value);
          } else if (typeof element === 'object') {
            result.append(key, JSON.stringify(element));
          } else {
            result.append(key, element.toString());
          }
        }
      }
    } else {
      if (value || value === false || value === 0) {
        if (value instanceof File && resultType === 'form') {
          result.append(key, value);
        } else if (typeof value === 'object') {
          result.append(key, JSON.stringify(value));
        } else {
          result.append(key, value.toString());
        }
      }
    }
  }
  return resultType === 'params' ? result.toString() : result;
};

export const fastApiParamsSerializer = (input: {
  [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
}): string => fastApiSerializer(input, 'params') as string; // TODO: why does TypeScript throw an error if its not casted?

export const fastApiDataSerializer = (input: {
  [key: string]: string | number | undefined | string[] | number[] | undefined[] | any;
}): FormData => fastApiSerializer(input, 'form') as FormData; // TODO: why does TypeScript throw an error if its not casted?

/** Helper function that creates a useQuery hook that makes useQuery work in a way that allows
 * react-query to cancel the outgoing request when the user abandons the component that needs the
 * query */
export function useCreateCancellableQueryFn<RequestParams, ReturnType>(
  queryKey: string,
  axiosUrl: string,
  axiosConfig: (AxiosRequestConfig & { params: RequestParams }) | { data: FormData },
  disabled?: boolean,
) {
  const queryFn = () => {
    const source = axios.CancelToken.source();

    const promise = new Promise((resolve, reject) => {
      axios(axiosUrl, { ...axiosConfig, cancelToken: source.token })
        /* We do this since we only care about the data and just want to work with it right away and
      not have to write `foo.data` in our components */
        .then(({ data }) => resolve(data))
        .catch(reject);
    }) as Promise<ReturnType> & { cancel: () => void };

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

  return useQuery(queryKey, queryFn, { keepPreviousData: true, enabled: !disabled });
}

export function useCreateInfiniteQuery<ReturnType>(
  queryKey: QueryKey,
  limit: number,
  axiosUrl: string,
  axiosMethod: AxiosRequestConfig['method'],
  axiosParams: Record<string, any>,
  pageAttribute: string,
  keepPreviousData?: boolean,
  disabled?: boolean,
) {
  limit = limit || axiosParams.limit || 10;

  const queryFn = ({ pageParam = 0 }) => {
    const source = axios.CancelToken.source();

    const config: AxiosRequestConfig =
      axiosMethod === 'GET'
        ? {
            method: axiosMethod,
            params: { ...axiosParams, limit, offset: pageParam },
            paramsSerializer: fastApiParamsSerializer,
          }
        : {
            method: axiosMethod,
            data: fastApiDataSerializer({
              ...axiosParams,
              offset: pageParam,
              limit,
            }),
          };
    config.cancelToken = source.token;

    const promise = new Promise((resolve, reject) => {
      axios(axiosUrl, config)
        .then(({ data }) => {
          resolve({ ...data, offset: pageParam });
        })
        .catch(reject);
    }) as Promise<ReturnType> & { offset: number; cancel: () => void };

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };

  return useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (lastPage, allPages) => {
      //@ts-ignore
      if (allPages[0] && allPages[0]['num-' + pageAttribute] && allPages[0][pageAttribute]) {
        //@ts-ignore
        const total = allPages[0]['num-' + pageAttribute];
        const loaded = allPages.reduce((acc, cur) => {
          //@ts-ignore
          return acc + (cur[pageAttribute]?.length || 0);
        }, 0);
        if (total > loaded) {
          //@ts-ignore
          return lastPage.offset + limit;
        }
      } else {
        //@ts-ignore
        if (allPages[allPages.length - 1][pageAttribute].length > 0) {
          //@ts-ignore
          return lastPage.offset + limit;
        }
      }
    },
    keepPreviousData,
    enabled: !disabled,
  });
}
