import React, { MouseEventHandler, useMemo } from 'react';

import Codefy from '../../../../codefy';
import PaneContentLoading from '../../paneContentLoading';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useSearchSnippet } from '../../../../controllers/api/subscriptions/search/searchSnippet';
import clsx from 'clsx';

export default function SearchResultsImage({
  searchResult,
  onClick,
}: {
  searchResult: Codefy.Objects.SearchResult;
  onClick: MouseEventHandler<HTMLImageElement>;
}) {
  const globalClasses = useGlobalStyles();
  const loaderStyle: React.CSSProperties = useMemo(() => ({ minHeight: '300px' }), []);

  const { data: imageData, isLoading } = useSearchSnippet({
    document_id: searchResult.document_id,
    highlights: searchResult.highlights,
  });

  return (
    <>
      {isLoading ? (
        <div style={loaderStyle}>
          <PaneContentLoading />
        </div>
      ) : (
        <img
          className={clsx(globalClasses.snippetImage, globalClasses.pointer)}
          src={`data:image/png;base64,${imageData}`}
          onClick={onClick}
        />
      )}
    </>
  );
}
