import {
  CommentsListEntry,
  CommentsListTag,
  CommentsListTagInstance,
} from '../panes/paneTypes/comments/commentsList';
import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import Codefy from '../../codefy';
import { GenericDialog } from './genericDialog';
import { useBetween } from 'use-between';
import { useCommentsGet } from '../../controllers/api/subscriptions/comments/commentsGet';
import { useQueryParam_commentsDialog_highlightCommentWithId } from '../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

type DialogCommentsStateType =
  | { entry_id: Codefy.Objects.Entry['id'] }
  | { tag_id: Codefy.Objects.Tag['id'] }
  | { tag_instance_id: Codefy.Objects.TagInstance['id'] };

const useDialogCommentsState = () => useState<DialogCommentsStateType>();

const useSharedDialogCommentsState = () => useBetween(useDialogCommentsState);

/** Used to open the dialog when a user comes via a /share/comment/<id> link. Otherwise, just the
 * panes would open, but not the comments dialog */
function useCommentsDialogListener() {
  const [, setState] = useSharedDialogCommentsState();
  const [
    commentsDialog_highlightCommentWithId,
  ] = useQueryParam_commentsDialog_highlightCommentWithId();
  const { data: comment } = useCommentsGet(commentsDialog_highlightCommentWithId);

  useEffect(() => {
    if (comment?.entry_id) {
      setState({ entry_id: comment.entry_id });
    } else if (comment?.tag_id) {
      setState({ tag_id: comment.tag_id });
    } else if (comment?.tag_instance_id) {
      setState({ tag_instance_id: comment.tag_instance_id });
    }
  }, [commentsDialog_highlightCommentWithId]);
}

export default function CommentsDialog() {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogCommentsState();

  const onClose = () => setState(undefined);

  useCommentsDialogListener();

  if (!state) return null;

  return (
    <GenericDialog title={t('comments.comments')} onClose={onClose} open>
      <Box pb={1}>
        {'entry_id' in state && <CommentsListEntry entry_id={state.entry_id} />}
        {'tag_id' in state && <CommentsListTag tag_id={state.tag_id} />}
        {'tag_instance_id' in state && (
          <CommentsListTagInstance tag_instance_id={state.tag_instance_id} />
        )}
      </Box>
    </GenericDialog>
  );
}

export const useOpenCommentsDialog = () => {
  const [, setState] = useSharedDialogCommentsState();
  return (state: DialogCommentsStateType) => setState(state);
};
