import React, { useState } from 'react';

import Codefy from '../../../codefy';
import FolderIcon from '@material-ui/icons/Folder';
import GenericDeleteDialog from '../genericDeleteDialog';
import { projectsPermission } from '../../../controllers/api/actions/projects/projectsPermission';
import { useBetween } from 'use-between';
import { useProjectsGet } from '../../../controllers/api/subscriptions/projects/projectsGet';
import { useTranslation } from 'react-i18next';

type DialogUnshareProjectStateType = {
  projectId: Codefy.Objects.Project['id'];
  email: Codefy.Objects.User['email'];
};

const useDialogUnshareProjectState = () => useState<DialogUnshareProjectStateType>();

const useSharedDialogUnshareProjectState = () => useBetween(useDialogUnshareProjectState);

export const UnshareProjectDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogUnshareProjectState();

  const { data: project } = useProjectsGet(state?.projectId);

  const onClose = () => setState(undefined);

  const onDelete = () => {
    if (!state?.projectId || !state.email) return;
    projectsPermission({
      project_id: state.projectId,
      email: state.email,
      access_level: undefined,
    });
  };

  if (!project?.name) return null;

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted unshares. */

  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      icon={<FolderIcon />}
      title={t('deleteDialog.removeFromMyWorkspaces')}
      content={<span>Workspace {project.name}</span>}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export const useOpenUnshareProjectDialog = () => {
  const [, setState] = useSharedDialogUnshareProjectState();
  return (state: DialogUnshareProjectStateType) => () => setState(state);
};
