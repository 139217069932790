import Codefy from '../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export const TAGLISTS_GET_URL = '/api/v1/taglists/get';

export const TAGLISTS_GET_KEY_TYPE = 'taglistsGet';

export const taglistsGetKey = (
  id?: Codefy.Objects.Taglist['id'],
  case_id?: Codefy.Objects.Directory['id'],
): [
  string,
  Codefy.Objects.Taglist['id'] | undefined,
  Codefy.Objects.Directory['id'] | undefined,
] => [TAGLISTS_GET_KEY_TYPE, id, case_id];

export const useTaglistsGet = ({
  taglist_id,
  case_id,
}: {
  taglist_id?: Codefy.Objects.Taglist['id'] | string;
  case_id?: Codefy.Objects.Directory['id'];
}) => {
  if (typeof taglist_id === 'string') {
    taglist_id = parseInt(taglist_id);
  }

  return useQuery(taglistsGetKey(taglist_id), async () => {
    if (!taglist_id) return undefined;
    const { data } = await axios.get<Codefy.Objects.Taglist>(TAGLISTS_GET_URL, {
      params: { taglist_id, case_id },
    });

    return data;
  });
};
