import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import { useTrashModeIcons, useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

import Codefy from '../../../../codefy';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import directoriesDownload from '../../../../controllers/api/actions/directories/directoriesDownload';
import { projectsTrash } from '../../../../controllers/api/actions/projects/projectsTrash';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenDeleteProjectDialog } from '../../../dialogs/delete/deleteProjectDialog';
import { useOpenRenameProjectDialog } from '../../../dialogs/rename/renameProjectDialog';
import { useOpenShareProjectDialog } from '../../../dialogs/share/shareProjectDialog';
import { useOpenUnshareProjectDialog } from '../../../dialogs/delete/unshareProjectDialog';
import { useQueryParam_trashMode } from '../../../../controllers/useGlobalQueryParams';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSetSearchParams from '../../../navbar/searchBar/useSetSearchParams';
import SearchIcon from '../../../icons/search';

/** Whatever is inside this wrapper will open a project context menu (rename, delete, ...) when
 * right-clicked */
export default function ProjectContextMenuWrapper({
  project,
  children,
  openOnLeftClick,
}: {
  project?: Codefy.Objects.Project;
  children: React.ReactChild | React.ReactChildren;
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
}) {
  const { t } = useTranslation();
  const isEveryone = useIsEveryone();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const openRenameProjectDialog = useOpenRenameProjectDialog();
  const openDeleteProjectDialog = useOpenDeleteProjectDialog();
  const openUnshareProjectDialog = useOpenUnshareProjectDialog();
  const openShareProjectDialog = useOpenShareProjectDialog();
  const onSetSearchScopeProject = useSetSearchParams('directory', project?.directory_id);

  const userEmail = useSelector((state: Codefy.State) => state.user?.email) ?? 'everyone';

  const userHasWriteAccess = project?.path.write_permission;

  const severalUsersHaveAccess =
    (project?.permissions?.map((permission) => permission.user.email).length || 1) > 1;

  const userIsOwner = project?.created_by?.email === userEmail;

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'projectContextMenu' });
  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  if (!project) return <>{children}</>;

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const onOpenProjectShareSettings = async () => {
    openShareProjectDialog({ projectId: project.id });
  };

  const onDownloadProject = () => {
    if (!project.directory_id) return;
    directoriesDownload(project.name, project.directory_id);
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const menu = (
    <Menu
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      {trashMode ? (
        <MenuItem
          data-e2e-id="project-contextmenu-download"
          onClick={() => projectsTrash({ project_ids: [project.id], trashed: false })}>
          <ListItemIcon>{trashModeIcons('restore')}</ListItemIcon>
          {trashModeStrings('restore')}
        </MenuItem>
      ) : (
        <>
          <MenuItem onClick={onSetSearchScopeProject}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            {t('entriesList.contextMenu.search')}
          </MenuItem>

          <MenuItem
            data-e2e-id="project-contextmenu-rename"
            onClick={openRenameProjectDialog({ projectId: project.id })}
            disabled={isEveryone || !userHasWriteAccess}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            {t('projectsList.contextMenu.rename')}
          </MenuItem>

          <MenuItem onClick={onOpenProjectShareSettings} disabled={isEveryone}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            {t('projectContextMenuWrapper.share')}
          </MenuItem>

          <MenuItem data-e2e-id="project-contextmenu-download" onClick={onDownloadProject}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            {t('projectsList.contextMenu.download')}
          </MenuItem>
          <MenuItem
            data-e2e-id="context-menu-delete-project"
            onClick={
              !severalUsersHaveAccess
                ? openDeleteProjectDialog({ projectId: project.id })
                : userIsOwner
                ? openDeleteProjectDialog({ projectId: project.id })
                : userHasWriteAccess
                ? openDeleteProjectDialog({ projectId: project.id })
                : openUnshareProjectDialog({ projectId: project.id, email: userEmail })
            }>
            <ListItemIcon>{trashModeIcons('trash')}</ListItemIcon>
            {userIsOwner
              ? trashModeStrings('trash')
              : t('projectContextMenuWrapper.removeFromMyWorkspaces')}
          </MenuItem>
        </>
      )}
    </Menu>
  );
  return (
    <div
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick) return;

        event.stopPropagation();
        event.preventDefault();
        setContextState({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        contextMenuState.open();
      }}>
      {children}
      {menu}
    </div>
  );
}
