import './selectionStyles.css';

import {
  DATA_SELECTABLE_WORD_BOX_ATTRIBUTE_NAME,
  DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME,
  DATA_SELECTABLE_WORD_TEXT_ATTRIBUTE_NAME,
  SELECTABLE_WORD_SELECTED_CLASSNAME,
} from './selectableWord';
import React, { ReactNode, createContext, useMemo, useRef, useState } from 'react';

import Codefy from '../../../../../codefy';
import { PDF_PAGE_CLASS_NAME } from '../pdfPage';
import SelectionMenu from '../../../../menus/selectionMenu';
import userIsTyping from '../../../../../hooks/userIsTyping';

export const SelectionContext = createContext<string>('');

/** An area in which selectable words can appear. Attaches listeners that watch for mouse events and
 * modify the SelectableWords classes so that they become visible.
 */
export default function SelectionArea({
  _document,
  selectionAreaId,
  children,
  style,
}: {
  _document: Codefy.Objects.Document;
  /** A unique ID for this selection area context, so that there can be several selection areas open
at once. */
  selectionAreaId: string;
  children: ReactNode;
  style?: React.CSSProperties;
}) {
  const [firstSelectedWordId, setFirstSelectedWordId] = useState<string>('');
  const [lastSelectedWordId, setLastSelectedWordId] = useState<string>('');
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const selectionAreaRef = useRef<HTMLDivElement>(null);

  /** The DOM element of the last selected word. Used to position the SelectionMenu (it is
   * positioned right at the last selected word). */
  const lastSelectedWordElement = useMemo(
    () =>
      document.querySelector(
        `.${selectionAreaId}[${DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME}="${lastSelectedWordId}"]`,
      ) as HTMLElement | undefined,
    [selectionAreaId, lastSelectedWordId, isSelecting],
  );

  const selectionMenuOpen = lastSelectedWordElement && !isSelecting;

  /** All words that can be selected in the current selection context. */
  const selectableWords = useMemo(
    () => selectionAreaRef.current?.querySelectorAll(`.${selectionAreaId}`),
    [isSelecting, selectionAreaId],
  );

  /** All words that are actively selected and are visible to the user. */
  const selectedWords = useMemo(
    () => selectionAreaRef.current?.querySelectorAll(`.${SELECTABLE_WORD_SELECTED_CLASSNAME}`),
    [isSelecting],
  );

  /** The text content of the selection, and the boxes of the selected words */
  const [selectedText, selectedBoxes] = useMemo(() => {
    let selectedText = '';
    const selectedBoxes: Codefy.Objects.Box[] = [];

    selectedWords?.forEach((word) => {
      const wordText = word.getAttribute(DATA_SELECTABLE_WORD_TEXT_ATTRIBUTE_NAME);
      const wordBoxJsonString = word.getAttribute(DATA_SELECTABLE_WORD_BOX_ATTRIBUTE_NAME);
      if (!wordText || !wordBoxJsonString) return;

      selectedText += wordText;
      selectedBoxes.push(JSON.parse(wordBoxJsonString));
    });

    return [selectedText, selectedBoxes];
  }, [isSelecting]);

  /** Given a point on the screen, calculates the closest selectableWord. Allows for some tolerance,
   * meaning the user can click somewhere close to a word, he doesn't need to click precisely on top
   * of the word. This allows for quicker and more natural selecting */
  const getClosestSelectableWordIdAtPoint = (x: number, y: number): string => {
    let closestSelectableWord: Element | undefined;
    let closestDistance: number = Number.MAX_VALUE;

    const selectableWordDirectlyUnderCursor = document
      .elementsFromPoint(x, y)
      .filter((element) => element.classList.contains(selectionAreaId))[0];

    /* If the mouse is on a word, simply use that word */
    if (selectableWordDirectlyUnderCursor) {
      closestSelectableWord = selectableWordDirectlyUnderCursor;
      /* Otherwise, find the word closest to the mouse */
    } else {
      const currentPage = document
        .elementsFromPoint(x, y)
        .filter((element) => element.classList.contains(PDF_PAGE_CLASS_NAME))[0];

      if (!currentPage) return '';

      const getDistanceBetweenTwoPoints = (x1: number, y1: number, x2: number, y2: number) => {
        let xs = x2 - x1;
        let ys = y2 - y1;
        xs *= xs;
        ys *= ys;
        return Math.sqrt(xs + ys);
      };

      const selectableWordsOnCurrentPage = currentPage.getElementsByClassName(selectionAreaId);
      for (let i = 0; i < selectableWordsOnCurrentPage.length; i++) {
        const word = selectableWordsOnCurrentPage.item(i);
        if (!word) continue;

        /* The center was chosen instead of a sum of distances from all corners because this is a more
        natural way to select, also otherwise some (e.g. very long) words could become unselectable
        when the sum of corner distances to a nearby short word happens to always be shorter. */
        const wordRect = word.getBoundingClientRect();
        const wordCenterX = (wordRect.x + wordRect.right) / 2;
        const wordCenterY = (wordRect.y + wordRect.bottom) / 2;
        const wordDistance = getDistanceBetweenTwoPoints(x, y, wordCenterX, wordCenterY);

        if (wordDistance < closestDistance) {
          closestSelectableWord = word;
          closestDistance = wordDistance;
        }
      }
    }

    if (!closestSelectableWord) return '';

    return closestSelectableWord.getAttribute(DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME) || '';
  };

  /** Adds a special class to all selected words so that they get a colored background via CSS and
   * appear visible to the user. */
  const renderSelectedWords = () => {
    selectableWords?.forEach((selectableWord) => {
      const selectableWordId = selectableWord.getAttribute(DATA_SELECTABLE_WORD_ID_ATTRIBUTE_NAME);
      if (!selectableWordId || !firstSelectedWordId || !lastSelectedWordId) return;
      if (
        /* User has selected just one word */
        (firstSelectedWordId === lastSelectedWordId && firstSelectedWordId === selectableWordId) ||
        /* User is selecting forwards (= mouse is moving down after click) */
        (lastSelectedWordId > firstSelectedWordId &&
          selectableWordId >= firstSelectedWordId &&
          selectableWordId <= lastSelectedWordId) ||
        /* User is selecting backwards (= mouse is moving up after click) */
        (lastSelectedWordId < firstSelectedWordId &&
          selectableWordId <= firstSelectedWordId &&
          selectableWordId >= lastSelectedWordId)
      ) {
        selectableWord.classList.add(SELECTABLE_WORD_SELECTED_CLASSNAME);
      } else {
        if (selectableWord.classList.contains(SELECTABLE_WORD_SELECTED_CLASSNAME)) {
          selectableWord.classList.remove(SELECTABLE_WORD_SELECTED_CLASSNAME);
        }
      }
    });
  };

  /** Removes the special class that adds a colored background via CSS, making the words disappear
   * again. */
  const resetSelectedWords = () => {
    setFirstSelectedWordId('');
    setLastSelectedWordId('');

    selectedWords?.forEach((word) => {
      word.classList.remove(SELECTABLE_WORD_SELECTED_CLASSNAME);
    });
  };

  /** When the user presses the mouse button, we store the first selected word. */
  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    /** Don't do anything if the user is currently editing text (e.g. user is typing a comment and
     * wants to select some text he typed ) */
    if (userIsTyping()) return;

    resetSelectedWords();
    setIsSelecting(true);
    setFirstSelectedWordId(getClosestSelectableWordIdAtPoint(event.pageX, event.pageY));
    setLastSelectedWordId('');
  };

  /** While the user moves the mouse, update the last selected word. */
  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (selectionMenuOpen || !isSelecting) return;

    const lastSelectedWordId = getClosestSelectableWordIdAtPoint(event.pageX, event.pageY);
    if (lastSelectedWordId) {
      setLastSelectedWordId(lastSelectedWordId);
    }

    renderSelectedWords();
  };

  /** When the user lifts his finger, stop updating the last selected word even if he continues
   * moving his mouse, and show the SelectionMenu. */
  const onMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
    if (selectionMenuOpen || !firstSelectedWordId) return;
    setIsSelecting(false);
    renderSelectedWords();
  };

  return (
    <SelectionContext.Provider value={selectionAreaId}>
      <div
        style={style}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        ref={selectionAreaRef}>
        {lastSelectedWordElement && !isSelecting && (
          <SelectionMenu
            menuAnchorElement={lastSelectedWordElement}
            _document={_document}
            handleContextMenuClose={resetSelectedWords}
            selectedBoxes={selectedBoxes}
            selectedText={selectedText}
            source="pdf"
          />
        )}
        {children}
      </div>
    </SelectionContext.Provider>
  );
}
