import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export const COMMENTS_LIST_URL = '/api/v1/comments/list';

export const COMMENTS_LIST_KEY_TYPE = 'commentsList';

/** A helper to reduce duplicate code since most comment-related API endpoints take these params */
export type GenericCommentParameters = { case_id?: Codefy.Objects.Directory['id'] } & (
  | {
      tag_instance_id?: Codefy.Objects.TagInstance['id'];
      type: 'tag_instance';
    }
  | {
      tag_id?: Codefy.Objects.Tag['id'];
      type: 'tag';
    }
  | {
      entry_id?: Codefy.Objects.Entry['id'];
      type: 'entry';
    }
);

export type CommentsListRequestType = (
  | GenericCommentParameters
  | {
      user_id?: Codefy.Objects.User['id'];
      assignee_id?: Codefy.Objects.User['id'];
      document_ids?: Codefy.Objects.Document['id'][];
      directory_ids?: Codefy.Objects.Directory['id'][];
    }
) & { limit?: number; offset?: number; descending?: boolean; trashed?: boolean };

export type CommentsListReturnType = { comments: Codefy.Objects.Comment[]; num_comments: number };

export const commentsListKey = (
  params?: CommentsListRequestType,
): [string, CommentsListRequestType | undefined] => [COMMENTS_LIST_KEY_TYPE, params];

export const useCommentsList = (params: CommentsListRequestType) =>
  useCreateInfiniteQuery<CommentsListReturnType>(
    commentsListKey(params),
    50,
    COMMENTS_LIST_URL,
    'GET',
    params,
    'comments',
    false,
  );
