import './droppableAreasWhileDraggingStyle.css';

import React, { useCallback } from 'react';

import Codefy from '../../../../codefy';
import accept from 'attr-accept';
import clsx from 'clsx';
import { documentsUpload } from '../../../../controllers/api/actions/documents/documentsUpload';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import useIsDraggingFile from '../../../../hooks/useIsDraggingFile';

/** A list of files that we currently are able to process in the backend */
export const acceptedFiles = [
  '.pdf',
  'application/pdf',
  '.odt',
  'application/vnd.oasis.opendocument.text',
  '.doc',
  'application/msword',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.ppt',
  'application/vnd.ms-powerpoint',
  '.pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.xls',
  'application/vnd.ms-excel',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.msg',
  'application/vnd.ms-outlook',
  '.eml',
  'message/rfc822',
  '.html',
  'text/html',
  '.txt',
  'text/plain',
  '.rtf',
  'application/rtf',
  'text/rtf',
  '.jpg',
  '.jpeg',
  'image/jpg',
  'image/jpeg',
  '.png',
  'image/png',
  '.tif',
  '.tiff',
  'image/tif',
  'image/tiff',
  'application/zip',
  '.zip',
];

export const isFileAccepted = (file: File): boolean => accept(file, acceptedFiles.join(','));

const useStyles = makeStyles(() => ({
  root: { width: '100%', height: '100%' },
}));

/** A wrapper component that allows the user to drag and drop a file to upload into a directory */
export default function DirectoryFileUploadWrapper({
  children,
  directory_id,
  disabled,
  clickable,
}: {
  /** What should be inside the wrapper */
  children: React.ReactNode;
  /** Which directory to upload to */
  directory_id?: Codefy.Objects.Directory['id'];
  /** Manually disable upload even if directory id provided (e.g. because user is not logged in) */
  disabled?: boolean;
  /** Should clicking the area open an upload field? */
  clickable?: boolean;
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  /** When the user drops a file onto the current wrapper */
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (!directory_id) return;

      const files = [...acceptedFiles, ...rejectedFiles];

      dispatch(documentsUpload(files, directory_id));
    },
    [directory_id],
  );

  /** Is the user dragging a file from his computer into the browser? Used to show the user all the
   * places he can drop the file */
  const isDraggingFile = useIsDraggingFile();

  const {
    getRootProps,
    getInputProps,
    /** True when the user is hovering the mouse over the current wrapper */
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: !clickable,
    /** Needs to be true since we have sometimes two drop areas above each other, e.g. the
     * entriesList folder pane and another folder inside this pane */
    noDragEventsBubbling: true,
    multiple: true,
    accept: acceptedFiles,
  });

  if (!directory_id || disabled) return <>{children}</>;

  return (
    <div
      {...getRootProps()}
      className={clsx(
        classes.root,
        isDragActive ? 'blinking_droppable_hover' : isDraggingFile ? 'blinking_droppable' : '',
      )}>
      <input
        {...getInputProps()}
        /** For handling uploads of directories */
        //@ts-ignore
        webkitdirectory=""
        //@ts-ignore
        directory=""
      />
      {children}
    </div>
  );
}
