import Codefy from '../../../../../codefy';
import { TAGS_DELETE_QUERY_KEYS_TO_REFRESH } from './tagsDelete';
import { executeAction } from '../../actionHelpers';

export const tagsTrash = (data: { tag_id: Codefy.Objects.Tag['id']; trashed?: boolean }) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/trash', method: 'POST' },
    data,
    queryKeysToRefresh: TAGS_DELETE_QUERY_KEYS_TO_REFRESH,
  });
