import { Box, Button, Chip, Input, Typography } from '@material-ui/core';
import { COLORS, componentTypeStyles, useGlobalStyles } from '../../globalThemeSettings';
import React, { ChangeEvent, useMemo, useState } from 'react';

import Codefy from '../../codefy';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
//@ts-ignore
import RegexEscape from 'regex-escape';
import TagLabelName from './tagLabelName';
import { annotationsCreate } from '../../controllers/api/actions/annotations/annotationsCreate';
import clsx from 'clsx';
import store from '../../controllers/store';
import { tagInstancesCreate } from '../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesCreate';
import { taglistsCreate } from '../../controllers/api/actions/taglists/taglistsCreate';
import { tagsCreate } from '../../controllers/api/actions/taglists/tags/tagsCreate';
import { useAnnotationsGet } from '../../controllers/api/subscriptions/annotations/annotationsGet';
import useCurrentCase from '../../hooks/useCurrentCase';
import { useLocalStorage } from 'react-use';
import useOnClickOutside from 'use-onclickoutside';
import { useProjectsGet } from '../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_taglist_id } from '../../controllers/useGlobalQueryParams';
import { useTaglistsGet } from '../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTranslation } from 'react-i18next';

/** Related to being able to select tags with the arrow keys */
export const TAG_ARROWKEY_SELECTED_ID = 'tag-arrowkey-selected';
export const clickOnSelectedByArrowkey = () => {
  const el = document.getElementById(TAG_ARROWKEY_SELECTED_ID) as HTMLDivElement;
  el?.click();
  store.dispatch({ type: 'setSearch', searchbarArrowKeyPosition: 0 });
  return el;
};

export const EDIT_LABELS_TEXT_FIELD_ID = 'EDIT_LABELS_TEXT_FIELD_ID';
const CREATE_TAG_BUTTON_ID = 'create-tag-button';

const focus_labels_text_field = () => {
  const el = document.getElementById(EDIT_LABELS_TEXT_FIELD_ID) as HTMLElement;
  el?.focus();
};

/** A component that allows searching/adding/removing tag instances, given an annotation or an entry
 */
export default function TagLabelsEditor({
  project_id,
  annotation_id,
  entry,
  annotation_tag,
  createAnnotationCallback,
  onClose,
  documentId,
  selectedBoxes,
  onClickOutside,
  taglistType,
  isArea,
  source,
  autoFocus,
  indent,
  maxHeight,
}: {
  project_id: Codefy.Objects.Project['id'];
  annotation_id?: Codefy.Objects.Annotation['id'];
  entry?: Codefy.Objects.Entry;
  annotation_tag?: Codefy.Objects.Tag;
  /** A function that when executed creates an annotation and returns the annotationId  */
  createAnnotationCallback?: (tag_id: Codefy.Objects.Tag['id']) => void;
  onClose?: (event?: any) => any;
  onClickOutside?: (event?: any) => any;
  documentId?: Codefy.Objects.Document['id'];
  selectedBoxes?: Codefy.Objects.Box[];
  taglistType: Codefy.Objects.Taglist['type'];
  /** Are the tags being created for an annotation that is a selected area (and not selected text)? */
  isArea?: boolean;
  /** Where is the user accessing this component from? Used for metrics. */
  source: string;
  autoFocus?: boolean;
  indent?: boolean;
  maxHeight?: number;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const { data: project } = useProjectsGet(project_id);

  const { id: currentCaseId } = useCurrentCase();

  const [taglist_id, set_taglist_id] = useQueryParam_taglist_id({
    taglistType,
  });
  const { data: taglist } = useTaglistsGet({ taglist_id });

  /** Localstorage is used because the recently clicked tag needs to be available immediately when
   * the user opens the SelectionMenu again. Waiting for API caches to refresh turned out to be too
   * slow and resulted in a jumpy UI (once the new information arrived). */
  const [recentTagIds, setRecentTagIds] = useLocalStorage<Codefy.Objects.Tag['id'][]>(
    'recentTagIds-' + taglist_id,
  );

  /** Stores the tag_id in the recent tag ids localstorage, making sure to only store the last 100
   * tags (to not overflow localstorage and/or slow things down) and deduplicating entries. */
  const storeRecentTagId = (tag_id: Codefy.Objects.Tag['id']) => {
    const recentTagIdsToSave = Array.from(new Set(recentTagIds?.slice(0, 100) || []));
    setRecentTagIds([tag_id, ...recentTagIdsToSave]);
  };

  const [arrowKeyPosition, setArrowKeyPosition] = useState<number>(0);

  const onClickOutsideRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(onClickOutsideRef, () => {
    onClickOutside?.();
  });

  const { data: annotation } = useAnnotationsGet({ annotation_id });

  const [inputValue, setInputValue] = useState<string>('');

  // TODO: Maybe extract to generic file?
  /** Fuzzily matches string, e.g:
   * Query: as doc n
   * Matches string: ASSIGNMENT##Document Name
   */
  const fuzzyMatch = (query: string, string: string) => {
    const escapedQuery = RegexEscape(query);
    const re = new RegExp('.*' + escapedQuery.split(' ').join('.*') + '.+', 'i');
    return string.match(re);
  };

  const finalTags = taglist?.tags
    .filter((tag) => {
      if (!tag || !taglist) return false;

      /* If the user has not typed in a search term, return all tags */
      if (!inputValue) return true;

      return (
        /* Otherwise, return those tags that match start with the typed in text ...*/
        tag.name.toLowerCase().startsWith(inputValue) ||
        /* ...or match the string in a fuzzy way */
        fuzzyMatch(inputValue, tag.name) ||
        /* ...or match it literally */
        inputValue.toLowerCase() === tag.name.toLowerCase()
      );
    })
    /** Prevent selecting the same tag twice */
    .filter((tag) => {
      if (!tag) return false;

      if (taglistType === 'annotation') {
        if (annotation?.tag_instances?.find((ti) => ti.tag_id === tag.id)) {
          return false;
        }
      } else if (taglistType === 'entry') {
        if (entry?.tag_instances?.find((ti) => ti.tag_id === tag.id)) {
          return false;
        }
        if (annotation_tag?.annotation_tag_instances?.find((ti) => ti.tag_id === tag.id)) {
          return false;
        }
      }
      return true;
    })
    /** Prevent tags from the front type appearing */
    .filter(() => {
      if (taglist?.type !== taglistType) return false;
      return true;
    })
    /** Deduplicate list */
    .reduce((acc: Codefy.Objects.Tag[], cur) => {
      if (!acc.find((e: Codefy.Objects.Tag) => e.id === cur?.id) && cur) acc.push(cur);
      return acc;
    }, [])
    .sort((a, b) => {
      if (recentTagIds?.includes(a.id) && !recentTagIds.includes(b.id)) {
        return -1;
      } else if (!recentTagIds?.includes(a.id) && recentTagIds?.includes(b.id)) {
        return 1;
      } else if (!recentTagIds?.includes(a.id) && !recentTagIds?.includes(b.id)) {
        return a.name.localeCompare(b.name);
      } else {
        return recentTagIds.indexOf(a.id) - recentTagIds.indexOf(b.id);
      }
    });

  const onCreateTagInstance = (tag: Codefy.Objects.Tag) => {
    if (taglistType === 'annotation') {
      if (annotation_id) {
        tagInstancesCreate({ annotation_id, type: 'annotation', tag_id: tag.id, source });
      } else {
        if (createAnnotationCallback) {
          createAnnotationCallback(tag.id);
        } else if (documentId && selectedBoxes && selectedBoxes?.length > 0) {
          annotationsCreate({
            document_id: documentId,
            boxes: selectedBoxes,
            type: 'annotation',
            tag_id: tag.id,
            area: isArea,
            source,
          });
        }
      }
    } else if (taglistType === 'entry') {
      if (entry?.id) {
        tagInstancesCreate({ entry_id: entry.id, type: 'entry', tag_id: tag.id, source });
      } else if (annotation_tag?.id && currentCaseId) {
        tagInstancesCreate({
          tag_id: tag.id,
          type: 'annotation_tag',
          annotation_tag_id: annotation_tag?.id,
          case_id: currentCaseId,
          source,
        });
      }
    }

    storeRecentTagId(tag.id);
  };

  const createNewTagButton = useMemo(() => {
    /* If the user didn't type in anything, there is no new tag to create */
    if (!inputValue) return null;

    /* If there is a tag with that name already, do not show "create new tag" button */
    if (taglist?.tags?.map((tag) => tag.name.toLowerCase()).includes(inputValue.toLowerCase()))
      return null;

    const onCreate = async () => {
      if (!project?.directory_id) return;

      setInputValue('');
      onClose?.();

      let taglist_id = taglist?.id;

      if (!taglist_id || taglist?.type !== taglistType) {
        const taglist = await taglistsCreate({
          directory_id: project?.directory_id,
          name:
            taglistType === 'annotation'
              ? t('tagLabelsEditor.newContentTaglist')
              : t('tagLabelsEditor.newEntryTaglist'),
          type: taglistType,
        });
        set_taglist_id(taglist?.id);
        taglist_id = taglist?.id;
      }
      if (taglist_id) {
        const tag = await tagsCreate({
          taglist_id,
          name: inputValue,
        });
        if (!tag?.id) {
          return;
        }

        onCreateTagInstance(tag);
      }
    };

    return (
      <Box m={-0.5} mr={0}>
        <Button variant="text" onClick={onCreate} id={CREATE_TAG_BUTTON_ID}>
          <Typography
            className={clsx(
              globalClasses.heading,
              globalClasses.noWrap,
              globalClasses.noUppercase,
            )}>
            {t('tagLabelsEditor.createTag')}
          </Typography>
        </Button>
      </Box>
    );
  }, [inputValue, taglist?.id, taglist?.tags.length]);

  const onChangeInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const createTagButton = document.getElementById(CREATE_TAG_BUTTON_ID) as HTMLElement;
    let existingTagButtonWasClicked = false;

    switch (event.key) {
      case 'Enter':
        if (arrowKeyPosition > 0) {
          existingTagButtonWasClicked = !!clickOnSelectedByArrowkey();
        }
        if (!existingTagButtonWasClicked && createTagButton) {
          createTagButton?.click();
        }

        break;
      case 'Escape':
        setArrowKeyPosition(0);
        break;
      case 'ArrowDown':
        if (arrowKeyPosition < (finalTags?.length || 0)) {
          setArrowKeyPosition(arrowKeyPosition + 1);
          setTimeout(focus_labels_text_field, 0);
        }
        break;
      case 'ArrowUp':
        if (arrowKeyPosition > 0) {
          setArrowKeyPosition(arrowKeyPosition - 1);
          setTimeout(focus_labels_text_field, 0);
        }
        break;
      default:
        setArrowKeyPosition(1);
    }
  };

  return (
    <Box minWidth={350} pb={1}>
      <div ref={onClickOutsideRef} onClick={(event) => event.stopPropagation()}>
        <Box
          pl={2}
          pr={2}
          pb={1}
          onClick={() => {
            /** The text field sometimes stops being clickable for some reason, this should fix it */
            focus_labels_text_field();
          }}>
          <Input
            autoFocus={autoFocus}
            placeholder={t('tagLabelsEditor.searchOrCreateTagInName')}
            disableUnderline
            fullWidth
            autoComplete="off"
            onChange={onChangeInput}
            onKeyDown={onKeyDown}
            startAdornment={
              <Box mr={4} mb={-0.4}>
                {taglistType === 'annotation' && <LabelOutlinedIcon />}
                {taglistType === 'entry' && <LabelIcon />}
              </Box>
            }
            endAdornment={createNewTagButton}
            inputProps={{ id: EDIT_LABELS_TEXT_FIELD_ID }}
          />
        </Box>

        <Box p={2} pt={0} pb={0} ml={indent && 6.5} maxHeight={maxHeight || 300} overflow="auto">
          {finalTags?.map((tag, index) => {
            if (!tag) return;

            const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
              event.stopPropagation();
              onCreateTagInstance(tag);
              onClose?.();
            };
            const selectedViaArrowKey = arrowKeyPosition === index + 1;

            return (
              <ClickableTag
                key={tag.id}
                name={tag.name}
                color={tag.color}
                selectedViaArrowKey={selectedViaArrowKey}
                onClick={onClick}
              />
            );
          })}
        </Box>
      </div>
    </Box>
  );
}

function ClickableTag({
  name,
  color,
  selectedViaArrowKey,
  onClick,
}: {
  name: Codefy.Objects.Tag['name'];
  color: Codefy.Objects.Tag['color'];
  selectedViaArrowKey: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) {
  const chipStyle: React.CSSProperties = useMemo(
    () => ({
      backgroundColor: color || COLORS.defaultTagColor,
      ...componentTypeStyles.tagLabel,
      ...(selectedViaArrowKey ? componentTypeStyles.tagLabelSelected : {}),
    }),
    [color, selectedViaArrowKey],
  );

  return (
    <Box mb={1}>
      <Chip
        size="small"
        style={chipStyle}
        id={selectedViaArrowKey ? TAG_ARROWKEY_SELECTED_ID : ''}
        onClick={onClick}
        label={<TagLabelName name={name} maxWidth={350} />}
      />
    </Box>
  );
}
