import React, { useMemo } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import {
  useQueryParam_search_directoryId,
  useQueryParam_search_documentId,
  useQueryParam_search_orderBy,
  useQueryParam_search_query,
} from '../../../../../controllers/useGlobalQueryParams';

import ImageIcon from '@material-ui/icons/Image';
import { PaneKeys } from '../../paneKeys';
import PaneTitleBar from '../../../paneTitleBar';
import SearchCodefyIcon from '../../../../icons/searchCodefy';
import SearchIcon from '../../../../icons/search';
import SortIcon from '@material-ui/icons/Sort';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { searchSearchKey } from '../../../../../controllers/api/subscriptions/search/searchSearch';
import { useDirectoriesGet } from '../../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../../controllers/api/subscriptions/documents/documentsGet';
import useSearchParams from '../useSearchParams';
import { useTranslation } from 'react-i18next';
import { useUserSetting_searchPane_snippetVariant } from '../../../../../controllers/api/subscriptions/users/userSettings';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import RelevantIcon from '../../../../icons/relevant';

export const SEARCH_SUGGEST_LIMIT = 5;

/** Hack so that we don't load search results twice */
const useSearchResultsCount = () => {
  // We use the useIsFetching hook to just trigger a recalculation of the searchResultsCount
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _fetching = useIsFetching();
  const searchParams = useSearchParams({ variant: 'searchPane' });
  const searchData = useQueryClient().getQueryData(searchSearchKey(searchParams));
  const num_results = (searchData as any)?.pages?.[0]?.num_results;
  return num_results as number | undefined;
};
export default function SearchPaneTitleBar() {
  const [search_query] = useQueryParam_search_query();
  const { t } = useTranslation();

  const [search_directoryId] = useQueryParam_search_directoryId();
  const [search_documentId] = useQueryParam_search_documentId();
  const [search_orderBy, set_search_orderBy] = useQueryParam_search_orderBy();

  const [
    userSettingSearchPaneSnippetVariant,
    set_userSettingSearchPaneSnippetVariant,
  ] = useUserSetting_searchPane_snippetVariant();

  const { data: directory } = useDirectoriesGet(search_directoryId);
  const { data: document } = useDocumentsGet(search_documentId);

  const searchScopeSuffix = useMemo(() => {
    if (search_documentId && document)
      return t('searchPane.inDocument', { name: document.path.entry_name });
    if (search_directoryId && directory)
      return t('searchPane.inFolder', {
        name: directory.path?.entry_name || directory.path?.project_name,
      });
    return '';
  }, [
    search_documentId,
    document?.path.entry_name,
    directory?.path?.entry_name,
    directory?.path?.project_name,
    search_directoryId,
  ]);

  const num_results = useSearchResultsCount();

  const searchParams = useSearchParams({ variant: 'searchPane' });

  const facetmenuEntries = [];
  facetmenuEntries.push({
    key: 'snippetVariant',
    text:
      userSettingSearchPaneSnippetVariant === 'image'
        ? t('snippetVariant.showText')
        : t('snippetVariant.showImage'),
    icon: userSettingSearchPaneSnippetVariant === 'image' ? <TextFieldsIcon /> : <ImageIcon />,
    onClick: () =>
      set_userSettingSearchPaneSnippetVariant(
        userSettingSearchPaneSnippetVariant === 'image' ? 'text' : 'image',
      ),
  });

  facetmenuEntries.push({
    key: 'sort',
    text: t('useTagMenuEntries.sortBy'),
    icon: <SortIcon />,
    menuEntries: [
      {
        key: 'sortRelevance',
        text: t('searchPane.relevance'),
        icon: <RelevantIcon />,
        selected: !search_orderBy || search_orderBy === 'relevance',
        onClick: () => set_search_orderBy('relevance'),
      },
      {
        key: 'sortName',
        text: t('searchPane.appearance'),
        icon: <FormatListNumberedIcon />,
        selected: search_orderBy === 'appearance',
        onClick: () => set_search_orderBy('appearance'),
      },
    ],
  });

  let title;
  if (searchParams.method === 'more_like_this') {
    title = `${t('searchMain.similarityResults')} ${searchScopeSuffix}`;
  } else {
    title =
      num_results !== undefined
        ? `${t('searchMain.resultsFor', {
            count: num_results,
          })} "${search_query}" ${searchScopeSuffix}`
        : `${t('searchMain.loading')} "${search_query}" ${searchScopeSuffix}`;
  }

  return (
    <PaneTitleBar
      icon={searchParams.method === 'more_like_this' ? <SearchCodefyIcon /> : <SearchIcon />}
      title={title}
      paneKey={PaneKeys.search}
      menuEntries={facetmenuEntries}
    />
  );
}
