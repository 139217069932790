import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../globalThemeSettings';

/** Codefy is currently not at all optimized for mobile screens, so we show this
 * placeholder instead of showing an app that will be buggy and give a very bad
 * impression */
export default function MobileScreenBlocker() {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  return (
    <div
      style={{
        position: 'absolute',
        left: '30%',
        top: '50%',
        transform: 'translate(-25%, -50%)',
        zIndex: 9999999999,
        backgroundColor: '',
      }}>
      <Typography className={globalClasses.heading} align="center">
        {t('mobileScreenWarning')}
      </Typography>
    </div>
  );
}
