import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../globalThemeSettings';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useProjectsList } from '../../../controllers/api/subscriptions/projects/projectsList';
import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
  useQueryParam_search_orderBy,
} from '../../../controllers/useGlobalQueryParams';
import Autocomplete from '../../panes/paneTypes/search/autocomplete';
import AutocompletePlaceholder from '../../panes/paneTypes/search/autocompletePlaceholder';
import SearchResultsItem from '../../panes/paneTypes/search/searchResultsItem';
import Codefy from '../../../codefy';
import Highlighter from 'react-highlight-words';
import List from '@material-ui/core/List';
import PaneContentEmpty from '../../panes/paneContentEmpty';
import PaneContentLoading from '../../panes/paneContentLoading';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { SEARCHBAR_ARROWKEY_SELECTED_ID } from './searchBar';
import SnippetVariantSwitch from './snippetVariantSwitch';
import clsx from 'clsx';
import { setSearchBarInputValue } from './setSearchBarInputValue';
import { useEntriesList } from '../../../controllers/api/subscriptions/entries/entriesList';
import { usePaneActions } from '../../panes/usePaneActions';
import { useSearch } from '../../../controllers/api/subscriptions/search/searchSearch';
import useSearchParams from '../../panes/paneTypes/search/useSearchParams';
import { useTranslation } from 'react-i18next';
import { useUserSetting_searchDropdown_snippetVariant } from '../../../controllers/api/subscriptions/users/userSettings';
import SearchOrderBySwitch from './searchOrderBySwitch';

const RESULTS_COUNT = 10;
export const ENTRIES_COUNT = 5;
export const ENTRIES_SHOW_MORE_COUNT = 100;
const PROJECTS_COUNT = 5;
const PROJECTS_SHOW_MORE_COUNT = 100;

type ClassesParams = { width: number; open?: boolean };

const useStyles = makeStyles<any, ClassesParams>({
  root: {
    display: ({ open }: ClassesParams) => (open ? 'initial' : 'none'),
    position: 'fixed',
    top: '50px',
    left: '220px',
    width: ({ width }: ClassesParams) => width + 350,
    maxWidth: `calc(95vw - 220px)`,
    overflowY: 'auto',
    maxHeight: '90vh',
    backgroundColor: 'white',
  },
});

export default function SearchDropdown({ width }: { width: number }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId] = useQueryParam_searchBar_documentId();
  const searchOpen = useSelector((state: Codefy.State) => state.search.open);
  const searchQuery = useSelector((state: Codefy.State) => state.search.query);
  const searchbarArrowKeyPosition = useSelector(
    (state: Codefy.State) => state.search.searchbarArrowKeyPosition,
  );
  const paneActions = usePaneActions();
  const dispatch = useDispatch();

  const [
    userSetting_searchDropdown_snippetVariant,
    set_userSetting_searchDropdown_snippetVariant,
  ] = useUserSetting_searchDropdown_snippetVariant();

  const [search_orderBy, set_search_orderBy] = useQueryParam_search_orderBy();

  /** Has the user clicked on the "show more" button under the entries? */
  const [showMoreEntriesClicked, setShowMoreEntriesClicked] = useState<boolean>(false);

  /** Has the user clicked on the "show more" button under the projects? */
  const [showMoreProjectsClicked, setShowMoreProjectsClicked] = useState<boolean>(false);

  const searchParamsWhileTyping = useSearchParams({ variant: 'searchBar' });

  const {
    data: searcherSearchPages,
    isFetching: searcherSearchFetching,
    isFetched: searcherSearchFetched,
  } = useSearch(
    {
      ...searchParamsWhileTyping,
      limit: RESULTS_COUNT,
      limit_total_preview_images_count:
        (userSetting_searchDropdown_snippetVariant || 'image') == 'image' ? 3 : 0,
      order_by: search_orderBy,
    },
    true,
  );

  const onOpenSearchPane = () => {
    if (searchQuery) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.search,
        params: {
          search_method: 'insta',
          search_query: searchQuery,
          search_directoryId: searchBar_directoryId,
          search_documentId: searchBar_documentId,
        },
      });
    }

    dispatch({ type: 'setSearch', open: false });
  };

  const searcherResults = searcherSearchPages?.pages[0].results || [];
  const searcherAutocompletions = (searcherSearchPages?.pages[0].autocompletions || []) as string[];
  const searcherResultsCount = searcherSearchPages?.pages[0].num_results;

  const {
    data: entriesPages,
    isFetching: entriesFetching,
    isFetched: entriesFetched,
  } = useEntriesList({
    directory_ids: searchParamsWhileTyping.directory_ids,
    name: searchParamsWhileTyping.query,
    limit: showMoreEntriesClicked ? ENTRIES_SHOW_MORE_COUNT : ENTRIES_COUNT,
    descending: true,
    recursive: true,
  });

  const {
    data: projectsPages,
    isFetching: projectsFetching,
    isFetched: projectsFetched,
  } = useProjectsList({
    name: searchParamsWhileTyping.query,
    limit: showMoreEntriesClicked ? PROJECTS_SHOW_MORE_COUNT : PROJECTS_COUNT,
    descending: true,
  });

  const entries = entriesPages?.pages[0].entries || [];
  const projects = projectsPages?.pages[0].projects || [];

  const classes = useStyles({
    width,
    open: searchOpen,
  });
  const globalClasses = useGlobalStyles();

  const dontClose: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
    event.stopPropagation();
  }, []);

  let autocompleteEntries;
  /** Autocomplete placeholders should only be shown at the very beginning, to fill the
   * void. Later, when the user continues to type, it should just wait for the new
   * results, because in that case showing placeholders would increase the perceived
   * delay. */
  if (searcherSearchFetching && !searcherAutocompletions && searchQuery !== '') {
    autocompleteEntries = [0, 1, 2, 3, 4].map((index) => <AutocompletePlaceholder key={index} />);
  } else if (searcherAutocompletions?.length > 0) {
    autocompleteEntries = searcherAutocompletions?.map((autocomplete) => (
      <Autocomplete key={autocomplete} autocomplete={autocomplete} query={searchQuery} />
    ));
  }

  const renderEntry = (entry: Codefy.Objects.Entry, index: number) => {
    const selected = index + 1 === searchbarArrowKeyPosition;
    return (
      <ListItem
        key={'overlay-entry-search-by-name-' + entry.id}
        button
        dense
        selected={selected}
        id={selected ? SEARCHBAR_ARROWKEY_SELECTED_ID : ''}
        onClick={() => {
          if (entry.document) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.document.path.directory_id },
              reset: true,
            });
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.pdfViewer,
              params: { pdfViewer_documentId: entry.document.id },
              reset: true,
            });
          }
          if (entry.directory) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.directory.id },
            });
          }
          if (entry.taglist) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.taglist.path.directory_id },
              reset: true,
            });
            if (entry.taglist.type === 'annotation') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTaglist,
                params: { annotationTaglist_id: entry.taglist.id },
              });
            } else if (entry.taglist.type === 'entry') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTaglist,
                params: { entryTaglist_id: entry.taglist.id },
              });
            }
          }
          dispatch({ type: 'setSearch', open: false, query: '' });
          setSearchBarInputValue('');
          setShowMoreEntriesClicked(false);
        }}>
        <ListItemIcon className={globalClasses.narrowListItemIcon}>
          {<EntryFileTypeIcon entryMimetype={entry.mimetype} style={{ marginRight: '3px' }} />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Highlighter
              searchWords={searchQuery.split(' ')}
              autoEscape={true}
              textToHighlight={entry.name}
              unhighlightStyle={{ fontWeight: 'bold' }}
              highlightStyle={{
                backgroundColor: COLORS.textHighlightBackground,
                color: COLORS.primary,
                fontWeight: 'bold',
              }}
            />
          }
        />
      </ListItem>
    );
  };

  const renderProject = (project: Codefy.Objects.Project, index: number) => {
    const selected = index + 1 === searchbarArrowKeyPosition;
    const projectIsShared =
      project.permissions.map((permission) => permission.user.email).includes('everyone') ||
      project.permissions.length > 1;
    return (
      <ListItem
        key={'overlay-project-search-by-name-' + project.id}
        button
        dense
        selected={selected}
        id={selected ? SEARCHBAR_ARROWKEY_SELECTED_ID : ''}
        onClick={() => {
          // See projectsListItem.tsx line 41
          history.push(
            `/?panes=projectsList&panes=entriesList&entriesList_directoryId=${project.directory_id}&search_directoryId=${project.directory_id}&searchBar_directoryId=${project.directory_id}`,
          );
          dispatch({ type: 'setSearch', open: false, query: '' });
          setSearchBarInputValue('');
          setShowMoreProjectsClicked(false);
        }}>
        <ListItemIcon className={globalClasses.narrowListItemIcon}>
          {
            <ListItemIcon className={globalClasses.narrowListItemIcon}>
              {projectIsShared ? <FolderSharedIcon /> : <FolderIcon />}
            </ListItemIcon>
          }
        </ListItemIcon>
        <ListItemText
          primary={
            <Highlighter
              searchWords={searchQuery.split(' ')}
              autoEscape={true}
              textToHighlight={project.name}
              unhighlightStyle={{ fontWeight: 'bold' }}
              highlightStyle={{
                backgroundColor: COLORS.textHighlightBackground,
                color: COLORS.primary,
                fontWeight: 'bold',
              }}></Highlighter>
          }
        />
      </ListItem>
    );
  };

  if (!searchQuery) return null;

  if (
    searcherResults.length === 0 &&
    searcherSearchFetched &&
    entriesFetched &&
    entries.length === 0 &&
    projectsFetched &&
    projects.length === 0
  ) {
    return (
      <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
        <PaneContentEmpty text={t('searchBar.noResults')} />
      </div>
    );
  }

  if (showMoreEntriesClicked) {
    return (
      <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
        <List dense>
          <ListItem
            button
            onClick={() => {
              setShowMoreEntriesClicked(false);
            }}>
            <ListItemText
              primary={
                <Typography className={globalClasses.link}>
                  {t('searchBar.goBackToAllResults')}
                </Typography>
              }></ListItemText>
          </ListItem>
          <ListItem dense>
            <Typography className={globalClasses.subheading}>
              {t('searchBar.documentsAndFolders')}
            </Typography>
          </ListItem>
          {entries?.map(renderEntry)}
          {entriesPages?.pages[0].num_entries &&
            entriesPages?.pages[0].num_entries > ENTRIES_SHOW_MORE_COUNT && (
              <ListItem button>
                <ListItemText
                  primary={
                    <Typography className={globalClasses.link}>
                      {t('searchBar.showingXResultsOnly', { count: ENTRIES_SHOW_MORE_COUNT })}
                    </Typography>
                  }></ListItemText>
              </ListItem>
            )}
        </List>
      </div>
    );
  }

  if (showMoreProjectsClicked) {
    return (
      <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
        <List dense>
          <ListItem
            button
            onClick={() => {
              setShowMoreProjectsClicked(false);
            }}>
            <ListItemText
              primary={
                <Typography className={globalClasses.link}>
                  {t('searchBar.goBackToAllResults')}
                </Typography>
              }></ListItemText>
          </ListItem>
          <ListItem dense>
            <Typography className={globalClasses.subheading}>{t('searchBar.projects')}</Typography>
          </ListItem>
          {projects?.map(renderProject)}
          {projectsPages?.pages[0].num_projects &&
            projectsPages?.pages[0].num_projects > PROJECTS_SHOW_MORE_COUNT && (
              <ListItem button>
                <ListItemText
                  primary={
                    <Typography className={globalClasses.link}>
                      {t('searchBar.showingXResultsOnly', { count: PROJECTS_SHOW_MORE_COUNT })}
                    </Typography>
                  }></ListItemText>
              </ListItem>
            )}
        </List>
      </div>
    );
  }

  return (
    <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
      <div
        className={clsx(
          !(searcherSearchFetching || entriesFetching || projectsFetching) &&
            globalClasses.invisible,
        )}>
        <PaneContentLoading />
      </div>

      <Grid container>
        <Grid item md={4} style={{ borderLeft: `1px solid ${COLORS.uiSkeleton}` }}>
          <List dense>
            <ListItem dense>
              <Typography className={globalClasses.subheading}>
                {t('searchBar.projects')}
              </Typography>
            </ListItem>
            {projects.map(renderProject)}
            {!!projectsPages?.pages[0].num_projects &&
              projectsPages?.pages[0].num_projects > PROJECTS_COUNT && (
                <ListItem
                  button
                  onClick={() => {
                    setShowMoreProjectsClicked(true);
                  }}>
                  <ListItemText
                    primary={
                      <Typography className={globalClasses.link}>
                        {t('searchBar.showMore')}
                      </Typography>
                    }></ListItemText>
                </ListItem>
              )}
          </List>
        </Grid>

        <Grid item md={4} style={{ borderLeft: `1px solid ${COLORS.uiSkeleton}` }}>
          <List dense>
            <ListItem dense>
              <Typography className={globalClasses.subheading}>
                {t('searchBar.documentsAndFolders')}
              </Typography>
            </ListItem>
            {entries.map(renderEntry)}
            {!!entriesPages?.pages[0].num_entries &&
              entriesPages?.pages[0].num_entries > ENTRIES_COUNT && (
                <ListItem
                  button
                  onClick={() => {
                    setShowMoreEntriesClicked(true);
                  }}>
                  <ListItemText
                    primary={
                      <Typography className={globalClasses.link}>
                        {t('searchBar.showMore')}
                      </Typography>
                    }></ListItemText>
                </ListItem>
              )}
          </List>
        </Grid>

        <Grid
          item
          md={4}
          style={{
            borderLeft: `1px solid ${COLORS.uiSkeleton}`,
            borderRight: `1px solid ${COLORS.uiSkeleton}`,
          }}>
          <List dense>
            <ListItem dense>
              <Typography className={globalClasses.subheading}>
                {t('searchBar.searchSuggestions')}
              </Typography>
            </ListItem>
            {autocompleteEntries}
          </List>
        </Grid>
      </Grid>

      <Box onClick={dontClose} mt={-1}>
        <ListItem dense style={{ borderTop: `1px solid ${COLORS.uiSkeleton}`, paddingTop: '20px' }}>
          <Typography className={globalClasses.subheading} noWrap>
            <Grid container wrap="nowrap">
              <Grid item>
                <Box mt={0.5}>{t('searchBar.content')}</Box>
              </Grid>
              <Grid item>
                <Box ml={0.5}>
                  <SnippetVariantSwitch
                    value={userSetting_searchDropdown_snippetVariant || 'image'}
                    onChange={set_userSetting_searchDropdown_snippetVariant}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box ml={0.5}>
                  <SearchOrderBySwitch
                    value={search_orderBy || 'relevance'}
                    onChange={set_search_orderBy}
                  />
                </Box>
              </Grid>
            </Grid>
          </Typography>
        </ListItem>
      </Box>

      {searcherResults?.map((searcherResult, index) => (
        <SearchResultsItem
          searchQuery={searcherSearchFetching ? undefined : searchQuery}
          index={index}
          num_results={searcherResultsCount}
          isInstasearchResult
          searchResult={searcherResult}
          key={`${searcherResult.file_id}-${searcherResult.part_ix}`}
          defaultSnippetVariant={userSetting_searchDropdown_snippetVariant || 'image'}
        />
      ))}

      {searcherResultsCount
        ? searcherResultsCount > RESULTS_COUNT && (
            <ListItem button onClick={onOpenSearchPane}>
              <ListItemText
                primary={
                  <Typography className={globalClasses.link}>
                    {t('searchBar.showAll', { resultsCount: searcherResultsCount })}
                  </Typography>
                }></ListItemText>
            </ListItem>
          )
        : null}
    </div>
  );
}
