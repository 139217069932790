import GetAppIcon from '@material-ui/icons/GetApp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BiScreenshot } from 'react-icons/bi';
import { BsCursorText } from 'react-icons/bs';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import {
  DEFAULT_PDF_SCALE,
  useUserSetting_pdf_scale,
} from '../../../../controllers/api/subscriptions/users/userSettings';
import {
  useQueryParam_pdfViewer_areaSelection,
  useQueryParam_pdfViewer_documentId,
  useQueryParam_pdfViewer_page,
} from '../../../../controllers/useGlobalQueryParams';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import useSetSearchParams from '../../../navbar/searchBar/useSetSearchParams';
import PaneTitleBar from '../../paneTitleBar';
import { CommentsMenuEntry } from '../comments/commentsMenuEntry';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import { PaneKeys } from '../paneKeys';
import { AnnotationsMenuEntry } from '../tag/annotation/annotationsMenuEntry';
import { TagInstancePredictionsSourcePaneTitleBarMenuEntry } from '../tagInstancePredictions/tagInstancePredictionsPaneTitleBarMenuEntry';
import { PdfViewerVariantType } from './pdfViewer';
import PdfViewerPaneTitleBarCurrentPage from './pdfViewerPaneTitleBarCurrentPage';

export default function PdfViewerPaneTitleBar({
  pdfViewerVariant,
}: {
  pdfViewerVariant: PdfViewerVariantType;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [scale, setScale] = useUserSetting_pdf_scale();

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(pdfViewerVariant);
  const [pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);
  const [, set_pdfViewer_areaSelection] = useQueryParam_pdfViewer_areaSelection(pdfViewerVariant);

  const openRenameEntryDialog = useOpenRenameEntryDialog();

  const { data: document, isLoading: documentIsLoading, isError } = useDocumentsGet(
    pdfViewer_documentId,
  );

  const onSetSearchScopeDocument = useSetSearchParams('document', pdfViewer_documentId);

  const onDownloadDocument = useCallback(() => {
    if (!document) return;
    documentsDownload(document.id);
  }, [document?.id]);

  const menuEntries = useMemo(
    () => [
      {
        key: 'zoomOut',
        text: 'Zoom out',
        icon: <ZoomOutIcon />,
        onClick: () => {
          setScale((scale || DEFAULT_PDF_SCALE) - 0.1);
        },
      },
      {
        key: 'setZoomLevel',
        icon: (
          <span className={globalClasses.text}>
            {Math.round((scale || DEFAULT_PDF_SCALE) * 100) + '%'}
          </span>
        ),
        menuEntries: [0.5, 0.75, 0.9, 1, 1.1, 1.25, 1.5, 2, 3].map((zoomLevel) => ({
          key: zoomLevel.toString(),
          selected: (scale || DEFAULT_PDF_SCALE) === zoomLevel,
          text: Math.round(zoomLevel * 100) + '%',
          onClick: () => setScale(zoomLevel),
        })),
      },
      {
        key: 'zoomIn',
        text: 'Zoom in',
        icon: <ZoomInIcon />,
        onClick: () => {
          setScale((scale || DEFAULT_PDF_SCALE) + 0.1);
        },
      },
      {
        key: 'divider1',
        text: '',
        divider: true,
      },
      {
        key: 'page',
        text: 'Page',
        inactive: true,
        icon: <PdfViewerPaneTitleBarCurrentPage pdfViewerVariant={pdfViewerVariant} />,
      },
      {
        key: 'divider2',
        text: '',
        divider: true,
      },
      {
        key: 'download',
        text: t('pdfViewerPaneTitleBar.download'),
        onClick: onDownloadDocument,
        icon: <GetAppIcon />,
      },
      {
        key: 'divider3',
        text: '',
        divider: true,
      },
      {
        key: 'selection-text',
        text: t('pdfViewerPaneTitleBar.textSelection'),
        icon: <BsCursorText color={COLORS.primary} />,
        onClick: () => set_pdfViewer_areaSelection(false),
      },
      {
        key: 'selection-area',
        text: t('pdfViewerPaneTitleBar.areaSelection'),
        icon: <BiScreenshot color={COLORS.primary} />,
        onClick: () => set_pdfViewer_areaSelection(true),
      },
      {
        key: 'divider4',
        text: '',
        divider: true,
      },
    ],
    [document?.id, scale, pdfViewer_page, document?.comments?.length],
  );

  const icon = useMemo(
    () =>
      document?.path?.entry_mimetype && (
        <EntryFileTypeIcon entryMimetype={document.path.entry_mimetype} size={20} />
      ),
    [document?.path.entry_mimetype],
  );

  const tagInstancePredictionsMenuEntry = TagInstancePredictionsSourcePaneTitleBarMenuEntry({
    documentId: document?.id,
  });

  const commentsMenuEntry = CommentsMenuEntry({ documentId: document?.id });
  const annotationsMenuEntry = AnnotationsMenuEntry({ documentId: document?.id });

  const title = (document && document.path?.entry_name) || '';

  return (
    <PaneTitleBar
      icon={icon}
      title={title}
      titleCopyable
      onSearchCallback={onSetSearchScopeDocument}
      onSearchCallbackText={t('pdfViewerPaneTitleBar.searchIn', { name: title })}
      tagInstancePredictionsMenuEntry={tagInstancePredictionsMenuEntry}
      commentsMenuEntry={commentsMenuEntry}
      annotationsMenuEntry={annotationsMenuEntry}
      onRenameCallback={openRenameEntryDialog({ entryId: document?.path.entry_id })}
      paneKey={pdfViewerVariant === 2 ? PaneKeys.pdfViewer2 : PaneKeys.pdfViewer}
      menuEntries={menuEntries}
      loading={documentIsLoading}
      isError={isError}
    />
  );
}
