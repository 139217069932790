import Codefy from '../../../../codefy';
import { QueryKey } from 'react-query';
import stringify from 'json-stable-stringify';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export type SearchSearchRequestParams = {
  query: string;
  directory_ids?: Codefy.Objects.Directory['id'][];
  recursive?: boolean;
  document_ids?: Codefy.Objects.Document['id'][];
  offset?: number;
  limit?: number;
  limit_total_preview_images_count?: number;
  method?: 'ngram' | 'more_like_this' | 'query_string';
  order_by?: 'relevance' | 'appearance';
};

export type SearchSearchReturnType = {
  query: string;
  num_results: number;
  results: Codefy.Objects.SearchResult[];
  autocompletions: string[];
};

export const SEARCH_SEARCH_KEY_TYPE = 'searchSearch';

export const searchSearchKey = (params: any): QueryKey => {
  // So that we can cache queries better since this does not change the content
  if ('limit_total_preview_images_count' in params) {
    delete params.limit_total_preview_images_count;
  }

  return [SEARCH_SEARCH_KEY_TYPE, stringify(params)];
};

export const SEARCH_SEARCH_METHOD_FULL_TRIGGERS = [
  '"',
  '(',
  ')',
  'AND',
  'OR',
  'NOT',
  '*',
  '~',
  '^',
];

export const useSearch = (params: SearchSearchRequestParams, keepPreviousData?: boolean) => {
  if (params.method !== 'more_like_this') {
    if (SEARCH_SEARCH_METHOD_FULL_TRIGGERS.some((trigger) => params.query.includes(trigger))) {
      params.method = 'query_string';
    } else {
      params.method = 'ngram';
    }
  }

  return useCreateInfiniteQuery<SearchSearchReturnType>(
    searchSearchKey(params),
    10,
    '/api/v1/search/search',
    'POST',
    { ...params, query: params.query.trim() },
    'results',
    keepPreviousData,
    !params.query,
  );
};
