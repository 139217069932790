import { COLORS, useGlobalStyles } from '../../../../../globalThemeSettings';
import React, { MouseEventHandler, useMemo } from 'react';

import Codefy from '../../../../../codefy';
import { Skeleton } from '@material-ui/lab';
import { useSearchSnippet } from '../../../../../controllers/api/subscriptions/search/searchSnippet';

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export default function AnnotationImage({
  annotation,
  onClick,
}: {
  annotation: Codefy.Objects.Annotation;
  onClick: MouseEventHandler<HTMLImageElement>;
}) {
  const globalClasses = useGlobalStyles();
  const loaderStyle: React.CSSProperties = useMemo(
    () => ({ minHeight: '400px', width: '100%' }),
    [],
  );

  const { data: imageData, isLoading } = useSearchSnippet({
    document_id: annotation.path.document_id,
    highlights: annotation.boxes.map((box) => {
      const rgb = hexToRgb(annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor);
      return {
        word_box: box,
        color: rgb ? [rgb.r, rgb.g, rgb.b] : [150, 150, 150],
      };
    }),
  });

  return (
    <>
      {isLoading ? (
        <div style={loaderStyle}>
          <Skeleton variant="rect" width="100%" height="400px" />
        </div>
      ) : (
        <img
          className={globalClasses.snippetImage}
          src={`data:image/png;base64,${imageData}`}
          onClick={onClick}
        />
      )}
    </>
  );
}
