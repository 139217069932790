import './commentStyle.css';

import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import React, { useState } from 'react';
import { useTrashModeIcons, useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

import Codefy from '../../../../codefy';
import EditIcon from '@material-ui/icons/Edit';
import GenericContextMenu from '../../../menus/genericContextMenu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NO_DRAG_SCROLL_CLASSNAME } from '../../panesViewportDragScrollHandler';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ROUTE_SHARE_COMMENT } from '../../../../controllers/routes/routes';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import { commentsEdit } from '../../../../controllers/api/actions/comments/commentsEdit';
import { commentsTrash } from '../../../../controllers/api/actions/comments/commentsTrash';
import gfm from 'remark-gfm';
import moment from 'moment';
import useHover from '../../../../hooks/useHover';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import useKeyPress from '../../../../hooks/useKeyPress';
import { useQueryParam_trashMode } from '../../../../controllers/useGlobalQueryParams';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(() =>
  createStyles({
    commentsRoot: {
      marginTop: '-10px',
      textAlign: 'left',
      wordBreak: 'break-all',
    },
    commentDeleteIcon: {
      float: 'right',
    },
    commentDeleteIconInactive: {
      float: 'right',
      opacity: 0,
    },
    createRoot: { paddingTop: '15px' },
    createRootInactive: {
      paddingTop: '15px',
      filter: 'grayscale(100%)',
    },
    createAvatar: {
      textAlign: 'center',
      marginTop: '2px',
      marginLeft: '7px',
    },
    createAvatarInactive: {
      textAlign: 'center',
      marginTop: '2px',
      marginLeft: '7px',
      opacity: 0.5,
    },
    createTextField: {
      background: '#00000000',
    },
    createTextFieldInactive: {
      background: '#00000000',
    },
    /* This is necessary to render text with line breaks, otherwise it's all one looong
       line */
    text: {
      whiteSpace: 'pre-line',
      margin: '0px',
    },
    cancelButton: {
      opacity: 0.7,
    },
    mentionIcon: {
      marginBottom: '-5px',
    },
    mentionEmail: {
      cursor: 'text',
    },
    mentionFormGroup: {
      marginLeft: '15px',
    },
    highlighted: {
      backgroundColor: COLORS.primaryLight,
    },
  }),
);

export function Comment({
  comment,
  showGoToButton,
  highlighted,
}: {
  comment: Codefy.Objects.Comment;
  showGoToButton?: boolean;
  highlighted?: boolean;
}) {
  const globalClasses = useGlobalStyles();
  const classes = useStyles({});
  const currentUser = useSelector((state: Codefy.State) => state.user);
  const [hoverRef, isHovered] = useHover();
  const { t } = useTranslation();
  const useCtrl = useKeyPress(17);
  const isEveryone = useIsEveryone();
  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const [trashing, setTrashing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [commentText, setCommentText] = useState(comment.text);
  const [saving, setSaving] = useState(false);

  const onTrash = async () => {
    if (trashing) return;
    setTrashing(true);
    await commentsTrash({ comment_id: comment.id });
  };

  const onRestore = async () => {
    await commentsTrash({ comment_id: comment.id, trashed: false });
  };

  const onSave = async () => {
    setSaving(true);
    await commentsEdit({
      comment_id: comment.id,
      text: commentText,
    });
    setSaving(false);
    setEditing(false);
  };

  const onCancel = () => {
    setCommentText(comment.text);
    setEditing(false);
  };

  /** Every time a key is pressed, check if it happens to be the enter key AND ctrl is held down, if
   * yes, save the comment */
  const onKeyDownSaveIfCtrlEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCodeForTheEnterKey = 13;
    if (event.keyCode === keyCodeForTheEnterKey) {
      if (useCtrl || event.metaKey /* for Mac OSX */) {
        onSave();
      }
    }
  };

  const commentIsFromCurrentUser =
    currentUser?.email && currentUser?.email === comment.created_by?.email;

  const onClickGoTo = () => {
    window.location.href = ROUTE_SHARE_COMMENT + comment.id;
  };

  let menuEntries =
    isEveryone || !commentIsFromCurrentUser
      ? []
      : [
          {
            key: 'edit',
            text: t('annotations.comments.edit'),
            icon: <EditIcon />,
            onClick: () => setEditing(true),
            disabled: !commentIsFromCurrentUser,
          },
          {
            key: 'trash',
            text: trashModeStrings('trash'),
            icon: trashModeIcons('trash'),
            onClick: onTrash,
            disabled: !commentIsFromCurrentUser,
          },
        ];

  if (trashMode) {
    menuEntries = [
      {
        key: 'restore',
        text: trashModeStrings('restore'),
        icon: trashModeIcons('restore'),
        onClick: onRestore,
        disabled: !commentIsFromCurrentUser,
      },
    ];
  }

  return (
    <GenericContextMenu menuEntries={menuEntries}>
      <div
        ref={hoverRef}
        data-e2e-id="annotation-comment"
        className={clsx(NO_DRAG_SCROLL_CLASSNAME, highlighted && classes.highlighted)}>
        <Box m={1} p={1} ml={0} mr={0}>
          <Box>
            {showGoToButton && !trashMode && (
              <Box
                display="flex"
                justifySelf="flex-end"
                className={globalClasses.floatRightVisible}
                onClick={onClickGoTo}>
                <Tooltip title={t('comments.showContent') || ''} enterDelay={1000}>
                  <IconButton size="small">
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {!isEveryone && commentIsFromCurrentUser && (
              <GenericContextMenu menuEntries={menuEntries} openOnLeftClick>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  style={isHovered ? { float: 'right' } : { opacity: 0, float: 'right' }}>
                  <MoreVertIcon />
                </IconButton>
              </GenericContextMenu>
            )}
          </Box>
          <b>{comment.created_by?.name || t('deletedUser')}</b> ·{' '}
          <Tooltip enterDelay={500} title={moment(comment.created_at).format('LLLL')}>
            <a
              href={ROUTE_SHARE_COMMENT + comment.id}
              className={clsx(globalClasses.linkHidden, globalClasses.textLight)}>
              {moment(comment.created_at).fromNow()}
            </a>
          </Tooltip>
          <br />
          {editing ? (
            <>
              <TextField
                multiline
                inputProps={{
                  'data-e2e-id': 'edit-comment-to-annotation-field',
                }}
                disabled={saving}
                placeholder={t('annotations.comments.editComment') + '…'}
                onChange={(event) => setCommentText(event.target.value)}
                value={commentText}
                fullWidth
                variant="standard"
                size="small"
                className={classes.createTextField}
                onKeyDown={onKeyDownSaveIfCtrlEnter}
              />
              <Box display="flex" justifyContent="flex-end" m={1}>
                <Button onClick={onCancel} className={globalClasses.cancelButton} variant="text">
                  {t('annotations.comments.cancel')}
                </Button>
                <Button
                  data-e2e-id="edit-comment-to-annotation-button"
                  className={globalClasses.dialogButton}
                  variant="text"
                  disabled={commentText.length === 0 || saving}
                  onClick={onSave}>
                  {t('annotations.comments.saveChanges')}
                </Button>
              </Box>
            </>
          ) : (
            <span className={clsx(globalClasses.text, 'comment')}>
              <ReactMarkdown remarkPlugins={[gfm]}>
                {comment.text.replace('\n', '\n\n')}
              </ReactMarkdown>
            </span>
          )}
        </Box>
      </div>
    </GenericContextMenu>
  );
}
