import {
  useQueryParam_tag_directoryId,
  useQueryParam_tag_documentId,
  useQueryParam_tag_tagId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import { Grid } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { TagInstancePredictionsSourcePaneTitleBarMenuEntry } from '../tagInstancePredictions/tagInstancePredictionsPaneTitleBarMenuEntry';
import useCurrentProjectId from '../../../../hooks/useCurrentProjectId';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useOpenEditTagDialog } from '../../../dialogs/edit/editTagDialog';
import useTagMenuEntries from './useTagMenuEntries';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

export default function TagPaneTitleBar({
  taglistType,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType });
  const [tag_directoryId] = useQueryParam_tag_directoryId({ taglistType });
  const [tag_documentId] = useQueryParam_tag_documentId({ taglistType });

  const { data: directory } = useDirectoriesGet(tag_directoryId);
  const { data: document } = useDocumentsGet(tag_documentId);
  const currentProjectId = useCurrentProjectId();

  const name =
    document?.path.entry_name ||
    directory?.path?.entry_name ||
    directory?.path?.project_name ||
    '...';

  const { data: tag, isError: isErrorTag } = useTagsGet({ tag_id: tag_tagId });

  const { data: taglist, isError: isErrorTaglist } = useTaglistsGet({
    taglist_id: tag?.taglist_id,
  });

  const tagMenuEntries = useTagMenuEntries({
    project_id: currentProjectId,
    tag,
    writePermission: taglist?.path.write_permission || false,
    addPermission: taglist?.path.add_permission || false,
    full: false,
  });

  const openEditTagDialog = useOpenEditTagDialog();

  const tagInstancePredictionsMenuEntry = TagInstancePredictionsSourcePaneTitleBarMenuEntry({
    entryTagId: tag_tagId,
  });

  return (
    <PaneTitleBar
      icon={taglistType === 'annotation' ? <LabelOutlinedIcon /> : <LabelIcon />}
      onRenameCallback={tag && openEditTagDialog({ tagId: tag?.id })}
      isError={isErrorTag || isErrorTaglist}
      title={
        <span>
          <Grid container alignItems="baseline" spacing={1} wrap="nowrap">
            {/* <Grid item>
              <small style={{ opacity: 0.7 }}>{tag?.cell}</small>
            </Grid> */}

            <Grid item className={globalClasses.overflowEllipsis}>
              {tag?.name || t('annotations.annotationsInside', { name })}
            </Grid>
          </Grid>
        </span>
      }
      paneKey={taglistType === 'annotation' ? PaneKeys.annotationTag : PaneKeys.entryTag}
      menuEntries={tagMenuEntries}
      tagInstancePredictionsMenuEntry={
        taglistType === 'entry' ? tagInstancePredictionsMenuEntry : undefined
      }
    />
  );
}
