import { CircularProgress, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ListRange, Virtuoso } from 'react-virtuoso';
import React, { useCallback } from 'react';

import Codefy from '../../../../codefy';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import { UploadBatchesListItem } from './UploadBatchesListItem';
import { patchUploadBatchesListBatches } from './patchUploadBatchesListBatches';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUploadBatchesListBatches } from '../../../../controllers/api/subscriptions/uploadBatches/uploadBatchesListBatches';

export default function UploadsBatches() {
  const { t } = useTranslation();
  const uploads = useSelector((state: Codefy.State) => state.uploads);

  const isPreparingUploads = useSelector((state: Codefy.State) => state.isPreparingUploads);

  const {
    data: uploadBatchesPages,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchedAfterMount,
  } = useUploadBatchesListBatches({});

  const patchedUploadBatchesPages = uploadBatchesPages?.pages?.map((uploadBatchesPage) =>
    patchUploadBatchesListBatches(uploadBatchesPage, uploads),
  );

  const uploadBatches =
    patchedUploadBatchesPages?.reduce((acc: Codefy.Objects.UploadBatch[], cur) => {
      for (const upload_batch of cur.patched_upload_batches) {
        acc.push(upload_batch);
      }
      return acc;
    }, []) || [];

  const renderUploadBatch = (index: number) => {
    if (!uploadBatches[index]) return <span></span>;
    return <UploadBatchesListItem uploadBatch={uploadBatches[index]} />;
  };

  /** Triggered when the user scrolls in the list */
  const onRangeChanged = useCallback(
    (range: ListRange) => {
      /** If user didn't scroll far enough yet */
      if (range.endIndex < uploadBatches.length - 50) return;

      /** If there is nothing more to load */
      if (!hasNextPage) return;

      fetchNextPage();
    },
    [uploadBatches.length, hasNextPage],
  );

  if (isLoading && uploadBatches.length === 0) {
    return <PaneContentLoading />;
  }

  if (
    isFetchedAfterMount &&
    uploadBatches.length === 0 &&
    uploadBatchesPages?.pages[0]?.localBatches.length === 0
  ) {
    return <PaneContentEmpty text={t('uploadBatch.noUploadsYet')} />;
  }

  return (
    <>
      {isPreparingUploads && (
        <ListItem divider>
          <ListItemIcon>
            <CircularProgress size={22} thickness={5} />
          </ListItemIcon>
          <ListItemText primary={t('uploadBatch.preparingUpload')} />
        </ListItem>
      )}
      {uploadBatchesPages?.pages[0]?.localBatches.map((localbatch) => (
        <UploadBatchesListItem key={localbatch.upload_batch_uuid} uploadBatch={localbatch} />
      ))}
      <div style={{ width: '100%', height: '100%' }}>
        <Virtuoso
          totalCount={uploadBatches?.length || 0}
          itemContent={renderUploadBatch}
          rangeChanged={onRangeChanged}
        />
      </div>
    </>
  );
}
