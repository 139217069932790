import React, { useState } from 'react';

import Account from '../panes/paneTypes/account';
import { GenericDialog } from './genericDialog';
import { useBetween } from 'use-between';
import { useTranslation } from 'react-i18next';

type DialogAccountStateType = {};

const useDialogAccountState = () => useState<DialogAccountStateType>();

const useSharedDialogAccountState = () => useBetween(useDialogAccountState);

export const AccountDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogAccountState();

  const onClose = () => setState(undefined);

  if (!state) return null;

  return (
    <GenericDialog title={t('account.account')} onClose={onClose} open>
      <Account />
    </GenericDialog>
  );
};

export const useOpenAccountDialog = () => {
  const [, setState] = useSharedDialogAccountState();
  return (state: DialogAccountStateType) => setState(state);
};
