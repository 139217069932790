import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export type MentionsListParams = {
  project_id?: Codefy.Objects.Project['id'];
  assignee_id?: Codefy.Objects.User['id'];
  creator_id?: Codefy.Objects.User['id'];
  offset?: number;
  limit?: number;
  order_by?: 'created_at' | 'created_by' | 'assigned_to' | 'done' | 'done_at';
  descending?: boolean;
};

export type MentionsListReturnType = { mentions: Codefy.Objects.Mention[]; num_mentions: number };

export const MENTIONS_LIST_KEY_TYPE = 'mentionsList';

const MENTIONS_LIST_URL = '/api/v1/mentions/list';

export const mentionsListKey = (params: MentionsListParams): [string, Record<string, any>] => [
  MENTIONS_LIST_KEY_TYPE,
  params,
];

export const useMentionsList = (params: MentionsListParams) =>
  useCreateInfiniteQuery<MentionsListReturnType>(
    mentionsListKey(params),
    10,
    MENTIONS_LIST_URL,
    'GET',
    params,
    'mentions',
    false,
    !params.assignee_id,
  );
