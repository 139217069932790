import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_publicationUuid,
} from '../controllers/useGlobalQueryParams';

import { Helmet } from 'react-helmet';
import React from 'react';
import { useDirectoriesGet } from '../controllers/api/subscriptions/directories/directoriesGet';
import { usePublicationsGetByUuid } from '../controllers/api/subscriptions/publications/publicationsGetByUuid';

/** Dynamically changes the HTML <head> content to provide a user-friendly page title and also
 * inserts open graph <meta> tags for social media link previews */
export default function HtmlHeadRenderer() {
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const [publicationUuid] = useQueryParam_publicationUuid();
  const { data: directory } = useDirectoriesGet(entriesList_directoryId);
  const { data: publication } = usePublicationsGetByUuid(publicationUuid);

  const publicationImageUrl =
    publication &&
    `${window.location.protocol}//${window.location.hostname}/p/${publication.uuid}/preview.png`;

  const titleTag = (
    <title>
      {directory?.path?.project_name ? `${directory?.path?.project_name} | Codefy` : 'Codefy'}
    </title>
  );

  if (!publication) {
    return <Helmet>{titleTag}</Helmet>;
  } else {
    return (
      <Helmet>
        {titleTag}
        <meta name="title" property="og:title" content={publication?.title} />
        <meta name="description" property="og:description" content={publication?.description} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Codefy" />
        <meta name="image" property="og:image" content={publicationImageUrl} />
      </Helmet>
    );
  }
}
