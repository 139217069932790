import { Divider, ListItemIcon, MenuItem } from '@material-ui/core';

import { GenericNestableMenuEntry } from './genericNestableMenuEntry';
import NestedMenuItem from './nestedMenuItem';
import React from 'react';
import clsx from 'clsx';
import { useGlobalStyles } from '../../globalThemeSettings';

export function GenericNestableMenuItem({
  menuItem,
  parentMenuOpen,
  itemClickedCallback,
}: {
  menuItem: GenericNestableMenuEntry;
  parentMenuOpen: boolean;
  itemClickedCallback?: () => void;
}) {
  const globalClasses = useGlobalStyles();

  if (menuItem.disabled) return null;

  if (menuItem.divider) {
    return <Divider style={{ marginTop: '7px', marginBottom: '7px' }} />;
  }

  const onClick: React.MouseEventHandler<HTMLLIElement> = (event) => {
    /** Clicking on a nestable menu should not close the menu */
    if (menuItem.menuEntries && menuItem.menuEntries.length > 0) {
      event.stopPropagation();
      return;
    }

    const keepOpen = menuItem.onClick?.(event);
    if (!keepOpen || typeof keepOpen === 'object') {
      itemClickedCallback?.();
    }
  };

  if (!menuItem.menuEntries) {
    return (
      <MenuItem
        disableGutters={menuItem.disableGutters}
        button={menuItem?.onClick ? true : undefined}
        onClick={onClick}
        disabled={menuItem.disabled || menuItem.inactive}
        selected={menuItem.selected}
        data-e2e-id={menuItem.dataE2eId}
        className={clsx(globalClasses.listEntry)}>
        {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
        {menuItem.text}
      </MenuItem>
    );
  }

  return (
    <NestedMenuItem
      label={menuItem.text}
      icon={menuItem.icon}
      parentMenuOpen={parentMenuOpen}
      onClick={menuItem?.onClick || menuItem.menuEntries ? onClick : undefined}
      disabled={menuItem.disabled || menuItem.inactive}
      selected={menuItem.selected}
      data-e2e-id={menuItem.dataE2eId}>
      {menuItem.menuEntries
        .filter((menuItem) => !menuItem.disabled)
        .map((menuItem) => (
          <GenericNestableMenuItem
            key={menuItem.key}
            menuItem={menuItem}
            parentMenuOpen={parentMenuOpen}
            itemClickedCallback={itemClickedCallback}
          />
        ))}
    </NestedMenuItem>
  );
}
