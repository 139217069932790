import {
  useUserSetting_taglist_descending,
  useUserSetting_taglist_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import AddIcon from '@material-ui/icons/Add';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import Codefy from '../../../../codefy';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenCreateTagDialog } from '../../../dialogs/create/createTagDialog';
import { useOpenExportPrettyWordDialog } from '../../../dialogs/export/exportPrettyWordDialog';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function useTaglistMenuEntries({
  taglist,
}: {
  taglist?: Codefy.Objects.Taglist;
}): GenericNestableMenuEntry[] {
  const { t } = useTranslation();
  const isEveryone = useIsEveryone();

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);
  //const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId();
  //const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const [taglist_descending, set_taglist_descending] = useUserSetting_taglist_descending();
  const [taglist_orderBy, set_taglist_orderBy] = useUserSetting_taglist_orderBy();

  const { id: currentCaseId } = useCurrentCase();

  //const { data: directory } = useDirectoriesGet(entriesList_directoryId);
  // const { data: document } = useDocumentsGet(pdfViewer_documentId);
  // const { data: project } = useProjectsGet(directory?.path?.project_id);

  // const uploadInputRef = useRef(null);

  const openCreateTagDialog = useOpenCreateTagDialog();
  const openExportPrettyWordDialog = useOpenExportPrettyWordDialog();

  if (!taglist || !userEmail) return [];

  // const onClickUpload = () => {
  //   //@ts-ignore
  //   uploadInputRef?.current?.click?.();
  //   /** true = keep menu open (so that the <input> element still exists when the user selects files) */
  //   return true;
  // };

  // const onExportExcelCurrentCase = () => {
  //   if (!pdfViewer_documentId || !taglist || !currentCaseId) return;
  //   taglistsExportCustomExcel({ directory_ids: [currentCaseId], taglist_id: taglist.id });
  // };

  // const onExportMarkdownDocument = () => {
  //   if (!pdfViewer_documentId) return;
  //   taglistsExportMarkdown({ taglist_id: taglist.id, document_id: pdfViewer_documentId });
  // };

  // const onExportMarkdownAll = () => {
  //   taglistsExportMarkdown({ taglist_id: taglist.id });
  // };

  // const onTrainModel = () => {
  //   taglistsTrainModel({ taglist_id: taglist.id });
  // };

  return [
    {
      key: 'new',
      text: t('useTaglistMenuEntries.newLabel'),
      icon: <AddIcon />,
      onClick: openCreateTagDialog({ taglistId: taglist.id }),
      disabled: isEveryone || !taglist.path.add_permission,
    },
    {
      key: 'sort',
      text: t('useTaglistMenuEntries.sortBy'),
      icon: <SortIcon />,
      menuEntries: [
        {
          key: 'sortName',
          text: t('useTaglistMenuEntries.name'),
          icon: <SortByAlphaIcon />,
          selected: taglist_orderBy === 'name',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTaglistMenuEntries.AZName'),
              selected: !taglist_descending && taglist_orderBy === 'name',
              onClick: async () => {
                await set_taglist_descending(false);
                await set_taglist_orderBy('name');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTaglistMenuEntries.ZAName'),
              selected: taglist_descending && taglist_orderBy === 'name',
              onClick: async () => {
                await set_taglist_descending(true);
                await set_taglist_orderBy('name');
              },
            },
          ],
        },
        {
          key: 'sortCreationDate',
          text: t('useTaglistMenuEntries.creationDate'),
          icon: <ScheduleIcon />,
          selected: taglist_orderBy === 'created_at',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTaglistMenuEntries.oldestFirst'),
              selected: !taglist_descending && taglist_orderBy === 'created_at',
              onClick: async () => {
                await set_taglist_descending(false);
                await set_taglist_orderBy('created_at');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTaglistMenuEntries.newestFirst'),
              selected: taglist_descending && taglist_orderBy === 'created_at',
              onClick: async () => {
                await set_taglist_descending(true);
                await set_taglist_orderBy('created_at');
              },
            },
          ],
        },
      ],
    },
    {
      key: 'export',
      text: t('exportDialog.downloadReport'),
      icon: <AssignmentReturnedOutlinedIcon />,
      onClick: () => {
        openExportPrettyWordDialog({
          taglist_id: taglist.id,
          case_id: currentCaseId,
        });
        // taglistsExportPrettyWord({
        //   taglist_id: taglist.id,
        //   case_id: currentCaseId,
        //   case_name: currentCaseName,
        //   user_email: userEmail,
        // });
      },
      disabled: taglist.type !== 'annotation' || taglist.tags.length === 0,
    },
    // Disabled on 2021-05-18, the code can be removed if we don't want custom excel exports anymore
    // {
    //   key: 'more',
    //   text: t('useTaglistMenuEntries.more'),
    //   icon: <MoreHorizIcon />,
    //   disabled: true, //currently disabled taglist.type !== 'annotation' || taglist.tags.length === 0,
    //   menuEntries: [
    //     {
    //       key: 'train',
    //       text: t('useTaglistMenuEntries.train'),
    //       icon: (
    //         <Badge badgeContent={taglist.predict_model_sample_count}>
    //           <CodefyIcon />
    //         </Badge>
    //       ),
    //       onClick: onTrainModel,
    //     },
    //     {
    //       key: 'exportMarkdown',
    //       text: t('useTaglistMenuEntries.exportAsMarkdownFile'),
    //       icon: (
    //         <FaMarkdown
    //           fontSize="small"
    //           style={{ marginLeft: '2px', marginBottom: '-3px' }}
    //           size={20}
    //         />
    //       ),
    //       menuEntries: [
    //         {
    //           key: 'onExportMarkdownDocument',
    //           text: t('useTaglistMenuEntries.document', {
    //             name: document?.path.entry_name,
    //           }),
    //           onClick: onExportMarkdownDocument,
    //           disabled: !pdfViewer_documentId,
    //         },
    //         {
    //           key: 'onExportMarkdownAll',
    //           text: t('useTaglistMenuEntries.all'),
    //           onClick: onExportMarkdownAll,
    //         },
    //       ],
    //     },
    //     {
    //       key: 'customExcelTemplate',
    //       text: t('useTaglistMenuEntries.exportInCustomExcelTemplate'),
    //       icon: (
    //         <Box ml={0.25}>
    //           <EntryFileTypeIcon entryMimetype="application/vnd.ms-excel" size={18} />
    //         </Box>
    //       ),
    //       menuEntries: [
    //         {
    //           key: 'export',
    //           text: t('useTaglistMenuEntries.exportInCustomExcelTemplate'),
    //           icon: <GetAppIcon />,
    //           disabled: !taglist.template_name,
    //           menuEntries: [
    //             {
    //               key: 'exportExcel',
    //               text: t('useTaglistMenuEntries.exportAsExcelFile'),
    //               icon: (
    //                 <FaFileExport
    //                   fontSize="small"
    //                   style={{ marginLeft: '4px', marginBottom: '-3px' }}
    //                   size={20}
    //                 />
    //               ),
    //               menuEntries: [
    //                 {
    //                   key: 'exportCase',
    //                   text: t('useTaglistMenuEntries.case', { name: currentCaseName }),
    //                   icon: (
    //                     <FaFileExport
    //                       fontSize="small"
    //                       style={{ marginLeft: '4px', marginBottom: '-3px' }}
    //                       size={20}
    //                     />
    //                   ),
    //                   onClick: onExportExcelCurrentCase,
    //                   disabled: !taglist.template_name || !pdfViewer_documentId || !currentCaseId,
    //                 },
    //                 {
    //                   key: 'exportAllCases',
    //                   text: t('useTaglistMenuEntries.allCases'),
    //                   icon: (
    //                     <FaFileExport
    //                       fontSize="small"
    //                       style={{ marginLeft: '4px', marginBottom: '-3px' }}
    //                       size={20}
    //                     />
    //                   ),
    //                   onClick: openExportExcelAllCasesDialog({
    //                     taglist_id: taglist.id,
    //                     project_directory_id: project?.directory_id,
    //                   }),
    //                   disabled: !taglist.template_name || !entriesList_directoryId,
    //                 },
    //               ],
    //               disabled: !taglist.template_name,
    //             },
    //           ],
    //         },
    //         {
    //           key: 'uploadExcel',
    //           text: taglist.template_name
    //             ? t('useTaglistMenuEntries.replaceAttachedExcelTemplate', {
    //                 name: taglist.template_name,
    //               })
    //             : t('useTaglistMenuEntries.attachExcelTemplate'),
    //           onClick: onClickUpload,
    //           icon: (
    //             <Box>
    //               <input
    //                 ref={uploadInputRef}
    //                 type="file"
    //                 multiple={false}
    //                 accept={[
    //                   '.xls',
    //                   'application/vnd.ms-excel',
    //                   '.xlsx',
    //                   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //                 ].join(', ')}
    //                 style={{ display: 'none' }}
    //                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
    //                   if (event.target.files && taglist.id) {
    //                     const files = Array.from(event.target.files);
    //                     taglistsUploadCustomExcelTemplate({
    //                       taglist_id: taglist.id,
    //                       template: files[0],
    //                     });
    //                   }
    //                 }}
    //               />
    //             </Box>
    //           ),
    //           disabled: isEveryone,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
}
