import Codefy from '../../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export const TAGS_GET_URL = '/api/v1/taglists/tags/get';

export const TAGS_GET_KEY_TYPE = 'tagsGet';

export type TagsGetRequestParams = {
  tag_id?: Codefy.Objects.Tag['id'];
  case_id?: Codefy.Objects.Directory['id'];
};

export const tagsGetKey = (
  params: TagsGetRequestParams,
): [string, TagsGetRequestParams | undefined] => [TAGS_GET_KEY_TYPE, params];

export const useTagsGet = (params: TagsGetRequestParams) => {
  return useQuery(tagsGetKey(params), async () => {
    if (!params?.tag_id) return undefined;
    const { data } = await axios.get(TAGS_GET_URL, { params });
    return data as Codefy.Objects.Tag;
  });
};
