import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../subscriptions/annotations/annotationsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../subscriptions/annotations/annotationsGet';
import Codefy from '../../../../codefy';
import { DIRECTORIES_PARENTS_KEY_TYPE } from '../../subscriptions/directories/directoriesParents';
import { DOCUMENTS_GET_KEY_TYPE } from '../../subscriptions/documents/documentsGet';
import { ENTRIES_GET_KEY_TYPE } from '../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import { TAGLISTS_GET_KEY_TYPE } from '../../subscriptions/taglists/taglistsGet';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { UPLOAD_BATCHES_GET_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesGet';
import { UPLOAD_BATCHES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatches';
import { UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { executeAction } from '../actionHelpers';

export const ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH = [
  ENTRIES_LIST_KEY_TYPE,
  ENTRIES_GET_KEY_TYPE,
  PROJECTS_LIST_KEY_TYPE,
  TAGLISTS_GET_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
  TAG_INSTANCES_GET_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_GET_KEY_TYPE,
  DIRECTORIES_PARENTS_KEY_TYPE,
  UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE,
  UPLOAD_BATCHES_GET_KEY_TYPE,
  UPLOAD_BATCHES_LIST_KEY_TYPE,
  DOCUMENTS_GET_KEY_TYPE,
];

export const entriesDelete = (data: { entry_ids: Codefy.Objects.Entry['id'][] }) =>
  executeAction<Codefy.Objects.Entry>({
    config: { url: '/api/v1/entries/delete', method: 'POST' },
    data,
    queryKeysToRefresh: ENTRIES_DELETE_QUERY_KEYS_TO_REFRESH,
  });
