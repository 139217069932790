import AddCommentIcon from './addComment';
import { Badge } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import Codefy from '../../codefy';
import React from 'react';

export default function CommentsIcon({
  commentsCount,
  hideAddIcon,
}: {
  commentsCount?: Codefy.Objects.Comment[]['length'];
  hideAddIcon?: boolean;
}) {
  if (!commentsCount) {
    if (hideAddIcon) return null;
    return <AddCommentIcon />;
  }

  return (
    <Badge badgeContent={commentsCount} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <ChatOutlinedIcon />
    </Badge>
  );
}
