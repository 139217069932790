import {
  GenericPublicationDialog,
  GenericPublicationDialogOnDoneCreate,
  GenericPublicationDialogOnDoneSave,
} from './genericPublicationDialog';
import React, { useState } from 'react';

import Codefy from '../../../codefy';
import { GenericDialog } from '../genericDialog';
import PaneContentLoading from '../../panes/paneContentLoading';
import { publicationsCreate } from '../../../controllers/api/actions/publications/publicationsCreate';
import { publicationsUpdate } from '../../../controllers/api/actions/publications/publicationsUpdate';
import { useBetween } from 'use-between';
import { useProjectsGet } from '../../../controllers/api/subscriptions/projects/projectsGet';

type DialogCreatePublicationStateType = {
  project_id: Codefy.Objects.Publication['project_id'];
  project_name: Codefy.Objects.Project['name'];
  target_url: Codefy.Objects.Publication['target_url'];
  image_blob: Blob;
  image_data_url: string;
};

const useDialogCreatePublicationState = () => useState<DialogCreatePublicationStateType>();

const useSharedDialogCreatePublicationState = () => useBetween(useDialogCreatePublicationState);

export const CreatePublicationDialog = () => {
  const [state, setState] = useSharedDialogCreatePublicationState();

  const onClose = () => setState(undefined);

  const onDoneCreate: GenericPublicationDialogOnDoneCreate = async (props) => {
    if (!state) return;

    return await publicationsCreate({
      project_id: state?.project_id,
      target_url: state?.target_url,
      title: props.title,
      description: props.description,
      image_blob: new File([state?.image_blob], 'preview'),
    });
  };

  const onDoneSave: GenericPublicationDialogOnDoneSave = async (props) => {
    if (!state) return;
    return await publicationsUpdate(props);
  };

  const { data: project, isFetching } = useProjectsGet(state?.project_id);

  if (!state) return null;

  if (isFetching)
    return (
      <GenericDialog title="" open>
        <PaneContentLoading />
      </GenericDialog>
    );

  return (
    <GenericPublicationDialog
      currentPublication={project?.publication}
      projectName={state.project_name}
      projectId={state.project_id}
      onDoneCreate={onDoneCreate}
      onDoneSave={onDoneSave}
      onClose={onClose}
      imageDataUrl={state.image_data_url}
    />
  );
};

export const useOpenCreatePublicationDialog = () => {
  const [, setState] = useSharedDialogCreatePublicationState();
  return (state: DialogCreatePublicationStateType) => setState(state);
};
