import NoAuthScreenWrapper from '../../../components/auth/noAuthScreenWrapper';
import PaneContentLoading from '../../../components/panes/paneContentLoading';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { usePublicationsGetByUuid } from '../../api/subscriptions/publications/publicationsGetByUuid';

interface MatchParams {
  publicationUuid: string;
}

/** Forwards a user that has accessed a publication to the correct target URL. This is necessary
 * (even though the backend also forwards /p/ routes) because after the first visit, the browser
 * might cache our React application and will try to use that when rendering /p/ links. Therefore,
 * the React application needs to be able to handle /p/ links in addition to the backend. */
export default function PublicationUrlForwarder({ match }: RouteComponentProps<MatchParams>) {
  const publicationUuid = match.params.publicationUuid;
  const { data: publication } = usePublicationsGetByUuid(publicationUuid);
  const history = useHistory();

  useEffect(() => {
    if (!publication) return;
    history.replace(publication.target_url);
  }, [!!publication]);

  return (
    <NoAuthScreenWrapper>
      <PaneContentLoading />
    </NoAuthScreenWrapper>
  );
}
