import { Grid } from '@material-ui/core';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SortIcon from '@material-ui/icons/Sort';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSearchRequestParams } from '../../../controllers/api/subscriptions/search/searchSearch';
import RelevantIcon from '../../icons/relevant';
import { PaneMenuBarEntry } from '../../panes/paneTitleBar';

export default function SearchOrderBySwitch({
  value,
  onChange,
}: {
  value: SearchSearchRequestParams['order_by'];
  onChange: (value: SearchSearchRequestParams['order_by']) => any;
}) {
  const { t } = useTranslation();

  return (
    <Grid component="label" container alignItems="center" alignContent="center">
      <Grid item></Grid>
      <Grid item>
        <PaneMenuBarEntry
          menuEntry={{
            key: 'sort',
            text: t('useTagMenuEntries.sortBy'),
            icon: <SortIcon />,
            menuEntries: [
              {
                key: 'sortRelevance',
                text: t('searchPane.relevance'),
                icon: <RelevantIcon />,
                selected: !value || value === 'relevance',
                onClick: () => onChange('relevance'),
              },
              {
                key: 'sortName',
                text: t('searchPane.appearance'),
                icon: <FormatListNumberedIcon />,
                selected: value === 'appearance',
                onClick: () => onChange('appearance'),
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}
