import { Box, Button, Chip, Tooltip, makeStyles } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../globalThemeSettings';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useQueryParam_nextUrl,
  useQueryParam_panes,
  useQueryParam_trashMode,
} from '../../controllers/useGlobalQueryParams';
import { useTrashModeIcons, useTrashModeStrings } from '../../hooks/useTrashModeVariants';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import AppLogo from '../appLogo/AppLogo';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Codefy from '../../codefy';
import { E2E } from '../../testing/common/data-e2e-ids';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { ROUTE_LOGIN } from '../../controllers/routes/routes';
import SearchBar from './searchBar/searchBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { setSearchBarInputValue } from './searchBar/setSearchBarInputValue';
import useIsEveryone from '../../hooks/useIsEveryone';
import { useOpenAccountDialog } from '../dialogs/accountDialog';
import { usePaneActions } from '../panes/usePaneActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  appBar: {
    display: 'flex',
    backgroundColor: COLORS.backgroundLight,
    color: COLORS.primary,
    marginTop: '3px',
    marginBottom: '-3px',
  },
  logo: {
    cursor: 'pointer',
  },
  flexGrow: { flexGrow: 1 },
});

export function Navbar() {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [panes] = useQueryParam_panes();
  const history = useHistory();
  const isEveryone = useIsEveryone();
  const dispatch = useDispatch();
  const userEmail = useSelector((state: Codefy.State) => state.user?.email);
  const userIsAdmin = useSelector((state: Codefy.State) => state.user?.admin);
  const paneActions = usePaneActions();
  const [, set_nextUrl] = useQueryParam_nextUrl();
  const [trashMode, set_trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const openAccountDialog = useOpenAccountDialog();

  const onOpenUploads = useCallback(() => {
    paneActions.addOrUpdatePane({ paneKey: PaneKeys.uploadBatches });
  }, [panes]);

  const onOpenAccount = useCallback(() => {
    if (isEveryone) {
      const nextUrl = window.location.href;
      history.push(ROUTE_LOGIN);
      set_nextUrl(nextUrl);
    } else {
      openAccountDialog({});
      dispatch({ type: 'setSearch', open: false });
    }
  }, [panes]);

  const onClickLogo = useCallback(() => {
    dispatch({ type: 'setSearch', query: '' });
    setSearchBarInputValue('');
    if (isEveryone) {
      window.location.href = 'https://codefy.de/kontakt';
    } else {
      history.push('/');
    }
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar variant="dense">
        <div onClick={onClickLogo}>
          <Box mb={-1} ml={0} mr={1.5} className={classes.logo}>
            <AppLogo width="125px" />
          </Box>
        </div>

        {trashMode ? (
          <Chip
            style={{ width: '100%', height: '40px' }}
            icon={trashModeIcons('trash')}
            onClick={() => set_trashMode(false)}
            onDelete={() => set_trashMode(false)}
            label={
              <span className={globalClasses.heading}>{trashModeStrings('youAreViewing')}</span>
            }
            deleteIcon={<ExitToAppIcon />}
          />
        ) : (
          <>
            <Box ml={1.5} width="100%" display="flex" flexWrap="nowrap">
              <SearchBar />

              {isEveryone && (
                <Box m={0.5} ml={2} display="inline-block">
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(globalClasses.dialogButton, globalClasses.getCodefyDemoButton)}
                    onClick={onClickLogo}>
                    {t('isEveryoneNavbar.getCodefyDemo')}
                  </Button>
                </Box>
              )}
            </Box>
            {userIsAdmin && (
              <Link to="/replay">
                <IconButton>
                  <PlayCircleFilledIcon />
                </IconButton>
              </Link>
            )}
            {!isEveryone && (
              <Tooltip title={trashModeStrings('open')}>
                <Box mr={-1}>
                  <IconButton onClick={() => set_trashMode(!trashMode)}>
                    {trashModeIcons('trash')}
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {!isEveryone && (
              <Tooltip title={t('uploadBatch.openUploadManager') || ''}>
                <Box mr={-1}>
                  <IconButton onClick={onOpenUploads}>
                    <CloudUploadIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {isEveryone ? (
              <>
                <Box m={0.5} ml={2} display="inline-block">
                  <Button className={clsx(globalClasses.dialogButton)} onClick={onClickLogo}>
                    {t('isEveryoneNavbar.contact')}
                  </Button>
                </Box>
                <Box m={0.5} ml={2} display="inline-block">
                  <Button
                    className={clsx(globalClasses.dialogButton)}
                    onClick={() => {
                      window.location.href = 'https://codefy.de/impressum';
                    }}>
                    {t('isEveryoneNavbar.imprint')}
                  </Button>
                </Box>
              </>
            ) : (
              <Tooltip title={(t('account.title') || '') + ': ' + userEmail}>
                <Box>
                  <IconButton
                    aria-label="user account"
                    edge="end"
                    color="inherit"
                    onClick={onOpenAccount}
                    data-e2e-id={E2E.account_open_account_pane_button}>
                    <AccountCircleIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
