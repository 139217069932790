import { ROUTE_LOGIN, ROUTE_PANES_VIEWPORT } from '../routes';
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import Codefy from '../../../codefy';
import { ENTRIES_GET_URL } from '../../api/subscriptions/entries/entriesGet';
import { GLOBAL_QUERY_PARAMS } from '../../useGlobalQueryParams';
import NoAuthScreenWrapper from '../../../components/auth/noAuthScreenWrapper';
import PaneContentLoading from '../../../components/panes/paneContentLoading';
import { PaneKeys } from '../../../components/panes/paneTypes/paneKeys';
import { TAGS_GET_URL } from '../../api/subscriptions/taglists/tags/tagsGet';
import { TAG_INSTANCES_GET_URL } from '../../api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../api/subscriptionHelpers';
import { useCommentsGet } from '../../api/subscriptions/comments/commentsGet';

interface MatchParams {
  commentId: string;
}

/** Forwards a user to a comment */
export default function CommentUrlForwarder({ match }: RouteComponentProps<MatchParams>) {
  const commentId = parseInt(match.params.commentId);
  const { data: comment, isError } = useCommentsGet(commentId);
  const history = useHistory();

  useEffect(() => {
    const go = async () => {
      if (!comment) return;

      const params: Record<string, any> = {
        [GLOBAL_QUERY_PARAMS.panes]: [PaneKeys.projectsList] as PaneKeys[],
        [GLOBAL_QUERY_PARAMS.commentsDialog_highlightCommentWithId]: comment.id,
      };

      if (comment.entry_id) {
        const entryResponse = await axios.get<Codefy.Objects.Entry>(ENTRIES_GET_URL, {
          params: { entry_id: comment.entry_id },
        });

        const entry = entryResponse.data;

        params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entriesList);
        params[GLOBAL_QUERY_PARAMS.entriesList_directoryId] = entry.path?.directory_id;

        if (entry?.document?.id) {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.pdfViewer);
          params[GLOBAL_QUERY_PARAMS.pdfViewer_documentId] = entry.document.id;
        } else if (entry?.directory?.id) {
          params[GLOBAL_QUERY_PARAMS.entriesList_directoryId] = entry.directory.id;
        } else if (entry?.taglist?.id) {
          if (entry.taglist.type === 'annotation') {
            params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.annotationTaglist);
            params[GLOBAL_QUERY_PARAMS.annotationTaglist_id] = entry.taglist.id;
          } else if (entry.taglist.type === 'entry') {
            params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entryTaglist);
            params[GLOBAL_QUERY_PARAMS.entryTaglist_id] = entry.taglist.id;
          }
        }
      } else if (comment.tag_id) {
        const tagResponse = await axios.get<Codefy.Objects.Tag>(TAGS_GET_URL, {
          params: { tag_id: comment.tag_id },
        });
        const tag = tagResponse.data;

        if (tag?.taglist_type === 'annotation') {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.annotationTaglist);
          params[GLOBAL_QUERY_PARAMS.annotationTaglist_id] = tag.taglist_id;
        } else if (tag.taglist_type === 'entry') {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entryTaglist);
          params[GLOBAL_QUERY_PARAMS.entryTaglist_id] = tag.taglist_id;
        }
      } else if (comment.tag_instance_id) {
        const tagInstanceResponse = await axios.get<Codefy.Objects.TagInstance>(
          TAG_INSTANCES_GET_URL,
          {
            params: { tag_instance_id: comment.tag_instance_id },
          },
        );
        const tagInstance = tagInstanceResponse.data;

        if (tagInstance.type === 'annotation') {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entriesList);
          params[GLOBAL_QUERY_PARAMS.entriesList_directoryId] = tagInstance.path?.directory_id;
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.pdfViewer);
          params[GLOBAL_QUERY_PARAMS.pdfViewer_documentId] =
            tagInstance.annotation.path.document_id;
          params[GLOBAL_QUERY_PARAMS.pdfViewer_page] = tagInstance.annotation.boxes?.[0]?.page;
          params[GLOBAL_QUERY_PARAMS.pdfViewer_boxes] = JSON.stringify(
            tagInstance.annotation.boxes,
          );
        } else if (tagInstance.type === 'annotation_tag') {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.annotationTaglist);
          params[GLOBAL_QUERY_PARAMS.annotationTaglist_id] = tagInstance.annotation_tag.taglist_id;
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.annotationTag);
          params[GLOBAL_QUERY_PARAMS.annotationTag_tagId] = tagInstance.annotation_tag.id;
        } else if (tagInstance.type === 'entry') {
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entryTaglist);
          params[GLOBAL_QUERY_PARAMS.entryTaglist_id] = tagInstance.taglist_id;
          params[GLOBAL_QUERY_PARAMS.panes].push(PaneKeys.entryTag);
          params[GLOBAL_QUERY_PARAMS.entryTag_tagId] = tagInstance.tag_id;
        }
      }

      const url = ROUTE_PANES_VIEWPORT + '?' + fastApiParamsSerializer(params);

      history.replace(url);
    };

    go();
  }, [!!comment]);

  if (isError) return <Redirect to={ROUTE_LOGIN + '?nextUrl=' + window.location.href} />;

  return (
    <NoAuthScreenWrapper>
      <PaneContentLoading />
    </NoAuthScreenWrapper>
  );
}
