import { COMMENT_QUERY_KEYS_TO_REFRESH } from './commentsCreate';
import Codefy from '../../../../codefy';
import { executeAction } from '../actionHelpers';

export const commentsEdit = async (data: {
  comment_id: Codefy.Objects.Comment['id'];
  text: string;
}) =>
  executeAction<Codefy.Objects.Comment>({
    config: { url: '/api/v1/comments/edit', method: 'POST' },
    data,
    queryKeysToRefresh: COMMENT_QUERY_KEYS_TO_REFRESH,
  });
