import { ALERT_STYLE, COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useTrashModeIcons, useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';
import {
  useUserSetting_annotations_snippetVariant,
  useUserSetting_tag_descending,
  useUserSetting_tag_filter,
  useUserSetting_tag_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import { Alert } from '@material-ui/lab';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Codefy from '../../../../codefy';
import CommentsIcon from '../../../icons/commentsIcon';
import { CreateComment } from '../comments/createComment';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import ImageIcon from '@material-ui/icons/Image';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';
import StorageIcon from '@material-ui/icons/Storage';
import TagLabelsEditor from '../../../tagLabels/tagLabelsEditor';
import TaglistSelector from '../taglist/taglistSelector';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { tagsColor } from '../../../../controllers/api/actions/taglists/tags/tagsColor';
import { tagsTrash } from '../../../../controllers/api/actions/taglists/tags/tagsTrash';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenCommentsDialog } from '../../../dialogs/commentsDialog';
import { useOpenEditTagDialog } from '../../../dialogs/edit/editTagDialog';
import { useOpenSavedSearchTermsDialog } from '../../../dialogs/edit/editTagSavedSearchTermsDialog';
import { usePaneActions } from '../../usePaneActions';
import { useProjectsGet } from '../../../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_trashMode } from '../../../../controllers/useGlobalQueryParams';
import useTagColorsMenuEntries from './useTagColorsMenuEntries';
import { useTranslation } from 'react-i18next';
import CodefyIcon from '../../../appLogo/codefyIcon';

export default function useTagMenuEntries({
  project_id,
  tag,
  writePermission,
  addPermission,
  owner,
  full,
}: {
  project_id?: Codefy.Objects.Project['id'];
  tag?: Codefy.Objects.Tag;
  writePermission: boolean;
  addPermission: boolean;
  owner?: Codefy.Objects.User;
  /** Should all options be shown or just a minimal subset? */
  full?: boolean;
}): GenericNestableMenuEntry[] {
  const { t } = useTranslation();
  //const modalActions = useModalActions();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();

  const globalClasses = useGlobalStyles();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const [
    userSettingAnnotationsSnippetVariant,
    set_userSettingAnnotationsSnippetVariant,
  ] = useUserSetting_annotations_snippetVariant();

  const { id: currentCaseId } = useCurrentCase();

  const { data: project } = useProjectsGet(project_id);
  //const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId();
  const [tag_descending, set_tag_descending] = useUserSetting_tag_descending();
  const [tag_orderBy, set_tag_orderBy] = useUserSetting_tag_orderBy();
  const [tag_filter, set_tag_filter] = useUserSetting_tag_filter();

  //const openEditTagCellDialog = useOpenEditTagCellDialog();
  const openEditTagDialog = useOpenEditTagDialog();
  const openCommentsDialog = useOpenCommentsDialog();
  const openSavedSearchTermsDialog = useOpenSavedSearchTermsDialog();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const tagColorMenuEntries = useTagColorsMenuEntries({
    currentColor: tag?.color,
    onSelectColor: (color) => {
      if (tag?.id) {
        tagsColor({ tag_id: tag.id, color });
      }
    },
  });

  if (!project?.id) return [];

  // const onTagExportTextTemplates = () => {
  //   if (!tag.id || !tag.taglist_id) return;
  //   modalActions.openModal({
  //     modal: ModalKeys.editExportTextTemplates,
  //     params: {
  //       editExportTextTemplates_tagId: tag.id,
  //     },
  //   });
  // };

  // const onExportMarkdownDocument = () => {
  //   if (!pdfViewer_documentId) return;
  //   taglistsExportMarkdown({
  //     taglist_id: tag.taglist_id,
  //     tag_id: tag.id,
  //     document_id: pdfViewer_documentId,
  //   });
  // };

  // const onExportMarkdownAll = () => {
  //   taglistsExportMarkdown({ taglist_id: tag.taglist_id, tag_id: tag.id });
  // };

  const onViewTrainingData = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: project?.directory_id },
    });
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.annotationTag,
      params: {
        annotationTag_tagId: tag?.id,
      },
    });
  };

  const onOpenComments = () => {
    if (!tag?.id) return;
    openCommentsDialog({ tag_id: tag?.id });
  };

  if (trashMode) {
    return [
      {
        key: 'trash',
        text: trashModeStrings('restore'),
        icon: trashModeIcons('restore'),
        disabled: !tag || !full || isEveryone || !writePermission,
        onClick: tag ? () => tagsTrash({ tag_id: tag?.id, trashed: false }) : undefined,
      },
    ];
  }

  return [
    {
      key: 'commentHeadline',
      text: (
        <Typography className={globalClasses.subheading}>
          {t('entriesList.contextMenu.comment')}
        </Typography>
      ),
      disabled: !tag || !full || !(addPermission || tag.comments.length !== 0),
    },
    {
      key: 'addComment',
      text: (
        <Box mr={1} ml={1} onClick={(event) => event.stopPropagation()} width={400} bgcolor="white">
          <Box maxWidth="100%">
            <CreateComment
              createCommentParams={{ type: 'tag', tag_id: tag?.id, case_id: currentCaseId }}
            />
          </Box>
        </Box>
      ),
      disableGutters: true,
      disabled: !tag || !addPermission || !full || tag.comments.length !== 0,
    },
    {
      key: 'viewComments',
      text: t('comments.viewComments'),
      icon: (
        <IconButton
          size="small"
          disabled
          style={{ color: COLORS.primary, backgroundColor: 'none' }}>
          <CommentsIcon commentsCount={tag?.comments.length} />
        </IconButton>
      ),
      onClick: onOpenComments,
      disabled: !tag || tag.comments.length === 0 || !full,
    },
    {
      key: 'taglistSelector',
      text: (
        <Box mt={1} width="100%">
          <Box m={2} mt={2} mb={1}>
            <Typography className={globalClasses.subheading}>
              {t('entriesList.contextMenu.projectManagement')}
            </Typography>
          </Box>
          <TaglistSelector directoryId={project.directory_id} taglistType="entry" menu />
          <Box width="100%" mt={1}>
            <TagLabelsEditor
              taglistType="entry"
              project_id={project?.id}
              annotation_tag={tag}
              source="tagList"
              indent
            />
          </Box>
        </Box>
      ),
      disabled:
        !tag ||
        tag.taglist_type === 'entry' ||
        !currentCaseId ||
        !full ||
        !operationsTagsEnabled ||
        !addPermission,
      disableGutters: true,
    },
    {
      key: 'editHeadline',
      text: (
        <Box mt={1}>
          <Typography className={globalClasses.subheading}>
            {t('entriesList.contextMenu.edit')}
          </Typography>
        </Box>
      ),
      disabled: !tag || !full,
    },
    {
      key: 'managedBy',
      disabled: !tag || writePermission === true || !owner || isEveryone,
      text: owner ? (
        <Box width="100%">
          <Alert severity="info" style={ALERT_STYLE}>
            {t('taglist.managedBy')}{' '}
            <a className={globalClasses.linkLight} href={`mailto:${owner?.email}`}>
              {owner?.name}
            </a>
            .
          </Alert>
        </Box>
      ) : (
        <span />
      ),
    },
    {
      key: 'edit',
      text: t('useTagMenuEntries.edit'),
      icon: <EditIcon />,
      onClick: openEditTagDialog({ tagId: tag?.id }),
      disabled: !tag || !writePermission,
    },
    {
      key: 'savedSearchTerms',
      text: t('useTagMenuEntries.savedSearchTerms'),
      icon: <CodefyIcon />,
      onClick: () => {
        if (!tag?.id) return;
        openSavedSearchTermsDialog({ tag_id: tag?.id });
      },
      disabled: !full || !tag || !writePermission,
    },
    {
      key: 'color',
      text: t('useTagMenuEntries.changeColor'),
      icon: <Brightness1Icon style={{ color: tag?.color || COLORS.defaultTagColor }} />,
      menuEntries: tagColorMenuEntries,
      disabled: !tag || !writePermission,
    },
    {
      key: 'viewTrainingData',
      text: t('useTagMenuEntries.viewTrainingData'),
      icon: <StorageIcon />,
      onClick: onViewTrainingData,
      disabled: !tag || tag.taglist_type !== 'annotation' || true, // currently disabled since we don't show data from training,
    },
    // {
    //   key: 'cell',
    //   text: tag.cell
    //     ? t('useTagMenuEntries.changeLinkedExcelCell')
    //     : t('useTagMenuEntries.linkToExcelCell'),
    //   icon: <GridOnIcon />,
    //   onClick: openEditTagCellDialog({ tagId: tag.id }),
    //   disabled: !full || isEveryone || !taglist.template_name || taglist.type !== 'annotation',
    // },
    // {
    //   key: 'exportTextTemplates',
    //   text: tag.export_text_templates
    //     ? t('useTagMenuEntries.editExistingExportTextTemplates')
    //     : t('useTagMenuEntries.addExportTextTemplates'),
    //   icon: <PlaylistAddCheckIcon />,
    //   onClick: onTagExportTextTemplates,
    //   disabled: !full || isEveryone || !taglist.template_name || taglist.type !== 'annotation',
    // },
    {
      key: 'sort',
      text: t('useTagMenuEntries.sortBy'),
      icon: <SortIcon />,
      disabled: full,
      menuEntries: [
        {
          key: 'sortName',
          text: t('useTagMenuEntries.name'),
          icon: <SortByAlphaIcon />,
          selected: tag_orderBy === 'name',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTagMenuEntries.AZName'),
              selected: !tag_descending && tag_orderBy === 'name',
              onClick: async () => {
                await set_tag_descending(false);
                await set_tag_orderBy('name');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTagMenuEntries.ZAName'),
              selected: tag_descending && tag_orderBy === 'name',
              onClick: async () => {
                await set_tag_descending(true);
                await set_tag_orderBy('name');
              },
            },
          ],
        },
        {
          key: 'sortCreationDate',
          text: t('useTagMenuEntries.creationDate'),
          icon: <ScheduleIcon />,
          selected: tag_orderBy === 'created_at',
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('useTagMenuEntries.oldestFirst'),
              selected: !tag_descending && tag_orderBy === 'created_at',
              onClick: async () => {
                await set_tag_descending(false);
                await set_tag_orderBy('created_at');
              },
            },
            {
              key: 'sortNameDescending',
              text: t('useTagMenuEntries.newestFirst'),
              selected: tag_descending && tag_orderBy === 'created_at',
              onClick: async () => {
                await set_tag_descending(true);
                await set_tag_orderBy('created_at');
              },
            },
          ],
        },
      ],
    },
    {
      key: 'filter',
      text: t('useTagMenuEntries.filterBy'),
      icon: <FilterListIcon />,
      selected: tag_filter !== 'all',
      disabled: full,
      menuEntries: [
        {
          key: 'useTagMenuEntries',
          text: t('useTagMenuEntries.all'),
          onClick: () => set_tag_filter('all'),
          selected: tag_filter === 'all',
        },
        {
          key: 'useTagMenuEntries',
          text: t('useTagMenuEntries.own'),
          onClick: () => set_tag_filter('own'),
          selected: tag_filter === 'own',
        },
      ],
    },
    // {
    //   key: 'export',
    //   text: 'Export',
    //   icon: <PublishIcon />,
    //   disabled: !full || taglist.type !== 'annotation' || tag.num_tag_instances === 0,
    //   menuEntries: [
    //     {
    //       key: 'exportMarkdown',
    //       text: t('useTagMenuEntries.exportAsMarkdownFile'),
    //       icon: (
    //         <FaMarkdown
    //           fontSize="small"
    //           style={{ marginLeft: '4px', marginBottom: '-3px' }}
    //           size={18}
    //         />
    //       ),
    //       menuEntries: [
    //         {
    //           key: 'exportDocument',
    //           text: t('useTagMenuEntries.currentDocument'),
    //           onClick: onExportMarkdownDocument,
    //           disabled: !pdfViewer_documentId,
    //         },
    //         {
    //           key: 'exportDirectoryAsOne',
    //           text: t('useTagMenuEntries.all'),
    //           onClick: onExportMarkdownAll,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      key: 'snippetVariant',
      text:
        userSettingAnnotationsSnippetVariant === 'image'
          ? t('snippetVariant.showText')
          : t('snippetVariant.showImage'),
      icon: userSettingAnnotationsSnippetVariant === 'image' ? <TextFieldsIcon /> : <ImageIcon />,
      disabled: full,
      onClick: () =>
        set_userSettingAnnotationsSnippetVariant(
          userSettingAnnotationsSnippetVariant === 'image' ? 'text' : 'image',
        ),
    },
    {
      key: 'trash',
      text: trashModeStrings('trash'),
      icon: trashModeIcons('trash'),
      disabled: !tag || !full || isEveryone || !writePermission,
      onClick: tag ? () => tagsTrash({ tag_id: tag?.id }) : undefined,
    },
  ];
}
