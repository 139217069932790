import Codefy from '../../../../../codefy';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const TAGS_LIST_URL = '/api/v1/taglists/tags/list';

export const TAGS_LIST_KEY_TYPE = 'tagsList';

export type TagsListParams = {
  taglist_id?: Codefy.Objects.Taglist['id'];
  tag_ids?: Codefy.Objects.Tag['id'][];
  directory_ids?: Codefy.Objects.Directory['id'][];
  case_id?: Codefy.Objects.Directory['id'];
  colors?: string[];
  offset?: number;
  limit?: number;
  order_by?: 'created_at' | 'created_by' | 'position' | 'name' | 'trashed_at';
  descending?: boolean;
  trashed?: boolean;
};

export type TagsListReturnType = { tags: Codefy.Objects.Tag[]; num_tags: number };

export const tagsListKey = (params: TagsListParams): [string, TagsListParams] => [
  TAGS_LIST_KEY_TYPE,
  params,
];

export const useTagsList = (params: TagsListParams) => {
  return useQuery(
    tagsListKey(params),
    async () => {
      if (
        (!params.tag_ids || params.tag_ids?.length === 0) &&
        (!params.directory_ids || params.directory_ids.length === 0) &&
        !params.taglist_id
      )
        return undefined;
      const { data } = await axios.get<TagsListReturnType>(TAGS_LIST_URL, {
        params,
        paramsSerializer: fastApiParamsSerializer,
      });

      return data;
    },
    { keepPreviousData: true },
  );
};
