import { Grid } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import React from 'react';
import { SnippetVariant } from '../../../controllers/api/subscriptions/users/userSettings';
import { PaneMenuBarEntry } from '../../panes/paneTitleBar';

export default function SnippetVariantSwitch({
  value,
  onChange,
}: {
  value: SnippetVariant;
  onChange: (value: SnippetVariant) => any;
}) {
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      alignContent="center"
      onClick={() => onChange(value === 'image' ? 'text' : 'image')}>
      <Grid item></Grid>
      <Grid item>
        <PaneMenuBarEntry
          menuEntry={{
            key: 'snippetVariant',
            // Not showing text since the tooltip stays open after clicking,
            // which looks weird
            //text: value === 'image' ? t('snippetVariant.showText') : t('snippetVariant.showImage'),
            icon: value === 'image' ? <TextFieldsIcon /> : <ImageIcon />,
            onClick: () => onChange(value === 'image' ? 'text' : 'image'),
          }}
        />
      </Grid>
    </Grid>
  );
}
