import Codefy from '../codefy';
import { useSelector } from 'react-redux';

/** Check if the  user is currently an anonymous viewer of a publicly shared project. */
const useIsEveryone = (): boolean => {
  const userEmail = useSelector((state: Codefy.State) => state.user?.email);

  const isEveryone = !userEmail || userEmail === 'everyone';

  return isEveryone;
};

export default useIsEveryone;
