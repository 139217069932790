import {
  useQueryParam_comments_directoryId,
  useQueryParam_comments_documentId,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_comments_descending,
  useUserSetting_comments_filter,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import { Box } from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SortIcon from '@material-ui/icons/Sort';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { useTranslation } from 'react-i18next';

export const CommentsPaneTitleBar = () => {
  const { t } = useTranslation();

  const [comments_filter, set_comments_filter] = useUserSetting_comments_filter();
  const [comments_descending, set_comments_descending] = useUserSetting_comments_descending();
  const [comments_directoryId] = useQueryParam_comments_directoryId();
  const [comments_documentId] = useQueryParam_comments_documentId();

  const { data: directory } = useDirectoriesGet(comments_directoryId);
  const { data: document } = useDocumentsGet(comments_documentId);

  const name =
    document?.path.entry_name ||
    directory?.path?.entry_name ||
    directory?.path?.project_name ||
    '...';

  const menuEntries: GenericNestableMenuEntry[] = [
    {
      key: 'sort',
      text: t('commentsPaneTitleBar.sortBy'),
      icon: <SortIcon />,
      menuEntries: [
        {
          key: 'sortCreationDate',
          text: t('commentsPaneTitleBar.creationDate'),
          icon: <ScheduleIcon />,
          menuEntries: [
            {
              key: 'sortNameAscending',
              text: t('commentsPaneTitleBar.oldestFirst'),
              selected: !comments_descending,
              onClick: async () => {
                await set_comments_descending(false);
              },
            },
            {
              key: 'sortNameDescending',
              text: t('commentsPaneTitleBar.newestFirst'),
              selected: comments_descending,
              onClick: async () => {
                await set_comments_descending(true);
              },
            },
          ],
        },
      ],
    },
    {
      key: 'filter',
      text: t('commentsPaneTitleBar.filterBy'),
      icon: <FilterListIcon />,
      selected: comments_filter !== 'all',
      menuEntries: [
        {
          key: 'commentsPaneTitleBar_all',
          text: t('commentsPaneTitleBar.all'),
          onClick: () => set_comments_filter('all'),
          selected: comments_filter === 'all',
        },
        {
          key: 'commentsPaneTitleBar_own',
          text: t('commentsPaneTitleBar.own'),
          onClick: () => set_comments_filter('own'),
          selected: comments_filter === 'own',
        },
        {
          key: 'commentsPaneTitleBar_mentions',
          text: t('commentsPaneTitleBar.mentions'),
          onClick: () => set_comments_filter('mentions'),
          selected: comments_filter === 'mentions',
        },
      ],
    },
  ];

  return (
    <PaneTitleBar
      icon={
        <Box mb={-1}>
          <ChatOutlinedIcon />
        </Box>
      }
      title={t('comments.commentsInside', { name })}
      paneKey={PaneKeys.comments}
      menuEntries={menuEntries}
    />
  );
};
