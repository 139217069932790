import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../../../subscriptions/annotations/annotationsList';

import { ANNOTATIONS_GET_KEY_TYPE } from '../../../../subscriptions/annotations/annotationsGet';
import Codefy from '../../../../../../codefy';
import { ENTRIES_GET_KEY_TYPE } from '../../../../subscriptions/entries/entriesGet';
import { ENTRIES_LIST_KEY_TYPE } from '../../../../subscriptions/entries/entriesList';
import { TAGLISTS_GET_KEY_TYPE } from '../../../../subscriptions/taglists/taglistsGet';
import { TAGS_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../../../actionHelpers';
import { tagInstancePredictionsListKey } from '../../../../subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';
import { queryClient } from '../../../../../../App';

export const TAG_INSTANCES_DELETE_QUERY_KEY_TO_REFRESH = [
  TAGLISTS_GET_KEY_TYPE,
  ENTRIES_LIST_KEY_TYPE,
  ENTRIES_GET_KEY_TYPE,
  TAG_INSTANCES_GET_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
  TAGS_GET_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_GET_KEY_TYPE,
];

export const tagInstancesDelete = async (data: {
  tag_instance_id: Codefy.Objects.TagInstance['id'];
}) => {
  const response = await executeAction<Codefy.Objects.TagInstance>({
    config: { url: '/api/v1/taglists/tags/instances/delete', method: 'POST' },
    data,
    queryKeysToRefresh: TAG_INSTANCES_DELETE_QUERY_KEY_TO_REFRESH,
  });

  if (response) {
    queryClient.invalidateQueries([tagInstancePredictionsListKey({ tag_id: response.tag_id })]);
  }

  return response;
};
