import useCurrentCase, { CurrentCaseEntry } from '../../../../hooks/useCurrentCase';
import {
  useQueryParam_taglist_id,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_taglist_descending,
  useUserSetting_taglist_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import { TagListTag } from './tagListTag';
import getTagsHierarchy from '../tag/getTagsHierarchy';
import { makeStyles } from '@material-ui/core';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagsList';
import { useTranslation } from 'react-i18next';
import { useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%', overflowY: 'auto' },
}));

export default function Taglist({ taglistType }: { taglistType: Codefy.Objects.Taglist['type'] }) {
  const { t } = useTranslation();
  const [taglist_orderBy] = useUserSetting_taglist_orderBy();
  const [taglist_descending] = useUserSetting_taglist_descending();
  const { id: currentCaseId } = useCurrentCase();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();

  const classes = useStyles();

  const [taglist_id] = useQueryParam_taglist_id({ taglistType });

  const { data: taglist } = useTaglistsGet({ taglist_id, case_id: currentCaseId });

  const { data: tags, isFetching } = useTagsList({
    taglist_id: taglist_id,
    order_by: trashMode ? 'trashed_at' : taglist_orderBy,
    descending: trashMode ? true : taglist_descending,
    case_id: currentCaseId,
    trashed: trashMode,
  });

  if (isFetching && !tags?.tags) return <PaneContentLoading />;

  if (!tags?.tags || tags.tags.length === 0 || !taglist)
    return (
      <PaneContentEmpty
        text={
          trashMode
            ? trashModeStrings('binEmpty')
            : taglistType === 'annotation'
            ? t('taglist.noAnnotationTags')
            : t('taglist.noEntryTags')
        }
      />
    );

  return (
    <div className={classes.root}>
      <CurrentCaseEntry />

      {getTagsHierarchy(tags.tags).map((tag) => (
        <TagListTag
          key={tag.id}
          project_id={taglist?.path.project_id}
          tag={tag}
          writePermission={taglist.path.write_permission}
          addPermission={taglist.path.add_permission}
          owner={taglist.owned_by}
        />
      ))}
    </div>
  );
}
