import React, { useEffect, useState } from 'react';

import { PANES_VIEWPORT_ELEMENT_ID } from './panesViewport';
import { PDF_PAGE_CLASS_NAME } from './paneTypes/pdfViewer/pdfPage';
import { throttle } from 'throttle-debounce';

/** Disable drag-to-scroll on some elements (usually elements that contain text) to prevent issues
 * when the user wants to select text  */
export const NO_DRAG_SCROLL_CLASSNAME = 'no-drag-scroll';

/** Allows dragging the application left and right, allowing for more comfortable horizontal
 * scrolling */
export function PanesViewportDragScrollHandler({ children }: { children: React.ReactNode }) {
  const [isDragging, setIsDragging] = useState<boolean>();
  const [pos, setPos] = useState<{ left: number; x: number }>();

  const onMouseMove = throttle(25, (event: MouseEvent) => {
    if (!pos || !isDragging) return;

    event.preventDefault();
    event.stopPropagation();

    const panesViewportElement = document.getElementById(PANES_VIEWPORT_ELEMENT_ID);
    if (!panesViewportElement) return;

    // How far the mouse has been moved
    /** Multiply by some factor so that the mouse has to travel less distance to reach the
     * destination */
    const dx = (event.clientX - pos.x) * 2.5;

    panesViewportElement.scrollLeft = pos.left - dx;
  });

  const onMouseUp = () => {
    setIsDragging(false);
    setPos(undefined);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document?.removeEventListener('mousemove', onMouseMove);
      document?.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging]);

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const panesViewportElement = document.getElementById(PANES_VIEWPORT_ELEMENT_ID);
    if (!panesViewportElement) return;

    const elementsFromPoint = document.elementsFromPoint(event.clientX, event.clientY);

    /* Skip moving if elements are present that require the drag movement, e.g. for text selection */
    const nodeNamesToSkip = ['INPUT', 'SPAN'];
    const classesToSkip = [PDF_PAGE_CLASS_NAME, NO_DRAG_SCROLL_CLASSNAME];
    for (const element of elementsFromPoint) {
      if (nodeNamesToSkip.includes?.(element?.nodeName)) return;
      for (const classToSkip of classesToSkip) {
        if (element?.className?.includes?.(classToSkip)) return;
      }
    }

    setPos({ left: panesViewportElement.scrollLeft, x: event.clientX });
    setIsDragging(true);
  };

  return <div onMouseDown={onMouseDown}>{children}</div>;
}
