import Codefy from '../../../codefy';
import { SearchSearchRequestParams } from '../../../controllers/api/subscriptions/search/searchSearch';
import { useSelector } from 'react-redux';

/** When a search query has more than this amount of words, use the "intelligent"/similarity search functionality */
export const SEARCH_METHOD_CUTOFF = 5;

export default function useSearchMethod(): SearchSearchRequestParams['method'] {
  /** Search query typed in by the user in the search field */
  const searchQuery = useSelector((state: Codefy.State) => state.search.query);

  return searchQuery.split(' ').length > SEARCH_METHOD_CUTOFF ? 'query_string' : 'ngram';
}
