import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_entries_descending,
  useUserSetting_entries_filterByMimetype,
  useUserSetting_entries_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import DirectoryFileUploadWrapper from './directoryFileUploadWrapper';
import { EntriesListItem } from './entriesListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useEntriesList } from '../../../../controllers/api/subscriptions/entries/entriesList';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

export const DEFAULT_SORTED_BY = 'mimetype';
export const DEFAULT_DESCENDING = true;

// TODO: Add ability to act on multiple items

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function EntriesListContent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const [entriesList_orderBy] = useUserSetting_entries_orderBy();
  const [entriesList_descending] = useUserSetting_entries_descending();
  const [entriesList_filterByMimetype] = useUserSetting_entries_filterByMimetype();

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const {
    data: entriesPages,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchedAfterMount,
  } = useEntriesList({
    directory_ids: entriesList_directoryId ? [entriesList_directoryId] : undefined,
    order_by: trashMode ? 'trashed_at' : entriesList_orderBy,
    descending: trashMode ? true : entriesList_descending,
    mimetypes: entriesList_filterByMimetype,
    trashed: trashMode,
  });

  const entriesList =
    entriesPages?.pages.reduce((acc: Codefy.Objects.Entry[], cur) => {
      for (const entry of cur.entries) {
        if (entry.mimetype === 'application/codefy.entry-taglist' && !operationsTagsEnabled) {
          continue;
        }

        acc.push(entry);
      }
      return acc;
    }, []) || [];

  if (!isFetchedAfterMount || (isLoading && entriesList.length === 0)) {
    return <PaneContentLoading />;
  }

  if (entriesList.length === 0)
    return (
      <DirectoryFileUploadWrapper directory_id={entriesList_directoryId}>
        <PaneContentEmpty
          text={trashMode ? trashModeStrings('binEmpty') : t('entriesList.noEntries')}
        />
      </DirectoryFileUploadWrapper>
    );

  return (
    <DirectoryFileUploadWrapper directory_id={entriesList_directoryId}>
      <div className={classes.root} ref={ref}>
        <InfiniteScroll
          className={classes.virtuoso}
          dataLength={entriesList?.length || 0}
          next={fetchNextPage}
          height={height - 1}
          hasMore={!!hasNextPage}
          loader={<PaneContentLoading />}>
          {entriesList.map((entry) => (
            <EntriesListItem key={entry.id} entry={entry} hideOpenInParentDirectory={true} />
          ))}
        </InfiniteScroll>
      </div>
    </DirectoryFileUploadWrapper>
  );
}
