import { ALERT_STYLE, useGlobalStyles } from '../../../globalThemeSettings';
import { Box, Button, TextareaAutosize, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { Alert } from '@material-ui/lab';
import Codefy from '../../../codefy';
import { GenericDialog } from '../genericDialog';
import { TagListTag } from '../../panes/paneTypes/taglist/tagListTag';
import { tagsSavedSearchTerms } from '../../../controllers/api/actions/taglists/tags/tagsSavedSearchTerms';
import { useBetween } from 'use-between';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

type DialogSavedSearchTermsStateType = { tag_id: Codefy.Objects.Tag['id'] };

const useDialogSavedSearchTermsState = () => useState<DialogSavedSearchTermsStateType>();

const useSharedDialogSavedSearchTermsState = () => useBetween(useDialogSavedSearchTermsState);

export const EditTagSavedSearchTermsDialog = () => {
  const [state, setState] = useSharedDialogSavedSearchTermsState();
  const { data: tag } = useTagsGet({ tag_id: state?.tag_id });
  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const onClose = () => setState(undefined);
  const onSave = async (newSavedSearchTerms: Codefy.Objects.Tag['saved_search_terms']) => {
    if (!tag?.id) return;

    return await tagsSavedSearchTerms({ tag_id: tag?.id, saved_search_terms: newSavedSearchTerms });
  };

  if (!state || !tag || !taglist) return null;

  return (
    <SavedSearchTermsDialogRenderer
      tag={tag}
      onSave={onSave}
      onClose={onClose}
      writePermission={taglist.path.write_permission}
      owner={taglist.owned_by}
    />
  );
};

export const SavedSearchTermsDialogRenderer = ({
  tag,
  onSave,
  onClose,
  writePermission,
  owner,
}: {
  tag: Codefy.Objects.Tag;
  onSave: (
    newSavedSearchTerms: Codefy.Objects.Tag['saved_search_terms'],
  ) => Promise<Codefy.Objects.Tag | undefined>;
  onClose: () => void;
  writePermission: boolean;
  owner: Codefy.Objects.Taglist['owned_by'];
}) => {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [savedSearchTerms, setSavedSearchTerms] = useState(tag.saved_search_terms);
  const [input, setInput] = useState<string>('');

  const onSaveSearchTerm = async (savedSearchTerms: string) => {
    const result = await onSave(savedSearchTerms);
    if (result) {
      setSavedSearchTerms(savedSearchTerms);
    }
  };

  return (
    <GenericDialog
      title={t('useTagMenuEntries.savedSearchTerms')}
      onClose={onClose}
      open
      fullScreen>
      <Box>
        <TagListTag tag={tag} writePermission={false} addPermission={false} hideButtons />
        {writePermission && (
          <TextareaAutosize
            rowsMin={10}
            placeholder={t('useTagMenuEntries.addNew')}
            defaultValue={savedSearchTerms}
            onChange={(event) => {
              setInput(event.target.value);
            }}
            style={{ width: '100%', marginTop: '5px', fontFamily: 'monospace' }}
          />
        )}
        <Button
          onClick={() => onSaveSearchTerm(input)}
          color="primary"
          className={globalClasses.dialogButton}>
          {t('useTagMenuEntries.saveSearchTerm')}
        </Button>
        {!writePermission && owner && (
          <Alert severity="info" style={ALERT_STYLE}>
            {t('taglist.managedBy')}{' '}
            <a className={globalClasses.linkLight} href={`mailto:${owner.email}`}>
              {owner.name}
            </a>
            .
          </Alert>
        )}

        <Typography className={globalClasses.textLight}>
          {t('useTagMenuEntries.predictionHelp', { postProcess: 'markdown-jsx' })}
        </Typography>
      </Box>
    </GenericDialog>
  );
};

export const useOpenSavedSearchTermsDialog = () => {
  const [, setState] = useSharedDialogSavedSearchTermsState();
  return (state: DialogSavedSearchTermsStateType) => setState(state);
};
