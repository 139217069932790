import {
  useQueryParam_tag_directoryId,
  useQueryParam_tag_documentId,
  useQueryParam_tag_tagId,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_tag_descending,
  useUserSetting_tag_filter,
  useUserSetting_tag_orderBy,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import TagInstance from './tagInstance';
import { makeStyles } from '@material-ui/core';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useCurrentProjectId from '../../../../hooks/useCurrentProjectId';
import { useMeasure } from 'react-use';
import { useSelector } from 'react-redux';
import { useTagInstancesList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';
import { useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function Tag({ taglistType }: { taglistType: Codefy.Objects.Taglist['type'] }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();

  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType });
  const [tag_directoryId] = useQueryParam_tag_directoryId({ taglistType });
  const [tag_documentId] = useQueryParam_tag_documentId({ taglistType });

  const [tag_descending] = useUserSetting_tag_descending();
  const [tag_orderBy] = useUserSetting_tag_orderBy();

  const [tag_filter] = useUserSetting_tag_filter();
  const userId = useSelector((state: Codefy.State) => state.user?.id);

  const { id: currentCaseId } = useCurrentCase();

  const { data: tag } = useTagsGet({ tag_id: tag_tagId });
  const currentProjectId = useCurrentProjectId();

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [refAlert, { height: heightAlert }] = useMeasure<HTMLDivElement>();

  const {
    data: tagInstancesPages,
    fetchNextPage,
    hasNextPage,
    isFetchedAfterMount,
  } = useTagInstancesList({
    tag_ids: tag_tagId ? [tag_tagId] : undefined,
    directory_ids: tag_directoryId ? [tag_directoryId] : undefined,
    document_ids: tag_documentId ? [tag_documentId] : undefined,
    case_ids: currentCaseId ? [currentCaseId] : undefined,
    owner_id: tag_filter === 'own' ? userId : undefined,
    order_by: trashMode ? 'trashed_at' : tag_orderBy,
    descending: tag_descending,
    types: [taglistType],
    trashed: trashMode,
  });

  const tagInstances =
    tagInstancesPages?.pages.reduce((acc: Codefy.Objects.TagInstance[], cur) => {
      for (const tagInstance of cur.tag_instances) {
        acc.push(tagInstance);
      }
      return acc;
    }, []) || [];

  const renderTagInstance = (index: number) => {
    if (!tagInstances || !tagInstances[index]) return <span></span>;

    return (
      <TagInstance
        project_id={currentProjectId}
        key={tagInstances[index].id}
        tagInstance={tagInstances[index]}
        showTagInformation={!tag}
      />
    );
  };

  if (!isFetchedAfterMount && tagInstances.length === 0) return <PaneContentLoading />;

  return (
    <div className={classes.root} ref={ref}>
      {isFetchedAfterMount && tagInstances.length === 0 ? (
        <PaneContentEmpty
          text={trashMode ? trashModeStrings('binEmpty') : t('annotations.listIsEmpty')}
        />
      ) : (
        <InfiniteScroll
          className={classes.virtuoso}
          dataLength={tagInstances.length || 0}
          next={fetchNextPage}
          height={height - (tag?.description ? heightAlert : 0) - 10}
          hasMore={!!hasNextPage}
          loader={<PaneContentLoading />}>
          {tagInstances.map((_, index) => renderTagInstance(index))}
        </InfiniteScroll>
      )}
    </div>
  );
}
