import Codefy from '../../../../codefy';
import { Comment } from '../comments/comment';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMeasure } from 'react-use';
import { useMentionsList } from '../../../../controllers/api/subscriptions/mentions/mentionsList';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function MentionsList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const userId = useSelector((state: Codefy.State) => state.user?.id);

  const {
    data: mentionsPages,
    isFetching,
    isFetchedAfterMount,
    fetchNextPage,
    hasNextPage,
  } = useMentionsList({
    assignee_id: userId,
    order_by: 'created_at',
    descending: true,
  });

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const mentions =
    mentionsPages?.pages.reduce((acc: Codefy.Objects.Mention[], cur) => {
      for (const task of cur.mentions) {
        acc.push(task);
      }
      return acc;
    }, []) || [];

  if (!isFetchedAfterMount && mentions.length === 0) return <PaneContentLoading />;

  if (!isFetching && mentions.length === 0)
    return (
      <>
        <PaneContentEmpty text={t('mentions.noMentions')} />
      </>
    );

  return (
    <div className={classes.root} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={mentions?.length || 0}
        next={fetchNextPage}
        height={height - 1}
        hasMore={!!hasNextPage}
        loader={<PaneContentLoading />}>
        {mentions
          .sort((a, b) =>
            (a.assigned_to?.name || 'ZZZZZ').localeCompare(b.assigned_to?.name || 'ZZZZZ'),
          )
          .map((task) => (
            <Comment key={task.id} comment={task.comment} showGoToButton />
          ))}
      </InfiniteScroll>
    </div>
  );
}
