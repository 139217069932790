import Codefy from '../../../../../../codefy';
import { PdfPageIndexContext } from '../../pdfViewer';
//@ts-ignore
import Region2D from 'region2d';
import { useContext } from 'react';

/** Convert a list of Boxes to a Region2D region */
const bs2r = (boxes: Codefy.Objects.Box[]): Region2D =>
  Region2D.fromRects(
    boxes
      /* Some boxes are zero-area, and this crashes Region2D. Those boxes would not be rendered
        anyways, so we just skip them. */
      .filter((box) => !(box.xmin === box.xmax && box.ymin === box.ymax))
      .map((box) => [
        Math.min(box.xmin, box.xmax),
        Math.min(box.ymin, box.ymax),
        Math.max(box.xmin, box.xmax),
        Math.max(box.ymin, box.ymax),
      ]),
  );

/** Convert a Region2D region to one or more Boxes */
const r2bs = (region: Region2D, page: number): Codefy.Objects.Box[] =>
  region.getRects().map((rect: any) => ({
    page,
    xmin: Math.min(rect.left, rect.right),
    xmax: Math.max(rect.left, rect.right),
    ymin: Math.min(rect.top, rect.bottom),
    ymax: Math.max(rect.top, rect.bottom),
  }));

/** Calculate which boxes of an annotation should actually be rendered so that there is no visual
 * clash.
 *
 * Note the two constraints we are working with:
 *
 * 1. Since we are using CSS's mix-blend-mode to make a rectangle appear "behind" a text (even
 *    though it's above the PDF rendered page layer), there must always only be one box on any given
 *    spot on the page, otherwise we get ugly "mixed" colors. Therefore, we cannot just plaster
 *    boxes on top of each other and make the higher boxes hide the lower boxes; we must actively
 *    do precise calculations so that there is only one box per spot.
 * 2. We cannot just iterate over all words (from the /api/layouts endpoint) that we get from the
 *    backend, because a document might get reprocessed, and the words might be at different
 *    positions, we might have annotations that don't overlap perfectly but rather "whereever they
 *    happen to touch".
 *
 * Given those constraints, the algorithm is as follows:
 *
 * 1. Find the shortest annotation (if two are equally short: the newest) and allocate it on a 2d
 *    plane.
 *
 * 2. take the next shortest/newest annotation and try to place it on the 2d plane, subtracting
 *    ("cutting out") any areas that have already been allocated by the first annotation.
 *
 * 3. Continue like that through all annotations, cutting out previously allocated space.
 */
export default function useAnnotationPatchBoxesRenderRectangle(
  annotations?: Codefy.Objects.Annotation[],
): Codefy.Objects.Annotation[] {
  const pageIndex = useContext(PdfPageIndexContext);
  if (!annotations || pageIndex === -1) return [];

  /* Sorting the annotations "correctly" is a critical part of making this algorithm user-friendly! */
  annotations = annotations.sort(
    (a, b) =>
      /* Shorter annotations take precedence (appear to be "on top") */
      (a.selected_text?.split(' ').length || 0) - (b.selected_text?.split(' ').length || 0) ||
      /* Out of two equally short annotations, the newer takes precedence (appears to be "on top") */
      b.created_at.localeCompare(a.created_at),
  );

  /** Keep track of which areas on a page are already occupied by a box. */
  let totalAllocatedRegion: any;

  annotations = annotations.map((annotation) => {
    /* Skip cutting out area annotations since they are just strokes (with no fill inside) */
    if (annotation.area) {
      annotation.boxesRenderRectangle = annotation.boxes;
      return annotation;
    }

    /* We use a try clause here because sometimes boxes are zero size and crash Region2D */
    try {
      const boxesOnCurrentPage = annotation.boxes
        /* Only consider boxes on current page */
        .filter((box) => box.page === pageIndex + 1)
        /* Only consider boxes that actually have a non-zero area. Note that the
        shredder might create zero-area boxes at the end of a text block (which
        is used for splitting up preview text in search results), which might
        trip up the bs2r function, which expects boxes to have non-zero area. */
        .filter((box) => box.xmax > box.xmin && box.ymax > box.ymin);

      let currentAnnotationRegion = bs2r(boxesOnCurrentPage);

      if (!totalAllocatedRegion) {
        /* For the first annotation, simply copy. */
        totalAllocatedRegion = currentAnnotationRegion;
      } else {
        /* For subsequent annotations, subtract ("cut out") the already allocated region from the
  annotation region */
        currentAnnotationRegion = currentAnnotationRegion.subtract(totalAllocatedRegion);
        /* Mark the remaining region as allocated for the next annotations to know. */
        totalAllocatedRegion = totalAllocatedRegion.union(currentAnnotationRegion);
      }

      annotation.boxesRenderRectangle = r2bs(currentAnnotationRegion, pageIndex + 1).flat();
    } catch (error) {
      console.error(error);
    }

    return annotation;
  });

  return annotations;
}
