import { Button } from '@material-ui/core';
import Codefy from '../../../codefy';
import { PANES_VIEWPORT_ELEMENT_ID } from '../../panes/panesViewport';
import PaneContentLoading from '../../panes/paneContentLoading';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import React from 'react';
import { hidePane } from '../../panes/pane';
import html2canvas from 'html2canvas';
import { useGlobalStyles } from '../../../globalThemeSettings';
import { useOpenCreatePublicationDialog } from './createPublicationDialog';
import { useProjectsGet } from '../../../controllers/api/subscriptions/projects/projectsGet';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function dataUrlToBlob(dataUrl: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUrl.split(',')[1]);

  // separate out the mime component
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export default function CreatePublicationButton({
  projectId,
  onClose,
}: {
  projectId: Codefy.Objects.Project['id'];
  onClose?: () => void;
}) {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const { data: project, isFetching } = useProjectsGet(projectId);

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);
  const openCreatePublicationDialog = useOpenCreatePublicationDialog();

  const onCreatePublication = async () => {
    if (!project?.id || !project?.name) return;

    const panesViewportElement = document.getElementById(PANES_VIEWPORT_ELEMENT_ID);
    if (!panesViewportElement) return;

    /* Hide the projectsList pane since otherwise all projects would be visible in the screenshot. */
    const projectsListPaneIsHidden = hidePane(PaneKeys.projectsList, true);
    if (!projectsListPaneIsHidden) return;

    const canvas = await html2canvas(panesViewportElement);
    const dataUrl = canvas.toDataURL();
    const blob = dataUrlToBlob(dataUrl);

    openCreatePublicationDialog({
      project_id: project.id,
      project_name: project.name,
      target_url: window.location.pathname + window.location.search,
      image_blob: blob,
      image_data_url: dataUrl,
    });

    /* Restore the projectsList pane after we are done with the screenshot */
    hidePane(PaneKeys.projectsList, false);

    onClose?.();
  };

  if (!project?.id) return null;

  if (isFetching) return <PaneContentLoading />;

  return (
    <Button
      disabled={!userEmail}
      aria-label="share"
      onClick={onCreatePublication}
      className={globalClasses.dialogButton}>
      {project.publication
        ? t('publicationDialog.editPublicShareLink')
        : t('publicationDialog.createPublicShareLink')}
    </Button>
  );
}
