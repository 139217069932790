import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const PROJECTS_GET_KEY_TYPE = 'projectsGet';

export const PROJECTS_GET_URL = '/api/v1/projects/get';

export const projectsGetKey = (
  id?: Codefy.Objects.Project['id'],
): [string, Codefy.Objects.Project['id'] | undefined] => [PROJECTS_GET_KEY_TYPE, id];

export const useProjectsGet = (project_id?: Codefy.Objects.Project['id'] | string) => {
  if (typeof project_id === 'string') {
    project_id = parseInt(project_id);
  }
  return useQuery(projectsGetKey(project_id), async () => {
    if (!project_id) return undefined;
    const { data } = await axios.get(PROJECTS_GET_URL, {
      params: { project_id },
      paramsSerializer: fastApiParamsSerializer,
    });
    return data as Codefy.Objects.Project;
  });
};
