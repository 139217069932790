import Codefy from '../../../../../../codefy';
import { TAG_INSTANCES_DELETE_QUERY_KEY_TO_REFRESH } from './tagInstancesDelete';
import { executeAction } from '../../../actionHelpers';

export const tagInstancesTrash = (data: {
  tag_instance_id: Codefy.Objects.TagInstance['id'];
  trashed?: boolean;
}) =>
  executeAction<Codefy.Objects.TagInstance>({
    config: { url: '/api/v1/taglists/tags/instances/trash', method: 'POST' },
    data,
    queryKeysToRefresh: TAG_INSTANCES_DELETE_QUERY_KEY_TO_REFRESH,
  });
