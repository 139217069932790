import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import Codefy from '../../codefy';
import EntryFileTypeIcon from '../panes/paneTypes/entriesList/entryFileTypeIcon';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import { GenericDialog } from './genericDialog';
import PaneContentLoading from '../panes/paneContentLoading';
import TagLabels from '../tagLabels/tagLabelsRenderer';
import { entriesMove } from '../../controllers/api/actions/entries/entriesMove';
import { useBetween } from 'use-between';
import { useEntriesGet } from '../../controllers/api/subscriptions/entries/entriesGet';
import { useEntriesList } from '../../controllers/api/subscriptions/entries/entriesList';
import { useGlobalStyles } from '../../globalThemeSettings';
import { useProjectsGet } from '../../controllers/api/subscriptions/projects/projectsGet';
import { useTranslation } from 'react-i18next';

type DialogMoveEntryStateType = {
  entryId?: Codefy.Objects.Entry['id'];
};

const useDialogMoveEntryState = () => useState<DialogMoveEntryStateType>();

const useSharedDialogMoveEntryState = () => useBetween(useDialogMoveEntryState);

export const MoveEntryDialog = () => {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogMoveEntryState();

  const { data: entry, isFetching } = useEntriesGet(state?.entryId);
  const { data: project } = useProjectsGet(entry?.path?.project_id);

  const projectIsShared =
    project &&
    (project.permissions.map((permission) => permission.user.email).includes('everyone') ||
      project.permissions.length > 1);

  type MoveReceiveDirectory = {
    id: number;
    name: string;
    icon: JSX.Element;
    tagInstances?: Codefy.Objects.TagInstanceInfo[];
  };

  const [directoryName, setDirectoryName] = useState<string>();
  const [selectedDirectoryId, setSelectedDirectoryId] = useState<MoveReceiveDirectory['id']>();

  const { data: directoryPages } = useEntriesList({
    directory_ids: project ? [project.directory_id] : undefined,
    recursive: true,
    mimetypes: ['inode/directory', 'inode/case'],
    limit: 100,
    name: directoryName,
    order_by: 'name',
    descending: false,
  });

  let directories =
    directoryPages?.pages.reduce((acc: MoveReceiveDirectory[], cur) => {
      for (const curEntry of cur.entries) {
        if (!curEntry.directory?.id) continue;
        if (curEntry.directory.id === entry?.path?.directory_id) {
          /* Don't allow moving a directory into where it already is */
          continue;
        }
        if (curEntry.directory.id === entry?.directory?.id) {
          /* Don't allow moving a directory into itself */
          continue;
        }

        acc.push({
          id: curEntry.directory?.id,
          name: curEntry.name,
          icon: <EntryFileTypeIcon entryMimetype={curEntry.mimetype} />,
          tagInstances: curEntry.tag_instances,
        });
      }
      return acc;
    }, []) || [];

  const onClose = () => {
    setState(undefined);
    setDirectoryName(undefined);
  };

  const onMove = () => {
    if (!selectedDirectoryId || !state?.entryId) return;
    entriesMove({ entry_ids: [state.entryId], directory_id: selectedDirectoryId });
    onClose();
  };

  if (isFetching || !project)
    return (
      <GenericDialog title={t('moveEntryDialog.moveEntry')} open={!!state} onClose={onClose}>
        <PaneContentLoading />
      </GenericDialog>
    );

  /* Append the project's root folder, if the entry is not already in there */
  if (entry?.path?.directory_id !== project?.directory_id) {
    directories = [
      {
        id: project?.directory_id,
        name: project?.name,
        icon: projectIsShared ? <FolderSharedIcon /> : <FolderIcon />,
      },
      ...directories,
    ];
  }

  return (
    <GenericDialog title={t('moveEntryDialog.moveEntry')} open={!!state} onClose={onClose}>
      <Box>
        <Autocomplete
          options={directories}
          fullWidth
          disableClearable
          forcePopupIcon={false}
          noOptionsText={t('moveEntryDialog.noDirectories')}
          size="small"
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => {
            if (!value || !value.id) return;
            setSelectedDirectoryId(value.id);
          }}
          renderOption={(option) => (
            <>
              <Grid container wrap="nowrap">
                <Grid item md={1}>
                  {option.icon}
                </Grid>
                <Grid item md={10}>
                  {option.name}
                  {option.tagInstances &&
                    option.tagInstances.length > 0 &&
                    entry?.path?.project_id && (
                      <TagLabels
                        isHighlight={false}
                        tagInstances={option.tagInstances}
                        lineBreaks={false}
                        taglistType={'entry'}
                      />
                    )}
                </Grid>
              </Grid>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              fullWidth
              size="small"
              onChange={(event) => setDirectoryName(event.target.value)}
              placeholder={t('moveEntryDialog.selectFolderOrCase')}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <Button
                    size="small"
                    onClick={onMove}
                    disabled={!selectedDirectoryId}
                    color="primary"
                    className={globalClasses.dialogButton}>
                    {t('moveEntryDialog.move')}
                  </Button>
                ),
              }}
            />
          )}
        />
      </Box>
    </GenericDialog>
  );
};

export const useOpenMoveEntryDialog = () => {
  const [, setState] = useSharedDialogMoveEntryState();
  return (state: DialogMoveEntryStateType) => () => setState(state);
};
