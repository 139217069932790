import {
  useQueryParam_pdfViewer_documentId,
  useQueryParam_tagInstancePredictions_directoryId,
  useQueryParam_tagInstancePredictions_documentId,
} from '../../controllers/useGlobalQueryParams';

import { PaneKeys } from '../panes/paneTypes/paneKeys';
import useCurrentCase from '../../hooks/useCurrentCase';
import { useDirectoriesGet } from '../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../controllers/api/subscriptions/documents/documentsGet';
import { useEffect } from 'react';
import { usePaneActions } from '../panes/usePaneActions';

/** Closes the PDF viewer and review assistant when switching to a different case. This is to
 * avoid inconsistent states of the UI, i.e. that the entriesList shows a folder from one case,
 * and the pdfViewer shows a PDF file from another case, leading to UI issues when the user then
 * triggers a case-related action, e.g. download a tag instances report for the current case. */
export function CaseIdChangeListener() {
  const paneActions = usePaneActions();
  const { id: currentCaseId } = useCurrentCase();
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const { data: document } = useDocumentsGet(pdfViewer_documentId);
  const [tagInstancePredictions_documentId] = useQueryParam_tagInstancePredictions_documentId();
  const [tagInstancePredictions_directoryId] = useQueryParam_tagInstancePredictions_directoryId();

  const { data: predictions_document } = useDocumentsGet(tagInstancePredictions_documentId);
  const { data: predictions_directory } = useDirectoriesGet(tagInstancePredictions_directoryId);

  useEffect(() => {
    const paneKeysToClose = [];

    if (
      document &&
      document.path.case_id &&
      document.path.case_id !== currentCaseId &&
      currentCaseId
    ) {
      // Only close pdfViewer variant 1. Variant 2 should never be touched automatically.
      paneKeysToClose.push(PaneKeys.pdfViewer);
    }

    if (
      predictions_document &&
      predictions_document.path.case_id &&
      predictions_document.path.case_id !== currentCaseId &&
      currentCaseId
    ) {
      paneKeysToClose.push(PaneKeys.tagInstancePredictions);
    }

    if (
      predictions_directory &&
      predictions_directory.path?.case_id &&
      predictions_directory.path?.case_id !== currentCaseId &&
      currentCaseId
    ) {
      paneKeysToClose.push(PaneKeys.tagInstancePredictions);
    }

    // Special case when predictions directory itself is a case
    if (
      predictions_directory &&
      predictions_directory.path?.entry_mimetype == 'inode/case' &&
      predictions_directory.id !== currentCaseId &&
      currentCaseId
    ) {
      paneKeysToClose.push(PaneKeys.tagInstancePredictions);
    }

    paneActions.closePanes(paneKeysToClose);
  }, [currentCaseId]);

  return null;
}
