import {
  BORDER_RADIUSES,
  BOX_SHADOWS,
  COLORS,
  FONT_SIZES,
  componentTypeStyles,
  useGlobalStyles,
} from '../../../globalThemeSettings';
import { Box, Chip, Grid, IconButton, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  getSearchBarInputValue,
  SEARCH_BAR_INPUT_ID,
  setSearchBarInputValue,
} from './setSearchBarInputValue';
import SearchDropdown from './searchDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
  useQueryParam_search_method,
  useQueryParam_search_query,
} from '../../../controllers/useGlobalQueryParams';

import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import Codefy from '../../../codefy';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import SearchIcon from '../../icons/search';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import clsx from 'clsx';
import { debounce } from 'throttle-debounce';
import store from '../../../controllers/store';
import { useDirectoriesGet } from '../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../controllers/api/subscriptions/documents/documentsGet';
import useIsEveryone from '../../../hooks/useIsEveryone';
import { useMeasure } from 'react-use';
import { usePaneActions } from '../../panes/usePaneActions';
import useSearchMethod from './useSearchMethod';
import useSetSearchParams from './useSetSearchParams';
import { useTranslation } from 'react-i18next';

/** Related to being able to select results with the arrow keys */
export const SEARCHBAR_ARROWKEY_SELECTED_ID = 'searchbar-arrowkey-selected';
export const clickOnSelectedByArrowkey = () => {
  const el = document.getElementById(SEARCHBAR_ARROWKEY_SELECTED_ID) as HTMLDivElement;
  el?.click();
  store.dispatch({ type: 'setSearch', searchbarArrowKeyPosition: 0 });
};

const useStyles = makeStyles({
  textField: {
    maxWidth: 900,
  },
  chip: {
    marginLeft: '5px',
    borderRadius: BORDER_RADIUSES.tiny,
    backgroundColor: COLORS.primaryLight,
  },
  input: {
    height: '40px',
    color: '#003366',
    borderRadius: BORDER_RADIUSES.huge,
    boxShadow: BOX_SHADOWS.small,
    fontSize: FONT_SIZES.large,
    backgroundColor: COLORS.background,
    border: componentTypeStyles.uiElement.border,
  },
  inputOutline: {
    border: '0px',
  },
  tagLabelDeleteIcon: {
    color: '#00000038',
    '&:hover': {
      color: '#00000055',
    },
  },
});

export default function SearchBar() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();
  const { t } = useTranslation();

  const [searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId] = useQueryParam_searchBar_documentId();

  const { data: document } = useDocumentsGet(searchBar_documentId);
  const { data: directory } = useDirectoriesGet(searchBar_directoryId);

  const onSetSearchScopeEverywhere = useSetSearchParams('everywhere');

  const [ref, measure] = useMeasure<HTMLDivElement>();

  const searchbarArrowKeyPosition = useSelector(
    (state: Codefy.State) => state.search.searchbarArrowKeyPosition,
  );
  const searchOpen = useSelector((state: Codefy.State) => state.search.open);
  const dispatch = useDispatch();

  /** Search query typed in by the user in the search field */
  const searchQuery = useSelector((state: Codefy.State) => state.search.query);
  /** Search query set in the URL */
  const [search_query] = useQueryParam_search_query();
  const [search_method] = useQueryParam_search_method();

  /** If the search query has more than 5 words, we use the "intelligent"/similarity search functionality. */
  const searchMethodBasedOnWordCount = useSearchMethod();

  const onClickTextField = () =>
    dispatch({ type: 'setSearch', open: true, searchbarArrowKeyPosition: 0 });

  const onClickClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();

    setSearchBarInputValue('');
    dispatch({ type: 'setSearch', open: false, query: '', searchbarArrowKeyPosition: 0 });
  };

  const throttledSetSearchQuery = useCallback(
    debounce(250, false, (searchQuery: string) => {
      dispatch({
        type: 'setSearch',
        query: searchQuery,
        open: true,
        searchbarArrowKeyPosition: 0,
      });
    }),
    [],
  );

  const onSearch = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();

    if (getSearchBarInputValue()) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.search,
        params: {
          search_method: searchMethodBasedOnWordCount,
          search_query: getSearchBarInputValue(),
          search_directoryId: searchBar_directoryId,
          search_documentId: searchBar_documentId,
        },
      });
      dispatch({ type: 'setSearch', open: false });
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    throttledSetSearchQuery(event.target.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!getSearchBarInputValue()) return;
    switch (event.key) {
      case 'Enter':
        if (searchbarArrowKeyPosition > 0) {
          clickOnSelectedByArrowkey();
        } else {
          onSearch();
        }
        break;
      case 'Escape':
        dispatch({ type: 'setSearch', open: false, searchbarArrowKeyPosition: 0 });
        break;
      case 'ArrowDown':
        // 2021-10-16 Currently disabled because both projects and entries are
        // selected via arrows which is a bug (and race condition when you press
        // enter)
        // if (searchbarArrowKeyPosition < ENTRIES_COUNT) {
        //   dispatch({
        //     type: 'setSearch',
        //     searchbarArrowKeyPosition: searchbarArrowKeyPosition + 1,
        //   });
        // }
        break;
      case 'ArrowUp':
        if (searchbarArrowKeyPosition > 0) {
          dispatch({
            type: 'setSearch',
            searchbarArrowKeyPosition: searchbarArrowKeyPosition - 1,
          });
        }
        break;
    }
  };

  const searchScope = useMemo(() => {
    const maxWidth = '200px';

    if (searchBar_documentId && document)
      return (
        <Grid container alignItems="center" wrap="nowrap">
          <EntryFileTypeIcon
            entryMimetype={document.path.entry_mimetype}
            style={{ marginRight: '3px' }}
          />{' '}
          <Box maxWidth={maxWidth}>
            <Typography className={clsx(globalClasses.listEntry, globalClasses.noWrap)} noWrap>
              {document.path.entry_name}
            </Typography>
          </Box>
        </Grid>
      );
    if (searchBar_directoryId && directory)
      return (
        <Grid container alignItems="center" wrap="nowrap">
          {directory.path?.entry_mimetype == 'inode/case' ? (
            <WorkOutlineIcon style={{ marginRight: '3px' }} />
          ) : (
            <FolderOpenIcon style={{ marginRight: '3px' }} />
          )}{' '}
          <Box maxWidth={maxWidth}>
            <Typography className={clsx(globalClasses.listEntry, globalClasses.noWrap)} noWrap>
              {directory.path?.entry_name || directory.path?.project_name}
            </Typography>
          </Box>
        </Grid>
      );
    return null;
  }, [searchBar_documentId, searchBar_directoryId, directory, document]);

  useEffect(() => {
    if (search_query && search_method === 'ngram') {
      dispatch({ type: 'setSearch', query: search_query });
      setSearchBarInputValue(search_query);
    }

    /** Focus search bar once on startup */
    if (!isEveryone) {
      window.document.getElementById(SEARCH_BAR_INPUT_ID)?.focus();
    }

    /** Capture CTRL+F to focus search bar */
    const onKeyDown = function (this: Window, e: KeyboardEvent) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70) || (e.metaKey && e.keyCode === 70)) {
        e.preventDefault();

        //setRefocused(true);
        window.document.getElementById(SEARCH_BAR_INPUT_ID)?.focus();
        dispatch({ type: 'setSearch', open: true });
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TextField
        className={classes.textField}
        variant="outlined"
        /** Cringeworthy hack to prevent Google from showing autocompletes */
        name={Math.random().toString()}
        size="small"
        fullWidth
        placeholder={t('searchBar.search')}
        onClick={onClickTextField}
        autoFocus
        onChange={onChange}
        onKeyDown={onKeyDown}
        defaultValue={searchQuery}
        type="text"
        autoComplete={Math.random().toString()}
        InputProps={{
          ref,
          id: SEARCH_BAR_INPUT_ID,
          classes: {
            notchedOutline: classes.inputOutline,
          },
          type: 'text',
          autoComplete: Math.random().toString(),
          className: classes.input,
          startAdornment: (
            <Box m={0.5} mr={1.5} ml={0}>
              <Grid container alignItems="center" wrap="nowrap">
                <Box mr={'10px'} mb={-0.5}>
                  <SearchIcon color={COLORS.uiSkeleton} />
                </Box>
                {searchScope && (
                  <Chip
                    className={classes.chip}
                    label={searchScope}
                    size="small"
                    onDelete={
                      /* Prevent an anonymous user from setting the search scope to everywhere */
                      isEveryone && !directory?.path?.directory_name
                        ? undefined
                        : onSetSearchScopeEverywhere
                    }
                    deleteIcon={
                      <IconButton size="small">
                        <CancelIcon className={classes.tagLabelDeleteIcon} fontSize="small" />
                      </IconButton>
                    }
                  />
                )}
              </Grid>
            </Box>
          ),
          endAdornment: (
            <Box>
              <Grid container alignItems="center" wrap="nowrap">
                <IconButton
                  onClick={onClickClose}
                  disabled={!searchQuery}
                  className={clsx(!searchQuery && globalClasses.invisible)}>
                  <CloseIcon />
                </IconButton>
                <div className={globalClasses.verticalIconDivider} />
                <IconButton onClick={onSearch} disabled={!searchQuery}>
                  <SearchIcon color={!searchQuery ? COLORS.uiSkeleton : undefined} />
                </IconButton>
              </Grid>
            </Box>
          ),
        }}
      />
      <SearchDropdown width={measure.width} />
    </>
  );
}
