import Codefy from '../../../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export const TAG_INSTANCES_GET_URL = '/api/v1/taglists/tags/instances/get';

export const TAG_INSTANCES_GET_KEY_TYPE = 'tagInstancesGet';

export const tagInstancesGetKey = (
  id?: Codefy.Objects.Taglist['id'],
): [string, Codefy.Objects.Taglist['id'] | undefined] => [TAG_INSTANCES_GET_KEY_TYPE, id];

export const useTagInstancesGet = (tag_instance_id?: Codefy.Objects.TagInstance['id']) => {
  return useQuery(tagInstancesGetKey(tag_instance_id), async () => {
    if (!tag_instance_id) return undefined;
    const { data } = await axios.get(TAG_INSTANCES_GET_URL, {
      params: { tag_instance_id },
    });
    return data as Codefy.Objects.TagInstance;
  });
};
