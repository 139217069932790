import {
  useQueryParam_tagInstancePredictions_directoryId,
  useQueryParam_tagInstancePredictions_documentId,
  useQueryParam_tagInstancePredictions_entryTagId,
} from '../../../../controllers/useGlobalQueryParams';

import { Badge } from '@material-ui/core';
import Codefy from '../../../../codefy';
import CodefyIcon from '../../../appLogo/codefyIcon';
import React from 'react';
import { useTagInstancePredictionsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';

export default function PredictionIndicator({ tag_id }: { tag_id: Codefy.Objects.Tag['id'] }) {
  const [tagInstancePredictions_documentId] = useQueryParam_tagInstancePredictions_documentId();
  const [tagInstancePredictions_directoryId] = useQueryParam_tagInstancePredictions_directoryId();
  const [tagInstancePredictions_entryTagId] = useQueryParam_tagInstancePredictions_entryTagId();

  const { data: resultsPages } = useTagInstancePredictionsList({
    tag_id: tag_id,
    document_ids: tagInstancePredictions_documentId
      ? [tagInstancePredictions_documentId]
      : undefined,
    directory_ids: tagInstancePredictions_directoryId
      ? [tagInstancePredictions_directoryId]
      : undefined,
    entry_tag_ids: tagInstancePredictions_entryTagId
      ? [tagInstancePredictions_entryTagId]
      : undefined,
  });

  if (!resultsPages)
    return (
      <Badge badgeContent="..." anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <CodefyIcon />
      </Badge>
    );

  return (
    <Badge
      showZero
      badgeContent={resultsPages.pages[0].num_results}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <CodefyIcon />
    </Badge>
  );
}
