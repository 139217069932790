import Codefy from '../../../../codefy';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export const COMMENTS_GET_KEY_TYPE = 'commentsGet';

export const COMMENTS_GET_URL = '/api/v1/comments/get';

export const commentsGetKey = (
  id?: Codefy.Objects.Comment['id'],
): [string, Codefy.Objects.Comment['id'] | undefined] => [COMMENTS_GET_KEY_TYPE, id];

export const useCommentsGet = (comment_id?: Codefy.Objects.Comment['id']) => {
  return useQuery(commentsGetKey(comment_id), async () => {
    if (!comment_id) return undefined;
    const { data } = await axios.get(COMMENTS_GET_URL, {
      params: { comment_id },
      paramsSerializer: fastApiParamsSerializer,
    });
    return data as Codefy.Objects.Comment;
  });
};
