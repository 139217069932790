import { BORDER_RADIUSES, COLORS } from '../../../../globalThemeSettings';
import {
  GenericCommentParameters,
  useCommentsList,
} from '../../../../controllers/api/subscriptions/comments/commentsList';
import TagInstance, { TagInstanceObject } from '../../../panes/paneTypes/tag/tagInstance';
import {
  useQueryParam_commentsDialog_highlightCommentWithId,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';

import { Box } from '@material-ui/core';
import Codefy from '../../../../codefy';
import { Comment } from './comment';
import CommentSubscription from './commentSubscription';
import { CreateComment } from './createComment';
import { EntriesListItem } from '../../../panes/paneTypes/entriesList/entriesListItem';
import PaneContentLoading from '../../../panes/paneContentLoading';
import React from 'react';
import { TagListTag } from '../../../panes/paneTypes/taglist/tagListTag';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useEntriesGet } from '../../../../controllers/api/subscriptions/entries/entriesGet';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useTagInstancesGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { useTaglistsGet } from '../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';

export type CommentsListType = 'entry' | 'tag' | 'tag_instance';

export const GenericCommentsList = ({
  comments,
  createCommentParams,
  showGoToButton,
}: {
  comments: Codefy.Objects.Comment[];
  createCommentParams?: GenericCommentParameters;
  showGoToButton?: boolean;
}) => {
  const isEveryone = useIsEveryone();
  const [
    commentsDialog_highlightCommentWithId,
    set_commentsDialog_highlightCommentWithId,
  ] = useQueryParam_commentsDialog_highlightCommentWithId();

  const onUnhighlightComment = () => {
    set_commentsDialog_highlightCommentWithId(undefined);
  };

  return (
    <Box width="100%" height={`100%`} onClick={onUnhighlightComment}>
      {comments?.map((comment) => (
        <Box key={comment.id}>
          <Comment
            comment={comment}
            highlighted={comment.id === commentsDialog_highlightCommentWithId}
            showGoToButton={showGoToButton}
          />
        </Box>
      ))}

      {!isEveryone && createCommentParams && (
        <Box>
          <Box borderRadius={BORDER_RADIUSES.tiny} border={'2px solid ' + COLORS.uiSkeleton}>
            <CreateComment createCommentParams={createCommentParams} alwaysFull />
          </Box>

          <Box mt={1} p={1} pt={0}>
            <CommentSubscription commentParams={createCommentParams} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const CommentsListEntry = ({ entry_id }: { entry_id: Codefy.Objects.Entry['id'] }) => {
  const [trashMode] = useQueryParam_trashMode();
  const { data: entry } = useEntriesGet(entry_id);
  const { data: comments } = useCommentsList({ type: 'entry', entry_id, trashed: trashMode });

  if (!entry || !comments) return <PaneContentLoading />;

  return (
    <>
      <EntriesListItem entry={entry} />
      <GenericCommentsList
        comments={comments.pages[0].comments}
        createCommentParams={{ type: 'entry', entry_id }}
      />
    </>
  );
};

export const CommentsListTag = ({ tag_id }: { tag_id: Codefy.Objects.Tag['id'] }) => {
  const [trashMode] = useQueryParam_trashMode();
  const { id: currentCaseId } = useCurrentCase();

  const { data: tag } = useTagsGet({ tag_id, case_id: currentCaseId });
  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id, case_id: currentCaseId });

  const { data: comments } = useCommentsList({
    type: 'tag',
    tag_id,
    case_id: currentCaseId,
    trashed: trashMode,
  });

  if (!tag || !comments) return <PaneContentLoading />;

  return (
    <>
      <TagListTag
        project_id={taglist?.path.project_id}
        tag={tag}
        writePermission={false}
        addPermission={false}
        hideButtons
      />
      <GenericCommentsList
        comments={comments.pages[0].comments}
        createCommentParams={{ type: 'tag', tag_id, case_id: currentCaseId }}
      />
    </>
  );
};

export const CommentsListTagInstance = ({
  tag_instance_id,
}: {
  tag_instance_id: Codefy.Objects.TagInstance['id'];
}) => {
  const [trashMode] = useQueryParam_trashMode();
  const { data: tag_instance } = useTagInstancesGet(tag_instance_id);
  const { data: tag } = useTagsGet({ tag_id: tag_instance?.tag_id });
  const { data: comments } = useCommentsList({
    type: 'tag_instance',
    tag_instance_id,
    trashed: trashMode,
  });

  if (!tag_instance || !comments || !tag) return <PaneContentLoading />;

  return (
    <>
      <Box
        mb={3}
        ml={tag_instance.type === 'annotation' ? -2 : 0}
        mr={tag_instance.type === 'annotation' ? -2 : 0}>
        <TagInstanceObject tagInstance={tag_instance} project_id={tag_instance.path.project_id} />
      </Box>
      {tag_instance.type !== 'annotation' && (
        <GenericCommentsList
          comments={comments.pages[0].comments}
          createCommentParams={{ type: 'tag_instance', tag_instance_id }}
        />
      )}
    </>
  );
};
