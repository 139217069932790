import { QueryKey, useQuery } from 'react-query';

import Codefy from '../../../../codefy';
import axios from 'axios';

type LayoutsGetRequestParams = {
  document_id: Codefy.Objects.Document['id'];
  page: number;
};

export type LayoutsGetReturnType = Codefy.Objects.LayoutPart[];

export const LAYOUTS_GET_KEY_TYPE = 'layoutsGet';

export const layoutsGetKey = (params: LayoutsGetRequestParams): QueryKey => [
  LAYOUTS_GET_KEY_TYPE,
  params.document_id,
  params.page,
];

export const useLayoutsGet = (params: LayoutsGetRequestParams) =>
  useQuery(layoutsGetKey(params), async () => {
    const { data } = await axios.get('/api/v1/parts/layout', {
      params,
    });
    return data as LayoutsGetReturnType;
  });
