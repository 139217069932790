import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export const PUBLICATIONS_LIST_URL = '/api/v1/publications/list';

export const PUBLICATIONS_LIST_KEY_TYPE = 'publicationsList';

export type PublicationsListParams = {
  project_id?: Codefy.Objects.Project['id'];
  limit?: number;
  offset?: number;
  order_by?: 'created_at' | 'created_by' | 'title' | 'view_count';
  descending?: boolean;
};

export type PublicationsListReturnType = {
  publications: Codefy.Objects.Publication[];
  num_publications: number;
};

export const publicationsListKey = (
  params: PublicationsListParams,
): [string, PublicationsListParams] => [PUBLICATIONS_LIST_KEY_TYPE, params];

export const usePublicationsList = (params: PublicationsListParams) =>
  useCreateInfiniteQuery<PublicationsListReturnType>(
    publicationsListKey(params),
    1,
    PUBLICATIONS_LIST_URL,
    'GET',
    params,
    'publications',
    !params.project_id,
  );
