import {
  useQueryParam_focusedPane,
  useQueryParam_panes,
} from '../../controllers/useGlobalQueryParams';

import { PaneKeys } from './paneTypes/paneKeys';

/** Between those panes, there is no large separating border, but rather they are connected.
 * IMPORTANT: Connected panes should be neighbours in `paneTypes.tsx` -> paneTypes
 */
export const connectedPanes: [PaneKeys, PaneKeys][] = [
  [PaneKeys.annotationTaglist, PaneKeys.annotationTag],
  [PaneKeys.annotationTaglist, PaneKeys.tagInstancePredictions],
  [PaneKeys.annotationTag, PaneKeys.annotationTagDescription],
  [PaneKeys.tagInstancePredictions, PaneKeys.annotationTag],
  [PaneKeys.entryTaglist, PaneKeys.entryTag],
  [PaneKeys.uploadBatches, PaneKeys.uploadBatch],
];

export const usePaneConnectedToTheLeft = (paneKey: PaneKeys): boolean => {
  const [panes] = useQueryParam_panes();

  return connectedPanes.some(([left, right]) => right === paneKey && panes.includes(left));
};

export const usePaneConnectedToTheRight = (paneKey: PaneKeys): boolean => {
  const [panes] = useQueryParam_panes();

  return connectedPanes.some(([left, right]) => left === paneKey && panes.includes(right));
};

export const usePaneFocusedSinceConnectedPaneIsFocused = (paneKey: PaneKeys): boolean => {
  const [focusedPane] = useQueryParam_focusedPane();

  return connectedPanes.some(
    ([left, right]) =>
      (focusedPane === left && paneKey === right) || (focusedPane === right && paneKey === left),
  );
};
