import React, { useState } from 'react';

import Codefy from '../../../codefy';
import GenericTextFieldDialog from '../genericTextFieldDialog';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { tagsCell } from '../../../controllers/api/actions/taglists/tags/tagsCell';
import { useBetween } from 'use-between';
import { useTagsGet } from '../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

type DialogEditTagCellStateType = {
  tagId?: Codefy.Objects.Tag['id'];
};

const useDialogEditTagCellState = () => useState<DialogEditTagCellStateType>();

const useSharedDialogEditTagCellState = () => useBetween(useDialogEditTagCellState);

export const EditTagCellDialog = () => {
  const { t } = useTranslation();

  const [state, setState] = useSharedDialogEditTagCellState();

  const { data: tag, isFetching } = useTagsGet({ tag_id: state?.tagId });

  const onClose = () => setState(undefined);

  const onEdit = async (cell: string) => {
    const tag_id = state?.tagId;
    if (!tag_id) return;
    tagsCell({ tag_id, cell });
  };

  if (!tag) return null;

  /* Make sure to break the cache of the GenericTagDialog when the user edits the same tag again */
  if (isFetching) return null;

  return (
    <GenericTextFieldDialog
      open={!!state}
      title={t('tagDialog.excelCell')}
      icon={<InsertCommentIcon />}
      onClose={onClose}
      onRename={onEdit}
      currentName={tag.cell?.toString() || ''}
    />
  );
};

export const useOpenEditTagCellDialog = () => {
  const [, setState] = useSharedDialogEditTagCellState();
  return (state: DialogEditTagCellStateType) => () => setState(state);
};
