// TODO @jakub add documentation

import { AnyAction, Dispatch } from 'redux';
import { queryClient } from '../../App';
import Codefy from '../../codefy';
import {
  AnnotationsListRequestParams,
  AnnotationsListReturnType,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../api/subscriptions/annotations/annotationsList';
import { COMMENTS_LIST_KEY_TYPE } from '../api/subscriptions/comments/commentsList';
import { documentsGetKey } from '../api/subscriptions/documents/documentsGet';
import { entriesGetKey } from '../api/subscriptions/entries/entriesGet';
import { entriesListKey, ENTRIES_LIST_KEY_TYPE } from '../api/subscriptions/entries/entriesList';
import { projectsGetKey } from '../api/subscriptions/projects/projectsGet';
import { projectsListKey } from '../api/subscriptions/projects/projectsList';
import { TAGLISTS_GET_KEY_TYPE } from '../api/subscriptions/taglists/taglistsGet';
import { TAG_INSTANCES_GET_KEY_TYPE } from '../api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../api/subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { TAGS_GET_KEY_TYPE } from '../api/subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../api/subscriptions/taglists/tags/tagsList';
import { UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE } from '../api/subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { UPLOAD_BATCHES_LIST_KEY_TYPE } from '../api/subscriptions/uploadBatches/uploadBatchesListBatches';
import { USERS_QUOTA_KEY_TYPE } from '../api/subscriptions/users/usersQuota';
import { CACHE_INVALIDATE } from './websocketEventTypes';

const processInvalidatedProjectIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.project_ids) {
    /* Refresh projects list */
    queryClient.invalidateQueries(projectsListKey({}));

    cacheInvalidationAction.project_ids.forEach((projectId) => {
      /* Refresh project */
      queryClient.invalidateQueries(projectsGetKey(projectId));
    });

    /* Refresh quota information */
    queryClient.invalidateQueries(USERS_QUOTA_KEY_TYPE);
  }
};

const processInvalidatedDirectoryIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.directory_ids) return;

  /* Refresh entries list */
  for (const directory_id of cacheInvalidateHandler.directory_ids) {
    queryClient.invalidateQueries(entriesListKey({ directory_ids: [directory_id] }));
  }
};

const processInvalidatedEntryIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.entry_ids) return;

  /* Refresh entries list */
  // TODO: Optimize
  queryClient.invalidateQueries(entriesListKey({}));

  /* Refresh individual entries */
  for (const entry_id of cacheInvalidateHandler.entry_ids) {
    queryClient.invalidateQueries(entriesGetKey(entry_id));
    queryClient.invalidateQueries(COMMENTS_LIST_KEY_TYPE);
    queryClient.invalidateQueries(ENTRIES_LIST_KEY_TYPE);
  }
};

const processInvalidatedTagIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.tag_ids) return;

  /* Refresh comments */
  queryClient.invalidateQueries(COMMENTS_LIST_KEY_TYPE);
  queryClient.invalidateQueries(TAGS_GET_KEY_TYPE);
  queryClient.invalidateQueries(TAGS_LIST_KEY_TYPE);
  queryClient.invalidateQueries(TAGLISTS_GET_KEY_TYPE);
};

const processInvalidatedTagInstanceIds = (
  cacheInvalidateHandler: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (!cacheInvalidateHandler.tag_instance_ids) return;

  /* Refresh comments */
  queryClient.invalidateQueries(COMMENTS_LIST_KEY_TYPE);
  queryClient.invalidateQueries(ANNOTATIONS_LIST_INFINITE_KEY_TYPE);
  queryClient.invalidateQueries(ANNOTATIONS_LIST_KEY_TYPE);
  queryClient.invalidateQueries(TAG_INSTANCES_GET_KEY_TYPE);
  queryClient.invalidateQueries(TAGS_GET_KEY_TYPE);
  queryClient.invalidateQueries(TAGS_LIST_KEY_TYPE);
  queryClient.invalidateQueries(TAGLISTS_GET_KEY_TYPE);
  queryClient.invalidateQueries(TAG_INSTANCES_LIST_KEY_TYPE);
};

const processInvalidatedDocumentIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.document_ids) {
    cacheInvalidationAction.document_ids.forEach((documentId) => {
      /* Refresh document */
      queryClient.invalidateQueries(documentsGetKey(documentId));
      queryClient.invalidateQueries(UPLOAD_BATCH_ENTRIES_LIST_KEY_TYPE);
      queryClient.invalidateQueries(UPLOAD_BATCHES_LIST_KEY_TYPE);
    });
  }
};

const processInvalidatedDocumentPages = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.document_pages) {
    cacheInvalidationAction.document_pages.forEach((documentPage) => {
      /* Refresh annotations */
      try {
        const queryKeys = [];
        for (const [queryKey] of queryClient.getQueriesData(ANNOTATIONS_LIST_KEY_TYPE)) {
          const check = () => {
            // TODO: Add comments

            const params = queryKey[1] as AnnotationsListRequestParams;
            if (!params.pages) return true;
            if (!params.document_ids) return true;
            if (params.document_ids && params.document_ids.includes(documentPage[0])) {
              return params.pages && params.pages.includes(documentPage[1]);
            } else {
              return false;
            }
          };

          if (check()) {
            queryKeys.push(queryKey);
          }
        }
        queryClient.invalidateQueries(queryKeys);
      } catch {
        /* we are using try since we are casting types which is dangerous */
      }
    });
  }
};

const processInvalidatedAnnotationIds = (
  cacheInvalidationAction: Codefy.Notifications.Payloads.CacheInvalidate,
) => {
  if (cacheInvalidationAction.annotation_ids) {
    /* Refresh annotation lists */
    const annotationsListQueries = queryClient.getQueriesData<AnnotationsListReturnType>(
      ANNOTATIONS_LIST_KEY_TYPE,
    );
    annotationsListQueries.forEach(([queryKey, data]) => {
      let shouldUpdate = false;
      try {
        data.annotations.forEach((annotation) => {
          if (cacheInvalidationAction.annotation_ids?.includes(annotation.id)) {
            shouldUpdate = true;
          }
        });
        if (shouldUpdate) {
          queryClient.invalidateQueries(queryKey);
        }
      } catch {
        /* we are using try since we are casting types which is dangerous */
      }
    });
  }
};

/** Listens for cache invalidate notifications and invalidates the relevant caches so
 * that they are refreshed. */
export const cacheInvalidateHandler = () => (next: Dispatch) => (action: AnyAction): AnyAction => {
  /* Ignore redux action if not a websocket message */
  if (action?.type !== 'REDUX_WEBSOCKET::MESSAGE') {
    return next(action);
  }

  const parsed = JSON.parse(action.payload.message) as Codefy.Objects.Notification;

  /* If the project permissions were updated, refetch all queries */
  if (parsed.type == 'project_permission_updated') {
    queryClient.invalidateQueries();
  } else if (parsed.type !== CACHE_INVALIDATE) {
    /* Ignore websocket message if not a 'cache_invalidate' message */
    return next(action);
  }

  const cacheInvalidationAction = parsed.action as Codefy.Notifications.Payloads.CacheInvalidate;

  /* The following functions all execute actions necessary to refresh the local cached data,
  depending on what has been marked as invalidated (= stale/old) */
  [
    processInvalidatedProjectIds,
    processInvalidatedDirectoryIds,
    processInvalidatedEntryIds,
    processInvalidatedTagIds,
    processInvalidatedTagInstanceIds,
    processInvalidatedDocumentIds,
    processInvalidatedDocumentPages,
    processInvalidatedAnnotationIds,
  ].forEach((invalidationProcessor) => invalidationProcessor(cacheInvalidationAction));

  return next(action);
};
