import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SearchNoResults() {
  const { t } = useTranslation();

  return (
    <div>
      <Box m={3}>{t('searchBar.noResults')}.</Box>
    </div>
  );
}
