import { Box, Chip, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { COLORS, componentTypeStyles, useGlobalStyles } from '../../globalThemeSettings';
import { useTrashModeIcons, useTrashModeStrings } from '../../hooks/useTrashModeVariants';

import Codefy from '../../codefy';
import CommentsIcon from '../icons/commentsIcon';
import { CreateComment } from '../panes/paneTypes/comments/createComment';
import EntryFileTypeIcon from '../panes/paneTypes/entriesList/entryFileTypeIcon';
import GenericContextMenu from '../menus/genericContextMenu';
import { GenericNestableMenuEntry } from '../menus/genericNestableMenuEntry';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import React from 'react';
import TagLabelName from './tagLabelName';
import { tagInstancesTrash } from '../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesTrash';
import { useOpenCommentsDialog } from '../dialogs/commentsDialog';
import { usePaneActions } from '../panes/usePaneActions';
import { useQueryParam_trashMode } from '../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

// these styles created a warning when put into the Chip override classes above
const useStyles = makeStyles({
  labelIcon: {
    marginRight: '2px',
  },
  // Importing into local classes here since we seem to be hitting this error:
  // https://github.com/mui-org/material-ui/issues/16374
  tagLabel: componentTypeStyles.tagLabel,
  tagLabelDeleteIcon: {
    color: '#00000038',
    '&:hover': {
      color: '#00000055',
    },
  },
});

/** A component that show nice looking tag instance chips that can be displayed next to an
 * annotation or an entry */
export default function TagLabels({
  tagInstances,
  isHighlight,
  lineBreaks,
  taglistType,
}: {
  tagInstances?: Codefy.Objects.TagInstanceInfo[];
  isHighlight?: boolean;
  /** Should there be line breaks between the tagLabels or should they be on the same line? */
  lineBreaks: boolean;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const [trashMode] = useQueryParam_trashMode();

  // if this is not tagged, don't show anything
  if (isHighlight === true) return null;

  if (!tagInstances) return null;

  return (
    <>
      <Grid container alignItems="center">
        {tagInstances
          .filter((tagInstance) => !!tagInstance.trashed === !!trashMode)
          .map((tagInstance) => (
            <TagInstanceLabel
              key={tagInstance.id}
              tagInstance={tagInstance}
              lineBreaks={lineBreaks}
              taglistType={taglistType}
            />
          ))}
      </Grid>
    </>
  );
}

export function TagInstanceLabel({
  tagInstance,
  lineBreaks,
  taglistType,
}: {
  tagInstance: Codefy.Objects.TagInstanceInfo;
  lineBreaks: boolean;
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const paneActions = usePaneActions();
  const openCommentsDialog = useOpenCommentsDialog();
  const globalClasses = useGlobalStyles();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const onDelete = () => {
    if (!tagInstance.id) return;

    tagInstancesTrash({ tag_instance_id: tagInstance.id });
  };

  const onRestore = () => {
    if (!tagInstance.id) return;

    tagInstancesTrash({ tag_instance_id: tagInstance.id, trashed: false });
  };

  const onOpenComments: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    openCommentsDialog({ tag_instance_id: tagInstance.id });
  };

  const menuEntries: GenericNestableMenuEntry[] = trashMode
    ? [
        {
          key: 'restore',
          text: trashModeStrings('restore'),
          icon: trashModeIcons('restore'),
          onClick: onRestore,
          disabled: !tagInstance.path?.write_permission,
        },
      ]
    : [
        {
          key: 'commentHeadline',
          text: (
            <Box>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.comment')}
              </Typography>
            </Box>
          ),
          disabled: !(tagInstance.path?.add_permission || tagInstance.comments.length !== 0),
        },
        {
          key: 'addComment',
          disableGutters: true,
          text: (
            <Box
              ml={1}
              mr={1}
              onClick={(event) => event.stopPropagation()}
              width={400}
              bgcolor="white">
              <CreateComment
                createCommentParams={{ type: 'tag_instance', tag_instance_id: tagInstance.id }}
                alwaysFull
              />
            </Box>
          ),
          disabled: !tagInstance.path?.add_permission || tagInstance.comments.length !== 0,
        },
        {
          key: 'viewComments',
          text: t('comments.viewComments'),
          icon: (
            <IconButton
              size="small"
              disabled
              style={{ color: COLORS.primary, backgroundColor: 'none' }}>
              <CommentsIcon commentsCount={tagInstance.comments.length} />
            </IconButton>
          ),
          onClick: onOpenComments,
          disabled: tagInstance.comments.length === 0,
        },
        {
          key: 'viewHeadline',
          text: (
            <Box mt={3}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.view')}
              </Typography>
            </Box>
          ),
        },
        {
          key: 'open',
          text: (
            <Box maxWidth={300} overflow="hidden" textOverflow="ellipsis">
              {t('tagLabels.viewAllItemsFor', { tagName: tagInstance.tag_name })}
            </Box>
          ),
          icon:
            taglistType === 'annotation' ? (
              <EntryFileTypeIcon entryMimetype="application/codefy.annotation-taglist" />
            ) : (
              <EntryFileTypeIcon entryMimetype="application/codefy.entry-taglist" />
            ),
          onClick: () => {
            if (taglistType === 'annotation') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTaglist,
                params: {
                  annotationTaglist_id: tagInstance.taglist_id,
                },
              });

              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTag,
                params: {
                  annotationTag_tagId: tagInstance.tag_id,
                },
              });
            } else if (taglistType === 'entry') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTaglist,
                params: {
                  entryTaglist_id: tagInstance.taglist_id,
                },
              });

              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTag,
                params: {
                  entryTag_tagId: tagInstance.tag_id,
                },
              });
            }
          },
        },
        {
          key: 'editHeadline',
          text: (
            <Box mt={3}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.edit')}
              </Typography>
            </Box>
          ),
          disabled: !tagInstance.path?.write_permission,
        },
        {
          key: 'delete',
          text: trashModeStrings('trash'),
          icon: trashModeIcons('trash'),
          onClick: onDelete,
          disabled: !tagInstance.path?.write_permission,
        },
      ];

  return (
    <GenericContextMenu key={tagInstance.id} openOnLeftClick menuEntries={menuEntries}>
      <Grid item md={lineBreaks ? 12 : undefined}>
        <Box
          /* Necessary for entry tagLabels so they're not stuck together */
          ml={0.5}
          mr={0.5}
          /* If the mb and mt margins are adjusted, make sure to also adjust
                useAnnotationPatchTagLabelPositions. We use no vertical margin so that the maximum
                of space is used. */
          mt={0}
          mb={lineBreaks ? 0 : 0.5}>
          <Chip
            style={{ backgroundColor: tagInstance?.tag_color || COLORS.defaultTagColor }}
            className={classes.tagLabel}
            size="small"
            key={tagInstance.id}
            onDelete={
              tagInstance.path?.write_permission
                ? tagInstance.trashed
                  ? onRestore
                  : onDelete
                : undefined
            }
            deleteIcon={
              <IconButton size="small">
                {tagInstance.trashed ? (
                  <Tooltip title={trashModeStrings('restore')}>
                    {trashModeIcons('restore', true, classes.tagLabelDeleteIcon)}
                  </Tooltip>
                ) : (
                  <Tooltip title={trashModeStrings('trash')}>
                    {trashModeIcons('restore', true, classes.tagLabelDeleteIcon, true)}
                  </Tooltip>
                )}
              </IconButton>
            }
            label={
              <Grid container alignContent="center" wrap="nowrap">
                <Grid item>
                  <Box>
                    <TagLabelName name={tagInstance.tag_name} />
                  </Box>
                </Grid>

                {tagInstance.comments && tagInstance.comments.length > 0 && (
                  <Grid item>
                    <Tooltip title={t('comments.viewComments') || ''}>
                      <div
                        style={{
                          marginTop: '-4px',
                          marginLeft: '2px',
                          marginBottom: '0px',
                          transform: 'scale(0.75)',
                          wordBreak: 'normal',
                          overflow: 'visible',
                        }}
                        onClick={onOpenComments}>
                        <CommentsIcon commentsCount={tagInstance.comments.length} />
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            }
          />
        </Box>
      </Grid>
    </GenericContextMenu>
  );
}
