import {
  COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE,
  CommentSubscriptionLevel,
} from '../../subscriptions/commentSubscriptions/commentSubscriptionsGet';

import { GenericCommentParameters } from '../../subscriptions/comments/commentsList';
import { executeAction } from '../actionHelpers';

type CommentSubscriptionsSetRequestType = GenericCommentParameters & {
  level: CommentSubscriptionLevel;
};

export const commentSubscriptionsSet = async (data: CommentSubscriptionsSetRequestType) =>
  executeAction<CommentSubscriptionLevel[]>({
    config: { url: '/api/v1/comment_subscriptions/set', method: 'POST' },
    data,
    queryKeysToRefresh: [COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE],
  });
