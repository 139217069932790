import React, { useState } from 'react';

import Codefy from '../../../codefy';
import DeleteIcon from '@material-ui/icons/Delete';
import GenericDeleteDialog from '../genericDeleteDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { uploadBatchesDelete } from '../../../controllers/api/actions/uploadBatches/uploadBatchesDelete';
import { useBetween } from 'use-between';
import { usePaneActions } from '../../panes/usePaneActions';
import { useTranslation } from 'react-i18next';
import { useUploadBatchesGet } from '../../../controllers/api/subscriptions/uploadBatches/uploadBatchesGet';

type DialogDeleteUploadBatchStateType = {
  upload_batch_uuid?: Codefy.Objects.UploadBatch['upload_batch_uuid'];
};

const useDialogDeleteUploadBatchState = () => useState<DialogDeleteUploadBatchStateType>();

const useSharedDialogDeleteUploadBatchState = () => useBetween(useDialogDeleteUploadBatchState);

export const DeleteUploadBatchDialog = () => {
  const { t } = useTranslation();
  const paneActions = usePaneActions();

  const [state, setState] = useSharedDialogDeleteUploadBatchState();

  const { data: uploadBatch } = useUploadBatchesGet({
    upload_batch_uuid: state?.upload_batch_uuid,
  });

  const onClose = () => setState(undefined);

  const onDelete = () => {
    if (!state?.upload_batch_uuid) return;
    uploadBatchesDelete({
      upload_batch_uuid: state.upload_batch_uuid,
    });
    paneActions.closePanes([PaneKeys.uploadBatch]);
  };

  if (!uploadBatch?.num_documents) return null;

  /* Just as another precaution not to accidentally expose the useKeyPress("Enter", ...) function
  that can trigger unwanted deletions. */
  if (!state) return null;

  return (
    <GenericDeleteDialog
      open={!!state}
      icon={<DeleteIcon />}
      title={t('uploadBatch.deleteUploadBatchDialogTitle')}
      content={
        <span>
          {t('uploadBatch.documentsWillBeDeleted', {
            count: uploadBatch.num_documents,
          })}
        </span>
      }
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export const useOpenDeleteUploadBatchDialog = () => {
  const [, setState] = useSharedDialogDeleteUploadBatchState();
  return (state: DialogDeleteUploadBatchStateType) => () => setState(state);
};
