import Codefy from '../../../../codefy';
import axios from 'axios';
import { useQuery } from 'react-query';

export type UsersListParams = {
  email?: string;
  name?: string;
  project?: boolean;
  offset?: number;
  limit?: number;
};

export type UsersListResponse = { users: Codefy.Objects.User[]; num_users: number };

export const USERS_LIST_KEY_TYPE = 'usersList';

export const usersListKey = (params: UsersListParams): [string, Record<string, any>] => [
  USERS_LIST_KEY_TYPE,
  params,
];

export const useUsersList = (params: UsersListParams) =>
  useQuery(
    usersListKey(params),
    async () => {
      const { data } = await axios.get('/api/v1/users/list', { params });
      return data as UsersListResponse;
    },
    { keepPreviousData: true },
  );
