import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  useQueryParam_editExportText_itemId,
  useQueryParam_modal,
} from '../../../../../controllers/useGlobalQueryParams';

import { Autocomplete } from '@material-ui/lab';
import { tagInstancesExportText } from '../../../../../controllers/api/actions/taglists/tags/tagInstances/tagInstancesExportText';
import { useTagInstancesGet } from '../../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesGet';
import { useTaglistsGet } from '../../../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTranslation } from 'react-i18next';

export default function EditExportTextModal() {
  const [editExportText_itemId] = useQueryParam_editExportText_itemId();
  const [, set_modal] = useQueryParam_modal();
  const onCloseModal = useCallback(() => set_modal(undefined, 'replaceIn'), []);
  const [exportText, setExportText] = useState<string>();
  const { t } = useTranslation();

  const { data: item } = useTagInstancesGet(editExportText_itemId);

  const { data: taglist } = useTaglistsGet({ taglist_id: item?.taglist_id });
  const tag = taglist?.tags.find((tag) => tag.id === item?.tag_id);

  const defaultText = item?.type === 'annotation' ? item.annotation.selected_text : '';

  const onSave = () => {
    if (!item?.id) return;

    if (exportText) {
      tagInstancesExportText({ tag_instance_id: item.id, export_text: exportText });
      onCloseModal();
    }
  };

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const text = event.target.value;
      setExportText(text);
    },
    [],
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && event.metaKey) {
      onSave();
    }
  };

  return (
    <div>
      <DialogTitle>
        {item?.export_text
          ? t('editExportTextModal.changeExportText')
          : t('editExportTextModal.setExportText')}
      </DialogTitle>
      <DialogContent>
        {tag?.export_text_templates && tag?.export_text_templates?.length > 0 ? (
          <Autocomplete
            options={tag?.export_text_templates}
            renderOption={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('editExportTextModal.selectTemplate')}
                variant="outlined"
                size="small"
                autoFocus
              />
            )}
            onChange={(event, value, reason) => {
              if (reason == 'select-option' && value) {
                setExportText(value);
              } else if (reason == 'clear') {
                setExportText(undefined);
              }
            }}
          />
        ) : (
          <TextField
            multiline
            autoFocus
            defaultValue={exportText || defaultText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            fullWidth
          />
        )}
      </DialogContent>

      <DialogActions>
        <ButtonGroup>
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            disabled={!exportText || exportText === defaultText}>
            Save
          </Button>
          <Button color="secondary" onClick={onCloseModal}>
            Close
          </Button>
        </ButtonGroup>
      </DialogActions>
    </div>
  );
}
