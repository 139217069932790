import { PdfViewerVariantType, scrollToPage } from './pdfViewer';
import {
  useQueryParam_pdfViewer_documentId,
  useQueryParam_pdfViewer_page,
} from '../../../../controllers/useGlobalQueryParams';

//@ts-ignore
import useKeypress from 'react-use-keypress';
import userIsTyping from '../../../../hooks/userIsTyping';

/** Allows pressing the down/right key to scroll to the beginning of the next page (and vice versa) */
export default function usePdfArrowKeyPageNavigator({
  pdfViewerVariant,
  afterNavigateCallback,
}: {
  pdfViewerVariant: PdfViewerVariantType;
  afterNavigateCallback: () => any;
}) {
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(pdfViewerVariant);
  const [pdfViewer_page] = useQueryParam_pdfViewer_page(pdfViewerVariant);

  useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event: KeyboardEvent) => {
    if (!pdfViewer_page || !pdfViewer_documentId) return;

    /** To navigate the right pdf viewer, the user can press the shift key */
    if (event.shiftKey && pdfViewerVariant === 1) return;
    if (!event.shiftKey && pdfViewerVariant === 2) return;

    /** Do not fire if the user is currently typing in a text field since the arrow keys are used to
     * navigate the typed text */
    if (userIsTyping()) return;

    event.preventDefault();
    event.stopPropagation();

    if (['ArrowRight', 'ArrowDown'].includes(event.key)) {
      scrollToPage(pdfViewerVariant, pdfViewer_documentId, pdfViewer_page);
    }
    if (['ArrowLeft', 'ArrowUp'].includes(event.key)) {
      scrollToPage(pdfViewerVariant, pdfViewer_documentId, pdfViewer_page - 2);
    }

    /** Update the scroll position without waiting for the onScrollContainer function, which is
     * debounced for performance reasons (= it fires with a delay). This immediate update is
     * necessary so that the user can press right/down e.g. 5 times very quickly, and the pdfViewer
     * will scroll 5 pages (eventually, there might be a small lag, but at least it will end up 5
     * pages lower)  */
    afterNavigateCallback();
  });
}
