import { Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';

import Annotation from './annotation/annotation';
import Codefy from '../../../../codefy';
import { EntriesListItem } from '../entriesList/entriesListItem';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import React from 'react';
import { TagListTag } from '../taglist/tagListTag';
import clsx from 'clsx';

// const useStyles = makeStyles(() => ({
//   annotation: {
//     cursor: 'pointer',
//     width: '100%',
//   },
//   tagInstanceCompleted: { backgroundColor: '#a8f79855' },
//   exportTextStyle: { cursor: 'pointer', backgroundColor: '#00000000' },
// }));

export default function TagInstance({
  tagInstance,
  project_id,
  showTagInformation,
}: {
  tagInstance: Codefy.Objects.TagInstance;
  project_id?: Codefy.Objects.Project['id'];
  showTagInformation?: boolean;
}) {
  const globalClasses = useGlobalStyles();

  // TODO: 2021-07-27: Currently not used while we wait for user feedback
  // const onClick = () => {
  //   /** Currently only annotations can be shown without showing a taglist as well */
  //   if (tagInstance.type === 'annotation') {
  //     paneActions.addOrUpdatePane({
  //       paneKey: PaneKeys.annotationTaglist,
  //       params: { annotationTaglist_id: tagInstance.taglist_id },
  //       reset: true,
  //     });
  //     paneActions.addOrUpdatePane({
  //       paneKey: PaneKeys.annotationTag,
  //       params: { annotationTag_tagId: tagInstance.tag_id },
  //       reset: true,
  //     });
  //   }
  // };

  return (
    <Box>
      {showTagInformation && (
        <Box borderLeft={`5px solid ${tagInstance.tag_color || COLORS.defaultTagColor}`}>
          <ListItem dense className={globalClasses.listEntry}>
            <ListItemIcon className={clsx(globalClasses.narrowListItemIcon)}>
              {tagInstance.type === 'annotation' && <LabelOutlinedIcon />}
              {tagInstance.type === 'entry' && <LabelIcon />}
            </ListItemIcon>
            <ListItemText primary={tagInstance.tag_name} />
          </ListItem>
        </Box>
      )}
      <TagInstanceObject tagInstance={tagInstance} project_id={project_id} />
    </Box>
  );
}

export function TagInstanceObject({
  tagInstance,
  project_id,
}: {
  tagInstance: Codefy.Objects.TagInstance;
  project_id?: Codefy.Objects.Project['id'];
}) {
  switch (tagInstance.type) {
    case 'annotation':
      return <Annotation annotation={tagInstance.annotation} key={tagInstance.id} />;
    case 'entry':
      return <EntriesListItem entry={tagInstance.entry} />;

    case 'annotation_tag':
      return (
        <TagListTag
          project_id={project_id}
          tag={tagInstance.annotation_tag}
          writePermission={tagInstance.path.write_permission}
          addPermission={tagInstance.path.add_permission}
        />
      );

    default:
      return <span />;
  }
}

// function TagInstanceExportText({
//   tagInstance,
//   defaultText,
// }: {
//   tagInstance: Codefy.Objects.TagInstance;
//   defaultText?: string;
// }) {
//   const { t } = useTranslation();
//   const classes = useStyles();
//   const modalActions = useModalActions();

//   const onClickExportText = () => {
//     modalActions.openModal({
//       modal: ModalKeys.editExportText,
//       params: { editExportText_itemId: tagInstance.id },
//     });
//   };

//   return (
//     <Grid item md={12} onClick={onClickExportText} className={classes.exportTextStyle}>
//       <Box p={1}>
//         <Typography variant="body2">
//           <FaFileExport
//             fontSize="small"
//             style={{ marginRight: '4px', marginBottom: '-3px' }}
//             size={18}
//           />
//           {t('tagInstance.exportedAs')}: <b>{tagInstance.export_text || defaultText || ''}</b>
//         </Typography>
//       </Box>
//     </Grid>
//   );
// }
