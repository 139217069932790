import { Box, Button, Grid, Popover, makeStyles } from '@material-ui/core';
import { COLORS, FONT_SIZES, componentTypeStyles } from '../../globalThemeSettings';
import React, { useMemo } from 'react';

import CloudIcon from '@material-ui/icons/Cloud';
import Codefy from '../../codefy';
import CopyToClipboard from 'react-copy-to-clipboard';
import EntryFileTypeIcon from '../panes/paneTypes/entriesList/entryFileTypeIcon';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GenericMenuButton from './genericMenuButton';
import { PaneKeys } from '../panes/paneTypes/paneKeys';
import { SEARCH_METHOD_CUTOFF } from '../navbar/searchBar/useSearchMethod';
import SearchCodefyIcon from '../icons/searchCodefy';
import SearchIcon from '../icons/search';
import ShareIcon from '@material-ui/icons/Share';
import { SharedSelection } from '../../controllers/routes/forwarders/selectionUrlForwarder';
import TagLabelsEditor from '../tagLabels/tagLabelsEditor';
import TaglistSelector from '../panes/paneTypes/taglist/taglistSelector';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { annotationsCreate } from '../../controllers/api/actions/annotations/annotationsCreate';
import { setSearchBarInputValue } from '../navbar/searchBar/setSearchBarInputValue';
import stringify from 'fast-stringify';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import useCurrentCase from '../../hooks/useCurrentCase';
import { useDispatch } from 'react-redux';
import useIsEveryone from '../../hooks/useIsEveryone';
//@ts-ignore
import useKeyPress from 'react-use-keypress';
import { usePaneActions } from '../panes/usePaneActions';
import { useProjectsGet } from '../../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_taglist_id } from '../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';
import { SearchSearchRequestParams } from '../../controllers/api/subscriptions/search/searchSearch';

export const useStyles = makeStyles(() => ({
  horizontalMenuButton: {
    ...componentTypeStyles.text,
    textTransform: 'none',
    fontSize: FONT_SIZES.default,
    color: COLORS.primary,
    paddingTop: 0,
    paddingBottom: 0,
  },

  horizontalMenuIconBox: {
    marginTop: '3px',
    marginRight: '6px',
  },
}));

export default function SelectionMenu({
  handleContextMenuClose,
  selectedText,
  menuAnchorElement,
  selectedBoxes,
  _document,
  isArea,
  source,
}: {
  handleContextMenuClose: () => void;
  selectedText?: string | null;
  selectedBoxes: Codefy.Objects.Box[];
  menuAnchorElement: HTMLElement;
  _document?: Codefy.Objects.Document;
  isArea?: boolean;
  /** Where is the user accessing this component from? Used for metrics */
  source: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isEveryone = useIsEveryone();
  const paneActions = usePaneActions();
  const dispatch = useDispatch();
  const [, copyToClipboard] = useCopyToClipboard();

  const { id: currentCaseId, name: currentCaseName } = useCurrentCase();
  const [annotationTaglist_id] = useQueryParam_taglist_id({
    taglistType: 'annotation',
  });

  const userHasAddPermission = _document?.path?.add_permission;

  const { data: project } = useProjectsGet(_document?.path.project_id);

  const handleOnCopy = () => {
    toast.success(t('paneTitleBar.shareLinkCopied'));
    handleContextMenuClose();
  };

  /** Copy the selected text to clipboard when pressing Ctrl+C */
  useKeyPress('c', (event: KeyboardEvent) => {
    if (!event.ctrlKey && !event.metaKey) return;
    if (!selectedText) return;

    copyToClipboard(selectedText);
    // For some reason it needs to be scheduled as a promise, otherwise copying to clipboard falis.
    setTimeout(handleContextMenuClose, 0);
  });

  const handleSearch = ({
    search_method,
    search_directoryId,
    search_documentId,
  }: {
    search_method?: SearchSearchRequestParams['method'];
    search_directoryId?: Codefy.Objects.Directory['id'];
    search_documentId?: Codefy.Objects.Document['id'];
  }) => {
    if (selectedText) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.search,
        params: {
          search_query: selectedText,
          search_method,
          search_directoryId,
          search_documentId,
          searchBar_directoryId: search_directoryId,
          searchBar_documentId: search_documentId,
        },
      });

      dispatch({ type: 'setSearch', query: selectedText, open: false });
      if (search_method === 'ngram') {
        setSearchBarInputValue(selectedText);
      } else {
        setSearchBarInputValue('');
      }
    }
    handleContextMenuClose();
  };

  // const linkExistence = () => {
  //   if (selectedBoxes?.length > 0) {
  //     const links: string[] = [];
  //     selectedBoxes.forEach((box) => {
  //       if (box.link) {
  //         links.push(box.link);
  //       }
  //     });
  //     if (links.length == 1) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // const handleOpenLink = () => {
  //   if (selectedBoxes?.length > 0) {
  //     const links: string[] = [];
  //     selectedBoxes.forEach((box) => {
  //       if (box.link) {
  //         links.push(box.link);
  //       }
  //     });
  //     window.open(links[0], '_blank');
  //   }
  // };

  /** Allows sharing a selection without having to create an annotation (useful for anonymous
   * viewers of public projects, i.e. the "everyone" user) */
  const selectionShareLink = useMemo(() => {
    if (_document?.id && selectedBoxes && selectedBoxes?.length > 0) {
      /* Since we want to keep the link short, we just share general areas */
      /** Calculate the extremes per page */
      let boxes = selectedBoxes;

      const generalBoxesObject = boxes.reduce(
        (
          acc: {
            [key: number]: { xmin: number; xmax: number; ymin: number; ymax: number };
          },
          cur,
        ) => {
          const page = acc[cur.page] || {
            xmin: cur.xmin,
            xmax: cur.xmax,
            ymin: cur.ymin,
            ymax: cur.ymax,
          };
          page.xmin = Math.min(page.xmin, cur.xmin);
          page.xmax = Math.max(page.xmax, cur.xmax);
          page.ymin = Math.min(page.ymin, cur.ymin);
          page.ymax = Math.max(page.ymax, cur.ymax);
          acc[cur.page] = page;
          return acc;
        },
        {},
      );
      /** Convert them into a boxes object, just like if the user selected huge words haha */
      boxes = Object.entries(generalBoxesObject).map(([page, extremes]) => ({
        page: parseInt(page),
        xmin: extremes.xmin,
        xmax: extremes.xmax,
        ymin: extremes.ymin,
        ymax: extremes.ymax,
      }));

      const selection: SharedSelection = { b: boxes, d: _document.id };

      const selectionZip = btoa(stringify(selection));

      /** The server part of the URL, where the frontend is running, e.g. https://test.codefy.de/ */
      const baseUrl = window.location.protocol + '//' + window.location.host;
      /** The final url that the user can share with others */
      const shareUrl = baseUrl + '/share/selection?s=' + selectionZip;
      return shareUrl;
    }
    // eslint-disable-next-line
  }, [selectedBoxes]);

  const search_method: SearchSearchRequestParams['method'] | undefined = selectedText
    ? selectedText.split(' ').length > SEARCH_METHOD_CUTOFF
      ? 'more_like_this'
      : 'ngram'
    : undefined;

  return (
    <Popover
      onClose={handleContextMenuClose}
      open={!!selectedBoxes}
      style={{ zIndex: 10001 }}
      anchorReference="anchorEl"
      anchorEl={menuAnchorElement}
      /* Important, otherwise the underlying SelectionArea will reset the selection before the
      SelectionMenu has a chance to act on a click */
      onMouseDown={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}>
      <Box m={1}>
        <Grid container alignItems="center" wrap="nowrap">
          {selectedText && (
            <Button
              data-e2e-id="copy-to-clipboard"
              className={classes.horizontalMenuButton}
              onClick={() => {
                copyToClipboard(selectedText);
                handleContextMenuClose();
              }}>
              <Box component="span" className={classes.horizontalMenuIconBox}>
                <FilterNoneIcon />
              </Box>
              <div>{t('viewer.contextMenu.copyText')}</div>
            </Button>
          )}

          {selectedText && search_method === 'ngram' && (
            <GenericMenuButton
              title={t('viewer.contextMenu.search')}
              button={
                <Button data-e2e-id="search-menu-item" className={classes.horizontalMenuButton}>
                  <Box component="span" className={classes.horizontalMenuIconBox}>
                    <SearchIcon />
                  </Box>
                  {t('viewer.contextMenu.search')}
                </Button>
              }
              menuEntries={[
                {
                  key: 'document',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inDocument')} <b>{_document?.path.entry_name}</b>
                    </span>
                  ),
                  icon: (
                    <EntryFileTypeIcon entryMimetype={_document?.path.entry_mimetype} size={20} />
                  ),
                  onClick: () =>
                    handleSearch({ search_method: 'ngram', search_documentId: _document?.id }),
                },
                {
                  key: 'folder',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inFolder')} <b>{_document?.path.directory_name}</b>
                    </span>
                  ),
                  icon: <span>{<FolderOpenIcon />}</span>,
                  onClick: () =>
                    handleSearch({
                      search_method: 'ngram',
                      search_directoryId: _document?.path.directory_id,
                    }),
                  disabled:
                    !_document?.path.directory_name ||
                    _document?.path.directory_id === currentCaseId,
                },
                {
                  key: 'case',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inCase')} <b>{currentCaseName}</b>
                    </span>
                  ),
                  icon: <span>{<WorkOutlineIcon />}</span>,
                  onClick: () =>
                    handleSearch({
                      search_method: 'ngram',
                      search_directoryId: currentCaseId,
                    }),
                  disabled: !currentCaseName,
                },
                {
                  key: 'workspace',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inWorkspace')} <b>{_document?.path.project_name}</b>
                    </span>
                  ),
                  icon: <FolderIcon />,
                  onClick: () =>
                    handleSearch({
                      search_method: 'ngram',
                      search_directoryId: project?.directory_id,
                    }),
                },
                {
                  key: 'everywhere',
                  text: t('viewer.contextMenu.everywhere'),
                  icon: <CloudIcon />,
                  onClick: () => handleSearch({ search_method: 'ngram' }),
                  disabled: isEveryone,
                },
              ]}
            />
          )}

          {selectedText && search_method === 'more_like_this' && (
            <GenericMenuButton
              title={t('viewer.contextMenu.semanticSearch')}
              button={
                <Button data-e2e-id="ai-search-menu-item" className={classes.horizontalMenuButton}>
                  <Box component="span" className={classes.horizontalMenuIconBox}>
                    <SearchCodefyIcon />
                  </Box>
                  {t('viewer.contextMenu.semanticSearch')}
                </Button>
              }
              menuEntries={[
                {
                  key: 'document',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inDocument')} <b>{_document?.path.entry_name}</b>
                    </span>
                  ),
                  icon: (
                    <EntryFileTypeIcon entryMimetype={_document?.path.entry_mimetype} size={20} />
                  ),
                  onClick: () =>
                    handleSearch({
                      search_method: 'more_like_this',
                      search_documentId: _document?.id,
                    }),
                },
                {
                  key: 'folder',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inFolder')} <b>{_document?.path.directory_name}</b>
                    </span>
                  ),
                  icon: <span>{<FolderOpenIcon />}</span>,
                  onClick: () =>
                    handleSearch({
                      search_method: 'more_like_this',
                      search_directoryId: _document?.path.directory_id,
                    }),
                  disabled:
                    !_document?.path.directory_name ||
                    _document?.path.directory_id === currentCaseId,
                },
                {
                  key: 'case',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inCase')} <b>{currentCaseName}</b>
                    </span>
                  ),
                  icon: <span>{<WorkOutlineIcon />}</span>,
                  onClick: () =>
                    handleSearch({
                      search_method: 'more_like_this',
                      search_directoryId: currentCaseId,
                    }),
                  disabled: !currentCaseName,
                },
                {
                  key: 'workspace',
                  text: (
                    <span>
                      {t('viewer.contextMenu.inWorkspace')} <b>{_document?.path.project_name}</b>
                    </span>
                  ),
                  icon: <FolderIcon />,
                  onClick: () =>
                    handleSearch({
                      search_method: 'more_like_this',
                      search_directoryId: project?.directory_id,
                    }),
                },
                {
                  key: 'everywhere',
                  text: t('viewer.contextMenu.everywhere'),
                  icon: <CloudIcon />,
                  onClick: () => handleSearch({ search_method: 'more_like_this' }),
                  disabled: isEveryone,
                },
              ]}
            />
          )}

          {selectionShareLink && (
            <CopyToClipboard text={selectionShareLink} onCopy={handleOnCopy}>
              <Button
                key={'copy-share-link-to-clipboard'}
                className={classes.horizontalMenuButton}
                data-e2e-id={'copy-share-link-to-clipboard'}>
                <Box component="span" className={classes.horizontalMenuIconBox}>
                  <ShareIcon />
                </Box>
                {t('viewer.contextMenu.share')}
              </Button>
            </CopyToClipboard>
          )}

          {/* {selectedText && linkExistence() && (
            <Button
              onClick={handleOpenLink}
              data-e2e-id="open-link-item"
              className={classes.horizontalMenuButton}>
              <Box component="span" className={classes.horizontalMenuIconBox}>
                <OpenInNewIcon />
              </Box>
              {t('viewer.contextMenu.openLink')}
            </Button>
          )} */}
        </Grid>

        {!(isEveryone || !userHasAddPermission) &&
          _document?.path.project_id &&
          annotationTaglist_id && <Box mt={0.5} />}

        <Grid container alignItems="flex-start">
          <Box mt={1}>
            {(selectedText || isArea) && _document?.path.directory_id && (
              <TaglistSelector
                directoryId={_document?.path.directory_id}
                taglistType="annotation"
              />
            )}
          </Box>
        </Grid>
      </Box>
      {!(isEveryone || !userHasAddPermission) &&
        _document?.path.project_id &&
        annotationTaglist_id && (
          <>
            <TagLabelsEditor
              autoFocus
              source={source}
              isArea={isArea}
              taglistType="annotation"
              onClose={handleContextMenuClose}
              project_id={_document?.path.project_id}
              documentId={_document.id}
              selectedBoxes={selectedBoxes}
              createAnnotationCallback={
                source === 'pdf'
                  ? (tag_id: Codefy.Objects.Tag['id']) => {
                      if (selectedBoxes?.length > 0) {
                        const boxes = selectedBoxes;

                        annotationsCreate({
                          document_id: _document.id,
                          boxes,
                          area: isArea,
                          type: 'annotation',
                          tag_id,
                          source,
                        });
                      }
                      handleContextMenuClose();
                    }
                  : undefined
              }
            />
          </>
        )}
    </Popover>
  );
}
