import { useDirectoriesGet } from '../controllers/api/subscriptions/directories/directoriesGet';
import { useProjectsGet } from '../controllers/api/subscriptions/projects/projectsGet';
import { useQueryParam_entriesList_directoryId } from '../controllers/useGlobalQueryParams';

export default function useCurrentProjectDirectoryId() {
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const { data: directory } = useDirectoriesGet(entriesList_directoryId);
  const { data: project } = useProjectsGet(directory?.path?.project_id);

  return project?.directory_id;
}
