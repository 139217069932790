import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../globalThemeSettings';
import {
  PROJECTS_GET_URL,
  useProjectsGet,
} from '../../../controllers/api/subscriptions/projects/projectsGet';
import React, { useMemo, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import Codefy from '../../../codefy';
import CreatePublicationButton from '../publication/createPublicationButton';
import { GenericDialog } from '../genericDialog';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PaneContentLoading from '../../panes/paneContentLoading';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import PermissionLevelFullIcon from '../../icons/permissionLevelFull';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PublicIcon from '@material-ui/icons/Public';
import axios from 'axios';
import clsx from 'clsx';
import { getPublicationShareLink } from '../publication/genericPublicationDialog';
import isValidEmail from 'is-valid-email';
import { projectsPermission } from '../../../controllers/api/actions/projects/projectsPermission';
import { toast } from 'react-toastify';
import { useBetween } from 'use-between';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { useIsPaneOpen } from '../../../controllers/useGlobalQueryParams';
import { usePaneActions } from '../../panes/usePaneActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUsersList } from '../../../controllers/api/subscriptions/users/usersList';

/** Cannot be in codefy.d.ts because of some import errors */
export enum PermissionAccessLevel {
  READONLY = 'readonly',
  CONTRIBUTOR = 'contributor',
  FULL = 'full',
}

type DialogShareProjectStateType = {
  projectId?: Codefy.Objects.Project['id'];
};

const useDialogShareProjectState = () => useState<DialogShareProjectStateType>();

const useSharedDialogShareProjectState = () => useBetween(useDialogShareProjectState);

export const ShareProjectDialog = () => {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const publicationsFeatureFlagEnabled = useFeatureFlag('publications');

  const userEmail = useSelector((state: Codefy.State) => state.user?.email);

  const [state, setState] = useSharedDialogShareProjectState();

  // Those needs to be split up for some reason, otherwise the Autocomplete text field will not
  // reset itself after submitting
  const [shareWithEmail, setShareWithEmail] = useState<string>('');
  const [searchEmail, setSearchEmail] = useState<string>();

  const [showAccessLevels, setShowAccessLevels] = useState(false);
  const [accessLevel, setAccessLevel] = useState<PermissionAccessLevel>(
    PermissionAccessLevel.CONTRIBUTOR,
  );

  const { data: project, isFetchedAfterMount } = useProjectsGet(state?.projectId);

  const { data: users } = useUsersList({ email: searchEmail, limit: 5 });
  const autocompleteOptions = !users
    ? []
    : /** Remove users the project is already shared with */
      users.users.filter(
        (user) =>
          !project?.permissions.map((permission) => permission.user.email).includes(user.email),
      );

  const onShare = () => {
    if (shareWithEmail === userEmail) {
      toast.error(t('shareProjectDialog.cannotShareWithYourself'));
    }
    if (state?.projectId && shareWithEmail) {
      projectsPermission({
        project_id: state.projectId,
        email: shareWithEmail,
        access_level: accessLevel,
      });
      setSearchEmail('');
      setShareWithEmail('');
    }
  };

  const onClose = () => {
    setState(undefined);
    setShareWithEmail('');
    setSearchEmail(undefined);
    setShowAccessLevels(false);
    setAccessLevel(PermissionAccessLevel.CONTRIBUTOR);
  };

  if (!state) return null;

  if (!isFetchedAfterMount)
    return (
      <GenericDialog
        title={t('shareProjectDialog.shareThisWorkspace', { projectName: project?.name || '' })}
        open={!!state}
        onClose={onClose}>
        <PaneContentLoading />
      </GenericDialog>
    );

  return (
    <GenericDialog
      title={t('shareProjectDialog.shareThisWorkspace', { projectName: project?.name || '' })}
      open={!!state}
      onClose={onClose}>
      <Box>
        <Box>
          <Grid container alignItems="center" alignContent="center" wrap="nowrap">
            <Box mr={1}>
              <PersonAddIcon />
            </Box>
            <Box width="100%">
              <Autocomplete
                size="small"
                id="share-with"
                freeSolo
                disabled={!project?.path.add_permission}
                disableClearable
                fullWidth
                value={shareWithEmail}
                style={{ minWidth: '300px' }}
                options={autocompleteOptions}
                getOptionLabel={(user) => (typeof user === 'object' ? user.email : user)}
                onChange={(event, value, reason) => {
                  if (reason === 'select-option' && value) {
                    if (typeof value === 'object') {
                      setShareWithEmail(value.email);
                    } else {
                      setShareWithEmail(value);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <Button
                          onClick={onShare}
                          disabled={!shareWithEmail || !isValidEmail(shareWithEmail)}
                          color="primary"
                          className={globalClasses.dialogButton}>
                          {t('shareProjectDialog.share')}
                        </Button>
                      ),
                    }}
                    autoFocus
                    inputProps={{
                      ...params.inputProps,
                      autocomplete: 'new-password',
                    }}
                    variant="standard"
                    placeholder={t('shareProjectDialog.shareWith')}
                    size="small"
                    fullWidth
                    onChange={(event) => {
                      setSearchEmail(event.target.value);
                      setShareWithEmail(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          {showAccessLevels ? (
            <>
              <Box ml={4} mt={1}>
                <Select
                  variant="outlined"
                  style={{ maxHeight: '30px' }}
                  value={accessLevel}
                  onChange={(event) => setAccessLevel(event.target.value as PermissionAccessLevel)}>
                  <MenuItem value={PermissionAccessLevel.FULL}>
                    {t('shareProjectDialog.full')}
                  </MenuItem>
                  <MenuItem value={PermissionAccessLevel.CONTRIBUTOR}>
                    {t('shareProjectDialog.contributor')} ({t('shareProjectDialog.recommended')})
                  </MenuItem>
                  <MenuItem value={PermissionAccessLevel.READONLY}>
                    {t('shareProjectDialog.readonly')}
                  </MenuItem>
                </Select>
              </Box>
              <Box ml={4} mt={1}>
                <Typography className={globalClasses.textLight}>
                  {accessLevel === PermissionAccessLevel.FULL && t('shareProjectDialog.descFull')}
                  {accessLevel === PermissionAccessLevel.CONTRIBUTOR &&
                    t('shareProjectDialog.descContributor', { postProcess: 'markdown-jsx' })}
                  {accessLevel === PermissionAccessLevel.READONLY &&
                    t('shareProjectDialog.descReadonly')}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              {shareWithEmail && (
                <Box ml={4} mt={1}>
                  <Typography className={globalClasses.textLight}>
                    {t('shareProjectDialog.descDefault')}{' '}
                    <span
                      className={globalClasses.linkLight}
                      onClick={() => setShowAccessLevels(true)}>
                      {t('shareProjectDialog.edit')}
                    </span>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box mt={2}>
          <Typography className={globalClasses.subheading}>
            {t('shareProjectDialog.workspaceMembers')}
          </Typography>
          <Box mt={2}>
            {project?.permissions.map((permission) => (
              <UserPermission
                key={permission.user.id}
                project={project}
                permission={permission}
                onClose={onClose}
              />
            ))}
          </Box>
        </Box>

        {publicationsFeatureFlagEnabled && state.projectId && !project?.publication && (
          <Box mt={8}>
            <Box mb={2}>
              <Divider />
            </Box>
            <Typography className={globalClasses.subheading}>
              {t('shareProjectDialog.publishWorkspace')}
            </Typography>

            <Box mt={1}>
              <CreatePublicationButton projectId={state?.projectId} onClose={onClose} />
            </Box>
          </Box>
        )}
      </Box>
    </GenericDialog>
  );
};

function UserPermission({
  project,
  permission,
  onClose,
}: {
  project: Codefy.Objects.Project;
  permission: Codefy.Objects.ProjectPermission;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const userId = useSelector((state: Codefy.State) => state.user?.id);

  /** True if the currently logged in user can edit permissions for the project */
  const userCanEditPermissions =
    project?.owned_by?.id === userId ||
    project?.permissions.some(
      (permission) =>
        permission.access_level === PermissionAccessLevel.FULL && permission.user.id === userId,
    );

  const [accessLevel, setAccessLevel] = useState<PermissionAccessLevel>(permission.access_level);

  const icon = useMemo(() => {
    if (permission.user.email === 'everyone') return <PublicIcon />;
    switch (permission.access_level) {
      case PermissionAccessLevel.FULL:
        return <PermissionLevelFullIcon />;
      case PermissionAccessLevel.CONTRIBUTOR:
        return permission.user.confirmed ? <PersonIcon /> : <MailOutlineIcon />;
      case PermissionAccessLevel.READONLY:
        return <PersonOutlineIcon />;
    }
  }, [permission.access_level]);

  return (
    <Box m={1} ml={0} mt={2}>
      <Grid container alignItems="center" alignContent="center" wrap="nowrap">
        <Box mr={1}>{icon}</Box>
        <Box width="100%">
          <Grid container alignContent="space-between" spacing={2}>
            <Grid item md={7}>
              {permission.user.email === 'everyone' ? (
                <>
                  <Typography>
                    <b>
                      {t('shareProjectDialog.public')}{' '}
                      {project.publication && (
                        <>
                          <span className={globalClasses.textLight}>
                            {t('publicationDialog.linkWasOpenedNTimes', {
                              count: project.publication.view_count,
                            })}
                          </span>
                        </>
                      )}
                      {project.publication && (
                        <>
                          <br />
                          <a
                            className={clsx(
                              globalClasses.linkLight,
                              globalClasses.underlineOnHover,
                            )}
                            href={getPublicationShareLink(project.publication)}>
                            {getPublicationShareLink(project.publication)}
                          </a>
                        </>
                      )}
                    </b>
                  </Typography>

                  <Typography> {t('shareProjectDialog.anyoneWithALink')}</Typography>
                  <Box ml={-1}>
                    <CreatePublicationButton projectId={project.id} onClose={onClose} />
                  </Box>
                </>
              ) : (
                <>
                  <Typography>
                    <b>{permission.user.name}</b>
                  </Typography>
                  <Typography>{permission.user.email} </Typography>
                  {project.created_by?.id === permission.user.id && (
                    <Typography className={globalClasses.textLight}>
                      {t('shareProjectDialog.creator')}
                    </Typography>
                  )}
                  {!permission.user.confirmed && (
                    <Typography className={globalClasses.textLight}>
                      {t('shareProjectDialog.invited')}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item md={5}>
              <Select
                variant="outlined"
                style={{ maxHeight: '30px' }}
                value={accessLevel}
                disabled={!userCanEditPermissions}
                onChange={(event) => {
                  // Don't update the frontend when removing since it looks weird
                  if (event.target.value) {
                    setAccessLevel(event.target.value as PermissionAccessLevel);
                  }

                  projectsPermission({
                    project_id: project.id,
                    email: permission.user.email,
                    access_level: event.target.value as PermissionAccessLevel,
                  });
                }}>
                {permission.user.email !== 'everyone' && (
                  <MenuItem value={PermissionAccessLevel.FULL}>
                    {t('shareProjectDialog.full')}
                  </MenuItem>
                )}

                {permission.user.email !== 'everyone' && (
                  <MenuItem value={PermissionAccessLevel.CONTRIBUTOR}>
                    {t('shareProjectDialog.contributor')}
                  </MenuItem>
                )}

                <MenuItem value={PermissionAccessLevel.READONLY}>
                  {t('shareProjectDialog.readonly')}
                </MenuItem>

                <Divider />

                <MenuItem value={undefined}>
                  <span style={{ color: COLORS.deleteStrong }}>
                    {t('shareProjectDialog.remove')}
                  </span>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}

export const useOpenShareProjectDialog = () => {
  const [, setState] = useSharedDialogShareProjectState();
  const isEntriesListPaneOpen = useIsPaneOpen(PaneKeys.entriesList);
  const paneActions = usePaneActions();
  return async (state: DialogShareProjectStateType) => {
    /* If the entriesList pane is not open, open it, otherwise the user could share empty screens */
    if (!isEntriesListPaneOpen) {
      const projectResponse = await axios.get<Codefy.Objects.Project>(PROJECTS_GET_URL, {
        params: { project_id: state.projectId },
      });
      const project = projectResponse.data;
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entriesList,
        params: { entriesList_directoryId: project.directory_id },
      });
    }

    setState(state);
  };
};
