import axios from 'axios';
import { eventWithTime } from 'rrweb/typings/types';
import { useQuery } from 'react-query';

export type SessionsGetParams = {
  session_key: string | undefined;
};

export type SessionsGetResponse = {
  session_key: string;
  created_at: string;
  events: eventWithTime[];
};

export const SESSIONS_GET_KEY_TYPE = 'sessionsGet';

export const sessionsGetKey = (params: SessionsGetParams): [string, Record<string, any>] => [
  SESSIONS_GET_KEY_TYPE,
  params,
];

export const useSessionsGet = (params: SessionsGetParams) =>
  useQuery(sessionsGetKey(params), async () => {
    if (!params.session_key) return undefined;
    const { data } = await axios.get<SessionsGetResponse>('/api/v1/sessions/get', { params });
    return data;
  });
