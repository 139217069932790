import { PANE_WIDTHS, PaneType, paneTypes } from './paneTypes/paneTypes';
import React, { useEffect, useMemo, useState } from 'react';
import { useIsPaneCollapsed, useQueryParam_panes } from '../../controllers/useGlobalQueryParams';
import { usePaneConnectedToTheLeft, usePaneConnectedToTheRight } from './usePaneConnected';

import { Box } from '@material-ui/core';
import { PaneKeys } from './paneTypes/paneKeys';
import clsx from 'clsx';
import { getPaneE2eId } from '../../testing/common/data-e2e-ids';
import { throttle } from 'throttle-debounce';
import { useGlobalStyles } from '../../globalThemeSettings';
import useIsEveryone from '../../hooks/useIsEveryone';
import useLocalStorage from '@rehooks/local-storage';
import { useResizeDetector } from 'react-resize-detector';
import { useStyles } from './panesViewport';

export const getPaneElementId = (paneKey: PaneKeys) => `pane-${paneKey}`;

/** Allows hiding a sensitive pane, e.g. the projectsList pane when taking a screenshot for a
publication. Returns whether hiding operation succeeded. */
export const hidePane = (paneKey: PaneKeys, hidden: boolean): boolean => {
  const projectsListPane = document.getElementById(getPaneElementId(paneKey));
  if (!projectsListPane) return false;
  projectsListPane.style.display = hidden ? 'none' : 'initial';
  return true;
};

export const usePaneWidth = (paneKey: PaneKeys) => {
  const pane = paneTypes.find((p) => p.key === paneKey);
  return useLocalStorage<number>('paneWidth-' + pane?.key, pane?.width || PANE_WIDTHS.MEDIUM);
};

function Pane({ pane }: { pane: PaneType }) {
  const isEveryone = useIsEveryone();
  const classes = useStyles();
  const [panes] = useQueryParam_panes();
  const paneIsFirst = pane.key === panes?.[0];
  const width = `${pane.width}px`;
  const globalClasses = useGlobalStyles();

  const paneIsCollapsed = useIsPaneCollapsed(pane.key);

  const paneConnectedToTheLeft = usePaneConnectedToTheLeft(pane.key);
  const paneConnectedToTheRight = usePaneConnectedToTheRight(pane.key);

  const { width: newPaneWidth, ref } = useResizeDetector();

  const [paneWidth, setPaneWidth] = usePaneWidth(pane.key);

  useEffect(() => {
    if (!newPaneWidth) return;
    setPaneWidth(newPaneWidth);
  }, [newPaneWidth]);

  const style: React.CSSProperties = useMemo(
    () => ({
      minWidth: paneIsCollapsed ? undefined : pane.minWidth || pane.width * 0.75,
      maxWidth: paneIsCollapsed ? undefined : pane.width * 3,
      flex: '0 0 auto',
      width: paneIsCollapsed ? undefined : paneWidth,
      marginLeft: paneConnectedToTheLeft
        ? paneIsCollapsed
          ? '1px'
          : '0px'
        : paneIsFirst
        ? '12px'
        : '6px',
      marginRight: paneConnectedToTheRight ? (paneIsCollapsed ? '1px' : '0px') : '6px',
      borderTopLeftRadius: paneConnectedToTheLeft ? '0px' : undefined,
      borderBottomLeftRadius: paneConnectedToTheLeft ? '0px' : undefined,
      borderTopRightRadius: paneConnectedToTheRight ? '0px' : undefined,
      borderBottomRightRadius: paneConnectedToTheRight ? '0px' : undefined,
    }),
    [
      paneIsCollapsed,
      paneWidth,
      width,
      paneIsFirst,
      isEveryone,
      paneConnectedToTheLeft,
      paneConnectedToTheRight,
    ],
  );

  const paneTitleBarStyle: React.CSSProperties = useMemo(() => {
    if (!paneIsCollapsed) return {};
    return {
      position: 'absolute',
      marginTop: '40px',
      width: paneWidth,
      maxWidth: '70vh',
      borderTopLeftRadius: paneConnectedToTheRight ? '0px' : undefined,
    };
  }, [paneIsCollapsed, paneWidth, paneConnectedToTheRight]);

  return (
    <>
      <div
        ref={ref}
        key={pane.key}
        className={clsx(globalClasses.uiElement, paneIsCollapsed && classes.collapsedPane)}
        style={style}
        id={getPaneElementId(pane.key)}
        data-e2e-id={getPaneE2eId(pane.key, 'entirePane')}>
        <div
          className={clsx(paneIsCollapsed && globalClasses.uiElement)}
          style={paneTitleBarStyle}
          data-e2e-id={getPaneE2eId(pane.key, 'titleBar')}>
          {pane.paneTitleBar}
        </div>
        <div
          className={clsx(classes.paneContent, paneIsCollapsed && classes.collapsedPaneContent)}
          data-e2e-id={getPaneE2eId(pane.key, 'content')}>
          {pane.content}
        </div>
      </div>
      {paneIsCollapsed ? <Box width={60} flex={'0 0 auto'} /> : <PaneResizeHandler pane={pane} />}
    </>
  );
}

function PaneResizeHandler({ pane }: { pane: PaneType }) {
  const classes = useStyles();

  const [isDragging, setIsDragging] = useState<boolean>();
  const [start, setStart] = useState<number>();
  const [paneWidth, setPaneWidth] = usePaneWidth(pane.key);
  const [originalPaneWidth, setOriginalPaneWidth] = useState(paneWidth);

  const minWidth = pane.minWidth || pane.width * 0.75;
  /** A max width is defined as a precaution so that the user does not erroneously make a huuuuuge
   * pane and doesn't know what is going on.*/
  const maxWidth = pane.width * 3;

  useEffect(() => {
    const onMouseMove = throttle(25, (event: MouseEvent) => {
      if (!start || !isDragging) return;

      const end = event.clientX;
      const newPaneWidth = originalPaneWidth + (end - start);

      if (newPaneWidth < minWidth) return;
      if (newPaneWidth > maxWidth) return;

      setPaneWidth(newPaneWidth);
    });
    const onMouseUp = () => {
      setIsDragging(false);
      setOriginalPaneWidth(paneWidth);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [start, isDragging, originalPaneWidth, paneWidth]);

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
    setStart(event.clientX);
    event.preventDefault();
    event.stopPropagation();
  };

  return <div onMouseDown={onMouseDown} className={classes.dragHandle} />;
}

const MemoizedPane = React.memo(Pane, (prev, next) => prev.pane.key === next.pane.key);
MemoizedPane.displayName = 'Pane';

export default MemoizedPane;
