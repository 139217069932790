import { Button, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_pdfViewer_documentId,
  useQueryParam_search_orderBy,
} from '../../../../controllers/useGlobalQueryParams';

import SearchResultsItem from './searchResultsItem';
import Codefy from '../../../../codefy';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentLoading from '../../paneContentLoading';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import SearchNoResults from './searchNoResults';
import { useDispatch } from 'react-redux';
import { useEntriesList } from '../../../../controllers/api/subscriptions/entries/entriesList';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useHistory } from 'react-router';
import { useMeasure } from 'react-use';
import { usePaneActions } from '../../usePaneActions';
import { useSearch } from '../../../../controllers/api/subscriptions/search/searchSearch';
import useSearchParams from './useSearchParams';
import { useTranslation } from 'react-i18next';
import { useUserSetting_searchPane_snippetVariant } from '../../../../controllers/api/subscriptions/users/userSettings';

const useStyles = makeStyles(() => ({
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
  padded: { padding: '30px 30px 0px 30px' },
}));

export default function SearchResultsPaneContent() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const paneActions = usePaneActions();
  const dispatch = useDispatch();
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const [userSetting_searchPane_snippetVariant] = useUserSetting_searchPane_snippetVariant();
  const [search_orderBy] = useQueryParam_search_orderBy();

  const searchParams = useSearchParams({ variant: 'searchPane' });

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const {
    data: searcherSearchPages,
    isFetching: searcherSearchFetching,
    failureCount: searcherSearchFailureCount,
    hasNextPage,
    fetchNextPage,
  } = useSearch(
    {
      ...searchParams,
      limit_total_preview_images_count:
        (userSetting_searchPane_snippetVariant || 'image') == 'image' ? 3 : 0,
      order_by: search_orderBy,
    },
    true,
  );

  const SearchResults =
    searcherSearchPages?.pages.reduce((acc: Codefy.Objects.SearchResult[], cur) => {
      for (const result of cur.results) {
        acc.push(result);
      }
      return acc;
    }, []) || [];

  const {
    data: entriesPages,
    failureCount: entriesFailureCount,
    isLoading: entriesLoading,
  } = useEntriesList({
    directory_ids: searchParams.directory_ids,
    name: searchParams.query,
    recursive: true,
  });
  const entries = entriesPages?.pages[0].entries || [];

  const goBackButton = (
    <Button
      onClick={() => history.goBack()}
      style={{ marginLeft: '10px' }}
      variant="outlined"
      size="small"
      color="primary">
      {t('searchFilter.goBack')}
    </Button>
  );

  if (searcherSearchFailureCount > 0 || entriesFailureCount > 0) {
    return <div className={classes.padded}>Error :( {goBackButton}</div>;
  }

  if (
    !searcherSearchFetching &&
    !entriesLoading &&
    SearchResults.length === 0 &&
    entries.length === 0
  ) {
    return <SearchNoResults />;
  }

  const renderEntry = (entry: Codefy.Objects.Entry) => {
    return (
      <ListItem
        button
        divider
        selected={
          (entry.document && pdfViewer_documentId === entry.document.id) ||
          (entry.directory && entriesList_directoryId === entry.directory.id)
        }
        onClick={() => {
          if (entry.document) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.document.path.directory_id },
              reset: true,
            });
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.pdfViewer,
              params: { pdfViewer_documentId: entry.document.id },
              reset: true,
            });
          }
          if (entry.directory) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.directory.id },
            });
          }
          if (entry.taglist) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.taglist.path.directory_id },
              reset: true,
            });
            if (entry.taglist.type === 'annotation') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTaglist,
                params: { annotationTaglist_id: entry.taglist.id },
              });
            } else if (entry.taglist.type === 'entry') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTaglist,
                params: { entryTaglist_id: entry.taglist.id },
              });
            }
          }
          dispatch({ type: 'setSearch', open: false });
        }}>
        <ListItemIcon className={globalClasses.narrowListItemIcon}>
          {<EntryFileTypeIcon entryMimetype={entry.mimetype} style={{ marginRight: '3px' }} />}
        </ListItemIcon>
        <ListItemText primary={<b>{entry.name}</b>} />
      </ListItem>
    );
  };

  if (searcherSearchFetching && !SearchResults) {
    return <PaneContentLoading />;
  }

  return (
    <div className={classes.virtuoso} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={SearchResults.length || 0}
        next={fetchNextPage}
        height={height - 1}
        hasMore={!!hasNextPage}
        loader={<PaneContentLoading />}>
        {
          /** When searching INSIDE a document, the user does not expect other documents to appear
           * as search results. **/
          !searchParams.document_ids && entries.map((entry) => renderEntry(entry))
        }
        {searcherSearchFetching && <PaneContentLoading />}
        {SearchResults?.map((searcherResult, index) => (
          <SearchResultsItem
            searchQuery={searcherSearchFetching ? undefined : searchParams.query}
            index={index}
            num_results={searcherSearchPages?.pages[0].num_results}
            isInstasearchResult
            searchResult={searcherResult}
            key={`${searcherResult.file_id}-${searcherResult.part_ix}-${index}`}
            defaultSnippetVariant={userSetting_searchPane_snippetVariant || 'image'}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
}
