import {
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  ANNOTATIONS_LIST_KEY_TYPE,
} from '../../subscriptions/annotations/annotationsList';

import Codefy from '../../../../codefy';
import { TAGS_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagsList';
import { TAG_INSTANCES_LIST_KEY_TYPE } from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';
import { executeAction } from '../actionHelpers';

export const ANNOTATIONS_DELETE_QUERY_KEYS_TO_REFRESH = [
  ANNOTATIONS_LIST_KEY_TYPE,
  ANNOTATIONS_LIST_INFINITE_KEY_TYPE,
  TAG_INSTANCES_LIST_KEY_TYPE,
  TAGS_LIST_KEY_TYPE,
];

export const annotationsDelete = async (data: { annotation_id: Codefy.Objects.Annotation['id'] }) =>
  executeAction<Codefy.Objects.Annotation>({
    config: { url: '/api/v1/annotations/delete', method: 'POST' },
    data,
    queryKeysToRefresh: ANNOTATIONS_DELETE_QUERY_KEYS_TO_REFRESH,
  });
