import Codefy from '../../../../../codefy';
import { TAGLISTS_GET_KEY_TYPE } from '../../../subscriptions/taglists/taglistsGet';
import { TAGS_GET_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsGet';
import { TAGS_LIST_KEY_TYPE } from '../../../subscriptions/taglists/tags/tagsList';
import { executeAction } from '../../actionHelpers';
import { tagInstancePredictionsListKey } from '../../../subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';

export const tagsSavedSearchTerms = (data: {
  tag_id: Codefy.Objects.Tag['id'];
  saved_search_terms: Codefy.Objects.Tag['saved_search_terms'];
}) =>
  executeAction<Codefy.Objects.Tag>({
    config: { url: '/api/v1/taglists/tags/saved_search_terms', method: 'POST' },
    data,
    queryKeysToRefresh: [
      TAGLISTS_GET_KEY_TYPE,
      TAGS_GET_KEY_TYPE,
      TAGS_LIST_KEY_TYPE,
      tagInstancePredictionsListKey({ tag_id: data.tag_id }),
    ],
  });
