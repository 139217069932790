import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Codefy from '../../codefy';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import PaneContentLoading from '../panes/paneContentLoading';
import PasswordHelperText from './passwordHelperText';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isPasswordValid } from './validation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      overflow: 'hidden',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    bottomButtons: {
      paddingTop: theme.spacing(3),
    },
  }),
);

/** A screen allowing the user to set new password */

const ConfirmResetPassword = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [token] = useQueryParam('token', StringParam);
  const [alsoSetName] = useQueryParam('name', BooleanParam);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Codefy.API.ErrorMessage>();
  const [isFinished, setIsFinished] = useState(false);

  const formData = new FormData();
  formData.append('token', token as string);
  formData.append('password', password);
  if (name) {
    formData.append('name', name);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const canSubmit = alsoSetName
    ? token && isPasswordValid(password) && passwordConfirmation && name
    : token && isPasswordValid(password) && passwordConfirmation;

  /** Some common props for the <TextField> components to avoid duplicate code */
  const commonTextFieldProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'normal',
    required: true,
    fullWidth: true,
  };

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await axios.post<Codefy.Objects.User>('/api/v1/users/confirm_reset_password', formData);
      setIsFinished(true);
    } catch (error) {
      setError(error.response.data);
      setIsLoading(false);
    }
  };

  const title = alsoSetName
    ? t('confirmResetPassword.secureYourAccount')
    : t('confirmResetPassword.changePassword');

  if (isFinished) {
    return (
      <NoAuthScreenWrapper>
        <Grid container>
          <Grid item md={12}>
            <Typography>
              {alsoSetName
                ? t('confirmResetPassword.successConfirmationTextName')
                : t('confirmResetPassword.successConfirmationText')}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
              onClick={() => {
                window.location.pathname = '/';
              }}>
              {t('confirmResetPassword.continue')}
            </Button>
          </Grid>
        </Grid>
      </NoAuthScreenWrapper>
    );
  }

  if (isLoading) {
    return (
      <NoAuthScreenWrapper>
        <PaneContentLoading />
      </NoAuthScreenWrapper>
    );
  }

  if (error) {
    return (
      <NoAuthScreenWrapper>
        <Alert severity="error">
          {t('confirmResetPassword.error', { error: getErrorText(error) })}{' '}
          <a href={`mailto:info@codefy.de?subject=Help with error: ${getErrorText(error)}`}>
            info@codefy.de
          </a>
        </Alert>
      </NoAuthScreenWrapper>
    );
  }

  return (
    <NoAuthScreenWrapper title={title}>
      {!token && (
        <div className={classes.textBlock}>
          <Typography color="error" variant="body1" align="center">
            {t('confirmResetPassword.invalidTokenText')}
          </Typography>
        </div>
      )}

      {token && (
        <form className={classes.form} noValidate onSubmit={(e) => e.preventDefault()}>
          {alsoSetName && (
            <Slide in direction="right">
              <TextField
                {...commonTextFieldProps}
                label={t('authScreen.name')}
                name="name"
                type={'text'}
                id="name"
                autoComplete="name"
                autoFocus
                variant="outlined"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Slide>
          )}

          <Slide in direction="right">
            <TextField
              {...commonTextFieldProps}
              label={t('authScreen.password')}
              name="password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              autoFocus={!alsoSetName}
              error={!!password && !isPasswordValid(password)}
              helperText={
                password && !isPasswordValid(password) && <PasswordHelperText password={password} />
              }
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Slide>

          <Slide in direction="right">
            <TextField
              {...commonTextFieldProps}
              label={t('authScreen.confirmPassword')}
              name="confirm-password"
              type={showPassword ? 'text' : 'password'}
              id="confirm-password"
              value={passwordConfirmation}
              error={!!passwordConfirmation && passwordConfirmation !== password}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
            />
          </Slide>

          <Grid container direction="row" justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
                disabled={!canSubmit}
                onClick={onSubmit}>
                {t('confirmResetPassword.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </NoAuthScreenWrapper>
  );
};

export default ConfirmResetPassword;
