import { CircularProgress, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  useQueryParam_panes,
  useQueryParam_uploadBatch_status,
  useQueryParam_uploadBatch_uuid,
} from '../../../../controllers/useGlobalQueryParams';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Codefy from '../../../../codefy';
import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { PaneKeys } from '../paneKeys';
import { usePaneActions } from '../../usePaneActions';

export function UploadBatchesListItemStatus({
  uploadBatch,
  documentStatus,
  uploadBatchNumKey,
  description,
  secondary,
  type,
  clickable,
}: {
  uploadBatch: Codefy.Objects.UploadBatch;
  documentStatus: DocumentStatus;
  uploadBatchNumKey: keyof Codefy.Objects.UploadBatch;
  description: string;
  secondary?: string;
  type: 'success' | 'error' | 'working';
  /** Not all statuses are clickable since some of them look fairly buggy and are not really useful
   * for the user anyway */
  clickable?: boolean;
}) {
  const paneActions = usePaneActions();
  const [panes] = useQueryParam_panes();
  const [uploadBatch_uuid] = useQueryParam_uploadBatch_uuid();
  const [uploadBatch_status] = useQueryParam_uploadBatch_status();

  const icon = useMemo(() => {
    switch (type) {
      case 'error':
        return <ErrorOutlineIcon />;
      case 'success':
        return <CheckCircleOutlineIcon />;
      case 'working':
        return <CircularProgress size={22} thickness={5} />;
    }
  }, [type]);

  const value = uploadBatch[uploadBatchNumKey];

  if (value === undefined) return null;
  if (value < 1) return null;

  return (
    <ListItem
      //@ts-ignore
      button={clickable}
      divider
      selected={
        uploadBatch_uuid === uploadBatch.upload_batch_uuid &&
        uploadBatch_status === documentStatus &&
        panes.includes(PaneKeys.uploadBatch)
      }
      onClick={
        clickable
          ? () => {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.uploadBatch,
                params: {
                  uploadBatch_uuid: uploadBatch.upload_batch_uuid,
                  uploadBatch_status: documentStatus,
                },
              });
            }
          : undefined
      }>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={`${description} (${uploadBatch[uploadBatchNumKey]})`}
        secondary={secondary}
      />
    </ListItem>
  );
}
