import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, createStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryParamProvider } from 'use-query-params';
import './App.css';
import Codefy from './codefy';
import Favicon from './components/favicon/favicon';
import HtmlHeadRenderer from './components/htmlHeadRenderer';
import AppLayout from './components/layout';
import store from './controllers/store';
import { GLOBAL_MUI_THEME } from './globalThemeSettings';
import { IsDraggingFileProvider } from './hooks/useIsDraggingFile';
import './i18n/i18n';
import { langMUMapping } from './i18n/localeMappings';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /** Don't retry failed queries so that we can act after something has been deleted */
      retry: false,
      /** Disable automatic refresh of all queries on window refocus. This was causing a lot
       * of unecessary requests because we are already refetching in the background thanks to
       * the Websocket connection. The only missing case (user closes laptop for a few hours)
       * is taken care by listening to the wake-event and refetching then. */
      refetchOnWindowFocus: false,
      /** If, immediately after having reloaded the data, another reload request comes in, ignore it.
   This is because after performing an action, we already reload the data, and then a
   notification might come in to request the data being reloaded yet another time.  */
      // Depreciated on 2020-12-19 let's see if this improves the tagging experience
      // staleTime: 1000,
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    toastContent: {
      margin: '20px',
      fontWeight: 'bold',
      cursor: 'default',
    },
  }),
);

/** The entry point for our application is just a wrapper that provides acess to the
 * store */
export const App = () => {
  /** Sets the Material Design theme settings for the entire application */
  const { i18n } = useTranslation();
  const classes = useStyles();
  const theme = createMuiTheme(
    GLOBAL_MUI_THEME,
    langMUMapping[i18n.language as Codefy.Objects.LanguageType],
  );

  moment.locale(i18n.language as Codefy.Objects.LanguageType);
  return (
    <QueryClientProvider client={queryClient}>
      {/* Listens to whether the user is dragging a file from his system into the browser */}
      <IsDraggingFileProvider>
        <Provider store={store}>
          {/* Provides the theme settings to the Material Design components */}
          <ThemeProvider theme={theme}>
            {/* Sets favicon */}
            <Favicon />
            {/* Resets the many different default CSS settings that vary by browser to a common baseline */}
            <CssBaseline />
            {/* Provides routing features (history and location) */}
            <BrowserRouter>
              {/* Allows to read and manipulate the URL query, such as /project?project_id=5 */}
              <QueryParamProvider ReactRouterRoute={Route}>
                <ToastContainer
                  bodyClassName={classes.toastContent}
                  position={toast.POSITION.TOP_RIGHT}
                  hideProgressBar
                  pauseOnHover={false}
                  autoClose={10000}
                  closeButton={false}
                />

                <HtmlHeadRenderer />
                <AppLayout />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryParamProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </IsDraggingFileProvider>
    </QueryClientProvider>
  );
};
