import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordHelperText from '../../../../auth/passwordHelperText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { commonTextFieldProps } from '../index';
import { handleActionError } from '../../../../../controllers/api/actions/actionHelpers';
import { isPasswordValid } from '../../../../auth/validation';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userChangePassword } from '../../../../../controllers/api/actions/user/userChangePassword';
import { userGet } from '../../../../../controllers/api/actions/user/userGet';

// eslint-disable-next-line react/display-name
export const PasswordSettingContent = forwardRef(
  (
    props: {
      setSaveDisabled: any;
      setLoading: any;
    },
    ref: any,
  ): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [checkPassword, setCheckPassword] = React.useState(false);

    // The component instance (ref) gets extended
    // with a function returned from the callback
    // passed as the second argument
    useImperativeHandle(ref, () => ({
      saveData() {
        props.setLoading(ref, true);
        // submit data possible only if save button is enabled
        dispatch(
          userChangePassword({
            old_password: password,
            new_password: newPassword,
          }),
        )
          // @ts-ignore
          .then(() => {
            // update user information on setting view
            dispatch(userGet());

            toast.success(t('account.passwordSuccess'));

            props.setLoading(ref, false);
          }) // @ts-ignore
          .catch((error: any) => {
            props.setLoading(ref, false);
            handleActionError(error);
          });
      },
    }));

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
      event.preventDefault();
    };

    useEffect(() => {
      // set enabled if newPassword matches the confirmation
      if (
        isPasswordValid(newPassword) &&
        newPassword !== '' &&
        passwordConfirmation !== '' &&
        newPassword === passwordConfirmation
      ) {
        props.setSaveDisabled(ref, false);
      } else {
        props.setSaveDisabled(ref, true);
      }
    });

    return (
      <div>
        <Typography variant="caption">
          <TextField
            {...commonTextFieldProps}
            label={t('account.oldPassword')}
            name="password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            size="small"
            variant="standard"
            autoComplete="current-password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            {...commonTextFieldProps}
            label={t('account.newPassword')}
            name="new-password"
            type={showPassword ? 'text' : 'password'}
            id="new-password"
            size="small"
            variant="standard"
            value={newPassword}
            error={checkPassword ? !isPasswordValid(newPassword) : false}
            helperText={
              (checkPassword ? !isPasswordValid(newPassword) : false) && (
                <PasswordHelperText password={newPassword} />
              )
            }
            onChange={(event) => setNewPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            {...commonTextFieldProps}
            label={t('account.confirmNewPass')}
            name="confirm-password"
            type={showPassword ? 'text' : 'password'}
            id="confirm-password"
            size="small"
            variant="standard"
            value={passwordConfirmation}
            error={
              newPassword !== '' &&
              passwordConfirmation !== '' &&
              newPassword !== passwordConfirmation
            }
            helperText={
              password !== '' &&
              passwordConfirmation !== '' &&
              password !== passwordConfirmation && <PasswordHelperText />
            }
            onFocus={() => setCheckPassword(true)}
            onChange={(event) => setPasswordConfirmation(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Typography>
      </div>
    );
  },
);
