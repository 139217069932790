import Codefy from '../../../../codefy';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import React from 'react';
import { useOpenEditTagDialog } from '../../../dialogs/edit/editTagDialog';
import { useQueryParam_tag_tagId } from '../../../../controllers/useGlobalQueryParams';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

export default function TagDescriptionPaneTitleBar({
  taglistType,
}: {
  taglistType: Codefy.Objects.Taglist['type'];
}) {
  const [tag_id] = useQueryParam_tag_tagId({ taglistType });
  const { data: tag } = useTagsGet({ tag_id });
  const { t } = useTranslation();

  const openEditTagDialog = useOpenEditTagDialog();

  return (
    <PaneTitleBar
      icon={<CommentIcon />}
      title={t('taglist.noteFor', { name: tag?.name || '...' }) || ''}
      paneKey={
        taglistType === 'annotation'
          ? PaneKeys.annotationTagDescription
          : PaneKeys.entryTagDescription
      }
      menuEntries={[
        {
          key: 'edit',
          text: t('useTagMenuEntries.edit'),
          icon: <EditIcon />,
          onClick: openEditTagDialog({ tagId: tag_id }),
        },
      ]}
    />
  );
}
