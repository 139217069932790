import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import DirectoryFileUploadWrapper from '../entriesList/directoryFileUploadWrapper';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ProjectContextMenuWrapper from './projectContextMenuWrapper';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useHistory } from 'react-router';
import useHover from '../../../../hooks/useHover';
import useIsEveryone from '../../../../hooks/useIsEveryone';

export function ProjectsListEntry({ project }: { project: Codefy.Objects.Project }) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const [trashMode] = useQueryParam_trashMode();

  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();
  const { data: directory } = useDirectoriesGet(entriesList_directoryId);

  const isEveryone = useIsEveryone();
  const [hoverRef, isHovered] = useHover();

  const projectIsShared =
    project.permissions.map((permission) => permission.user.email).includes('everyone') ||
    project.permissions.length > 1;

  const onOpenProject = () => {
    if (trashMode) return;
    // FIXME: WHY ARE THERE RACE CONDITIONS EVERYWHERE
    // Problem: Open a document, then switch to another project. The pdfViewer pane will stay open
    // with no content for some reason!
    //
    // if (project.taglist_id) {
    //   paneActions.addOrUpdatePane({
    //     paneKey: PaneKeys.taglist,
    //     params: { taglist_id: project.taglist_id },
    //     reset: true,
    //   });
    // }
    // paneActions.addOrUpdatePane({
    //   paneKey: PaneKeys.entriesList,
    //   params: { entriesList_directoryId: project.directory_id },
    //   reset: true,
    // });

    // /** Shut down any previously opened project */
    // paneActions.closePane(PaneKeys.pdfViewer);
    // paneActions.closePane(PaneKeys.tag);

    history.push(
      `/?panes=projectsList&panes=entriesList&entriesList_directoryId=${project.directory_id}&search_directoryId=${project.directory_id}&searchBar_directoryId=${project.directory_id}`,
    );
  };

  const primaryText = <Typography variant="body1">{project.name}</Typography>;

  return (
    <ProjectContextMenuWrapper project={project} openOnLeftClick={trashMode}>
      <DirectoryFileUploadWrapper directory_id={project.directory_id} disabled={isEveryone}>
        <div ref={hoverRef}>
          <ListItem
            button
            divider
            selected={project.id === directory?.path?.project_id}
            onClick={onOpenProject}>
            <ListItemIcon className={globalClasses.narrowListItemIcon}>
              {projectIsShared ? <FolderSharedIcon /> : <FolderIcon />}
            </ListItemIcon>

            <ListItemText primary={primaryText} />

            <ProjectContextMenuWrapper project={project} openOnLeftClick>
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                style={isHovered ? {} : { opacity: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </ProjectContextMenuWrapper>
          </ListItem>
        </div>
      </DirectoryFileUploadWrapper>
    </ProjectContextMenuWrapper>
  );
}
