import {
  ROUTE_PANES_VIEWPORT,
  ROUTE_SHARE_COMMENT,
  ROUTE_SHARE_PROJECT,
} from '../../controllers/routes/routes';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { AxiosRequestConfig } from 'axios';
import { Button } from '@material-ui/core';
import Codefy from '../../codefy';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import PaneContentLoading from '../panes/paneContentLoading';
import Typography from '@material-ui/core/Typography';
import { getErrorText } from '../../controllers/api/actions/actionHelpers';
import { isAxiosError } from './axiosHelper';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock: {
      margin: theme.spacing(4, 0),
    },
  }),
);

/** A screen allowing the user to confirm email after registration */

const ConfirmRegister = (): JSX.Element => {
  const classes = useStyles();
  const [token] = useQueryParam('token', StringParam);
  const { t } = useTranslation();

  type ConfirmRegisterResponse = {
    project_id?: Codefy.Objects.Project['id'];
    comment_id?: Codefy.Objects.Comment['id'];
  };

  const config: AxiosRequestConfig = {
    url: '/api/v1/users/confirm_register',
    method: 'GET',
    params: { token },
  };

  const [{ response, loading, error }, refetch] = useAxios(config, { manual: true });

  useEffect(() => {
    if (token) {
      refetch(config);
    }
  }, [token]);

  const processResponseData = async (responseData: ConfirmRegisterResponse) => {
    if (responseData.comment_id) {
      window.location.href = ROUTE_SHARE_COMMENT + responseData.comment_id;
    } else if (responseData.project_id) {
      window.location.href = ROUTE_SHARE_PROJECT + responseData.project_id;
    } else {
      window.location.href = ROUTE_PANES_VIEWPORT;
    }
  };

  useEffect(() => {
    if (response?.data) {
      processResponseData(response.data);
    }
  }, [response?.data]);

  if (response) {
    return (
      <NoAuthScreenWrapper>
        <PaneContentLoading />
      </NoAuthScreenWrapper>
    );
  }

  return (
    <NoAuthScreenWrapper title={t('confirmRegister.emailConfirmation')}>
      <div className={classes.textBlock}>
        {loading && <PaneContentLoading />}
        {!token && (
          <Typography color="error" variant="body1" align="center">
            {t('confirmRegister.invalidTokenText')}
          </Typography>
        )}
        {isAxiosError(error) && (
          <Typography color="error" variant="body1" align="center">
            {getErrorText(error?.response?.data) || error?.name}
          </Typography>
        )}
      </div>
      {!loading && token && (
        <Grid container justify="center">
          <Grid item>
            <Link style={{ textDecoration: 'none' }} href="/">
              <Button variant="contained" color="primary">
                {t('confirmRegister.signIn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </NoAuthScreenWrapper>
  );
};

export default ConfirmRegister;
