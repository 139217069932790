import Codefy from '../../../../codefy';
import mime from 'mime-types';
import axios from 'axios';
import { DOCUMENTS_GET_URL } from '../../subscriptions/documents/documentsGet';

export const documentsDownload = async (document_id: Codefy.Objects.Document['id']) => {
  const { data } = await axios.get(DOCUMENTS_GET_URL, { params: { document_id } });
  const document = data;
  fetch('/api/v1/documents/download?document_id=' + document.id, {
    method: 'GET',
    headers: {
      'Content-Type': document.path.entry_mimetype || '',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = window.document.createElement('a');
      // Create blob link to download
      link.href = window.URL.createObjectURL(new Blob([blob]));

      const extension = mime.extension(document.path.entry_mimetype || '');
      link.setAttribute(
        'download',
        (document.path?.entry_name || document.id.toString()) + '.' + extension,
      );

      // Append to html link element page
      window.document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.remove();
    });
};
