import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CircularProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Codefy from '../../../../codefy';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';

export function getUploadBatchesListItemIcon(uploadBatch: Codefy.Objects.UploadBatch): JSX.Element {
  if (!!uploadBatch.num_documents_uploading && uploadBatch.num_documents_uploading > 0)
    return <CircularProgress size={22} thickness={5} />;

  if (!!uploadBatch.num_documents_unprocessed_ocr && uploadBatch.num_documents_unprocessed_ocr > 0)
    return <CircularProgress size={22} thickness={5} />;

  if (!!uploadBatch.num_documents_unprocessed && uploadBatch.num_documents_unprocessed > 0)
    return <CircularProgress size={22} thickness={5} />;

  if (!!uploadBatch.num_documents_failed && uploadBatch.num_documents_failed > 0)
    return <ErrorOutlineIcon />;

  if (
    !!uploadBatch.num_documents_error_upload_failed &&
    uploadBatch.num_documents_error_upload_failed > 0
  )
    return <ErrorOutlineIcon />;

  if (
    !!uploadBatch.num_documents_error_quota_exceeded &&
    uploadBatch.num_documents_error_quota_exceeded > 0
  )
    return <ErrorOutlineIcon />;

  if (!!uploadBatch.num_documents_already_exists && uploadBatch.num_documents_already_exists > 0)
    return <ErrorOutlineIcon />;

  if (
    !!uploadBatch.num_documents_error_format_not_supported &&
    uploadBatch.num_documents_error_format_not_supported > 0
  )
    return <ErrorOutlineIcon />;

  if (!!uploadBatch.num_documents_processed && uploadBatch.num_documents_processed > 0)
    return <CheckCircleOutlineIcon />;

  if (!!uploadBatch.num_documents_uploaded && uploadBatch.num_documents_uploaded > 0)
    return <CheckCircleOutlineIcon />;

  /* Should never happen? */
  return <CloudUploadIcon />;
}
