import { Route, Switch } from 'react-router-dom';

import AfterRegister from '../../components/auth/afterRegister';
import AnnotationUrlForwarder from './forwarders/annotationUrlForwarder';
import Codefy from '../../codefy';
import CommentUrlForwarder from './forwarders/commentUrlForwarder';
import ConfirmChangeEmail from '../../components/auth/confirmChangeEmail';
import ConfirmRegister from '../../components/auth/confirmRegister';
import ConfirmResetPassword from '../../components/auth/confirmResetPassword';
import { LoginAndRegister } from '../../components/auth/loginAndRegister';
import PanesViewport from '../../components/panes/panesViewport';
import ProjectUrlForwarder from './forwarders/projectUrlForwarder';
import PublicationUrlForwarder from './forwarders/publicationUrlForwarder';
import React from 'react';
import ResetPassword from '../../components/auth/resetPassword';
import SelectionUrlForwarder from './forwarders/selectionUrlForwarder';
import SessionsPlayer from '../../components/sessions/sessionsPlayer';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import MobileScreenBlocker from '../../components/mobileScreenBlocker';

export const ROUTE_LOGIN = '/login';
export const ROUTE_PANES_VIEWPORT = '/';
export const ROUTE_SHARE_PROJECT = '/share/project/';
export const ROUTE_SHARE_COMMENT = '/share/comment/';
export const ROUTE_SHARE_ANNOTATION = '/share/annotation/';

export default function Routes() {
  const userIsAdmin = useSelector((state: Codefy.State) => state.user?.admin);

  if (isMobile && !userIsAdmin) {
    return <MobileScreenBlocker />;
  }

  return (
    <Switch>
      <Route path="/after-register" component={AfterRegister} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/confirm-reset-password" component={ConfirmResetPassword} />
      <Route path="/confirm-change-email" component={ConfirmChangeEmail} />
      <Route path="/confirm-register" component={ConfirmRegister} />
      <Route path="/share/selection" component={SelectionUrlForwarder} />
      <Route path={ROUTE_SHARE_PROJECT + ':projectId'} component={ProjectUrlForwarder} />
      <Route path={ROUTE_SHARE_COMMENT + ':commentId'} component={CommentUrlForwarder} />
      <Route path={ROUTE_SHARE_ANNOTATION + ':annotationId'} component={AnnotationUrlForwarder} />
      <Route path="/p/:publicationUuid" exact component={PublicationUrlForwarder} />
      <Route path={ROUTE_LOGIN} component={LoginAndRegister} />
      {userIsAdmin && <Route path="/replay" exact component={SessionsPlayer} />}
      <Route path={ROUTE_PANES_VIEWPORT} exact component={PanesViewport} />
    </Switch>
  );
}
