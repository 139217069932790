import { Box, Popover } from '@material-ui/core';
import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';

import { GenericNestableMenuEntry } from './genericNestableMenuEntry';
import { GenericNestableMenuItem } from './genericNestableMenuItem';
import React from 'react';

/** Whatever is inside this wrapper will open a context menu (e.g. rename, delete, ...) when
 * right-clicked */
export default function GenericContextMenu({
  children,
  menuEntries,
  openOnLeftClick,
  id,
  hideChildrenIfEmpty,
}: {
  children: React.ReactElement | React.ReactElement[];
  menuEntries: GenericNestableMenuEntry[];
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
  id?: string;
  hideChildrenIfEmpty?: boolean;
}) {
  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'genericContextMenu' });

  const handleItemClick = () => {
    contextMenuState.close();
  };

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const menu = (
    <Popover
      id={id}
      {...bindMenu(contextMenuState)}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      onClick={onMenuClick}>
      <Box mt={1} mb={1}>
        {menuEntries.map((menuItem) => (
          <GenericNestableMenuItem
            key={menuItem.key}
            menuItem={menuItem}
            parentMenuOpen={bindMenu(contextMenuState).open}
            itemClickedCallback={handleItemClick}
          />
        ))}
      </Box>
    </Popover>
  );

  if (menuEntries.filter((menuEntry) => !menuEntry.disabled).length === 0) {
    if (hideChildrenIfEmpty) return null;
    return <>{children}</>;
  }

  return (
    <div
      {...bindContextMenu(contextMenuState)}
      onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (!contextState.mouseX) {
          setContextState({
            mouseX: event.clientX - 0,
            mouseY: event.clientY - 2,
          });
        }
        bindContextMenu(contextMenuState).onContextMenu(event);
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick) return;

        event.stopPropagation();
        event.preventDefault();

        if (!contextState.mouseX) {
          setContextState({
            mouseX: event.clientX - 0,
            mouseY: event.clientY - 2,
          });
        }

        contextMenuState.open();
      }}>
      {children}
      {menu}
    </div>
  );
}
