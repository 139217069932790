import { COLORS } from '../../globalThemeSettings';
import React from 'react';

/** Icon used for indicating the normal search mode */
export default function SearchIcon({ color }: { color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path
            fill={color || COLORS.primary}
            d="M16.85,14.31H16L15.75,14A6.57,6.57,0,0,0,17.36,9.7a6.66,6.66,0,1,0-6.66,6.66A6.57,6.57,0,0,0,15,14.75l.28.29v.81L20.44,21,22,19.44Zm-6.15,0A4.61,4.61,0,1,1,15.31,9.7,4.6,4.6,0,0,1,10.7,14.31Z"
          />
        </g>
      </g>
    </svg>
  );
}
