import { Box, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  useIsPaneOpen,
  useQueryParam_pdfViewer_documentId,
  useQueryParam_taglist_id,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import CommentsIcon from '../../../icons/commentsIcon';
import EntryContextMenuWrapper from './entryContextMenuWrapper';
import EntryFileTypeIcon from './entryFileTypeIcon';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import TagLabels from '../../../tagLabels/tagLabelsRenderer';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useHover from '../../../../hooks/useHover';
import { useOpenCommentsDialog } from '../../../dialogs/commentsDialog';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

// TODO: Refactor/Cleanup

export function EntriesListItem({
  entry,
  hideOpenInParentDirectory,
}: {
  entry: Codefy.Objects.Entry;
  hideOpenInParentDirectory?: boolean;
}) {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const [trashMode] = useQueryParam_trashMode();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const paneActions = usePaneActions();

  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const [taglist_id] = useQueryParam_taglist_id({
    taglistType: 'entry',
  });
  const [annotationTaglist_id] = useQueryParam_taglist_id({
    taglistType: 'annotation',
  });

  const annotationTaglistOpen = useIsPaneOpen(PaneKeys.annotationTaglist);
  const entryTaglistOpen = useIsPaneOpen(PaneKeys.entryTaglist);

  const [hoverRef, isHovered] = useHover();

  const openCommentsDialog = useOpenCommentsDialog();

  const onOpenEntry = () => {
    if (entry.directory) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entriesList,
        params: { entriesList_directoryId: entry.directory.id },
      });
    } else if (entry.document) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.pdfViewer,
        params: {
          pdfViewer_documentId: entry.document.id,
          /* Opening a document from the file list should always open on the first page, even if
          a "last read page" value is available */
          pdfViewer_page: 1,
        },
        reset: true,
      });
    } else if (entry.taglist) {
      if (entry.taglist.type === 'annotation') {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.annotationTaglist,
          params: { annotationTaglist_id: entry.taglist.id },
        });
        paneActions.closePanes([PaneKeys.annotationTag]);
      } else if (entry.taglist.type === 'entry') {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.entryTaglist,
          params: { entryTaglist_id: entry.taglist.id },
        });
        paneActions.closePanes([PaneKeys.entryTag]);
      }
    }
  };
  const name = useMemo(() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return entry.name;
      default:
        return entry.name;
    }
  }, [entry.name, entry.mimetype]);

  const icon = useMemo(() => {
    switch (entry.mimetype) {
      case 'inode/directory':
        return <FolderOpenIcon />;
      case 'inode/case':
        return <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />;
      default:
        return (
          <Box ml={'1px'}>
            <EntryFileTypeIcon entryMimetype={entry.mimetype} size={22} />
          </Box>
        );
    }
  }, [!!entry.directory, entry.id, entry.directory?.id, entry.mimetype]);

  return (
    <EntryContextMenuWrapper
      openOnLeftClick={trashMode}
      entry={entry}
      hideOpenInParentDirectory={hideOpenInParentDirectory}>
      <div ref={hoverRef}>
        <ListItem
          button
          divider
          selected={
            (entry.document && pdfViewer_documentId === entry.document?.id) ||
            (entryTaglistOpen && entry.taglist && taglist_id === entry.taglist.id) ||
            (annotationTaglistOpen && entry.taglist && annotationTaglist_id == entry.taglist.id)
          }
          disabled={entry.document && entry.document?.status !== 1}
          onClick={onOpenEntry}>
          <ListItemIcon className={globalClasses.narrowListItemIcon}>{icon}</ListItemIcon>
          <Box pr={4}>
            <Box className={globalClasses.listEntry} mb={-0.5} mt={0.5}>
              <span style={{ wordBreak: 'break-all' }}>
                {name}{' '}
                {entry.path?.project_id && entry.comments && entry.comments.length > 0 && (
                  <Tooltip title={t('comments.viewComments') || ''}>
                    <IconButton
                      size="small"
                      style={{ wordBreak: 'normal' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        openCommentsDialog({ entry_id: entry.id });
                      }}>
                      <CommentsIcon commentsCount={entry.comments.length} />
                    </IconButton>
                  </Tooltip>
                )}
              </span>
            </Box>

            {operationsTagsEnabled && entry.path?.project_id && (
              <Box mt={1}>
                <TagLabels
                  tagInstances={entry.tag_instances}
                  lineBreaks={false}
                  taglistType="entry"
                />
              </Box>
            )}
          </Box>

          <EntryContextMenuWrapper
            entry={entry}
            openOnLeftClick
            hideOpenInParentDirectory={hideOpenInParentDirectory}>
            <ListItemSecondaryAction style={{ float: 'right' }}>
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                style={isHovered ? {} : { opacity: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </EntryContextMenuWrapper>
        </ListItem>
      </div>
    </EntryContextMenuWrapper>
  );
}
