import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export type ProjectsListParams = {
  starred?: Codefy.Objects.Project['starred'];
  offset?: number;
  limit?: number;
  order_by?:
    | 'created_at'
    | 'created_by'
    | 'owned_by'
    | 'name'
    | 'accessed_at'
    | 'num_documents'
    | 'num_users'
    | 'trashed_at';
  descending?: boolean;
  name?: string;
  trashed?: boolean;
};

type ProjectsListReturnType = {
  projects: Codefy.Objects.Project[];
  num_projects: number;
};

export const PROJECTS_LIST_KEY_TYPE = 'projectsList';

export const projectsListKey = (params: ProjectsListParams): [string, {} | undefined] => [
  PROJECTS_LIST_KEY_TYPE,
  params,
];

export const useProjectsList = (params: ProjectsListParams) =>
  useCreateInfiniteQuery<ProjectsListReturnType>(
    projectsListKey(params),
    100,
    '/api/v1/projects/list',
    'GET',
    params,
    'projects',
  );
