import { GenericCommentParameters } from '../comments/commentsList';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import { useQuery } from 'react-query';

export enum CommentSubscriptionLevel {
  'all' = 'all',
  'mentions' = 'mentions',
  'none' = 'none',
}

export const COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE = 'commentSubscriptionsGet';

export const COMMENT_SUBSCRIPTIONS_GET_URL = '/api/v1/comment_subscriptions/get';

export const commentSubscriptionsGetKey = (
  params: GenericCommentParameters,
): [string, GenericCommentParameters] => [COMMENT_SUBSCRIPTIONS_GET_KEY_TYPE, params];

export const useCommentSubscriptionsGet = (params: GenericCommentParameters) => {
  return useQuery(commentSubscriptionsGetKey(params), async () => {
    const { data } = await axios.get(COMMENT_SUBSCRIPTIONS_GET_URL, {
      params,
      paramsSerializer: fastApiParamsSerializer,
    });
    return data as CommentSubscriptionLevel;
  });
};
