import axios from 'axios';
import { useQuery } from 'react-query';

export type SessionsListParams = {
  limit?: number;
};

export type SessionsListResponse = {
  sessions: { session_key: string; created_at: string; count: number }[];
};

export const SESSIONS_LIST_KEY_TYPE = 'sessionsList';

export const sessionsListKey = (params: SessionsListParams): [string, Record<string, any>] => [
  SESSIONS_LIST_KEY_TYPE,
  params,
];

export const useSessionsList = (params: SessionsListParams) =>
  useQuery(sessionsListKey(params), async () => {
    const { data } = await axios.get<SessionsListResponse>('/api/v1/sessions/list', { params });
    return data;
  });
