import {
  Badge,
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import {
  useIsPaneOpen,
  useQueryParam_panes,
  useQueryParam_tag_tagId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import CommentsIcon from '../../../icons/commentsIcon';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericContextMenu from '../../../menus/genericContextMenu';
import IconButton from '@material-ui/core/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import PredictionIndicator from './predictionIndicator';
import React from 'react';
import TagLabels from '../../../tagLabels/tagLabelsRenderer';
import clsx from 'clsx';
import useHover from '../../../../hooks/useHover';
import { useLocalStorage } from '@rehooks/local-storage';
import { useOpenCommentsDialog } from '../../../dialogs/commentsDialog';
import { usePaneActions } from '../../usePaneActions';
import { useSelector } from 'react-redux';
import useTagMenuEntries from '../tag/useTagMenuEntries';
import { useTranslation } from 'react-i18next';

export function TagListTag({
  project_id,
  tag,
  writePermission,
  addPermission,
  owner,
  hideButtons,
}: {
  project_id?: Codefy.Objects.Project['id'];
  tag: Codefy.Objects.Tag;
  writePermission: boolean;
  addPermission: boolean;
  owner?: Codefy.Objects.User;
  hideButtons?: boolean;
}) {
  const userId = useSelector((state: Codefy.State) => state.user?.id);

  writePermission = writePermission || tag?.owned_by?.id === userId;
  addPermission = addPermission || tag?.owned_by?.id === userId;

  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const tagMenuEntries = useTagMenuEntries({
    project_id,
    tag,
    full: true,
    writePermission,
    addPermission,
    owner,
  });
  const [panes] = useQueryParam_panes();
  const paneActions = usePaneActions();
  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType: tag.taglist_type });
  const [hoverRef, isHovered] = useHover();
  const [collapsedTags, setCollapsedTags] = useLocalStorage<number[]>('collapsed_tags');

  const openCommentsDialog = useOpenCommentsDialog();

  const tagInstancePredictionsOpen = useIsPaneOpen(PaneKeys.tagInstancePredictions);

  const tagIsCollapsed = collapsedTags?.includes(tag.id);
  const toggleTagCollapsed: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (tagIsCollapsed) {
      setCollapsedTags(collapsedTags?.filter((tagId) => tagId !== tag.id) || []);
    } else {
      setCollapsedTags([...(collapsedTags || []), tag.id]);
    }
  };

  const onClick = () => {
    if (tag.taglist_type === 'annotation') {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.annotationTaglist,
        params: {
          annotationTaglist_id: tag.taglist_id,
        },
      });
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.annotationTag,
        params: {
          annotationTag_tagId: tag.id,
        },
      });
      if (tagInstancePredictionsOpen) {
        paneActions.addOrUpdatePane({
          paneKey: tagInstancePredictionsOpen
            ? PaneKeys.tagInstancePredictions
            : PaneKeys.annotationTag,
          params: {
            annotationTag_tagId: tag.id,
          },
        });
      }
      //paneActions.closePane(PaneKeys.annotationsList);
    } else if (tag.taglist_type === 'entry') {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entryTaglist,
        params: {
          entryTaglist_id: tag.taglist_id,
        },
      });
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.entryTag,
        params: {
          entryTag_tagId: tag.id,
        },
      });
      //paneActions.closePane(PaneKeys.annotationsList);
    }
  };

  return (
    <>
      <GenericContextMenu key={tag.id} menuEntries={tagMenuEntries}>
        <Box borderLeft={`5px solid ${tag.color || COLORS.defaultTagColor}`}>
          <ListItem
            button
            divider
            selected={
              tag.id === tag_tagId &&
              (panes.includes(PaneKeys.annotationTag) ||
                panes.includes(PaneKeys.entryTag) ||
                panes.includes(PaneKeys.tagInstancePredictions))
            }
            key={tag.id}
            onClick={onClick}
            innerRef={hoverRef}>
            {tag.taglist_type === 'annotation' && tagInstancePredictionsOpen && (
              <ListItemIcon className={clsx(globalClasses.narrowListItemIcon)}>
                <PredictionIndicator tag_id={tag.id} />
              </ListItemIcon>
            )}

            <ListItemIcon className={clsx(globalClasses.narrowListItemIcon)}>
              <Badge
                badgeContent={tag.num_tag_instances}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {tag.taglist_type === 'annotation' && <LabelOutlinedIcon />}
                {tag.taglist_type === 'entry' && <LabelIcon />}
              </Badge>
            </ListItemIcon>

            <ListItemText
              primary={
                <span>
                  <Box pr={7}>
                    <Grid container alignItems="center" spacing={2}>
                      {tag?.cell && (
                        <Grid item>
                          <span className={globalClasses.textLight}>{tag?.cell}</span>
                        </Grid>
                      )}
                      <Grid item className={globalClasses.listEntry}>
                        {tag.name}
                        {tag.annotation_tag_instances && tag.annotation_tag_instances.length > 0 && (
                          <>
                            <br />
                            <TagLabels
                              tagInstances={tag.annotation_tag_instances}
                              lineBreaks={false}
                              taglistType="entry"
                            />
                          </>
                        )}
                        {project_id && tag.comments && tag.comments.length > 0 && (
                          <Box display="inline" position="absolute">
                            <Tooltip title={t('comments.viewComments') || ''}>
                              <IconButton
                                size="small"
                                style={{ marginTop: '-4px', marginLeft: '3px' }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  openCommentsDialog({ tag_id: tag.id });
                                }}>
                                <CommentsIcon commentsCount={tag.comments.length} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  {tagMenuEntries.length > 0 && !hideButtons && (
                    <ListItemSecondaryAction>
                      <Grid container wrap="nowrap" onClick={(event) => event.stopPropagation}>
                        {tag.childTags && tag.childTags.length > 0 && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={toggleTagCollapsed}>
                            {tagIsCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                          </IconButton>
                        )}
                        <GenericContextMenu
                          key={tag.id}
                          menuEntries={tagMenuEntries}
                          openOnLeftClick
                          hideChildrenIfEmpty>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            style={isHovered ? {} : { opacity: 0 }}>
                            <MoreVertIcon />
                          </IconButton>
                        </GenericContextMenu>
                      </Grid>
                    </ListItemSecondaryAction>
                  )}
                </span>
              }
            />
          </ListItem>
        </Box>
      </GenericContextMenu>
      {tag.childTags && !tagIsCollapsed && (
        <Box ml={2}>
          {tag.childTags.map((childTag) => (
            <TagListTag
              key={childTag.id}
              project_id={project_id}
              tag={childTag}
              writePermission={writePermission}
              addPermission={addPermission}
              owner={owner}
              hideButtons={hideButtons}
            />
          ))}
        </Box>
      )}
    </>
  );
}
