import { useDispatch, useSelector } from 'react-redux';

import Codefy from '../../../../codefy';
import { EntriesListRequestParams } from '../entries/entriesList';
import { ProjectsListParams } from '../projects/projectsList';
import { TagInstancesListParams } from '../taglists/tags/tagInstances/tagInstancesList';
import { TagsListParams } from '../taglists/tags/tagsList';
import { userChangeSettings } from '../../actions/user/userChangeSettings';

/** Helper function that gets the user setting from the redux store and sends changes to the backend */
const useUserSetting = <T>(setting: string, defaultValue?: T) => {
  const dispatch = useDispatch();

  const value = useSelector(
    (state: Codefy.State) => state.user?.settings?.[setting] ?? defaultValue,
  ) as T | undefined;

  const setValue = (newValue: T | undefined) =>
    dispatch(userChangeSettings({ [setting]: newValue }));

  return [value, setValue] as const;
};

export const useUserSetting_taglist_descending = () => useUserSetting('taglist_descending', false);

export const useUserSetting_taglist_orderBy = () =>
  useUserSetting<TagsListParams['order_by']>('taglist_orderBy', 'name');

export const useUserSetting_taglist_showPredictions = () =>
  useUserSetting('taglist_showPredictions', true);

export const useUserSetting_tag_descending = () => useUserSetting('tag_descending', true);

export const useUserSetting_tag_orderBy = () =>
  useUserSetting<TagInstancesListParams['order_by']>('tag_orderBy', 'created_at');

export const useUserSetting_tag_filter = () => useUserSetting<'all' | 'own'>('tag_filter', 'all');

export const useUserSetting_entries_descending = () => useUserSetting('entries_descending', false);

export const useUserSetting_entries_filterByMimetype = () =>
  useUserSetting<Codefy.Objects.EntryMimeType[]>('entries_filterByMimetype');

export const useUserSetting_entries_orderBy = () =>
  useUserSetting<EntriesListRequestParams['order_by']>('entries_orderBy', 'mimetype');

export const useUserSetting_projects_descending = () => useUserSetting('projects_descending', true);

export const useUserSetting_projects_filterByOwner = () =>
  useUserSetting<string>('projects_filterByOwner');

export const useUserSetting_projects_orderBy = () =>
  useUserSetting<ProjectsListParams['order_by']>('projects_orderBy', 'accessed_at');

export const DEFAULT_PDF_SCALE = 0.9;

export const useUserSetting_pdf_scale = () => useUserSetting('pdf_scale', DEFAULT_PDF_SCALE);

export const useUserSetting_comments_filter = () =>
  useUserSetting<'all' | 'own' | 'mentions'>('comments_filter', 'all');

export const useUserSetting_comments_descending = () =>
  useUserSetting<boolean>('comments_descending', true);

export type SnippetVariant = 'text' | 'image';

export const useUserSetting_searchDropdown_snippetVariant = () =>
  useUserSetting<SnippetVariant>('searchDropdown_snippetVariant', 'image');

export const useUserSetting_searchPane_snippetVariant = () =>
  useUserSetting<SnippetVariant>('searchPane_snippetVariant', 'image');

export const useUserSetting_assistantResults_snippetVariant = () =>
  useUserSetting<SnippetVariant>('assistantResults_snippetVariant', 'image');

export const useUserSetting_annotations_snippetVariant = () =>
  useUserSetting<SnippetVariant>('annotations_snippetVariant', 'image');
