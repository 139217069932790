import { ROUTE_LOGIN, ROUTE_PANES_VIEWPORT } from '../routes';
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import { GLOBAL_QUERY_PARAMS } from '../../useGlobalQueryParams';
import NoAuthScreenWrapper from '../../../components/auth/noAuthScreenWrapper';
import PaneContentLoading from '../../../components/panes/paneContentLoading';
import { PaneKeys } from '../../../components/panes/paneTypes/paneKeys';
import { fastApiParamsSerializer } from '../../api/subscriptionHelpers';
import { useAnnotationsGet } from '../../api/subscriptions/annotations/annotationsGet';

interface MatchParams {
  annotationId: string;
}

/** Forwards a user to an annotation */
export default function AnnotationUrlForwarder({ match }: RouteComponentProps<MatchParams>) {
  const annotationId = parseInt(match.params.annotationId);
  const { data: annotation, isError } = useAnnotationsGet({ annotation_id: annotationId });
  const history = useHistory();

  useEffect(() => {
    if (!annotation) return;

    const url =
      ROUTE_PANES_VIEWPORT +
      '?' +
      fastApiParamsSerializer({
        [GLOBAL_QUERY_PARAMS.panes]: [
          PaneKeys.projectsList,
          PaneKeys.entriesList,
          PaneKeys.pdfViewer,
        ],
        [GLOBAL_QUERY_PARAMS.entriesList_directoryId]: annotation?.path.directory_id,
        [GLOBAL_QUERY_PARAMS.pdfViewer_documentId]: annotation.path.document_id,
        [GLOBAL_QUERY_PARAMS.pdfViewer_boxes]: JSON.stringify(annotation.boxes),
        [GLOBAL_QUERY_PARAMS.pdfViewer_highlightAnnotationWithId]: annotation.id,
      });

    history.replace(url);
  }, [!!annotation]);

  if (isError) return <Redirect to={ROUTE_LOGIN + '?nextUrl=' + window.location.href} />;

  return (
    <NoAuthScreenWrapper>
      <PaneContentLoading />
    </NoAuthScreenWrapper>
  );
}
