import Codefy from '../../../../codefy';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';

export default function directoriesDownload(
  directory_name: string,
  directory_id: Codefy.Objects.Directory['id'],
) {
  fetch('/api/v1/directories/download?' + fastApiParamsSerializer({ directory_id }), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/zip',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = window.document.createElement('a');
      // Create blob link to download
      link.href = window.URL.createObjectURL(new Blob([blob]));

      link.setAttribute('download', directory_name + '.zip');

      // Append to html link element page
      window.document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.remove();
    });
}
