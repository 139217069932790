import { Box, makeStyles } from '@material-ui/core';
import {
  useQueryParam_comments_directoryId,
  useQueryParam_comments_documentId,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import {
  useUserSetting_comments_descending,
  useUserSetting_comments_filter,
} from '../../../../controllers/api/subscriptions/users/userSettings';

import Codefy from '../../../../codefy';
import { Comment } from './comment';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentEmpty from '../../paneContentEmpty';
import PaneContentLoading from '../../paneContentLoading';
import React from 'react';
import { useCommentsList } from '../../../../controllers/api/subscriptions/comments/commentsList';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useMeasure } from 'react-use';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

const useStyles = makeStyles(() => ({
  /** The overflow is very important, otherwise you cannot have more entries than the height of the
   * screen! */
  root: { height: '100%' },
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
}));

export default function CommentsList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const userId = useSelector((state: Codefy.State) => state.user?.id);
  const { id: currentCaseId } = useCurrentCase();

  const [comments_filter] = useUserSetting_comments_filter();
  const [comments_descending] = useUserSetting_comments_descending();
  const [comments_directoryId] = useQueryParam_comments_directoryId();
  const [comments_documentId] = useQueryParam_comments_documentId();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();

  const {
    data: commentsPages,
    isFetching,
    isFetchedAfterMount,
    fetchNextPage,
    hasNextPage,
  } = useCommentsList({
    case_id: currentCaseId,
    user_id: comments_filter === 'own' ? userId : undefined,
    assignee_id: comments_filter === 'mentions' ? userId : undefined,
    descending: comments_descending,
    directory_ids: comments_directoryId ? [comments_directoryId] : undefined,
    document_ids: comments_documentId ? [comments_documentId] : undefined,
    trashed: trashMode,
  });

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const comments =
    commentsPages?.pages.reduce((acc: Codefy.Objects.Comment[], cur) => {
      for (const comment of cur.comments) {
        acc.push(comment);
      }
      return acc;
    }, []) || [];

  if (!isFetchedAfterMount && comments.length === 0) return <PaneContentLoading />;

  if (!isFetching && comments.length === 0)
    return (
      <PaneContentEmpty
        text={trashMode ? trashModeStrings('binEmpty') : t('comments.noComments')}
      />
    );

  return (
    <div className={classes.root} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={comments?.length || 0}
        next={fetchNextPage}
        height={height - 1}
        hasMore={!!hasNextPage}
        loader={<PaneContentLoading />}>
        {comments.map((comment) => (
          <Box key={comment.id} ml={1} mr={1}>
            <Comment comment={comment} showGoToButton />
          </Box>
        ))}
      </InfiniteScroll>
    </div>
  );
}
